import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import { formatDecimal } from '../utils/formatUtils/utils'
import { globalErrorHandler } from './commonSlice'

interface CashAccountState {
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  cashAccount: any
  cashExternalAccount: any
  UPHOLD_ACCESSES_COLUMNS: any[]
  LINKED_ACCOUNTS_COLUMNS: any[]
}

const initialState: CashAccountState = {
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  cashAccount: {},
  cashExternalAccount: {},
  UPHOLD_ACCESSES_COLUMNS: [],
  LINKED_ACCOUNTS_COLUMNS: []
}

export const getCashAccount = createAsyncThunk(
  'getCashAccount',
  async ({ id }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await privateAPI.get(`/queries/cash_accounts/${id}`)
      return response.data
    } catch (e) {
      dispatch(globalErrorHandler())
      return rejectWithValue(e)
    }
  },
)

export const getUpholdAccounts = createAsyncThunk(
  'getUpholdAccounts',
  async ({ id }: any, { rejectWithValue, dispatch }) => {
    const obj = {
      cashAccountId: { filterValues: [+id], include: false },
      upholdAccessId : {},
      legalName : {},
      amlStatus : {},
      isActive : {},
      deactivatedReason : {},
      deactivatedAt : {},
      identityVerified : {},
      balanceUsd : {},
      notes : {},
      createdAt : {}
    }
    try {
      const response = await privateAPI.post(`/queries/uphold_accesses`, obj)
      return response.data
    } catch (e) {
      dispatch(globalErrorHandler())
      return rejectWithValue(e)
    }
  },
)

export const saveCashAccount = createAsyncThunk(
  'saveCashAccount',
  async ({ id, notes }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/queries/cash_accounts/${id}`, { notes })
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const initiateWithdraw = createAsyncThunk(
  'initiateWithdraw',
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/cash_external_accounts/${id}/withdraw`, data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const transferFunds = createAsyncThunk(
  'transferFunds',
  async ({id, data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/cashAccount/${id}/transferUsingLiquidshares`,  data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getCashAccountLinkedAccounts = createAsyncThunk(
  'getCashAccountLinkedAccounts',
  async ({ id }: any) => {
    const data = {
      source: {},
      createdAt: {},
      bankName: {},
      accountNumberSafe: {},
      amlStatus: {},
      cashAccountId: { filterValues: [+id] },
      cashExternalAccountId: {},
      isActive: { sortIndex: 1, sortAsc: false },
      achDepositAllowed: {},
      achWithdrawAllowed: {},
      action_CreateWithdraw: {},
      wireWithdrawAllowed: {},
    }
    try {
      const response = await privateAPI.post(
        '/queries/cash_external_accounts',
        data,
      )
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const cashAccountSlice = createSlice({
  name: 'cashAccount',
  initialState,
  reducers: {
    resetCashAccount: (state) => {
      state.cashAccount = null
    },
    resetCashExternalAccount: (state) => {
      state.cashExternalAccount = null
    },
    setCashExternalAccount: (state, { payload }) => {
      state.cashExternalAccount = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCashAccount.fulfilled, (state, { payload }: any) => {
      const obj = payload?.rows[0] || {}
      const cashTransactions = obj?.cashTransactions || []
      cashTransactions?.sort((a: any, b: any) => b.date - a.date)
      obj.notes= obj?.notes ? JSON.parse(obj?.notes) : []
      state.cashAccount = obj
      cashTransactions?.forEach(
        (transaction: any) =>
          (transaction.amount = formatDecimal(transaction.amount)),
      )
    })
    builder.addCase(
      getCashAccountLinkedAccounts.fulfilled,
      (state, { payload }: any) => {
        const { rows, columns } = payload || {}
        if (rows && rows.length) {
          rows.forEach((row: any) => {
            if (
              row?.amlStatus !== 'REJECTED' &&
              row.isActive &&
              (row.achWithdrawAllowed || row.wireWithdrawAllowed)
            ) {
              row.canWithdraw = true
            }
          })
          const cols = columns?.map((col: any) => {
            col.key = col.name
            if (col?.type === 'Boolean') {
              col.renderer = 'Boolean'
            }
            return col
          })
          state.LINKED_ACCOUNTS_COLUMNS = cols
          state.cashAccount.linkedAccounts = rows || []
        } else {
          state.cashAccount.linkedAccounts = []
        }
      },
    )
    builder.addCase(getUpholdAccounts.fulfilled, (state, { payload }) => {
      const { rows, columns } = payload || {}
      if (rows && rows.length) {
        const arr = rows?.map((row: any) => {
          const data = { ...row }
          if (row?.notes) {
            data.notes = JSON.parse(row?.notes || [])
          }
          return data
        })
        state.cashAccount.upholdAccounts = arr || []
        const cols = columns?.map((col: any) => {
          col.key = col.name
          if (col.key === 'notes') {
            col.renderer = 'Notes'
          }
          if (col.name === 'isActive' || col.name === 'identityVerified') {
            col.renderer = 'Boolean'
          }
          return col
        })
        state.UPHOLD_ACCESSES_COLUMNS = cols
      } else {
        state.cashAccount.upholdAccounts = []
      }
    })
  },
})

export const {
  resetCashAccount,
  resetCashExternalAccount,
  setCashExternalAccount,
} = cashAccountSlice.actions

export default cashAccountSlice.reducer
