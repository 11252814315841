import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import api from '../../APIs/private'
import {
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import { formatDecimal, formatToPT } from '../../utils/formatUtils/utils'
import { getUpholdAccess } from '../upholdAccesses/UpholdAccessDetail'
import { getUser } from '../../utils/commonActions'
import Select from '../assets/components/Select'

const getPromoFulfillment = async (id: string) => {
  const response = await api.get(`/queries/promo_fulfillments/${id}`)
  return response?.data?.rows?.[0]
}

const PromoFulfillmentDetail: any = () => {
  const queryClient = useQueryClient()
  const { id }: any = useParams()
  const methods = useForm()
  const {
    reset,
    handleSubmit,
    register,
    formState: { isDirty },
  } = methods

  const { data, isInitialLoading: promoFulfillmentLoading } = useQuery({
    queryKey: ['promo_fulfillments', id],
    queryFn: async () => await getPromoFulfillment(id),
    enabled: +id > 0,
  })

  const { amount, createdAt, promoFulfillmentId, userId, upholdAccessId } =
    data || {}

  const { data: user, isInitialLoading: userLoading } = useQuery({
    queryKey: ['promo_fulfillments_users', id],
    queryFn: () => getUser(userId),
    enabled: +userId > 0,
  })

  const { fullName } = user || {}

  const { data: upholdAccess, isInitialLoading: upholdAccessLoading } = useQuery({
    queryKey: ['promo_fulfillments_uphold_accesses', id],
    queryFn: () => getUpholdAccess(upholdAccessId),
    enabled: +upholdAccessId > 0,
  })

  const { cashAccountOwnerName } = upholdAccess || {}

  const savePromoFulfillment = useMutation({
    mutationFn: async (data) =>
      await api.post(`/queries/promo_fulfillments/${id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['promo_fulfillments', id],
      })
    },
  })

  useEffect(() => {
    reset(data)
  }, [data, reset])

  const btnLoading = savePromoFulfillment.isLoading

  if (promoFulfillmentLoading || userLoading || upholdAccessLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(savePromoFulfillment.mutate)}>
          <div className="d-flex justify-content-between">
            <h3>Promo Fulfillment No.{promoFulfillmentId}</h3>
            <div className="d-flex">
              <CLoadingButton
                disabled={!isDirty}
                className="btn-success"
                loading={btnLoading}
                type="submit"
              >
                Save
              </CLoadingButton>
            </div>
          </div>
          <h4>Promo Fulfillment Information</h4>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>Created At</CFormLabel>
              <CFormInput disabled value={formatToPT(createdAt, 'MM/DD/YY')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Currency Type</CFormLabel>
              <CFormInput disabled {...register('currencyType')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Amount</CFormLabel>
              <CFormInput disabled value={formatDecimal(amount || 0)} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Promo Name</CFormLabel>
              <CFormInput disabled {...register('promoName')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Status</CFormLabel>
              <CFormSelect
                disabled={savePromoFulfillment.isLoading}
                {...register('status')}
              >
                <option value="NEEDS_REVIEW">Needs Review</option>
                <option value="REJECTED">Rejected</option>
                <option value="FAILED">Failed</option>
                <option value="COMPLETED">Completed</option>
              </CFormSelect>
            </div>
            {user && (
              <div className="col-md-3">
                <CFormLabel>User</CFormLabel>
                <Select watchValue={fullName} id={userId} query="users" readOnly />
              </div>
            )}
            {upholdAccess && (
              <div className="col-md-3">
                <CFormLabel>Uphold Access</CFormLabel>
                <Select watchValue={cashAccountOwnerName} id={upholdAccessId} query="uphold_accesses" readOnly />
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default PromoFulfillmentDetail
