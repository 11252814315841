import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../features/hooks'
import { enableUserMfaEmail, getUser } from '../../../../features/usersSlice'
import { RootState } from '../../../../store'
import UserMFA from './UserMFA'

const MFAs: LabelType[] = [{ type: 'EMAIL' }, { type: 'SMS' }, { type: 'TOTP' }]

type LabelType = {
  type: string
}

const LABEL: any = {
  TOTP: 'Authenticator App',
  EMAIL: 'Email',
  SMS: 'Phone Number',
}

const UserMFATab: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    user: { userMfas, userId },
  } = useAppSelector((state: RootState) => state.users)

  const results = MFAs.filter(
    ({ type: id1 }) => !userMfas.some(({ type: id2 }) => id2 === id1),
  )

  const isUserMfas = userMfas.map((mfa: LabelType) => ({
    ...mfa,
    isUsers: true,
  }))

  const handleEnableUserMFaEmail = async () => {
    await dispatch(enableUserMfaEmail({ id: userId }))
    await dispatch(getUser({ id: userId }))
  }

  if (!userMfas || userMfas.length === 0) {
    return null
  }

  const items = [
    isUserMfas.find((mfa: LabelType) => mfa.type === 'EMAIL') ||
      results.find((mfa: LabelType) => mfa.type === 'EMAIL'),
    isUserMfas.find((mfa: LabelType) => mfa.type === 'TOTP') ||
      results.find((mfa: LabelType) => mfa.type === 'TOTP'),
    isUserMfas.find((mfa: LabelType) => mfa.type === 'SMS') ||
      results.find((mfa: LabelType) => mfa.type === 'SMS'),
  ]

  return (
    <div>
      <div className="mt-4 w-50 mx-1">
        {items.map(({ type, isUsers }: any, i: number) => (
          <UserMFA
            type={type}
            key={i}
            LABEL={LABEL}
            isUsers={isUsers}
            handleEnableUserMFaEmail={handleEnableUserMFaEmail}
          />
        ))}
      </div>
    </div>
  )
}

export default UserMFATab
