import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import SellOrderDetailForm from './SellOrderDetailForm'
import {
  getSellOrder,
  getSellOrderTransactions,
  resetSellOrder,
  saveSellOrder,
} from '../../features/sellOrderSlice'
import { getUser, resetUser } from '../../features/usersSlice'
import { getEntity } from '../../features/entitySlice'

const SellOrderDetail: React.FC = () => {
  const { id }: any = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { sellOrder } = useAppSelector((state: RootState) => state.sellOrders)
  const methods = useForm()

  const { reset } = methods

  useEffect(() => {
    const getSellOrderById = async () => {
      const res = await dispatch(getSellOrder({ id }))
      const data = res?.payload?.rows?.[0] || {}
      await dispatch(getSellOrderTransactions({ id: data.sellOrderId }))
      if (data.userId) {
        await dispatch(getUser({ id: data.userId }))
      } else if (data.entityId) {
        await dispatch(getEntity({ id: data.entityId }))
      }
      setLoading(false)
    }
    getSellOrderById()
    return () => {
      dispatch(resetSellOrder())
      dispatch(resetUser())
    }
  }, [id, dispatch])

  useEffect(() => reset(sellOrder), [reset, sellOrder])

  const onSubmit = async (data: any) => {
    delete data.costBasis
    setBtnLoading(true)
    if (data.notes && data.notes.length) {
      data.notes.forEach((note: any) => delete note.updated)
    }
    data.notes = JSON.stringify(data.notes)
    await dispatch(saveSellOrder({ id, data }))
    await dispatch(getSellOrder({ id }))
    setBtnLoading(false)
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <FormProvider {...methods}>
      <PageLayout>
        <SellOrderDetailForm btnLoading={btnLoading} onSubmit={onSubmit} />
      </PageLayout>
    </FormProvider>
  )
}

export default SellOrderDetail
