import {
  CButton,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import React from 'react'

interface WarningModalProps {
  header: string
  body: string
  abandon?: string
  proceed: string
  abandonAction?: () => void
  proceedAction: () => void
  open: boolean
  loading?: boolean
}

const WarningModal: React.FC<WarningModalProps> = ({
  header,
  body,
  abandon,
  proceed,
  abandonAction,
  proceedAction,
  open,
  loading = false,
}: WarningModalProps) => {
  return (
    <CModal
      visible={open}
      alignment="center"
      onClose={abandonAction ? abandonAction : proceedAction}
    >
      <CModalHeader>{header}</CModalHeader>
      <CModalBody>{body}</CModalBody>
      <CModalFooter>
        {abandon && <CButton onClick={abandonAction}>{abandon}</CButton>}
        <CLoadingButton
          className={`${!abandon ? 'btn-primary' : 'btn-danger'}`}
          onClick={proceedAction}
          loading={loading}
        >
          {proceed}
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  )
}

export default WarningModal
