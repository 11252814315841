import React from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import CashExternalAccountDetailForm from './CashExternalAccountDetailForm'
import privateAPI from '../../APIs/private'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { uploadDocs } from '../../utils/commonActions'

type ColumnType = {
  label: string
  name: string
  type: string
}

type CashExternalAccountType = {
  cashExternalAccountId: number
  cashAccountId: number
  cashAccountOwnerName: string
  userId?: number
  entityId?: number
  creditAmount: number
  debitAmount: number
  amlStatus: string
  source: string
  achDepositAllowed: boolean
  achWithdrawAllowed: boolean
  wireWithdrawAllowed: boolean
  isActive: boolean
  notes: string
  action_CreateWithdraw: boolean
  createdAt: number
  rowCount: number
  cashTransactions: any[]
  documents: any[]
}

type CashExternalAccountObject = {
  data: {
    columns: ColumnType[]
    rows: CashExternalAccountType[]
  }
}

const getCashExternalAccount = async (
  id: string,
): Promise<CashExternalAccountObject> => {
  return await privateAPI.get(`/queries/cash_external_accounts/${id}`)
}

const saveCashExternalAccount = async (id: string, data: any) =>
  await privateAPI.post(`/queries/cash_external_accounts/${id}`, data)

const getEntity = async (id: number | undefined) => {

  return await privateAPI.get(`/queries/entities/${id}`)
}
const getCashAccount = async (id: number | undefined) =>
  await privateAPI.get(`/queries/cash_accounts/${id}`)

const getUser = async (id: number | undefined) => {
  const obj = {
    userId: { filterMin: id, filterMax: id },
    fullName: {},
  }
  return await privateAPI.post('/queries/users/', obj)
}

const CashExternalAccountDetail: React.FC = () => {
  const { id }: any = useParams()
  const queryClient = useQueryClient()
  const methods = useForm()
  const { reset } = methods

  const {
    data: cashExternalAccountData,
    isInitialLoading: cashExternalAccountLoading,
  } = useQuery({
    queryKey: ['cash_external_accounts', id],
    queryFn: () => getCashExternalAccount(id),
    enabled: +id > 0,
  })

  const cashExternalAccount: CashExternalAccountType | undefined =
    cashExternalAccountData?.data.rows?.[0] || undefined

  const { data: userData, isInitialLoading: userLoading }: any = useQuery({
    queryKey: ['cash_external_account_user', cashExternalAccount?.userId],
    queryFn: () => getUser(cashExternalAccount?.userId),
    enabled: !!cashExternalAccount && !!cashExternalAccount?.userId,
  })

  const user = userData?.data?.rows?.[0] || undefined

  const { data: cashAccountData, isInitialLoading: cashAccountLoading } =
    useQuery({
      queryKey: [
        'cash_external_account_cash_account',
        cashExternalAccount?.cashAccountId,
      ],
      queryFn: () => getCashAccount(cashExternalAccount?.cashAccountId),
      enabled: !!cashExternalAccount && !!cashExternalAccount?.cashAccountId,
    })

  const cashAccount = cashAccountData?.data?.rows?.[0] || undefined

  const { data: entityData, isInitialLoading: entityLoading } = useQuery({
    queryKey: ['cash_external_account_entity', cashExternalAccount?.entityId],
    queryFn: () => getEntity(cashExternalAccount?.entityId),
    enabled: !!cashExternalAccount && !!cashExternalAccount?.entityId,
  })

  const entity = entityData?.data?.rows?.[0] || undefined
  useEffect(() => {
    const data: CashExternalAccountType | undefined =
      cashExternalAccount || undefined
    if (data?.notes && typeof data.notes === 'string') {
      data.notes = JSON.parse(data.notes)
    }
    reset(data)
  }, [reset, cashExternalAccount])

  const saveCashExternalAccountMutation = useMutation({
    mutationFn: async (data: any) => {
      if (data.notes && data.notes.length) {
        data.notes.forEach((note: any) => delete note.updated)
      }
      data.notes = JSON.stringify(data.notes)
      await saveCashExternalAccount(id, data)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['cash_external_accounts', id],
      }),
  })

  const uploadDocsMutation = useMutation({
    mutationFn: async ({
      e,
      type,
    }: {
      e: React.ChangeEvent<HTMLInputElement>
      type: string
    }) => {
      await uploadDocs({
        acceptedFile: e?.target?.files?.[0],
        fileName: e?.target?.files?.[0].name,
        type,
        cashExternalAccountId: id,
      })
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['cash_external_accounts', id],
      }),
  })

  const loading =
    cashExternalAccountLoading ||
    userLoading ||
    entityLoading ||
    cashAccountLoading

  const btnLoading =
    uploadDocsMutation.isLoading || saveCashExternalAccountMutation.isLoading

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <FormProvider {...methods}>
      <PageLayout>
        <CashExternalAccountDetailForm
          onSubmit={saveCashExternalAccountMutation.mutate}
          btnLoading={btnLoading}
          fileSelectedHandler={(
            e: React.ChangeEvent<HTMLInputElement>,
            type: string,
          ) => uploadDocsMutation.mutate({ e, type })}
          cashExternalAccount={cashExternalAccount}
          user={user}
          entity={entity}
          cashAccount={cashAccount}
        />
      </PageLayout>
    </FormProvider>
  )
}

export default CashExternalAccountDetail
