import React from 'react'

interface FilterNotEqualProps {
  handleChange: (event: any) => void
  value: boolean
}

const FilterNotEqual: React.FC<FilterNotEqualProps> = ({
  handleChange,
  value,
}: FilterNotEqualProps) => {
  return (
    <label className="null-filter">
      Not Equal To
      <input type="checkbox" onChange={handleChange} checked={value} />
    </label>
  )
}

export default FilterNotEqual
