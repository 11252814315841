import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { RootState } from '../../../store'
import PageLayout from '../../../layout/PageLayout'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import Alert from '../../../components/Alert'
import { createBlueSkyFee, resetError } from '../../../features/seriesSlice'
import SubmitButton from '../../../components/SubmitButton'
import useQuery from '../../../hooks/useQuery'
import { useHistory } from 'react-router-dom'
import BlueSkyFeeDetailForm from './BlueSkyFeeDetailForm'

const CreateBlueSkyFeeDetail: React.FC = () => {
  const methods = useForm()
  const query = useQuery()
  const dispatch = useAppDispatch()
  const [btnLoading, setBtnLoading] = useState(false)
  const { error, errorMessage } = useAppSelector(
    (state: RootState) => state.serieses,
  )
  const id = query.get('seriesId')
  const seriesName = query.get('seriesName')
  const state = query.get('state')
  const history = useHistory()

  const { handleSubmit } = methods

  useEffect(() => dispatch(resetError()), [dispatch])

  const onSubmit = async (data: any) => {
    const obj = { ...data, blueSkyFeeId: null, seriesId: id, state }
    setBtnLoading(true)
    const response = await dispatch(createBlueSkyFee(obj))
    setBtnLoading(false)
    if (response && response.payload === 200) {
      dispatch(resetError())
      history.goBack()
    }
  }

  return (
    <PageLayout>
      {error && errorMessage && (
        <Alert
          color="danger"
          visible={error}
          onClose={() => dispatch(resetError())}
          text={errorMessage}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h3>{seriesName?.replace('Linqto Liquidshares LLC -', '')}</h3>
              <h4>Series No. {id}</h4>
            </div>
            <SubmitButton loading={btnLoading} text="Save" />
          </div>
          <BlueSkyFeeDetailForm loading={btnLoading} />
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default CreateBlueSkyFeeDetail
