import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import { RegionDropdown } from 'react-country-region-selector'
import { CFormInput, CFormLabel, CFormSelect, CCollapse } from '@coreui/react-pro'
import CountryDropdown from '../../../components/CountryDropdown'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'

interface TrustedContactProps {
  loading: boolean,
  isVisible: boolean
}

const TrustedContact: React.FC<TrustedContactProps> = ({ loading, isVisible }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext()

  const { user } = useAppSelector((state: RootState) => state.users)
  const { trustedContact } = user

  const handleChange = () => {
    setValue('trustedContact.state', '')
  }
  return (
    <div className='row mt-4' style={{ paddingLeft: 20 }}>
      <CCollapse visible={isVisible}>
        <div className='row mb-2'>
          <div className='col-md-3'>
            <CFormLabel>First Name</CFormLabel>
            <CFormInput disabled={loading} {...register('trustedContact.firstName')} />
          </div>
          <div className='col-md-3'>
            <CFormLabel>Last Name</CFormLabel>
            <CFormInput disabled={loading} {...register('trustedContact.lastName')} />
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-md-3'>
            <CFormLabel>Phone</CFormLabel>
            <PhoneInput
              disabled={loading}
              name="trustedContact.phone"
              control={control}
              international
              countryCallingCodeEditable={false}
              defaultCountry="US"
              rules={{
                validate: (value: string) =>
                  value ? isValidPhoneNumber(value) : null,
              }}
            />
            {errors?.trustedContact?.phone && (
              <span className="text-danger">
                Please enter a valid phone number.
              </span>
            )}
          </div>
          <div className='col-md-3'>
            <CFormLabel>Relationship</CFormLabel>
            <CFormSelect
              disabled={loading}
              defaultValue={trustedContact?.relationship}
              {...register('trustedContact.relationship')}
            >
              <option value="">Select Relationship</option>
              <option value="Spouse">Spouse</option>
              <option value="Son/Daughter">Son/Daughter</option>
              <option value="Parent">Parent</option>
              <option value="Relative">Relative</option>
              <option value="Friend">Friend</option>
              <option value="Lawyer / Accountant / Financial Advisor">Lawyer / Accountant / Financial Advisor</option>
              <option value="Other">Other</option>
            </CFormSelect>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-md-6 mx-1">
            <Controller
              control={control}
              name='trustedContact.country'
              render={({
                field: { onChange, value },
              }) => (
                <CountryDropdown
                  disabled={loading}
                  name='trustedContact.country'
                  label="Country"
                  ref={null}
                  onChange={(val: string, e: any) => handleChange(onChange(val, e))}
                  value={value}
                />
              )}
            />
            {errors?.trustedContact && errors?.trustedContact?.country?.length
              ? errors?.trustedContact.country && (
                <span className="text-danger">
                  {errors?.trustedContact?.country?.message}
                </span>
              )
              : null}
          </div>
          <div className="col-md-6">
            <CFormLabel>State</CFormLabel>
            <Controller
              control={control}
              name={'trustedContact.state'}
              render={({
                field: { onChange, value }
              }) => (
                <RegionDropdown
                  disabled={loading}
                  country={watch('trustedContact.country')}
                  classes="form-select"
                  onChange={onChange}
                  value={value ?? undefined}
                />
              )}
            />
            {errors?.trustedContact && errors?.trustedContact?.length
              ? errors?.trustedContact?.state && (
                <span className="text-danger">
                  {errors?.trustedContac?.state?.message}
                </span>
              )
              : null}
          </div>
        </div>
        <div className="row mb-2 mt-2">
          <div className="col-md-3">
            <CFormLabel>Street 1</CFormLabel>
            <CFormInput
              disabled={loading}
              {...register('trustedContact.street1')}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Street 2</CFormLabel>
            <CFormInput
              disabled={loading}
              {...register('trustedContact.street2')}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-3">
            <CFormLabel>City</CFormLabel>
            <CFormInput
              disabled={loading}
              {...register('trustedContact.city')}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Postal Code/Zip</CFormLabel>
            <CFormInput
              disabled={loading}
              {...register('trustedContact.zip')}
            />
          </div>
        </div>
      </CCollapse>
    </div>
  )
}

export default TrustedContact
