import { CFormLabel, CFormSelect } from '@coreui/react-pro'
import React from 'react'
import { useAppDispatch } from '../../../features/hooks'
import { handleGroupFilterChange } from '../../../features/listsSlice'

const GroupFieldFilter: any = ({ field }: any) => {
  const { name, value, type, label } = field || {}
  const dispatch = useAppDispatch()

  const handleChange = (v: string) => {
    dispatch(handleGroupFilterChange({ name, v }))
  }

  if (type === 'Number') {
    return (
      <div className="d-flex align-items-center mt-4">
        <CFormLabel className="me-2 mt-1 w-25">{label}</CFormLabel>
        <CFormSelect
          className="w-25"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        >
          <option value="NONE">-none-</option>
          <option value="SUM">Sum</option>
          <option value="AVG">Avg</option>
          <option value="MIN">Min</option>
          <option value="MAX">Max</option>
        </CFormSelect>
      </div>
    )
  }
  return (
    <div className="d-flex align-items-center mt-4">
      <CFormLabel className="me-2 mt-1 w-25">{label}</CFormLabel>
      <CFormSelect
        className="w-25"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      >
        <option value="MINUTE">Minute</option>
        <option value="HOUR">Hour</option>
        <option value="DAY">Day</option>
        <option value="WEEK">Week</option>
        <option value="WEEK_OF_MONTH">Week Of Month</option>
        <option value="MONTH">Month</option>
        <option value="QUARTER">Quarter</option>
        <option value="YEAR">Year</option>
      </CFormSelect>
    </div>
  )
}

export default GroupFieldFilter
