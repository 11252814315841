import React, { useEffect, useState } from 'react'
import {
  getBrokerOrder,
  getBrokerOrders,
  getBrokerUser,
} from '../../features/brokerSlice'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import PageLayout from '../../layout/PageLayout'
import { RootState } from '../../store'
import qs from 'qs'
import Spinner from '../../components/Spinner'
import BrokerModal from './BrokerModal'
import GenericTable from '../../components/GenericTable'

const INITIAL_DATA = {
  // start: 0,
  // length: 100,
  orderAsc: false,
  orderColumnName: 'orderDoneAt',
  // CurPage: 0,
  searchValue: '',
  width: window.innerWidth,
  brokerApprovalStatus: [],
}

const ClosedOrders: React.FC = () => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { rows } = useAppSelector((state: RootState) => state.broker)
  const user = useAppSelector((state: RootState) => state.user)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(getBrokerOrders(qs.stringify(INITIAL_DATA)))
      setLoading(false)
    }
    if (user.isBrokerDealer) {
      fetchData()
    }
  }, [dispatch, user.isBrokerDealer])

  const handleClick = async (item: any) => {
    if (item) {
      setLoading(true)
      await dispatch(getBrokerUser({ id: item.userId }))
      await dispatch(getBrokerOrder({ id: item.orderId }))
      setLoading(false)
      setOpen(true)
    }
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <>
      {open && <BrokerModal setOpen={setOpen} />}
      <PageLayout>
        <GenericTable items={rows} handleClick={handleClick} />
      </PageLayout>
    </>
  )
}

export default ClosedOrders
