import React, { useCallback } from 'react'
import GenericTable from '../../../components/GenericTable'
import { useAppDispatch } from '../../../features/hooks'
import useFetchUserData from '../../../hooks/useFetchUserData'
import { getUserSellOffers } from '../../../features/usersSlice'
import Loading from './Loading'

interface UserSellOffersProps {
  id: string
  offers: any[]
  columns: any[]
  handleClick: (item: any) => void
}

const UserSellOffers: React.FC<UserSellOffersProps> = ({
  id,
  offers,
  columns,
  handleClick,
}: UserSellOffersProps) => {
  const dispatch = useAppDispatch()
  const loading = useFetchUserData(
    useCallback(() => dispatch(getUserSellOffers({ id })), [dispatch, id]),
  )

  if (loading) return <Loading />
  return (
    <GenericTable items={offers} columns={columns} handleClick={handleClick} />
  )
}

export default UserSellOffers
