import React, { useState } from 'react'
import PageLayout from '../../layout/PageLayout'
import { Controller, useForm } from 'react-hook-form'
import {
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { useHistory } from 'react-router'
import useQuery from '../../hooks/useQuery'
import { useAppDispatch } from '../../features/hooks'
import { createEntity } from '../../features/entitySlice'

const CreateEntity: React.FC = () => {
  const [btnLoading, setBtnLoading] = useState(false)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const query = useQuery()
  const userId = query.get('userId') || null
  const fullName = query.get('fullName') || ''

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm()

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    data.status = 'REQUESTED'
    await dispatch(
      createEntity({ data: { ...data, userId, userFullName: fullName } }),
    )
    setBtnLoading(false)
    history.goBack()
  }

  return (
    <PageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between align-items-start">
          <h3 className="mb-4">Create Entity</h3>
          <CLoadingButton
            className="btn-success"
            loading={btnLoading}
            type="submit"
          >
            Save
          </CLoadingButton>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>Name</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('name', { required: 'This field is required' })}
            />
            {errors.name && (
              <span className="text-danger">{errors.name.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Type</CFormLabel>
            <CFormSelect
              disabled={btnLoading}
              defaultValue="JOINT_TENANTS"
              {...register('type', { required: 'This field is required.' })}
            >
              <option value="NON_PROFIT_CORPORATION_OR_ENDOMENT_OR_FOUNDATION">
                Non Profit Corporation or Endoment or Foundation
              </option>
              <option value="INDIVIDUAL_RETIREMENT_ACCOUNT_OR_INDIVIDUAL_RETIREMENT_ANNUITY">
                Individual Retirement Account or Individual Retirement Annuity
              </option>
              <option value="CORPORATION">Corporation</option>
              <option value="TRUST">Trust</option>
              <option value="TENANTS_IN_COMMON">Tenants In Common</option>
              <option value="JOINT_TENANTS">Joint Tenants</option>
              <option value="PARTNERSHIP_OR_LIMITED_LIABILITY_COMPANY">
                Partnership Or Limited Liability Company
              </option>
            </CFormSelect>
          </div>
          <div className="col-md-3">
            <CFormLabel>Sub Type</CFormLabel>
            <CFormSelect
              disabled={btnLoading}
              defaultValue="NONE"
              {...register('subType', { required: 'This field is required.' })}
            >
              <option value="NONE">None</option>
              <option value="TRADITIONAL_IRA">Traditional IRA</option>
              <option value="ROTH_IRA">Roth IRA</option>
              <option value="SEP_IRA">Sep IRA</option>
            </CFormSelect>
          </div>
          <div className="col-md-3">
            <CFormLabel>Tax Classification</CFormLabel>
            <CFormSelect
              disabled={btnLoading}
              defaultValue="NONE"
              {...register('taxClassification', {
                required: 'This field is required.',
              })}
            >
              <option value="NONE">None</option>
              <option value="SOLE_PROPRIETOR">Sole Proprietor</option>
              <option value="C_CORP">C Corp</option>
              <option value="S_CORP">S Corp</option>
              <option value="PARTNERSHIP">Partnership</option>
              <option value="TRUST">Trust</option>
              <option value="LLC_C_CORP">Llc C Corp</option>
              <option value="LLC_S_CORP">Llc S Corp</option>
              <option value="LLC_PARTNERSHIP">Llc Partnership</option>
            </CFormSelect>
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>Account Designation</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('accountDesignation', {
                required: 'This field is required',
              })}
            />
            {errors.accountDesignation && (
              <span className="text-danger">
                {errors.accountDesignation.message}
              </span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Contact Person</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('contactPerson', {
                required: 'This field is required',
              })}
            />
            {errors.contactPerson && (
              <span className="text-danger">
                {errors.contactPerson.message}
              </span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>User Title</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('userTitle', { required: 'This field is required' })}
            />
            {errors.userTitle && (
              <span className="text-danger">{errors.userTitle.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>User</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              style={{ cursor: 'pointer' }}
              readOnly
              onClick={() => history.push(`/users/${userId}`)}
              className="form-control"
              value={fullName}
            />
          </div>
          <div className="col-md-6">
            <CFormLabel>Tax ID</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('taxId', { required: 'This field is required' })}
            />
            {errors.taxId && (
              <span className="text-danger">{errors.taxId.message}</span>
            )}
          </div>
        </div>
        <h4 className="mb-4">Address Information</h4>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>Street 1</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('street1', { required: 'This field is required' })}
            />
            {errors.street1 && (
              <span className="text-danger">{errors.street1.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Street 2</CFormLabel>
            <CFormInput disabled={btnLoading} {...register('street2')} />
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>City</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('city', { required: 'This field is required' })}
            />
            {errors.city && (
              <span className="text-danger">{errors.city.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>State</CFormLabel>
            <Controller
              rules={{ required: 'This field is required.' }}
              control={control}
              name="state"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <RegionDropdown
                  disabled={btnLoading}
                  classes="form-select"
                  country={watch('country')}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.state && (
              <span className="text-danger">{errors.state.message}</span>
            )}
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-6">
            <CFormLabel>Country</CFormLabel>
            <Controller
              rules={{ required: 'This field is required.' }}
              control={control}
              name="country"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <CountryDropdown
                  disabled={btnLoading}
                  classes="form-select"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.country && (
              <span className="text-danger">{errors.country.message}</span>
            )}
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-6">
            <CFormLabel>Zip</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('zip', { required: 'This field is required' })}
            />
            {errors.zip && (
              <span className="text-danger">{errors.zip.message}</span>
            )}
          </div>
        </div>
      </form>
    </PageLayout>
  )
}

export default CreateEntity
