import { useMemo, useCallback } from 'react'
import _ from 'lodash'
// Used for Function Components
const useDebounce: any = (fn: () => void, time: number) =>
  useMemo(() => _.debounce(fn, time), [fn, time])

export const useDebounceSearch: any = (fn: (v: any) => void, time: number) =>
  useCallback(_.debounce(fn, time), []) // eslint-disable-line react-hooks/exhaustive-deps

export default useDebounce
