import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { getTransactions } from '../../features/transactionSlice'
import GenericTable from '../../components/GenericTable'

const Transactions: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { rows } = useAppSelector((state: RootState) => state.transactions)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(getTransactions())
      setLoading(false)
    }
    fetchData()
  }, [dispatch])

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <GenericTable
        items={rows}
        handleClick={(item: any) =>
          item?.tagId && history.push(`/tags/${item.tagId}`)
        }
      />
    </PageLayout>
  )
}

export default Transactions
