import React, { useEffect } from 'react'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import Select, { SingleValue } from 'react-select'
import { CButton } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilX } from '@coreui/icons'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import Loading from './Loading'
import privateAPI from '../../../APIs/private'
import { useQuery } from '@tanstack/react-query'

type RowProps = { tagId: number; name: string; userCount: number }

const getTags = async () => {
  const response = await privateAPI.post('/queries/tags', {
    tagId: {},
    name: {},
    userCount: {},
  })
  return response.data
}

const Tag: React.FC = () => {
  const { user } = useAppSelector((state: RootState) => state.users)
  const { reset } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'tags',
  })
  const { data, isLoading } = useQuery({
    queryKey: ['user_tags'],
    queryFn: () => getTags(),
  })

  const rows: RowProps[] = data?.rows || []

  useEffect(() => reset(user), [reset, user])

  const results = _.differenceBy(rows, fields, 'name')

  const tags = [
    ...results.map((row) => ({
      ...row,
      label: row.name,
      value: row.name,
    })),
  ]

  if (isLoading) return <Loading />

  return (
    <div className="d-flex flex-column row g-3 mb-4">
      <div className="col-md-12">
        <h4>User Tags</h4>
        <div className="d-flex">
          {fields && fields.length
            ? fields.map((tag: any, i: number) => (
              <Controller
                key={tag.id}
                name={`tags.${i}.name`}
                render={({ field }) => (
                  <CButton
                    {...field}
                    className="me-4 d-flex justify-content-between align-items-center btn-sm"
                    onClick={() => remove(i)}
                  >
                    {tag.name}
                    <CIcon icon={cilX} />
                  </CButton>
                )}
              />
            ))
            : null}
        </div>
      </div>
      <div className="col-md-6">
        <Select
          options={tags}
          onChange={(tag: SingleValue<RowProps>) =>
            append({ name: tag?.name, tagId: tag?.tagId })
          }
        />
      </div>
    </div>
  )
}

export default Tag
