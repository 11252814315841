import React from 'react'
import { CButton, CFormCheck, CFormInput, CFormLabel } from '@coreui/react-pro'
import { useFieldArray, useFormContext } from 'react-hook-form'
import CIcon from '@coreui/icons-react'
import { cilMinus, cilPlus } from '@coreui/icons'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'

const KeyOfficer: React.FC<any> = ({ field, index, remove }: any) => {
  const { register } = useFormContext()
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )

  const {
    fields: workHistory,
    append: appendWorkHistory,
    remove: removeWorkHistory,
  } = useFieldArray({ name: `companyDetail.keyOfficers[${index}].workHistory` })

  const {
    fields: companyTitles,
    append: appendCompanyTitles,
    remove: removeCompanyTitles,
  } = useFieldArray({
    name: `companyDetail.keyOfficers[${index}].companyTitles`,
  })

  return (
    <div className="d-flex align-items-center w-100">
      <div key={field.id} className="row d-flex w-100">
        <div className="col-md-3">
          <CFormLabel>Name</CFormLabel>
          <CFormInput
            {...register(`companyDetail.keyOfficers.${index}.name`)}
            disabled={btnLoading}
          />
        </div>
        <div className="col-md-3">
          <div className="d-flex">
            <CFormLabel>Work History</CFormLabel>
            <CButton
              className="rounded-circle ms-2"
              size="sm"
              onClick={() => appendWorkHistory({ companyName: '' })}
              disabled={btnLoading}
            >
              <CIcon icon={cilPlus} />
            </CButton>
          </div>
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {workHistory && workHistory.length > 0 ? (
              workHistory.map((work: any, idx: number) => (
                <div className="d-flex align-items-center mb-2" key={work.id}>
                  <CFormInput
                    {...register(
                      `companyDetail.keyOfficers[${index}].workHistory[${idx}].companyName`,
                    )}
                    disabled={btnLoading}
                  />
                  <CButton
                    size="sm"
                    className="bg-danger ms-2 rounded-circle"
                    onClick={() => removeWorkHistory(idx)}
                    disabled={btnLoading}
                  >
                    <CIcon icon={cilMinus} />
                  </CButton>
                </div>
              ))
            ) : (
              <p className="text-danger">There are no company titles</p>
            )}
          </div>
        </div>
        <div className="col-sm">
          <div className="d-flex">
            <CFormLabel>Company Titles</CFormLabel>
            <CButton
              className="rounded-circle ms-2"
              size="sm"
              onClick={() =>
                appendCompanyTitles({ title: '', isCurrent: false })
              }
              disabled={btnLoading}
            >
              <CIcon icon={cilPlus} />
            </CButton>
          </div>
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {companyTitles && companyTitles.length > 0 ? (
              companyTitles.map((work: any, idx: number) => (
                <div
                  className="col-sm d-flex align-items-center mb-2"
                  key={work.id}
                >
                  <div className="col-md-5">
                    <CFormInput
                      {...register(
                        `companyDetail.keyOfficers[${index}].companyTitles[${idx}].title`,
                      )}
                      disabled={btnLoading}
                    />
                  </div>
                  <div className="d-flex align-items-center col-md-1 ms-5">
                    <CFormCheck
                      label="Current"
                      {...register(
                        `companyDetail.keyOfficers[${index}].companyTitles[${idx}].isCurrent`,
                      )}
                      disabled={btnLoading}
                    />
                    <CButton
                      size="sm"
                      className="bg-danger ms-2 rounded-circle"
                      onClick={() => removeCompanyTitles(idx)}
                      disabled={btnLoading}
                    >
                      <CIcon icon={cilMinus} />
                    </CButton>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-danger">There are no company titles</p>
            )}
          </div>
        </div>
      </div>
      <div>
        <CButton
          size="sm"
          className="bg-danger ms-2 rounded-circle"
          style={{ float: 'right', marginTop: '30px' }}
          onClick={() => remove(index)}
          disabled={btnLoading}
        >
          <CIcon icon={cilMinus} />
        </CButton>
      </div>
    </div>
  )
}

export default KeyOfficer
