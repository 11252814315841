import React from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import OriginationDetailForm from './OriginationDetailForm'
import api from '../../APIs/private'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Document, Note } from '../../types/General'

type FormValues = {
  companyName: string
  fullName: string
  status: string
  cancelReason: string
  createdAt: Date
  updatedAt: Date
  certificateNum: number
  shareClass: string
  sharePrice: number
  shares: number
  purchaseAmount: number
  optionsStrikePrice: number
  fields: Note[]
  notes: Note[] | string
}

export type Origination = {
  originationId: number
  cancelReason: string
  certificateNum: number
  companyId: number
  companyName: string
  documents: Document[]
  fullName: string
  notes: string
  optionsStrikePrice: number
  purchaseAmount: number
  shareClass: string
  sharePrice: number
  shares: number
  status: string
  updatedAt: string
  userId: number
  rowCount: number
  createdAt: number
}

const getOrigination = async (id: string): Promise<Origination> => {
  const response = await api.get(`/queries/originations/${id}`)
  const origination: Origination = response?.data?.rows?.[0] || {}
  return origination
}

const updateOrigination = async (id: string, data: FormValues) =>
  await api.post(`/queries/originations/${id}`, data)

const OriginationDetail: React.FC = () => {
  const methods = useForm()
  const { id }: any = useParams()
  const queryClient = useQueryClient()

  const { data: originationData, isInitialLoading: originationLoading } =
    useQuery({
      queryFn: () => getOrigination(id),
      queryKey: ['origination', id],
      enabled: +id !== -1,
    })

  const updateOriginationMutation = useMutation({
    mutationFn: async (data: FormValues) => {
      const notes: Note[] | string = data.notes || []
      if (notes && notes.length && typeof notes === 'object') {
        notes?.forEach((note: any) => delete note.updated)
      }
      await updateOrigination(id, {
        ...data,
        notes: JSON.stringify(notes),
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['origination', id] })
    },
  })

  useEffect(() => {
    const notes: string = originationData?.notes || ''
    const obj = {
      ...originationData,
      notes: originationData?.notes ? JSON.parse(notes) : [],
    }
    methods.reset(obj)
  }, [methods, originationData])

  const btnLoading = updateOriginationMutation.isLoading
  const onSubmit = updateOriginationMutation.mutate

  if (originationLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <FormProvider {...methods}>
      <PageLayout>
        <OriginationDetailForm
          btnLoading={btnLoading}
          onSubmit={onSubmit}
          origination={originationData}
        />
      </PageLayout>
    </FormProvider>
  )
}

export default OriginationDetail
