import {
  CButton,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import { addUserPurchaseCredit, getUser, getUserPurchaseCredits } from '../../../features/usersSlice'
import moment from 'moment'

interface AddPurchaseCreditProps {
  header: string
  abandonAction: () => void
  open: boolean
  userId: string
}

const AddPurchaseCreditModal: React.FC<AddPurchaseCreditProps> = ({
  open,
  header,
  abandonAction,
  userId,
}: AddPurchaseCreditProps) => {
  const dispatch = useAppDispatch()
  const me = useAppSelector((state: RootState) => state.user)
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data: any) => {
    const newNote = [{
      firstName: me.firstName,
      notes: data.note,
      time: Date.now()
    }]
    data.note = JSON.stringify(newNote)
    setLoading(true)
    // add the expiration days to current time and convert to unix timestamp
    data.expiresAt = moment().add(data.expiresAt, 'days').valueOf()
    await dispatch(addUserPurchaseCredit({ userId, data }))
    await dispatch(getUser({ id: userId }))
    await dispatch(getUserPurchaseCredits({ userId }))
    setLoading(false)
    abandonAction()
  }

  return (
    <CModal visible={open} alignment="center" onClose={abandonAction}>
      <CModalHeader>{header}</CModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CModalBody>
          <div className="row- g-3 mb-4">
            <div className="col-md-12">
              <CFormLabel>Amount</CFormLabel>
              <CFormInput
                type="number"
                step=".01"
                {...register('amount', { required: 'This field is required' })}
              />
              {errors.amount && (
                <span className="text-danger">{errors.amount.message}</span>
              )}
            </div>
          </div>
          <div className="row- g-3 mb-4">
            <div className="col-md-12">
              <CFormLabel>Expiration Window in Days</CFormLabel>
              <CFormInput
                type='number'
                step='1'
                {...register('expiresAt', { required: 'This field is required' })}
              />
              {errors.expiresAt && (
                <span className="text-danger">{errors.expiresAt.message}</span>
              )}
            </div>
          </div>
          <div className="row- g-3 mb-4">
            <div className="col-md-12">
              <CFormLabel>Notes</CFormLabel>
              <CFormInput
                {...register('note', { required: 'This field is required' })}
              />
              {errors.note && (
                <span className="text-danger">{errors.note.message}</span>
              )}
            </div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton className="btn-danger" onClick={abandonAction}>
            Cancel
          </CButton>
          <CLoadingButton loading={loading} type="submit">
            Add
          </CLoadingButton>
        </CModalFooter>
      </form>
    </CModal>
  )
}

export default AddPurchaseCreditModal
