import React from 'react'

const Null: React.FC<{
  handleNullChange: (e: any) => any
  filterIsNull: boolean
}> = ({ handleNullChange, filterIsNull }: any) => {
  return (
    <div>
      <label onClick={handleNullChange} className="null-filter">
        Is Null
        <input type="checkbox" defaultChecked={filterIsNull} />
      </label>
    </div>
  )
}

export default Null
