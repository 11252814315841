import React, { useState } from 'react'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton
} from '@coreui/react-pro'
import Spinner from './Spinner'
import api from '../APIs/private'
import { useQuery } from '@tanstack/react-query'
import GenericTable from './GenericTable'
import { cilArrowLeft, cilArrowRight } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const AUDIT_LOGS_COLUMNS = [
  {
    key: 'action',
    name: 'action',
    label: "Action",
    type: 'String'
  },
  {
    key: 'rowTable',
    name: 'rowTable',
    label: "Table",
    type: 'String'
  },
  {
    key: 'updatedAt',
    name: 'updatedAt',
    label: "Updated At",
    type: 'Date',
    renderer: 'DateTime',
  },
  {
    key: 'updatedByUserName',
    name: 'updatedByUserName',
    label: "Updated By User Name",
    type: 'String'
  },
  {
    key: 'beforeData',
    name: 'beforeData',
    label: "Before Data",
    type: 'String',
    renderer: 'FixedWidthJsonData',
  },
  {
    key: 'afterData',
    name: 'afterData',
    label: "After Data",
    type: 'String',
    renderer: 'FixedWidthJsonData',
  },
]

interface AuditLogsModalProps {
  open: boolean
  type: string
  id: number
  abandonAction: () => void
}

const getLogs = async (type: string, id: number, currentPage: number) => {
  const res = await api.get(`/changeLogsFor/${type}/${id}?start=${(currentPage * 10)}&length=10`)
  return res.data
}

const AuditLogsModal: React.FC<AuditLogsModalProps> = ({ open, abandonAction, type, id }: any) => {
  const [currentPage, setCurrentPage] = useState(0)
  const { data, isInitialLoading: auditLoading } = useQuery({
    queryKey: [`${type}-audit-logs`, id, currentPage],
    queryFn: async () => getLogs(type, id, currentPage),
    enabled: !!id && !!type,
  })

  if (auditLoading) {
    return (
      <CModal visible alignment="center" size="lg">
        <Spinner />
      </CModal>
    )
  }

  return (
    <CModal visible={open} alignment="center" size="xl" onClose={abandonAction}>
      <CModalHeader>
        <CModalTitle className='me-4'>Audit Logs</CModalTitle>
        <CButton className='me-2' disabled={currentPage === 0} onClick={() => setCurrentPage((curr) => curr > 0 ? curr - 1 : 0)}>
          <CIcon icon={cilArrowLeft} size="lg" />
        </CButton>
        <CButton disabled={!data?.length} onClick={() => setCurrentPage((curr) => curr + 1)}>
          <CIcon icon={cilArrowRight} size="lg" />
        </CButton>
      </CModalHeader>
      <CModalBody>
        <GenericTable
          columns={AUDIT_LOGS_COLUMNS}
          items={data}
        />
      </CModalBody>
    </CModal>
  )
}

export default AuditLogsModal