import React, { useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CLoadingButton,
} from '@coreui/react-pro'
import {
  addNote,
  getSellRequest,
  removeNote,
  saveSellRequest,
} from '../../features/sellRequestsSlice'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import Note from '../../components/Note'
import { formatToPT } from '../../utils/formatUtils/utils'

const SellRequestDetail: React.FC = () => {
  const { id }: any = useParams()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [note, setNote] = useState('')
  const [newNotes, setNewNotes] = useState<any>([])
  const dispatch = useAppDispatch()
  const { sellRequest } = useAppSelector(
    (state: RootState) => state.origination_sell_to_linqto_requests,
  )
  const { firstName: userFirstName } = useAppSelector(
    (state: RootState) => state.user,
  )
  const { register, handleSubmit, reset } = useForm()

  useEffect(() => {
    const getEntityById = async () => {
      await dispatch(getSellRequest({ id }))
      setLoading(false)
    }
    getEntityById()
  }, [id, dispatch])

  useEffect(() => {
    reset(sellRequest)
  }, [sellRequest, reset])

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    if (newNotes && newNotes.length) {
      newNotes.forEach((note: any) => delete note.updated)
      data.notes = JSON.stringify([...newNotes])
    }
    await dispatch(saveSellRequest({ id, data: { ...sellRequest, ...data } }))
    await dispatch(getSellRequest({ id }))
    setBtnLoading(false)
  }

  const updateRequestWithNote = () => {
    const requestNotes =
      sellRequest?.notes && sellRequest.notes.length
        ? [...JSON.parse(notes)]
        : []
    requestNotes.unshift({
      firstName: userFirstName,
      note,
      time: Date.now(),
      updated: true,
    })
    dispatch(addNote(requestNotes))
    setNewNotes([...requestNotes])
    setNote('')
  }

  const deleteNote = (note: any) => {
    const noteCopy = [...newNotes]
    const found = noteCopy.findIndex((t: any) => t.time === note.time)
    if (found !== -1) {
      noteCopy.splice(found, 1)
      setNewNotes([...noteCopy])
      dispatch(removeNote([...noteCopy]))
    }
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  const {
    originationSellToLinqtoRequestId,
    companyName,
    country,
    email,
    firstName,
    fullName,
    lastName,
    phone,
    sharePrice,
    shareClass,
    status,
    shares,
    updatedAt,
    notes,
  } = sellRequest

  let brokenNotes: any[] = []

  try {
    brokenNotes = notes && notes.length ? [...JSON.parse(notes)] : []
  } catch (e) {
    console.log('error breaking notes...')
  }

  return (
    <PageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between">
          <h3>
            Origination No.{originationSellToLinqtoRequestId} - {companyName}
          </h3>
          <CLoadingButton
            loading={btnLoading}
            className="btn-success"
            type="submit"
          >
            Save
          </CLoadingButton>
        </div>
        <h4>Origination Information</h4>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <CFormLabel className="form-label">Company Name</CFormLabel>
            <CInputGroup className="input-group-text">
              {companyName}
            </CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Country</CFormLabel>
            <CInputGroup className="input-group-text">
              {country || 'N/A'}
            </CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Email</CFormLabel>
            <CInputGroup className="input-group-text">{email}</CInputGroup>
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <CFormLabel className="form-label">First Name</CFormLabel>
            <CInputGroup className="input-group-text">{firstName}</CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Full Name</CFormLabel>
            <CInputGroup className="input-group-text">{fullName}</CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Last Name</CFormLabel>
            <CInputGroup className="input-group-text">{lastName}</CInputGroup>
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <CFormLabel className="form-label">Phone Number</CFormLabel>
            <CInputGroup className="input-group-text">{phone}</CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Share Price</CFormLabel>
            <CInputGroup className="input-group-text">{sharePrice}</CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Shares</CFormLabel>
            <CInputGroup className="input-group-text">{shares}</CInputGroup>
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <CFormLabel className="form-label">Share Class</CFormLabel>
            <CInputGroup className="input-group-text">{shareClass}</CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Order Status</CFormLabel>
            <select
              disabled={btnLoading}
              className="form-select"
              defaultValue={status}
              {...register('status')}
            >
              <option value="OPEN">Open</option>
              <option value="CLOSED">Closed</option>
              <option value="PROCESSING">Processing</option>
            </select>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Shares</CFormLabel>
            <CInputGroup className="input-group-text">{shares}</CInputGroup>
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <CFormLabel className="form-label">Updated At</CFormLabel>
            <CInputGroup className="input-group-text">
              {formatToPT(updatedAt, 'MMMM Do YYYY')}
            </CInputGroup>
          </div>
          <h4>Notes</h4>
          <div className="d-flex col-md-6">
            <CFormInput
              type="text"
              value={note}
              onChange={(e: any) => setNote(e.target.value)}
              disabled={btnLoading}
            />
            <CButton
              disabled={!note}
              onClick={() => updateRequestWithNote()}
              className="mx-2"
            >
              Add
            </CButton>
          </div>
          <div className="row">
            <div className="col-md-6">
              {brokenNotes && brokenNotes.length
                ? brokenNotes.map((note: any, i: number) => (
                    <React.Fragment key={i}>
                      <Note note={note} deleteNote={deleteNote} />
                    </React.Fragment>
                  ))
                : null}
            </div>
          </div>
        </div>
      </form>
    </PageLayout>
  )
}

export default SellRequestDetail
