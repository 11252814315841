import { cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CFormInput, CFormLabel } from '@coreui/react-pro'
import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import CompetitiveLandscape from './CompetitiveLandscape'
import FormatNumber from './FormatNumber'
import FundingRound from './FundingRound'
import ListValuationOverTime from './ListValuationOverTime'

const CompanyInformation: React.FC = () => {
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )
  const { register } = useFormContext()
  const {
    fields: valuationOverTimeFields,
    remove: removeValuationOverTimeFields,
    append: appendValuationOverTimeFields,
  } = useFieldArray({
    name: 'companyDetail.valuationOverTime',
  })

  const {
    fields: competitors,
    remove: removeCompetitors,
    append: appendCompetitors,
  } = useFieldArray({ name: 'companyDetail.competitors' })

  const {
    fields: fundingRounds,
    remove: removeFundingRounds,
    prepend: appendFundingRounds,
  } = useFieldArray({ name: 'companyDetail.fundingRounds' })

  const addField = () => {
    // MAX array length: 15
    if (valuationOverTimeFields && valuationOverTimeFields.length > 14) {
      alert('Cannot add any more fields...')
      return
    }

    appendValuationOverTimeFields({
      year: '2000',
      amount: '100000',
      valuationSource: '',
    })
  }

  return (
    <div style={{ marginBottom: '4rem' }}>
      <div className="row">
        <h3>Valuation & Funding</h3>
        <div className="row mb-4">
          <div className="col-md-3">
            <CFormLabel>Year Founded</CFormLabel>
            <CFormInput
              {...register('companyDetail.founded')}
              disabled={btnLoading}
            />
          </div>
          <div className="col-md-3 mt-0">
            <CFormLabel>Total Funds Raised</CFormLabel>
            <CFormInput
              {...register('companyDetail.totalRaised')}
              disabled={btnLoading}
            />
            <FormatNumber watchValue="companyDetail.totalRaised" isMoney />
          </div>
          <div className="col-md-3">
            <CFormLabel>Implied Valuation Share Price</CFormLabel>
            <CFormInput
              type="number"
              step=".01"
              {...register('companyDetail.impliedValuationSharePrice')}
              disabled={btnLoading}
            />
            <FormatNumber
              watchValue="companyDetail.impliedValuationSharePrice"
              isMoney
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Implied Valuation</CFormLabel>
            <CFormInput
              type="number"
              {...register('companyDetail.impliedValuation')}
              disabled={btnLoading}
            />
            <FormatNumber watchValue="companyDetail.impliedValuation" isMoney />
          </div>
          <div className="col-md-3">
            <CFormLabel>Valuation Post-Money</CFormLabel>
            <CFormInput
              type="number"
              {...register('companyDetail.postMoney')}
              disabled={btnLoading}
            />
            <FormatNumber watchValue="companyDetail.postMoney" isMoney />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-8 mt-0 w-100">
          <div className="d-flex align-items-center">
            <h6>Valuation Over Time</h6>
            <CButton
              className="rounded-circle"
              size="sm"
              style={{ margin: '10px' }}
              onClick={addField}
              disabled={btnLoading}
            >
              <CIcon icon={cilPlus} />
            </CButton>
          </div>
          <ListValuationOverTime
            fields={valuationOverTimeFields}
            remove={removeValuationOverTimeFields}
          />
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center">
          <h6>Competitive Landscape</h6>
          <CButton
            className="rounded-circle"
            size="sm"
            style={{ margin: '10px' }}
            onClick={() =>
              appendCompetitors({
                companyName: '',
                headquarters: {
                  city: '',
                  state: '',
                  country: '',
                },
                value: '',
                valueFootnote: '',
                revenue: '',
                revenueFootnote: '',
              })
            }
            disabled={btnLoading}
          >
            <CIcon icon={cilPlus} />
          </CButton>
        </div>
        <CompetitiveLandscape fields={competitors} remove={removeCompetitors} />
        <div className="d-flex align-items-center">
          <h6>Funding Rounds</h6>
          <CButton
            className="rounded-circle"
            size="sm"
            style={{ margin: '10px' }}
            onClick={() =>
              appendFundingRounds({
                date: '',
                round: '',
                amount: '',
                valuation: '',
                valuationSource: '',
                investors: [],
              })
            }
            disabled={btnLoading}
          >
            <CIcon icon={cilPlus} />
          </CButton>
        </div>
        <FundingRound fields={fundingRounds} remove={removeFundingRounds} />
      </div>
    </div>
  )
}

export default CompanyInformation
