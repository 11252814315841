import React, { useState } from 'react'
import { FormProvider, useForm, useFormState } from 'react-hook-form'
import { useParams } from 'react-router'
import PageLayout from '../../layout/PageLayout'
import { CFormInput, CFormLabel, CLoadingButton } from '@coreui/react-pro'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
// import history from '../../history'
import Alert from '../../components/Alert'
import { createOffer, resetError } from '../../features/offersSlice'
import { RootState } from '../../store'
import Select from '../assets/components/Select'
import { components, OptionProps } from 'react-select'
import Checkbox from '../../components/Checkbox'
import { useHistory } from 'react-router'

const Option = (props: OptionProps) => {
  const {
    data: { assetId, value, companyName },
  } = (props as any) || {}
  return (
    <components.Option {...props}>
      {companyName} - {value} (id: {assetId})
    </components.Option>
  )
}

const CreateOffer: React.FC = ({ loadAssetOptions }: any) => {
  const { id }: any = useParams()
  const history = useHistory()
  const [btnLoading, setBtnLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { error, errorMessage } = useAppSelector(
    (state: RootState) => state.offers,
  )

  const methods = useForm()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
    control,
    setValue,
    watch,
  } = methods

  const { dirtyFields } = useFormState({ control })

  const handleChange = (value: any) => {
    if (value) {
      setValue('asset.value', value.value, { shouldDirty: true })
      setValue('asset.id', value.assetId)
      setValue('asset.companyId', value.companyId)
      setValue('asset.companyName', value.companyName)
    }
  }

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    data.assetId = data.asset.id
    data.assetName = data.asset.value
    data.companyId = data.asset.companyId
    data.companyName = data.asset.companyName
    delete data.asset
    const res = await dispatch(createOffer({ id, data: { ...data } }))
    if (res && res.payload === 200) {
      setBtnLoading(false)
      reset()
      alert('Asset has been created.')
      history.goBack()
    } else {
      setBtnLoading(false)
    }
  }

  return (
    <PageLayout>
      {error && errorMessage && (
        <Alert
          color="danger"
          visible={error}
          onClose={() => dispatch(resetError())}
          text={errorMessage}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between">
            <h3>Create Offer </h3>
            <div className="d-flex">
              <CLoadingButton
                disabled={!isDirty || Object.keys(dirtyFields).length === 0}
                loading={btnLoading}
                className="btn-success mx-2"
                type="submit"
              >
                Save
              </CLoadingButton>
            </div>
          </div>
          <h4>Offer Information</h4>
          <div className="row g-3 mb-4 align-items-end">
            <div className="col-md-3">
              <CFormLabel className="form-label">Asset Name</CFormLabel>
              <Select
                options={Option}
                loadSuggestedOptions={loadAssetOptions}
                handleChange={(value: any) => handleChange(value)}
                watchValue={
                  watch('asset.companyName')
                    ? `${watch('asset.companyName')} - ${watch('asset.value')}`
                    : ''
                }
                query="assets"
                id={watch('asset.id')}
                {...register('asset', { required: 'This field is required' })}
                ref={null}
              />
              {errors.asset && (
                <span className="text-danger">{errors.asset.message}</span>
              )}
            </div>
            <div className="col-md-3">
              <CFormLabel className="form-label">Shares</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('shares')}
                type="number"
              />
            </div>
            <Checkbox
              formLabel="Is Active"
              registerValue="isActive"
              btnLoading={btnLoading}
            />
          </div>
          <div className="row g-3 mb-4">
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 10K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice10k')}
                type="number"
                step=".01"
              />
            </div>
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 25K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice25k')}
                type="number"
                step=".01"
              />
            </div>
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 50K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice50k')}
                type="number"
                step=".01"
              />
            </div>
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 100K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice100k')}
                type="number"
                step=".01"
              />
            </div>
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 200K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice200k')}
                type="number"
                step=".01"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default CreateOffer
