import React from 'react'
import {
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CLoadingButton,
} from '@coreui/react-pro'
import { Controller, useFormContext } from 'react-hook-form'
import Select from '../assets/components/Select'

const BrokerDetailForm: any = ({
  onSubmit,
  id,
  btnLoading,
  brokerInfoId,
  fullName,
  name,
  userId,
  createUserId,
  createUserFullName,
  entityId,
  handleChange,
}: any) => {
  const {
    handleSubmit,
    formState: { isDirty, errors },
    register,
  } = useFormContext()
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between">
        <h3>{id === '-1' ? 'Create Broker' : `Broker No.${brokerInfoId}`}</h3>
        <div className="d-flex">
          <CLoadingButton
            disabled={!isDirty}
            className="btn-success"
            loading={btnLoading}
            type="submit"
          >
            Save
          </CLoadingButton>
        </div>
      </div>
      <h4>Broker Information</h4>
      <div className="row g-3 mb-4">
        {userId && (
          <div className="col-md-3">
            <CFormLabel>User</CFormLabel>
            <Select
              readOnly
              watchValue={fullName}
              query="users"
              id={userId}
              {...register('data')}
              ref={null}
            />
          </div>
        )}
        {createUserId && (
          <div className="col-md-3">
            <CFormLabel>User</CFormLabel>
            <Select
              readOnly
              watchValue={createUserFullName}
              query="users"
              id={createUserId}
              {...register('data')}
              ref={null}
            />
          </div>
        )}
        {entityId && (
          <div className="col-md-3">
            <CFormLabel>Entity</CFormLabel>
            <Select
              readOnly
              watchValue={name}
              query="entities"
              id={entityId}
              {...register('data')}
              ref={null}
            />
          </div>
        )}
        <div className="col-md-3">
          <CFormLabel>Brokerage Firm Name</CFormLabel>
          <CFormInput
            disabled={btnLoading}
            {...register('equityFirmName', {
              required: 'This field is required',
            })}
          />
          {errors.equityFirmName && (
            <span className="text-danger">{errors.equityFirmName.message}</span>
          )}
        </div>
        <div className="col-md-3">
          <CFormLabel>Brokerage Firm DTC Number</CFormLabel>
          <Controller
            name="dtc"
            rules={{
              minLength: 4,
              maxLength: 4,
              required: 'This field is required.',
            }}
            render={({ field: { value } }) => (
              <CFormInput
                onChange={handleChange}
                value={value ?? ''}
                type="number"
                disabled={btnLoading}
              />
            )}
          />
          {errors.dtc &&
            (errors.dtc.type === 'minLength' ||
              errors.dtc.type === 'maxLength' ||
              errors.dtc.type === 'required') && (
              <span className="text-danger">DTC must be 4 characters.</span>
            )}
        </div>
        <div className="col-md-3">
          <CFormLabel>Brokerage Account Number</CFormLabel>
          <CFormInput
            disabled={btnLoading}
            {...register('accountNumber', {
              required: 'This field is required',
            })}
          />
          {errors.accountNumber && (
            <span className="text-danger">{errors.accountNumber.message}</span>
          )}
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>Name on Brokerage Account</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('nameOnAccount', {
                required: 'This field is required',
              })}
            />
            {errors.nameOnAccount && (
              <span className="text-danger">
                {errors.nameOnAccount.message}
              </span>
            )}
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-6">
            <CFormLabel>Special Instructions</CFormLabel>
            <CFormTextarea disabled={btnLoading} {...register('notes')} />
          </div>
        </div>
      </div>
    </form>
  )
}

export default BrokerDetailForm
