import React, { useState, useEffect } from 'react'
import {
  CNavGroup,
  CNavItem,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from '@coreui/react-pro'
import { v4 as uuidv4 } from 'uuid'
import Logo from '../assets/logo.png'
import { AppSidebarNav } from './AppSidebarNav'
import CIcon from '@coreui/icons-react'
// sidebar nav config
import navigation from '../_nav'
import { useAppDispatch, useAppSelector } from '../features/hooks'
import { toggle } from '../features/sidebarSlice'
import { RootState } from '../store'
import { cilList } from '@coreui/icons'
import useQuery from '../hooks/useQuery'

const AppSidebar = () => {
  const query = useQuery()
  const [navItems, setNavItems] = useState<any[]>([])
  const dispatch = useAppDispatch()
  const length = Number(query.get('length')) || 10
  const open = useAppSelector((state: RootState) => state.sidebar.open)
  const queries = useAppSelector((state: RootState) => state.user.queries)

  useEffect(() => {
    if (queries && queries.length) {
      const reportData = []
      queries.forEach((query) => {
        if (query && query.reports) {
          query.reports.forEach((report: any) => {
            reportData.push({
              component: CNavItem,
              name: report.name,
              to: `/list?queryName=${query.queryName}&reportId=${report.reportId
                }&current=0&start=0&length=${length}&params=${encodeURIComponent(
                  report?.queryParams,
                )}`,
              type: query.queryName,
            })
          })
        }
      })
      const data = queries.map((query) => {
        let queryReports = []
        if (query && query.reports && query.reports.length) {
          queryReports = query.reports.map((report: any) => {
            return {
              uniqueid: uuidv4(),
              component: CNavItem,
              name: report.name,
              to: `/list?queryName=${query.queryName.toLowerCase()}&reportId=${report.reportId
                }&current=0&start=0&length=${length}&params=${encodeURIComponent(
                  report?.queryParams,
                )}`,
            }
          })
        }
        return {
          component: CNavGroup,
          name: query.prettyName,
          icon: <CIcon icon={cilList} customClassName="nav-icon" />,
          items: queryReports,
          to: query.queryName.toLowerCase(),
          uniqueid: uuidv4(),
        }
      })
      setNavItems([...navigation, ...data])
    }
  }, [queries, length])

  return (
    <CSidebar position="fixed" visible={open}>
      <CSidebarBrand className="d-none d-md-flex bg-white">
        <div className="d-flex align-items-center w-100">
          <img
            className="m-auto"
            alt="Linqto Hero"
            src={Logo}
            height={32}
            width={192}
          />
        </div>
      </CSidebarBrand>
      <CSidebarNav className="bg-indigo">
        <>
          <AppSidebarNav items={navItems} />
        </>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex bg-indigo"
        onClick={() => dispatch(toggle())}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
