import React from 'react'

const PageCounter: any = ({ count, start, length }: any) => {
  return (
    <div>
      {count > 0 && (
        <div className="pagination entries">
          {count > 0 && (
            <div className="pagination entries">
              {start + 1} -{' '}
              {count < +start + +length ? count : +start + +length} of {count}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default PageCounter
