import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { RootState } from '../../../store'
import Spinner from '../../../components/Spinner'
import PageLayout from '../../../layout/PageLayout'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import Alert from '../../../components/Alert'
import {
  getRelatedDocuments,
  getSeriesFilings,
  getSeriesFormD,
  resetError,
  saveSeriesFormD,
} from '../../../features/seriesSlice'
import SeriesFormDDetailForm from './SeriesFormDDetailForm'
import SubmitButton from '../../../components/SubmitButton'
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilSettings } from '@coreui/icons'

const SeriesFormDDetail: React.FC = () => {
  const { id }: any = useParams()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const { series, error, errorMessage } = useAppSelector(
    (state: RootState) => state.serieses,
  )
  const methods = useForm()

  const { handleSubmit, reset } = methods

  useEffect(() => {
    const getSeriesById = async () => {
      if (+id !== -1) {
        await dispatch(getSeriesFormD({ id }))
        await dispatch(getSeriesFilings(id))
        setLoading(false)
      }
    }
    getSeriesById()
  }, [id, dispatch])

  useEffect(() => {
    reset(series)
  }, [series, reset])

  useEffect(() => () => dispatch(resetError()), [dispatch])

  const handleClick = async () => {
    const res = await dispatch(getRelatedDocuments(id))
    const { payload } = res || {}
    const { headers, data } = payload || {}
    const fileName = headers['content-disposition']?.split('filename=')?.[1]
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: 'application/zip',
        encoding: 'UTF-8'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    const response = await dispatch(saveSeriesFormD({ id, data: { ...data } }))
    if (response && response.payload === 200) {
      await dispatch(getSeriesFormD({ id }))
      await dispatch(getSeriesFilings(id))
      dispatch(resetError())
    }
    setBtnLoading(false)
  }

  const { seriesId, seriesName } = series || {}

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      {error && errorMessage && (
        <Alert
          color="danger"
          visible={error}
          onClose={() => dispatch(resetError())}
          text={errorMessage}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between align-items-end">
            <div className='d-flex align-items-end'>
              <h3 className='m-0'>{seriesName?.replace('Linqto Liquidshares LLC -', '')}</h3>
              <h3 className='ms-2 my-0'>Series No. {seriesId}</h3>
              <CDropdown className="ms-2">
              <CDropdownToggle caret={false} color="secondary">
                <CIcon icon={cilSettings} />
              </CDropdownToggle>
              <CDropdownMenu className="p-0 hover mt-1">
                <CDropdownItem
                  className="p-2"
                  onClick={handleClick}
                >
                  Generate ZIP
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
            </div>
            <SubmitButton loading={btnLoading} text="Save" />
          </div>
          <SeriesFormDDetailForm
            loading={btnLoading}
            btnLoading={btnLoading}
            setBtnLoading={setBtnLoading}
          />
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default SeriesFormDDetail
