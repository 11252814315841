import React, { useCallback, useEffect, useMemo } from 'react'
import PageLayout from '../../layout/PageLayout'
import { useParams, useHistory } from 'react-router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import privateAPI from '../../APIs/private'
import Spinner from '../../components/Spinner'
import { formatToPT } from '../../utils/formatUtils/utils'
import { CButton, CFormInput, CFormLabel, CFormSelect, CFormTextarea } from '@coreui/react-pro'
import Checkbox from '../../components/Checkbox'
import SubmitButton from '../../components/SubmitButton'
import GenericTable from '../../components/GenericTable'
import Select from '../assets/components/Select'
import { getSegmentList } from '../../utils/commonActions'
import { debounce } from 'lodash'
import { components } from 'react-select'
import ReactDatePicker from 'react-datepicker'
import CIcon from '@coreui/icons-react'
import { cilCopy } from '@coreui/icons'

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}

const BUNDLE_COMPANY_COLUMNS = [
  {
    key: 'bundleCompanyId',
    name: "bundleCompanyId',",
    label: "Bundle Company Id",
    type: "Number",
    renderer: "Id"
  },
  {
    key: 'companyId',
    name: "companyId",
    label: "Company Id",
    type: "Number",
    renderer: "Id"
  },
  {
    key: 'name',
    name: "name",
    label: "Name",
    type: "String"
  }
]

const getBundle = async (id: string) => {
  const response = await privateAPI.get(`/queries/bundles/${id}`)
  return response?.data?.rows?.[0] || {}
}

const getBundleCompany = async (id: string) => {
  const response = await privateAPI.post('/queries/bundle_companies', { bundleId: { filterValues: [id] }, bundleCompanyId: {}, companyId: {} })
  const companies: any = []
  const bundleCompanies = response?.data?.rows || []
  for (const bundleCompany of bundleCompanies) {
    const companyResponse = await privateAPI.post('/queries/companies', { companyId: { filterValues: [bundleCompany?.companyId] }, name: {} })
    const companyName = companyResponse?.data?.rows?.[0]?.name || ''
    companies.push({ ...bundleCompany, name: companyName })
  }
  return companies
}

const saveBundle = async (id: string, body: any) =>
  await privateAPI.post(`/queries/bundles/${id}`, body)

const getSegment = async (id: string) => {
  const response = await privateAPI.get(`/queries/segments/${id}`)
  return response?.data?.rows?.[0] || {}
}

const BundleDetail: React.FC<any> = () => {
  const { id }: any = useParams()
  const history = useHistory()
  const queryClient = useQueryClient()
  const methods = useForm()
  const { reset, register, handleSubmit, setValue, watch, formState: { errors } } = methods

  // bundle data
  const { data, isInitialLoading: bundleLoading } = useQuery({
    queryKey: ['bundles', id],
    queryFn: () => getBundle(id),
    enabled: +id > 0,
  })

  const bundle = useMemo(() => data, [data])
  const { segmentId, bundlePromoLink, hasExistingTrades } = bundle || {}

  // segment data
  const { data: segmentData, isInitialLoading: segmentLoading } = useQuery({
    queryKey: ['segments', segmentId],
    queryFn: () => getSegment(segmentId),
    enabled: segmentId > 0,
  })

  const segment = useMemo(() => segmentData, [segmentData])

  // bundle companies data
  const { data: bundleCompanies, isInitialLoading: bundleCompanyLoading } = useQuery({
    queryKey: ['bundle_companies', id],
    queryFn: () => getBundleCompany(id),
    enabled: +id > 0,
  })

  const { createdAt, bundleId, code } = bundle || {}

  const saveBundleMutation = useMutation({
    mutationFn: async (data: any) => {
      const obj = { ...data }
      // get startAt, finishAt from object
      const startAt = watch('startAt')
      const finishAt = watch('finishAt')
      // format dates from string to timestamp if they are not numbers
      obj.startAt = typeof startAt === 'number' ? startAt : Date.parse(startAt)
      obj.finishAt = typeof finishAt === 'number' ? finishAt : Date.parse(finishAt) 
      await saveBundle(id, obj)
    },
    onSuccess: () => {
      // if creating a new bundle, go back to the list page after saving
      if (isCreateBundle) {
        history.goBack()
      } else {
        // if updating an existing bundle, invalidate the queries to refetch the data
        queryClient.invalidateQueries({ queryKey: ['bundles', id] })
        queryClient.invalidateQueries({ queryKey: ['segments', segmentId] })
      }
  }
  })

  const fetchSegmentData = useCallback(
    async (e: string) => {
      const data = {
        segmentId: {},
        name: {},
        freeTextSearch: e,
        length: 10,
      }

      if (e.length > 0) {
        const response = await getSegmentList(data)
        if (response && response.length) {
          const options: any[] | undefined = response.map(
            (a: any) => {
              return {
                id: a.segmentId,
                value: a.name,
                label: a.name
              }
            },
          )
          return options
        }
      }
    },
    [],
  )

  const loadSegmentSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchSegmentData(inputValue).then((options: any[] | undefined) => callback(options))
      }, 500),
    [fetchSegmentData],
  )

  const handleSegmentChange = (value: any) => {
    if (value) {
      setValue('segmentName', value.value, { shouldDirty: true })
      setValue('segmentId', value.id, { shouldDirty: true })
    }
  }

  // form submit
  const onSubmit = async (data: any) => saveBundleMutation.mutate(data)

  // reset form data when bundle and segment data is loaded
  useEffect(() => reset({ ...bundle, segmentName: segment?.name }), [bundle, segment, reset])

  const { isLoading: saveLoading } = saveBundleMutation
  
  if (bundleLoading || bundleCompanyLoading || segmentLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  // check if creating a new bundle
  const isCreateBundle = +id === -1

  return (
    <>
      <PageLayout>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='d-flex justify-content-between'>
              <h4>{isCreateBundle ? 'Create Bundle' : `Bundle No. ${bundleId}`}</h4>
              <SubmitButton loading={saveLoading} text={isCreateBundle ? 'Create' : 'Save'} />
            </div>
            <div className="row g-3 mb-4">
              <div className="col-md-3">
                <CFormLabel>Name</CFormLabel>
                <CFormInput {...register('name', { required: 'This field is required' })} disabled={saveLoading || hasExistingTrades} />
                {errors.name && <span className="text-danger">{errors.name.message}</span>}
              </div>
              <div className="col-md-3">
                <CFormLabel>Display Name</CFormLabel>
                <CFormInput {...register('title')} disabled={saveLoading} />
                {errors.name && <span className="text-danger">{errors.name.message}</span>}
              </div>
              <div className="col-md-3">
                <CFormLabel>Display Price</CFormLabel>
                <CFormInput {...register('price')} disabled={saveLoading} />
                {errors.name && <span className="text-danger">{errors.name.message}</span>}
              </div>
              <div className="col-md-3">
                <CFormLabel>Type</CFormLabel>
                <CFormSelect {...register('type')} disabled={saveLoading || isCreateBundle || hasExistingTrades}>
                  <option value='FIXED_PRICE'>Fixed Price</option>
                  <option value='CURRENT PRICE'>Current Price</option>
                </CFormSelect>
                {errors.type && <span className="text-danger">{errors.type.message}</span>}
              </div>
              <div className="col-md-3">
                <CFormLabel>Code</CFormLabel>
                <CFormInput {...register('code')} disabled={!!code || hasExistingTrades} />
                {errors.code && <span className="text-danger">{errors.code.message}</span>}
              </div>
              <div className="col-md-3">
                <CFormLabel className="form-label">Segment</CFormLabel>
                <div className="d-flex flex-row">
                  <Select
                    options={Option}
                    loadSuggestedOptions={loadSegmentSuggestedOptions}
                    handleChange={(value: any) => handleSegmentChange(value)}
                    watchValue={watch('segmentName')}
                    query="segments"
                    id={watch('segmentId')}
                    readOnly={saveLoading}
                    {...register('segmentId', { required: 'This field is required' })}
                  />
                </div>
                {errors.segmentId && <span className="text-danger">{errors.segmentId.message}</span>}
              </div>
              <div className="col-md-3">
                <CFormLabel>Sort Index</CFormLabel>
                <CFormInput {...register('featuredIndex')} disabled={saveLoading} />
                {errors.code && <span className="text-danger">{errors.code.message}</span>}
              </div>
              {!isCreateBundle && <div className="col-md-3">
                <CFormLabel>Created At</CFormLabel>
                <CFormInput value={formatToPT(createdAt, 'MM/DD/YYYY')} disabled={saveLoading || !isCreateBundle} />
              </div>}
              <div className="col-md-3">
                <CFormLabel className="form-label">Start At</CFormLabel>
                <Controller
                  name='startAt'
                  render={({ field: { onChange, value } }) => <ReactDatePicker selected={value} className="form-control" onChange={onChange} disabled={saveLoading} showTimeSelect dateFormat="MM/dd/yyyy hh:mm a" />}
                />
              </div>
              <div className="col-md-3">
                <CFormLabel className="form-label">Finish At</CFormLabel>
                <Controller
                  name='finishAt'
                  render={({ field: { onChange, value } }) => <ReactDatePicker selected={value} className="form-control" onChange={onChange} disabled={saveLoading} showTimeSelect dateFormat="MM/dd/yyyy hh:mm a" />}
                />
              </div>
              <div className="col-md-3">
                <CFormLabel>Description</CFormLabel>
                <CFormTextarea {...register('description')} disabled={saveLoading} rows={3} />
              </div>
              <Checkbox formLabel="Is Active" registerValue="isActive" btnLoading={false} />
            </div>
            {bundlePromoLink && <div className='mb-5'>
              <h4>Bundle Link</h4>
              <a href={bundlePromoLink}>{bundlePromoLink}</a> <span style={{ cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(bundlePromoLink)}><CIcon icon={cilCopy} /></span>
            </div>}
            {!isCreateBundle && <div>
              <div className='d-flex justify-content-between'>
                <h4>Companies</h4>
                <CButton onClick={() => history.push(`/bundle_companies/-1?bundleId=${id}`)}>Add Company</CButton>
              </div>
              <GenericTable
                items={bundleCompanies ?? []}
                columns={BUNDLE_COMPANY_COLUMNS}
                handleClick={(item: any) => history.push(`/bundle_companies/${item.bundleCompanyId}?bundleId=${id}`)}
              />
            </div>}
          </form>
        </FormProvider>
      </PageLayout>
    </>
  )
}

export default BundleDetail
