import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import api from '../../APIs/private'
import { getData } from '../../utils/commonActions'
import {
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CLoadingButton,
} from '@coreui/react-pro'
import Select from '../assets/components/Select'
import { formatDecimal, formatToPT, getNumericDollar } from '../../utils/formatUtils/utils'

const getSeries = async (id: string) => {
  const response = await api.get(`/queries/serieses/${id}`)
  return response?.data?.rows?.[0]
}

const SeriesDetail: any = () => {
  const queryClient = useQueryClient()
  const { id }: any = useParams()
  const methods = useForm()
  const {
    reset,
    handleSubmit,
    register,
    formState: { isDirty },
  } = methods

  const { data: seriesData, isInitialLoading: seriesLoading } = useQuery({
    queryKey: ['serieses', id],
    queryFn: async () => await getSeries(id),
    enabled: +id > 0,
  })

  const {
    seriesId,
    companyId,
    costCompleted,
    filingDate,
    marginCompleted,
    revenueCompleted,
    sharesCompleted,
    sharesPending,
  } = seriesData || {}

  const { data: companyData, isInitialLoading: companyLoading } = useQuery({
    queryKey: ['serieses_company', companyId],
    queryFn: async () =>
      await getData('companies', {
        companyId: { filterMin: companyId, filterMax: companyId },
        name: {},
        companyName: {}
      }),
    enabled: !!companyId,
  })

  const { name: companyName } = companyData || {}

  const saveSeriesMutation = useMutation({
    mutationFn: async (data) =>
      await api.post(`/queries/serieses/${id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['serieses', id],
      })
    }
  })

  useEffect(() => {
    reset(seriesData)
  }, [seriesData, reset])

  const loading = seriesLoading || companyLoading

  const btnLoading = saveSeriesMutation.isLoading

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveSeriesMutation.mutate)}>
          <div className="d-flex justify-content-between">
            <h3>Series No.{seriesId}</h3>
            <div className="d-flex">
              <CLoadingButton
                disabled={!isDirty}
                className="btn-success"
                loading={btnLoading}
                type="submit"
              >
                Save
              </CLoadingButton>
            </div>
          </div>
          <h4>Series Information</h4>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>Series Name</CFormLabel>
              <CFormInput disabled {...register('name')} />
            </div>
            <div className='col-md-3'>
              <CFormLabel>Company Name</CFormLabel>
              <Select readOnly watchValue={companyName} query="companies" id={companyId} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Accession Number</CFormLabel>
              <CFormInput disabled {...register('accessionNumber')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Available Owners</CFormLabel>
              <CFormInput disabled {...register('availableOwners')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Cost Completed</CFormLabel>
              <CFormInput disabled value={formatDecimal(costCompleted || 0)} />
            </div>
            <div className="col-md-3">
              <CFormLabel>EFDID</CFormLabel>
              <CFormInput disabled {...register('efdid')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>EIN</CFormLabel>
              <CFormInput disabled {...register('ein')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Filing Date</CFormLabel>
              <CFormInput disabled value={formatToPT(filingDate, 'MM/DD/YY')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Margin Completed</CFormLabel>
              <CFormInput disabled value={formatDecimal(marginCompleted || 0)} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Max Owners</CFormLabel>
              <CFormInput disabled {...register('maxOwners')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Revenue Completed</CFormLabel>
              <CFormInput disabled value={formatDecimal(revenueCompleted || 0)} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Shares Completed</CFormLabel>
              <CFormInput disabled value={formatDecimal(sharesCompleted || 0)} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Shares Pending</CFormLabel>
              <CFormInput disabled value={getNumericDollar(sharesPending || 0)} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Closed State Filter</CFormLabel>
              <CFormTextarea disabled={saveSeriesMutation.isLoading} {...register('closedToStates')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>State Filter</CFormLabel>
              <CFormTextarea disabled={saveSeriesMutation.isLoading} {...register('limitedToStates')} />
            </div>
          </div>
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default SeriesDetail
