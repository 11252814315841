import React, { useState, useEffect, useMemo } from 'react'
import PageLayout from '../../layout/PageLayout'
import { useParams, useHistory } from 'react-router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'
import DiscountDetailForm from './DiscountDetailForm'
import privateAPI from '../../APIs/private'
import { DiscountFormValues } from './discount-types'
import Spinner from '../../components/Spinner'
import { formatTableRenderers } from '../../utils/formatUtils/utils'
import DiscountCompanyModal from './components/DiscountCompanyModal'
import DiscountTierModal from './components/DiscountTierModal'

const getDiscount = async (id: string) => {
  const response = await privateAPI.get(`/queries/DISCOUNTS/${id}`)
  return response.data
}

const getDiscountCompanies: any = async (id: number) => {
  const data = {
    discountCompanyId: {},
    companyId: {},
    companyName: {},
    discountId: { include: false, filterValues: [+id] }
  }
  const response = await privateAPI.post(
    '/queries/DISCOUNT_COMPANIES',
    data
  )

  const { rows, columns } = formatTableRenderers(
    response?.data?.rows,
    response?.data?.columns,
  )
  return { discountCompanies: rows, discountCompaniesColumns: columns }
}

const getDiscountTiers: any = async (id: number) => {
  const data = {
    discountTierId: {},
    minimumPurchase: {},
    amount: {},
    percent: {},
    discountId: { include: false, filterValues: [+id] }
  }
  const response = await privateAPI.post(
    '/queries/DISCOUNT_TIERS',
    data
  )

  const { rows, columns } = formatTableRenderers(
    response?.data?.rows,
    response?.data?.columns,
  )
  return { discountTiers: rows, discountTiersColumns: columns }
}

const createDiscount = async (body: DiscountFormValues) =>
  await privateAPI.post('/queries/DISCOUNTS/-1', body)

const saveDiscount = async (id: string, body: DiscountFormValues) =>
  await privateAPI.post(`/queries/DISCOUNTS/${id}`, body)

const DiscountDetail: React.FC<any> = () => {
  const { id }: any = useParams()
  const history = useHistory()
  const queryClient = useQueryClient()
  const methods = useForm()
  const { reset } = methods

  const [showDiscountCompany, setShowDiscountCompany] = useState(false)
  const [showDiscountTier, setShowDiscountTier] = useState(false)
  const [discountCompany, setDiscountCompany] = useState<any>(null)
  const [discountTier, setDiscountTier] = useState<any>(null)

  const { data, isInitialLoading } = useQuery({
    queryKey: ['discounts', id],
    queryFn: () => getDiscount(id),
    enabled: +id > 0,
  })

  const { data: discountCompaniesData } = useQuery({
    queryKey: ['discount_companies', id],
    queryFn: () => getDiscountCompanies(id),
    enabled: +id > 0,
  })

  const { data: discountTiersData } = useQuery({
    queryKey: ['discount_tiers', id],
    queryFn: () => getDiscountTiers(id),
    enabled: +id > 0,
  })

  const { discountCompanies, discountCompaniesColumns } = discountCompaniesData || {}
  const { discountTiers, discountTiersColumns } = discountTiersData || {}

  const saveDiscountMutation = useMutation({
    mutationFn: async (data: DiscountFormValues) => await saveDiscount(id, data),
    onSuccess: () => {
      history.goBack()
    }
  })

  const createDiscountMutation = useMutation({
    mutationFn: async (data: DiscountFormValues) => await createDiscount(data),
    onSuccess: () => {
      history.goBack()
    }
  })

  const discount = useMemo(() => data?.rows?.[0] || {}, [data?.rows])

  useEffect(() => reset(discount), [discount, reset])

  const { isLoading: saveLoading } = saveDiscountMutation
  const { isLoading: createLoading } = createDiscountMutation

  const closeDiscountCompanyModal = (action: any) => {
    setShowDiscountCompany(false)
    setDiscountCompany(null)
    if (action === 'success') {
      queryClient.invalidateQueries({ queryKey: ['discount_companies', id] })
    }
  }

  const closeDiscountTierModal = (action: any) => {
    setShowDiscountTier(false)
    setDiscountTier(null)
    if (action === 'success') {
      queryClient.invalidateQueries({ queryKey: ['discount_tiers', id] })
    }
  }

  if (isInitialLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <>
      <PageLayout>
        <FormProvider {...methods}>
          <DiscountDetailForm
            header={+id > 0 ? `Discount No. ${id}` : 'Create Discount'}
            onSubmit={
              +id > 0 ? saveDiscountMutation.mutate : createDiscountMutation.mutate
            }
            loading={+id > 0 ? saveLoading : createLoading}
            discount={discount}
            discountCompanies={discountCompanies}
            discountCompaniesColumns={discountCompaniesColumns}
            discountTiersColumns={discountTiersColumns}
            discountTiers={discountTiers}
            setDiscountCompany={setDiscountCompany}
            setDiscountTier={setDiscountTier}
            setShowDiscountCompany={setShowDiscountCompany}
            setShowDiscountTier={setShowDiscountTier}
          />
        </FormProvider>
      </PageLayout>
      {showDiscountCompany && (
        <DiscountCompanyModal
          header={discountCompany ? `Update Discount Company - ${discountCompany.discountCompanyId}` : 'Add Discount Company'}
          open={!!showDiscountCompany}
          discountId={discount?.discountId}
          discountCompany={discountCompany}
          abandonAction={(action?: any) => closeDiscountCompanyModal(action)}
        />
      )}
      {showDiscountTier && (
        <DiscountTierModal
          header={discountTier ? `Update Discount Tier - ${discountTier.discountTierId}` : 'Add Discount Tier'}
          open={showDiscountTier}
          discountId={discount?.discountId}
          discountTier={discountTier}
          abandonAction={(action?: any) => closeDiscountTierModal(action)}
        />
      )}
    </>
  )
}

export default DiscountDetail
