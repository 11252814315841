import React, { useEffect } from 'react'
import { CBadge, CSmartTable } from '@coreui/react-pro'
import useQuery from '../hooks/useQuery'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { capitalizeFirstLetter, toCamalCase } from '../utils/formatUtils/utils'
import { getBadgeColor } from '../utils/renderUtils/utils'

/* eslint react/prop-types: 0 */
const Table: any = ({
  items,
  handleClick,
  handleSort,
  columns,
  isList,
  sorter,
  setSorter,
}) => {
  const query = useQuery()
  const length = Number(query.get('length'))
  const params = query.get('params')
  const queryName = query.get('queryName')

  const { filtered } = useSelector((state) => state.list)

  useEffect(() => {
    try {
      const data = JSON.parse(params)
      for (const prop in data) {
        if (data[prop].sortIndex) {
          setSorter({
            column: prop,
            state: data[prop].sortAsc ? 'asc' : 'desc',
          })
        }
      }
    } catch (e) {
      console.log('UNABLE TO PARSE JSON --> TABLE: ', e)
    }
  }, [params, setSorter])

  // REMOVE ID FROM TABLE
  const updatedColumns = columns
    ? columns.filter(
        (col) =>
          col.renderer !== 'Id' ||
          col.name === 'faqListId' ||
          col.name === 'cashAccountId' ||
          col.name === 'upholdWithdrawId' ||
          col.name === 'upholdCashAccountId' ||
          (queryName === 'sell_offers' && col.name === 'userId'),
      )
    : []

  if (!items || items?.length === 0) {
    return (
      <h4 className="d-flex align-items-center justify-content-center mt-4">
        No Items Found
      </h4>
    )
  }

  const arr = [...items]
  if (items && items.length && columns && columns.length) {
    let obj = {}
    columns.forEach((col) => {
      obj[col.name] = ''
      for (const prop in filtered) {
        if (col.name === prop) {
          if (filtered[prop] != null) {
            obj[prop] = filtered[prop]
          } else {
            obj[prop] = null
          }
        }
      }
    })
    arr.splice(arr.length <= length ? arr.length + 1 : arr.length - 1, 0, {
      ...obj,
      footer: true,
    })
  }

  const scopedColumns = {}

  let wrapper = (column) => {
    const { name, type, key, renderer } = column || {}

    let obj = (row) => {
      const { footer } = row || {}
      const data = row[key]
      switch (type) {
        case 'Number': {
          if (renderer === 'Id' && data !== '') {
            return <td>{data}</td>
          } else if (renderer && data !== '') {
            if (renderer === 'Money') {
              return (
                <td style={{ textAlign: 'right' }}>
                  {data
                    ? `${data.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : data === 0
                    ? data
                    : ''}
                </td>
              )
            } else if (renderer === 'Shares') {
              return (
                <td style={{ textAlign: 'right' }}>
                  {data
                    ? `${data.toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      })}`
                    : footer
                    ? null
                    : 0}
                </td>
              )
            }
          }
          return (
            <td style={{ textAlign: 'right' }}>
              {data ? data.toLocaleString('en-US') : footer ? null : 0}
            </td>
          )
        }
        case 'String': {
          if (name && data !== '') {
            if (name.includes('status') || renderer?.includes('Status')) {
              return (
                <td>
                  <CBadge
                    style={{
                      backgroundColor: getBadgeColor(toCamalCase(data))[0],
                      color: getBadgeColor(toCamalCase(data))[1],
                    }}
                    className="w-100"
                  >
                    {data === 'Accredited' ? 'Verified' : data}
                  </CBadge>
                </td>
              )
            } else if (name.includes('notes') || renderer?.includes('Notes')) {
              return (
                <td>
                  {data.map((note, i) => (
                    <span className="d-block" key={i}>
                      <span className="fw-bold">{note.firstName}</span>{' '}
                      {note.notes} {note.date}{' '}
                    </span>
                  ))}
                </td>
              )
            } else if (name.includes('series')) {
              return <td>{data?.replace('Linqto Liquidshares LLC - ', '')}</td>
            }
          }
          return <td>{data}</td>
        }
        case 'Boolean': {
          return <td>{capitalizeFirstLetter(data?.toString() || '')}</td>
        }
        default:
          return <td>{data}</td>
      }
    }
    return obj
  }

  updatedColumns.forEach((t) => {
    scopedColumns[t.key] = wrapper(t)
  })

  return (
    <>
      <CSmartTable
        tableBodyProps={{ className: 'hover' }}
        sorterValue={sorter}
        columnSorter={{ external: true, resetable: false }}
        onSorterChange={(item) =>
          !_.isEqual(item, sorter) ? handleSort(item) : null
        }
        itemsPerPage={length + 1}
        items={arr}
        clickableRows
        onRowClick={(item) => (handleClick ? handleClick(item) : null)}
        columns={updatedColumns}
        pagination={!isList}
        tableProps={{
          hover: true,
          borderColor: 'primary',
          color: 'white',
          striped: true,
          align: 'bottom',
          responsive: true,
        }}
        scopedColumns={scopedColumns}
      />
    </>
  )
}

export default Table
