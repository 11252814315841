import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import qs from 'qs'

// Define a type for the slice state
interface BrokerState {
  columns: any[]
  filtered: any
  rows: any[]
  total: any
  pageCount: number
  curUser: any
  curOrder: any
}

// Define the initial state using that type
const initialState: BrokerState = {
  columns: [],
  filtered: {},
  rows: [],
  total: {},
  pageCount: 0,
  curUser: {},
  curOrder: {},
}

export const getBrokerUser = createAsyncThunk(
  'getBrokerUser',
  async ({ id }: any) => {
    try {
      const response = await privateAPI.get(`/brokerDealer/users/${id}`)
      return response.data
    } catch (e) {
      console.log('error getting entity', e)
    }
  },
)

export const getBrokerOrder = createAsyncThunk(
  'getBrokerOrder',
  async ({ id }: any) => {
    try {
      const response = await privateAPI.get(`/brokerDealer/orders/${id}`)
      return response.data
    } catch (e) {
      console.log('error getting entity', e)
    }
  },
)

export const updateBrokerOrder = createAsyncThunk(
  'updateBrokerOrder',
  async ({ id, data }: any) => {
    try {
      const response = await privateAPI.post(`/brokerDealer/orders/${id}`, {
        ...data,
      })
      return response.data
    } catch (e) {
      console.log('error updating order: ', e)
    }
  },
)

export const getBrokerOrders = createAsyncThunk(
  'getBrokerOrders',
  async (query: any) => {
    try {
      const body: any = {
        defaultInclude: false,
        orderId: {},
        user: {},
        series: {},
        shares: {},
        sharePrice: {},
        price: {},
        orderDoneAt: {},
        userId: {},
        status: {
          filterValues: ['COMPLETED'],
          include: false,
        },
        brokerApprovalStatus: {},
      }
      const parsedQuery = qs.parse(query)
      const sortkey = parsedQuery.orderColumnName as string
      const sortAsc = parsedQuery.orderAsc as string
      if (sortAsc && sortkey) {
        body[sortkey].sortIndex = 0
        body[sortkey].sortAsc = sortAsc === 'true'
      }
      body.brokerApprovalStatus.filterValues = parsedQuery.brokerApprovalStatus
      const response = await privateAPI.post('/brokerDealer/orders', {
        ...parsedQuery,
        ...body,
      })
      return response.data
    } catch (e) {
      console.log('error getting broker orders: ', e)
    }
  },
)

export const brokerSlice = createSlice({
  name: 'broker',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBrokerUser.fulfilled, (state, { payload }: any) => {
      state.curUser = payload.rows[0]
    })
    builder.addCase(getBrokerUser.rejected, (state, { payload }: any) => {
      console.log('error gettin broker user: ', payload)
    })
    builder.addCase(getBrokerOrder.fulfilled, (state, { payload }: any) => {
      state.curOrder = payload.rows[0]
    })
    builder.addCase(getBrokerOrder.rejected, (state, { payload }: any) => {
      console.log('error gettin broker user: ', payload)
    })
    builder.addCase(getBrokerOrders.fulfilled, (state, { payload }) => {
      state.columns = payload.columns
      state.filtered = payload.filtered
      state.rows = payload.rows
    })
    builder.addCase(getBrokerOrders.rejected, (state, action) => {
      console.log('rejected: ', action)
    })
  },
})

export default brokerSlice.reducer
