import axios from 'axios'
import history from '../history'

const request = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
})

request.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

request.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    history.push('/login')
  }
  throw error
})

export default request
