import React, { useState } from 'react'
import {
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import CIcon from '@coreui/icons-react'
import { cilSettings } from '@coreui/icons'
import Document from '../../components/Document'
import { useHistory } from 'react-router-dom'
import { RegionDropdown, CountryDropdown } from 'react-country-region-selector'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { uploadDocs } from '../../utils/commonActions'
import Note from '../../components/Note'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import Select from '../assets/components/Select'

const EntityDetailForm: React.FC = ({
  onSubmit,
  btnLoading,
  data,
  id,
  handleRegenerateDocuments,
}: any) => {
  const [note, setNote] = useState('')
  const queryClient = useQueryClient()
  const history = useHistory()
  const me = useAppSelector((state: RootState) => state.user)
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors, isDirty },
  } = useFormContext()

  const { fields, remove, prepend } = useFieldArray({ control, name: 'notes' })

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  const handleFileSelectedMutator = useMutation({
    mutationFn: async ({
      event,
      type,
    }: {
      event: React.ChangeEvent<HTMLInputElement>
      type: string
    }) => {
      event.preventDefault()
      const file = event.target.files?.[0] || null
      if (file) {
        await uploadDocs({
          acceptedFile: file,
          fileName: file.name,
          type,
          userId: data?.userId,
          entityId: data?.entityId,
        })
      }
    },
    onSuccess: () => queryClient.invalidateQueries(['entities', id]),
  })

  const {
    entityId,
    userId,
    name,
    type,
    subType,
    taxClassification,
    accountDesignation,
    contactPerson,
    userTitle,
    userFullName,
    taxId,
    street1,
    street2,
    city,
    zip,
    taxWithholdingAndReportingDocuments,
    documents,
    status,
  } = data || {}

  const loading = btnLoading || handleFileSelectedMutator.isLoading
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row g-3 mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-start">
            <h3>
              Entity No. {entityId} - {name}
            </h3>
            <CDropdown className="ms-2">
              <CDropdownToggle caret={false} color="secondary">
                <CIcon icon={cilSettings} />
              </CDropdownToggle>
              <CDropdownMenu className="p-0 hover mt-1">
                <CDropdownItem
                  className="p-2"
                  onClick={handleRegenerateDocuments}
                >
                  Regenerate Transaction Documents
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </div>
          <div>
            <CLoadingButton
              className="btn-success"
              loading={loading}
              type="submit"
              disabled={!isDirty}
            >
              Save
            </CLoadingButton>
          </div>
        </div>
        <h4>Entity Information</h4>
        <div className="col-md-3">
          <CFormLabel>Name</CFormLabel>
          <CFormInput
            disabled={loading}
            placeholder="Name"
            defaultValue={name}
            {...register('name', { required: 'This field is required.' })}
          />
          {errors.name && (
            <span className="text-danger">{errors.name.message}</span>
          )}
        </div>
        <div className="col-md-3">
          <CFormLabel>Type</CFormLabel>
          <CFormSelect
            disabled={loading}
            defaultValue={type}
            {...register('type', { required: 'This field is required.' })}
          >
            <option value="NON_PROFIT_CORPORATION_OR_ENDOMENT_OR_FOUNDATION">
              Non Profit Corporation or Endoment or Foundation
            </option>
            <option value="INDIVIDUAL_RETIREMENT_ACCOUNT_OR_INDIVIDUAL_RETIREMENT_ANNUITY">
              Individual Retirement Account or Individual Retirement Annuity
            </option>
            <option value="CORPORATION">Corporation</option>
            <option value="TRUST">Trust</option>
            <option value="TENANTS_IN_COMMON">Tenants In Common</option>
            <option value="JOINT_TENANTS">Joint Tenants</option>
            <option value="PARTNERSHIP_OR_LIMITED_LIABILITY_COMPANY">
              Partnership Or Limited Liability Company
            </option>
          </CFormSelect>
        </div>
        <div className="col-md-3">
          <CFormLabel>Sub Type</CFormLabel>
          <CFormSelect
            disabled={loading}
            defaultValue={subType}
            {...register('subType', { required: 'This field is required.' })}
          >
            <option value="NONE">None</option>
            <option value="TRADITIONAL_IRA">Traditional IRA</option>
            <option value="ROTH_IRA">Roth IRA</option>
            <option value="SEP_IRA">Sep IRA</option>
          </CFormSelect>
        </div>
        <div className="col-md-3">
          <CFormLabel>Tax Classification</CFormLabel>
          <CFormSelect
            disabled={loading}
            defaultValue={taxClassification}
            {...register('taxClassification', {
              required: 'This field is required.',
            })}
          >
            <option value="NONE">None</option>
            <option value="SOLE_PROPRIETOR">Sole Proprietor</option>
            <option value="C_CORP">C Corp</option>
            <option value="S_CORP">S Corp</option>
            <option value="PARTNERSHIP">Partnership</option>
            <option value="TRUST">Trust</option>
            <option value="LLC_C_CORP">Llc C Corp</option>
            <option value="LLC_S_CORP">Llc S Corp</option>
            <option value="LLC_PARTNERSHIP">Llc Partnership</option>
          </CFormSelect>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel>Account Designation</CFormLabel>
          <CFormInput
            disabled={loading}
            placeholder="Account Designation"
            defaultValue={accountDesignation}
            {...register('accountDesignation')}
          />
          {errors.accountDesignation && (
            <span className="text-danger">
              {errors.accountDesignation.message}
            </span>
          )}
        </div>
        <div className="col-md-3">
          <CFormLabel>Contact Person</CFormLabel>
          <CFormInput
            disabled={loading}
            placeholder="Contact Person"
            defaultValue={contactPerson}
            {...register('contactPerson')}
          />
          {errors.contactPerson && (
            <span className="text-danger">{errors.contactPerson.message}</span>
          )}
        </div>
        <div className="col-md-3">
          <CFormLabel>User Title</CFormLabel>
          <CFormInput
            disabled={loading}
            placeholder="User Title"
            defaultValue={userTitle}
            {...register('userTitle')}
          />
          {errors.userTitle && (
            <span className="text-danger">{errors.userTitle.message}</span>
          )}
        </div>
        <div className="col-md-3">
          <CFormLabel>User</CFormLabel>
          <Select
              readOnly
              watchValue={userFullName}
              query="users"
              id={userId}
              ref={null}
            />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel>Tax ID</CFormLabel>
          <CFormInput
            disabled={loading}
            placeholder="Tax ID"
            defaultValue={taxId}
            {...register('taxId', { required: 'This field is required.' })}
          />
        </div>
        <div className="col-md-3">
          <CFormLabel>Status</CFormLabel>
          <CFormSelect
            disabled={loading}
            defaultValue={status}
            {...register('status', {
              required: 'This field is required.',
            })}
          >
            <option value="REQUESTED">Requested</option>
            <option value="PENDING_REVIEW">Pending Review</option>
            <option value="APPROVED">Approved</option>
            <option value="REJECTED">Rejected</option>
          </CFormSelect>
        </div>
      </div>
      <h4>Address Information</h4>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel>Street 1</CFormLabel>
          <CFormInput
            disabled={loading}
            placeholder="Street 1"
            defaultValue={street1}
            {...register('street1', { required: 'This field is required.' })}
          />
        </div>
        <div className="col-md-3">
          <CFormLabel>Street 2</CFormLabel>
          <CFormInput
            disabled={loading}
            placeholder="Street 2"
            defaultValue={street2}
            {...register('street2')}
          />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel>City</CFormLabel>
          <CFormInput
            disabled={loading}
            placeholder="City"
            defaultValue={city}
            {...register('city', { required: 'This field is required.' })}
          />
        </div>
        <div className="col-md-3">
          <CFormLabel>State</CFormLabel>
          <Controller
            rules={{ required: 'This field is required.' }}
            name="state"
            render={({ field: { onChange, value } }) => (
              <RegionDropdown
                disabled={loading}
                classes="form-select"
                country={watch('country')}
                onChange={onChange}
                value={value}
              />
            )}
          />
          {errors.state && (
            <span className="text-danger">{errors.state.message}</span>
          )}
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-6">
          <CFormLabel>Country</CFormLabel>
          <Controller
            rules={{ required: 'This field is required.' }}
            name="country"
            render={({ field: { onChange, value } }) => (
              <CountryDropdown
                classes="form-select"
                disabled={loading}
                onChange={onChange}
                value={value}
              />
            )}
          />
          {errors.country && (
            <span className="text-danger">{errors.country.message}</span>
          )}
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-6">
          <CFormLabel>Zip</CFormLabel>
          <CFormInput
            disabled={loading}
            placeholder="Zip"
            defaultValue={zip}
            {...register('zip', { required: 'This field is required.' })}
          />
        </div>
      </div>
      <h4>Tax Reporting Document</h4>
      <div className="row g-3 mb-4 d-flex align-items-center">
        <div className="col-mb-6 w-50">
          {taxWithholdingAndReportingDocuments?.map(
            ({ name, url, createdAt }: any, i: number) => (
              <Document key={i} name={name} url={url} createdAt={createdAt} loading={loading} />
            ),
          )}
          <div className="d-flex justify-content-between">
            <CFormInput
              disabled={loading}
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFileSelectedMutator.mutate({
                  event: e,
                  type: 'ENTITY_TAX',
                })
              }
              accept=".pdf, .png, .jpg, .jpeg"
            />
          </div>
        </div>
      </div>
      <h4>Other Documents</h4>
      <div className="row g-3 mb-4 d-flex align-items-center">
        <div className="col-mb-6 w-50">
          {documents?.map(({ name, url, createdAt }: any, i: number) => (
            <Document key={i} name={name} url={url} createdAt={createdAt} loading={loading} />
          ))}
          <div className="d-flex justify-content-between">
            <CFormInput
              disabled={loading}
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFileSelectedMutator.mutate({
                  event: e,
                  type: 'INTERNAL_DOCUMENT',
                })
              }
              accept=".pdf, .png, .jpg, .jpeg"
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="col-md-12 d-flex align-items-end justify-content-between">
          <div className="d-flex flex-column w-100 me-2">
            <h4>Notes</h4>
            <CFormLabel>Add Note</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              value={note}
              onChange={(e: any) => setNote(e.target.value)}
            />
          </div>
          <CButton disabled={!note} type="button" onClick={updateNote}>
            Add
          </CButton>
        </div>
        {fields && fields.length
          ? fields.map((note: any, i: number) => (
            <Note
              index={note.id}
              key={note.id}
              note={note}
              deleteNote={() => remove(i)}
            />
          ))
          : null}
      </div>
    </form>
  )
}

export default EntityDetailForm
