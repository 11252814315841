import { cilDataTransferDown } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton } from '@coreui/react-pro'
import React from 'react'

const Download: any = ({ reportId }: any) => {
  return (
    <a
      className="d-flex justify-content-end"
      target="_blank"
      rel="noopener noreferrer"
      href={`/api/reports/${reportId}/results/xlsx`}
    >
      <CButton>
        <CIcon icon={cilDataTransferDown} />
      </CButton>
    </a>
  )
}

export default Download
