import React from 'react'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CFormTextarea,
} from '@coreui/react-pro'
import { useFieldArray, useFormContext } from 'react-hook-form'
import FormatNumber from './FormatNumber'
import CIcon from '@coreui/icons-react'
import { cilMinus, cilPlus } from '@coreui/icons'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'

const IndividualFundingRound: React.FC<any> = ({
  field,
  index,
  remove,
}: any) => {
  const { register } = useFormContext()
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )

  const {
    fields: investors,
    append: appendInvestors,
    remove: removeInvestors,
  } = useFieldArray({ name: `companyDetail.fundingRounds[${index}].investors` })

  return (
    <div className="d-flex align-items-center w-100">
      <div
        key={field.id}
        className="row g-3 d-flex align-items-start justify-content-between w-100"
      >
        <div className="col">
          <CFormLabel>Date</CFormLabel>
          <CFormInput
            {...register(`companyDetail.fundingRounds.${index}.date`)}
            disabled={btnLoading}
          />
        </div>
        <div className="col">
          <CFormLabel>Round</CFormLabel>
          <CFormInput
            {...register(`companyDetail.fundingRounds.${index}.round`)}
            disabled={btnLoading}
          />
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-start">
            <CFormLabel>Investor</CFormLabel>
            <CButton
              className="rounded-circle ms-2"
              size="sm"
              onClick={() => appendInvestors({ name: '' })}
              disabled={btnLoading}
            >
              <CIcon icon={cilPlus} />
            </CButton>
          </div>
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {investors && investors.length > 0 ? (
              investors.map((investor: any, idx: number) => (
                <div
                  className="d-flex align-items-center mb-2"
                  key={investor.id}
                >
                  <CFormInput
                    {...register(
                      `companyDetail.fundingRounds[${index}].investors[${idx}].name`,
                    )}
                    disabled={btnLoading}
                  />
                  <CButton
                    size="sm"
                    className="bg-danger ms-2 rounded-circle"
                    onClick={() => removeInvestors(idx)}
                    disabled={btnLoading}
                  >
                    <CIcon icon={cilMinus} />
                  </CButton>
                </div>
              ))
            ) : (
              <p className="text-danger">There are no investors</p>
            )}
          </div>
        </div>
        <div className="col">
          <CFormLabel>Amount Raised</CFormLabel>
          <CFormInput
            type="number"
            {...register(`companyDetail.fundingRounds.${index}.amount`)}
            disabled={btnLoading}
          />
          <FormatNumber
            watchValue={`companyDetail.fundingRounds.${index}.amount`}
            isMoney
          />
        </div>
        <div className="col">
          <CFormLabel>Valuation</CFormLabel>
          <CFormInput
            type="number"
            {...register(`companyDetail.fundingRounds.${index}.valuation`)}
            disabled={btnLoading}
          />
          <FormatNumber
            watchValue={`companyDetail.fundingRounds.${index}.valuation`}
            isMoney
          />
        </div>
        <div className="col">
          <CFormLabel>Valuation Source</CFormLabel>
          <CFormTextarea
            rows={1}
            style={{ marginRight: '10px', marginBottom: '10px' }}
            {...register(
              `companyDetail.fundingRounds.${index}.valuationSource`,
            )}
            disabled={btnLoading}
          />
        </div>
      </div>
      <div>
        <CButton
          size="sm"
          className="bg-danger ms-2 rounded-circle"
          style={{ float: 'right', marginTop: '30px' }}
          onClick={() => remove(index)}
          disabled={btnLoading}
        >
          <CIcon icon={cilMinus} />
        </CButton>
      </div>
    </div>
  )
}

export default IndividualFundingRound
