import React, { useCallback } from 'react'
import GenericTable from '../../../components/GenericTable'
import useFetchUserData from '../../../hooks/useFetchUserData'
import { useAppDispatch } from '../../../features/hooks'
import { getUserSellOrders } from '../../../features/usersSlice'
import Loading from './Loading'

interface UserSellOrdersProps {
  id: string
  sellOrders: any[]
  sellOrderColumns: any[]
  handleClick: (item: any) => any
}

const UserSellOrders: React.FC<UserSellOrdersProps> = ({
  sellOrders,
  sellOrderColumns,
  handleClick,
  id,
}: UserSellOrdersProps) => {
  const dispatch = useAppDispatch()
  const loading = useFetchUserData(
    useCallback(() => dispatch(getUserSellOrders({ id })), [dispatch, id]),
  )

  if (loading) return <Loading />
  return (
    <GenericTable
      items={sellOrders}
      columns={sellOrderColumns}
      handleClick={handleClick}
    />
  )
}

export default UserSellOrders
