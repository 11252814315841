import React from 'react'

const Control: any = ({ TABS, control, setControl }: any) => {
  return (
    <ul className="nav nav-tabs hover">
      {TABS.map((tag: any, i: number) => (
        <li
          className={`nav-item ${control === tag && 'active'}`}
          key={i}
          onClick={() => setControl(tag)}
        >
          <div className={`nav-link inactive ${tag === control && 'active'}`}>
            {tag}
          </div>
        </li>
      ))}
    </ul>
  )
}

export default Control
