import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'

interface TransactionState {
  filtered: any
  rows: any[]
  total: any
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  transaction: any
}

const initialState: TransactionState = {
  filtered: {},
  rows: [],
  total: {},
  columns: [],
  showColumn: [],
  originalColumns: [],
  transaction: null,
}

export const getTransactions = createAsyncThunk('getTransactions', async () => {
  const body = {
    accreditedCountry: {
      // include: false,
      sortIndex: null,
      sortAsc: false,
    },
    addressCountry: {
      // include: false,
      sortIndex: null,
      sortAsc: false,
    },
    companyName: {
      sortIndex: null,
      sortAsc: false,
    },
    createdAt: {
      sortIndex: null,
      sortAsc: false,
    },
    defaultInclude: true,
    doneAt: {},
    groupFields: false,
    price: {},
    sharePrice: {
      sortIndex: null,
      sortAsc: false,
    },
    shares: {
      sortIndex: null,
      sortAsc: false,
    },
    status: {
      sortIndex: null,
      sortAsc: false,
      filterValues: [],
    },
    userEmail: {
      sortIndex: null,
      sortAsc: false,
    },
    transactionId: {
      sortIndex: null,
      sortAsc: false,
    },
    attritionCause: {
      sortIndex: null,
      sortAsc: false,
    },
    userId: {
      sortIndex: null,
      sortAsc: false,
    },
    userProfileId: {
      sortIndex: null,
      sortAsc: false,
    },
  }
  try {
    const response = await privateAPI.post('/queries/transactions', {
      ...body,
    })
    return response.data
  } catch (e) {
    console.log('error getting transactions: ', e)
  }
})

export const getTransaction = createAsyncThunk(
  'getTransaction',
  async ({ id }: any) => {
    try {
      const response = await privateAPI.get(`/queries/transactions/${id}`)
      return response.data
    } catch (e) {
      console.log('error getting transactions: ', e)
    }
  },
)

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    resetTransaction: (state) => {
      state.transaction = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.rows = payload.rows
          state.filtered = payload.filtered
        }
      })
      .addCase(getTransactions.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(getTransaction.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.transaction = payload?.rows?.[0] || {}
        }
      })
  },
})

export const { resetTransaction } = transactionSlice.actions

export default transactionSlice.reducer
