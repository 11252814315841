import { CFormLabel, CFormSelect } from '@coreui/react-pro'
import React from 'react'
import { useAppSelector } from '../features/hooks'
import { RootState } from '../store'

interface CountryDropdownProps {
  label: string
  value: any
  onChange: any
  name: string
  disabled: boolean
  ref: any
}

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  label,
  value,
  onChange,
  name,
  disabled,
}: CountryDropdownProps) => {
  const { countries } = useAppSelector((state: RootState) => state.common)

  return (
    <>
      <CFormLabel>{label}</CFormLabel>
      <CFormSelect
        disabled={disabled}
        onChange={onChange}
        value={value}
        name={name}
      >
        <option value="">Select A Country</option>
        {countries && countries.length
          ? countries.map((country: string, i: number) => (
              <option key={i} value={country}>
                {country}
              </option>
            ))
          : null}
      </CFormSelect>
    </>
  )
}

export default CountryDropdown
