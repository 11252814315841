import {
  CButton
} from '@coreui/react-pro'
import React from 'react'
import GenericTable from '../../../components/GenericTable'
import privateAPI from '../../../APIs/private'
import Spinner from '../../../components/Spinner'
import { useQuery } from '@tanstack/react-query'
import { formatTableRenderers } from '../../../utils/formatUtils/utils'
import { useHistory } from 'react-router-dom'

interface MessageProps {
  id: number
}

const getCompanyMessages: any = async (id: number) => {
  const data = {
    companyMessageId: {},
    createdAt: {},
    isActive: {},
    companyId: { filterValues: [id] },
    name: {},
    title: {},
    type: {},
    message: {},
    startDate: {},
    endDate: {}
  }
  const response = await privateAPI.post('/queries/company_messages', data)

  const { rows, columns } = formatTableRenderers(
    response?.data?.rows,
    response?.data?.columns,
  )
  return { rows, columns }
}

const Messages: React.FC<MessageProps> = ({ id }: MessageProps) => {
  const history = useHistory()
  const { data: companyMessagesData, isInitialLoading } = useQuery({
    queryKey: ['company_messages', id],
    queryFn: () => getCompanyMessages(id),
    enabled: +id > 0,
  })
  if (isInitialLoading) return <Spinner />
  const { rows, columns } = companyMessagesData

  return (
    <div className="mt-4">
      <CButton onClick={() => history.push(`/company_messages/-1?newMessageCompanyId=${id}`)}>
        Add Company Message
      </CButton>
      <div className="row g-3">
        <div>
          <GenericTable
            items={rows}
            columns={columns}
            handleClick={(item: any) => history.push(`/company_messages/${item.companyMessageId}`)} />
        </div>
      </div>
    </div>
  )
}

export default Messages
