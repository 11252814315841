import { CButton } from '@coreui/react-pro'
import React from 'react'
import { useHistory } from 'react-router'
import GenericTable from '../../../components/GenericTable'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'

const entitiesColumns = [
  {
    key: 'entityId',
    name: 'entityId',
    label: 'Entity Id',
    type: 'Number',
    renderer: 'Id',
  },
  {
    key: 'name',
    name: 'name',
    label: 'Name',
    type: 'String',
  },
]

const UserEntities: React.FC = () => {
  const history = useHistory()
  const { user } = useAppSelector((state: RootState) => state.users)

  const { entities } = user || {}
  return (
    <div className="mt-4">
      <div className="d-flex justify-content-end">
        <CButton
          onClick={() =>
            history.push(
              `/create-entity?userId=${user.userId}&fullName=${user.fullName}`,
            )
          }
        >
          Create Entity
        </CButton>
      </div>
      <GenericTable
        items={entities}
        handleClick={(item: any) => history.push(`/entities/${item.entityId}`)}
        columns={entitiesColumns}
      />
    </div>
  )
}

export default UserEntities
