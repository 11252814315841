import React, { useEffect, useState } from 'react'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CFormLabel,
  CFormInput,
  CBadge,
  CFormSelect,
} from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { RootState } from '../../store'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import Document from '../../components/Document'
import { updateBrokerOrder } from '../../features/brokerSlice'
import Spinner from '../../components/Spinner'
import { formatToPT } from '../../utils/formatUtils/utils'

const MONTH: any = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

const BrokerModal: any = ({ setOpen }: any) => {
  const dispatch = useAppDispatch()
  const { curUser, curOrder } = useAppSelector(
    (state: RootState) => state.broker,
  )
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
    watch,
  } = useForm()

  const onSubmit = async (data: any) => {
    setLoading(true)
    await dispatch(updateBrokerOrder({ id: curOrder.orderId, data }))
    setLoading(false)
    setOpen(false)
    window.location.reload()
  }

  useEffect(() => reset(curOrder), [reset, curOrder])

  const {
    fullName,
    firstName,
    lastName,
    phone,
    birthDay,
    birthMonth,
    birthYear,
    email,
    country,
    street1,
    street2,
    city,
    state,
    documents,
    zip,
  } = curUser
  const {
    orderId,
    brokerApprovalStatus,
    series,
    price,
    sharePrice,
    orderDoneAt,
    brokerRejectedReason,
    documents: orderDocs,
  } = curOrder

  const renderOptions = () => {
    switch (brokerApprovalStatus) {
      case 'APPROVED':
        return (
          <>
            <option value="APPROVED">Approved</option>
            <option value="REJECTED">Rejected</option>
          </>
        )
      default:
        return (
          <>
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved</option>
            <option value="REJECTED">Rejected</option>
          </>
        )
    }
  }

  if (loading) {
    return (
      <CModal visible alignment="center">
        <CModalBody>
          <Spinner />
        </CModalBody>
      </CModal>
    )
  }

  return (
    <CModal visible alignment="center" size="lg" onClose={() => setOpen(false)}>
      <CModalHeader>
        <CModalTitle>Order No. {orderId}</CModalTitle>
      </CModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CModalBody>
          <div className="g-3 mb-4 d-flex justify-content-start">
            <span className="me-4">{fullName}</span>
            <CBadge color="primary">
              <span>{brokerApprovalStatus}</span>
            </CBadge>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-12 d-flex">
              <CFormSelect
                {...register('brokerApprovalStatus')}
                defaultValue={brokerApprovalStatus}
              >
                {renderOptions()}
              </CFormSelect>
              {watch('brokerApprovalStatus') === 'REJECTED' && (
                <CFormInput
                  className="w-100"
                  placeholder="Rejected Reason"
                  defaultValue={brokerRejectedReason}
                  {...register('brokerRejectedReason', {
                    required: 'This field is required',
                  })}
                />
              )}
              {isDirty && (
                <>
                  <CButton className="ms-2" type="submit">
                    Save
                  </CButton>
                </>
              )}
            </div>
          </div>
          <h4>Order Information</h4>
          <div className="row g-3 mb-2">
            <div className="col-md-3">
              <CFormLabel>Completed At</CFormLabel>
              <CFormInput
                readOnly
                value={formatToPT(orderDoneAt, 'MM/DD/YY')}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Series</CFormLabel>
              <CFormInput
                readOnly
                value={series.replace(/Linqto Liquidshares LLC - /, '')}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Price</CFormLabel>
              <CFormInput
                readOnly
                value={`$${price.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>SharePrice</CFormLabel>
              <CFormInput
                readOnly
                value={`$${sharePrice.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              />
            </div>
            <div className="row g-3 mb-2">
              <div className="d-flex justify-content-between">
                <div className="w-100 me-2">
                  <h4>User Documents</h4>
                  <div className="col-md-6 w-100">
                    {orderDocs && orderDocs.length
                      ? orderDocs.map(({ name, url, createdAt }: any, i: number) => (
                        <Document key={i} name={name} url={url} createdAt={createdAt} />
                      ))
                      : null}
                  </div>
                </div>
                <div className="w-100">
                  <h4>Transaction Documents</h4>
                  <div className="col-md-6 w-100">
                    {documents && documents.length
                      ? documents.map(({ name, url, createdAt }: any, i: number) => (
                        <Document key={i} name={name} url={url} createdAt={createdAt} />
                      ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4>User Information</h4>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>First Name</CFormLabel>
              <CFormInput readOnly value={firstName} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Last Name</CFormLabel>
              <CFormInput readOnly value={lastName} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Date Of Birth</CFormLabel>
              <CFormInput
                readOnly
                value={`${MONTH[birthMonth]} ${birthDay}, ${birthYear}`}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Email</CFormLabel>
              <CFormInput readOnly value={email} />
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>Phone</CFormLabel>
              <CFormInput readOnly value={phone} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Country</CFormLabel>
              <CFormInput readOnly value={country} />
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-12">
              <CFormLabel>Address</CFormLabel>
              <CFormInput
                readOnly
                value={`${street1} , ${street2} ${city} , ${state} , ${zip} , ${country}`}
              />
            </div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={() => setOpen(false)}>
            Okay
          </CButton>
        </CModalFooter>
      </form>
    </CModal>
  )
}

export default BrokerModal
