import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'

interface CompanyState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  loading: boolean
  company: any
  error: boolean
  errorMessage: string
}

const initialState: CompanyState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  loading: false,
  company: {},
  error: false,
  errorMessage: '',
}

export const getCompany = createAsyncThunk(
  'getCompany',
  async ({ id }: any) => {
    try {
      const response = await privateAPI.get(`/queries/COMPANIES/${id}`)
      return response.data
    } catch (e) {
      console.log(e)
    }
  },
)

export const saveCompany = createAsyncThunk(
  'saveCompany',
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/queries/COMPANIES/${id}`, data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getCompanies = createAsyncThunk(
  'getCompanies',
  async ({ found }: any) => {
    const params = found.queryParams
    try {
      const response = await privateAPI.post(
        '/queries/companies',
        JSON.parse(params),
      )
      return response.data
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
)

export const getCompaniesReports = createAsyncThunk(
  'getCompaniesReports',
  async () => {
    try {
      const response = await privateAPI.get('/queries/COMPANIES/reports')
      return response.data
    } catch (e) {
      console.log('e', e)
    }
  },
)

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = false
      state.errorMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompaniesReports.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompaniesReports.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.reports = [...payload]
        }
        state.loading = false
      })
      .addCase(getCompaniesReports.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
      .addCase(getCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompany.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.filtered = payload.filtered
          state.company = payload.rows[0]
        }
        state.loading = false
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(saveCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(saveCompany.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
        }
        state.loading = false
      })
      .addCase(saveCompany.rejected, (state, action) => {
        state.error = true
        state.errorMessage = 'There was an error saving this company.'
      })
      .addCase(getCompanies.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompanies.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.filtered = payload.filtered
          state.rows = payload.rows
          state.loading = false
        }
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export const { resetError } = companiesSlice.actions

export default companiesSlice.reducer
