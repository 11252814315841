import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import { formatTableRenderers } from '../utils/formatUtils/utils'
import history from '../history'

interface EntityState {
  filtered: any
  rows: any[]
  total: null
  columns: any[]
  originalColumns: any[]
  queryName: string
  reportName: string
  reports: any[]
  reportId: any
  chartType: string
  xAxis: any
  yAxis: any
  xAxisData: any[]
  yAxisData: any[]
  modifiedColumns: any[]
  groupFields: boolean
  groupFieldsFilters: any[]
  visualizeReport: boolean
  xAxisColumns: any[]
  yAxisColumns: any[]
}

const initialState: EntityState = {
  filtered: { completedTotal: 0, count: 0 },
  rows: [],
  total: null,
  columns: [],
  reports: [],
  originalColumns: [],
  queryName: '',
  reportName: '',
  reportId: '',
  chartType: '',
  xAxis: null,
  yAxis: null,
  xAxisData: [],
  yAxisData: [],
  modifiedColumns: [],
  groupFields: false,
  groupFieldsFilters: [],
  visualizeReport: false,
  xAxisColumns: [],
  yAxisColumns: [],
}

export const getListData = createAsyncThunk(
  'getListData',
  async ({
    queryName,
    found,
    current,
    length,
    xAxis = null,
    yAxis = null,
  }: any) => {
    try {
      const params = JSON.parse(found?.queryParams)
      const start = current * length
      try {
        const response = await privateAPI.post(`/queries/${queryName}`, {
          ...params,
          current,
          length:
            (params.visualizeReport !== undefined &&
              params.visualizeReport === true) ||
            params?.chartType === 'bar' ||
            params?.chartType === 'pie'
              ? 1000
              : length === 1000
              ? 10
              : length,
          start,
        })
        const visible =
          (response.data.xAxis || xAxis) && (response.data.yAxis || yAxis)
            ? true
            : false
        return {
          ...response.data,
          groupFields: params.groupFields ? true : false,
          xAxis: response.data?.xAxis ? response.data?.xAxis : xAxis,
          yAxis: response.data?.yAxis?.length ? response.data?.yAxis[0] : yAxis,
          visible,
        }
      } catch (e) {
        console.log('GET LIST SLICE ERROR: ', e)
      }
    } catch (e) {
      console.log('unable to parse query params', e)
    }
  },
)

export const getCustomizedListData = createAsyncThunk(
  'getCustomizedListData',
  async (
    { queryName, columns, length, reportId, params, visible, type }: any,
    { rejectWithValue },
  ) => {
    const obj: any = {}
    for (const key of columns) {
      obj[key.name] = { ...params[key.name] }
      if (params?.freeTextSearch) {
        obj.freeTextSearch = params.freeTextSearch
      }
      if (params?.groupFields) {
        obj.groupFields = params.groupFields
      }
    }
    for (const item in params) {
      if (
        params &&
        Object.values(params[item]).every((item: any) => item !== undefined)
      ) {
        if (obj[item] === undefined) {
          obj[item] = { ...obj[item], ...params[item], include: false }
        } else {
          if (item !== 'freeTextSearch' && item !== 'groupFields') {
            obj[item] = { ...obj[item], include: true }
          } else {
            if (item === 'freeTextSearch') {
              obj.freeTextSearch = obj[item]
            } else if (item === 'groupFields') {
              obj.groupFields = obj[item]
            }
          }
        }
      }
    }
    history.replace(
      `/list?queryName=${queryName}&reportId=${reportId}&current=${0}&start=${0}&length=${
        (obj.visualizeReport !== undefined && obj.visualizeReport === true) ||
        params?.chartType === 'bar' ||
        params?.chartType === 'pie'
          ? 1000
          : length === 1000
          ? 10
          : length
      }&params=${encodeURIComponent(JSON.stringify(obj))}`,
    )
    obj.current = 0
    obj.start = 0
    obj.length =
      (params.visualizeReport !== undefined &&
        params.visualizeReport === true) ||
      params?.chartType === 'bar' ||
      params?.chartType === 'pie'
        ? 1000
        : length === 1000
        ? 10
        : length
    try {
      const response = await privateAPI.post(`/queries/${queryName}`, {
        ...obj,
      })
      return response.data
    } catch (e) {
      console.log('GET LIST SLICE ERROR: ', e)
      return rejectWithValue(e)
    }
  },
)

export const getGraphData = createAsyncThunk(
  'getGraphData',
  async (
    { queryName, columns, length, params, visible, type, xAxis, yAxis }: any,
    { rejectWithValue },
  ) => {
    const obj: any = {}
    for (const key of columns) {
      obj[key.name] = { ...params[key.name] }
      if (params?.freeTextSearch) {
        obj.freeTextSearch = params.freeTextSearch
      }
      if (params?.groupFields) {
        obj.groupFields = params.groupFields
      }
      if (params?.chartType) {
        obj.chartType = params.chartType
      }
    }
    for (const item in params) {
      if (
        params &&
        Object.values(params[item]).every((item: any) => item !== undefined)
      ) {
        if (
          obj[item] === undefined &&
          item !== 'groupFields' &&
          item !== 'chartType'
        ) {
          obj[item] = { ...obj[item], ...params[item], include: false }
        } else {
          if (
            item !== 'freeTextSearch' &&
            item !== 'groupFields' &&
            item !== 'chartType'
          ) {
            obj[item] = { ...obj[item], include: true }
          } else {
            if (item === 'freeTextSearch') {
              obj.freeTextSearch = obj[item]
            } else if (item === 'groupFields') {
              obj.groupFields = true
            } else if (item === 'chartType') {
              obj.chartType = type
            }
          }
        }
      }
    }

    obj.current = 0
    obj.start = 0
    obj.length = 1000
    try {
      const response = await privateAPI.post(`/queries/${queryName}`, {
        ...obj,
      })
      return {
        data: response.data,
        x: JSON.parse(xAxis),
        y: JSON.parse(yAxis),
        params: obj,
      }
    } catch (e) {
      console.log('GET LIST SLICE ERROR: ', e)
      return rejectWithValue(e)
    }
  },
)

export const getListColumns = createAsyncThunk(
  'getListColumns',
  async ({ queryName, params }: any) => {
    try {
      const response = await privateAPI.get(`/queries/${queryName}/schema`)
      return { data: response.data, params: JSON.parse(params) }
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
)

export const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    toggleCheckbox: (state, { payload }: any) => {
      const { column, status } = payload
      if (status) {
        state.modifiedColumns = [...state.modifiedColumns, { ...column }]
      } else {
        const found = state.modifiedColumns.findIndex(
          (col: any) => col.name === column.name,
        )
        if (found > -1) {
          const arr = [...state.modifiedColumns]
          arr.splice(found, 1)
          state.modifiedColumns = [...arr]
        }
      }
      const modColumns = JSON.parse(JSON.stringify(state.modifiedColumns))
      const groupFieldsFilters = JSON.parse(
        JSON.stringify(state.groupFieldsFilters),
      )
      const arr = []
      if (state.groupFields) {
        for (let i = 0; i < modColumns.length; i++) {
          if (
            modColumns[i]?.type === 'Number' &&
            modColumns[i]?.renderer !== 'Id'
          ) {
            arr.push({
              name: modColumns[i].name,
              label: modColumns[i].label,
              value:
                groupFieldsFilters?.find(
                  (filter: any) => filter.name === modColumns[i].name,
                )?.value || 'SUM',
              type: 'Number',
            })
          } else if (modColumns[i]?.type === 'Date') {
            arr.push({
              name: modColumns[i].name,
              label: modColumns[i].label,
              value:
                groupFieldsFilters?.find(
                  (filter: any) => filter.name === modColumns[i].name,
                )?.value || 'MONTH',
              type: 'Date',
            })
          }
        }
        state.groupFieldsFilters = [...arr]
        const filteredXAxis = modColumns.filter(
          (item: any) => item?.type === 'Number',
        )
        const filteredYAxis = modColumns.filter(
          (item: any) => item?.type !== 'Number',
        )
        if (state.chartType === 'bar') {
          state.xAxisColumns = [...filteredYAxis]
          state.yAxisColumns = [...filteredXAxis]
          state.xAxis =
            filteredYAxis && filteredYAxis.length ? filteredYAxis[0] : null
          state.yAxis =
            filteredXAxis && filteredXAxis.length ? filteredXAxis[0] : null
        } else {
          state.xAxisColumns = [...filteredXAxis]
          state.yAxisColumns = [...filteredYAxis]
          state.xAxis =
            filteredXAxis && filteredXAxis.length ? filteredXAxis[0] : null
          state.yAxis =
            filteredYAxis && filteredYAxis.length ? filteredYAxis[0] : null
        }
      }
    },
    toggleVisualizeReportCheckbox: (state) => {
      const { columns, rows } = JSON.parse(JSON.stringify(state))
      const x = columns.find((item: any) => item.type !== 'Number')
      const y = columns.find((item: any) => item.type === 'Number')
      state.visualizeReport = !state.visualizeReport
      if (state.chartType === '') {
        state.chartType = 'bar'
        state.xAxis = x
        state.yAxis = y
        state.xAxisData = rows.map((row: any, i: number) => row[x?.name])
        state.yAxisData = rows.map((row: any, i: number) => row[y?.name])
      } else {
        if (state.chartType === 'bar') {
          state.xAxis = x
          state.yAxis = y
          state.xAxisData = rows.map((row: any, i: number) => row[x?.name])
          state.yAxisData = rows.map((row: any, i: number) => row[y?.name])
        } else {
          state.xAxis = y
          state.yAxis = x
          state.xAxisData = rows.map((row: any, i: number) => row[y?.name])
          state.yAxisData = rows.map((row: any, i: number) => row[x?.name])
        }
      }
    },
    initializeStore: (state, { payload }: any) => {
      const { columns, rows } = JSON.parse(JSON.stringify(state))
      const { visible, chartType, xAxis, yAxis } = payload || {}
      let x: any, y: any
      try {
        x = JSON.parse(xAxis)
        y = JSON.parse(yAxis)
        if (!x) {
          x = columns.find((item: any) => item.type !== 'Number')
        }
        if (!y) {
          y = columns.find((item: any) => item.type === 'Number')
        }
      } catch (e) {
        console.log('UNABLE TO PARSE X-Y AXIS')
      }
      if (JSON.parse(visible)) {
        state.visualizeReport = true
        state.groupFields = true
        if (chartType === 'bar') {
          state.xAxis = x
          state.yAxis = y
          state.xAxisData = rows.map((row: any, i: number) => row[x?.name])
          state.yAxisData = rows.map((row: any, i: number) => row[y?.name])
        } else {
          state.xAxis = x
          state.yAxis = y
          state.xAxisData = rows.map((row: any, i: number) => row[x?.name])
          state.yAxisData = rows.map((row: any, i: number) => row[y?.name])
        }
      }
      if (chartType !== 'null') {
        state.chartType = chartType
      }
    },
    updateChartType: (state, { payload }: any) => {
      state.chartType = payload
      const { modifiedColumns, columns } = JSON.parse(JSON.stringify(state))
      const x = columns.find((item: any) => item.type !== 'Number')
      const y = columns.find((item: any) => item.type === 'Number')
      const filteredXAxis = modifiedColumns.filter(
        (item: any) => item?.type === 'Number',
      )
      const filteredYAxis = modifiedColumns.filter(
        (item: any) => item?.type !== 'Number',
      )
      if (state.chartType === 'bar') {
        state.xAxis = x
        state.yAxis = y
        state.xAxisColumns = [...filteredYAxis]
        state.yAxisColumns = [...filteredXAxis]
      } else {
        state.xAxis = y
        state.yAxis = x
        state.xAxisColumns = [...filteredXAxis]
        state.yAxisColumns = [...filteredYAxis]
      }
    },
    updateAxis: (state, { payload }: any) => {
      if (payload.item) {
        if (payload.type === 'xAxis') {
          state.xAxis = payload.item
        } else {
          state.yAxis = payload.item
        }
      }
    },
    toggleGroupFieldsCheckbox: (state, { payload }: any) => {
      const arr = []
      state.groupFields = payload
      if (!payload) {
        state.visualizeReport = false
      }
      const modColumns = JSON.parse(JSON.stringify(state.modifiedColumns))
      if (state.groupFields) {
        for (let i = 0; i < modColumns.length; i++) {
          if (
            modColumns[i]?.type === 'Number' &&
            modColumns[i]?.renderer !== 'Id'
          ) {
            arr.push({
              name: modColumns[i].name,
              label: modColumns[i].label,
              value: 'SUM',
              type: 'Number',
            })
          } else if (modColumns[i]?.type === 'Date') {
            arr.push({
              name: modColumns[i].name,
              label: modColumns[i].label,
              value: 'MONTH',
              type: 'Date',
            })
          }
        }
        state.groupFieldsFilters = [...arr]
      }
    },
    handleGroupFilterChange: (state, { payload }: any) => {
      const { name, v } = payload
      const { groupFieldsFilters } = JSON.parse(JSON.stringify(state))
      if (groupFieldsFilters) {
        const found = groupFieldsFilters.findIndex(
          (col: any) => col.name === name,
        )
        if (found > -1) {
          const arr = [...state.groupFieldsFilters]
          arr[found] = { ...arr[found], value: v }
          state.groupFieldsFilters = [...arr]
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListData.pending, (state) => {
        state.xAxis = null
        state.yAxis = null
        state.xAxisData = []
        state.yAxisData = []
        state.chartType = ''
      })
      .addCase(getListData.fulfilled, (state, { payload }: any) => {
        const {
          columns,
          filtered,
          groupFields,
          rows,
          visible,
          xAxis,
          yAxis,
          reportId,
          reportName,
          queryName,
          chartType,
        } = payload || {}
        const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
          rows,
          columns,
          visible,
        )

        state.xAxis = null
        state.yAxis = null
        state.xAxisData = []
        state.yAxisData = []
        state.chartType = ''
        state.columns = dataColumns
        state.rows = dataRows
        state.filtered = filtered
        state.queryName = queryName
        state.reportName = reportName
        state.reportId = reportId?.toString() || ''
        state.groupFields = groupFields
        state.visualizeReport = false
        state.xAxis = xAxis
        state.yAxis = yAxis
        state.visualizeReport = visible
        state.chartType = chartType || ''

        if (visible) {
          state.xAxisData = dataRows.map(
            (row: any, i: number) => row[xAxis?.name],
          )
          state.yAxisData = dataRows.map(
            (row: any, i: number) => row[yAxis?.name],
          )
        }
      })
      .addCase(getListData.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.columns = []
      })
      .addCase(getListColumns.fulfilled, (state, { payload }: any) => {
        const params = payload.params || {}
        const { columns, name } = payload.data || {}
        columns.forEach((col: any) => (col.value = col.name))
        state.originalColumns = columns
        state.queryName = name
        state.modifiedColumns = state.columns
        const { groupFields, modifiedColumns } = JSON.parse(
          JSON.stringify(state),
        )
        const arr = []
        if (groupFields) {
          for (let i = 0; i < modifiedColumns.length; i++) {
            if (
              modifiedColumns[i]?.type === 'Number' &&
              modifiedColumns[i]?.renderer !== 'Id'
            ) {
              arr.push({
                name: modifiedColumns[i].name,
                label: modifiedColumns[i].label,
                value:
                  params[modifiedColumns[i].name]?.groupByFunction ||
                  params[modifiedColumns[i].name]?.groupByNone ||
                  'SUM',
                type: 'Number',
              })
            } else if (modifiedColumns[i]?.type === 'Date') {
              arr.push({
                name: modifiedColumns[i].name,
                label: modifiedColumns[i].label,
                value:
                  params[modifiedColumns[i].name]?.truncateDateFunction ||
                  'MONTH',
                type: 'Date',
              })
            }
          }
          state.groupFieldsFilters = [...arr]
          const filteredXAxis = modifiedColumns.filter(
            (item: any) => item?.type === 'Number',
          )
          const filteredYAxis = modifiedColumns.filter(
            (item: any) => item?.type !== 'Number',
          )
          if (state.chartType === 'bar') {
            state.xAxisColumns = [...filteredYAxis]
            state.yAxisColumns = [...filteredXAxis]
          } else {
            state.xAxisColumns = [...filteredXAxis]
            state.yAxisColumns = [...filteredYAxis]
          }
        }
      })
      .addCase(getListColumns.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(getCustomizedListData.fulfilled, (state, { payload }: any) => {
        const { columns, filtered, rows, queryName, reportName, reportId } =
          payload || {}
        const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
          rows,
          columns,
        )
        state.xAxis = null
        state.yAxis = null
        state.xAxisData = []
        state.yAxisData = []
        state.chartType = ''
        state.columns = dataColumns
        state.rows = dataRows
        state.filtered = filtered
        state.queryName = queryName
        state.reportName = reportName
        state.reportId = reportId?.toString() || ''
      })
      .addCase(getCustomizedListData.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(getGraphData.fulfilled, (state, { payload }: any) => {
        const { xAxis, yAxis } = JSON.parse(JSON.stringify(state))
        const {
          columns,
          filtered,
          rows,
          queryName,
          reportName,
          reportId,
          chartType,
        } = payload.data || {}
        const x = payload.x
        const y = payload.y
        const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
          rows,
          columns,
          true,
        )
        if (!xAxis && !yAxis && x && y) {
          state.xAxisData = dataRows.map((row: any, i: number) => row[x.name])
          state.yAxisData = dataRows.map((row: any, i: number) => row[y.name])
          state.xAxis = x
          state.yAxis = y
        } else {
          if (xAxis && yAxis) {
            if (chartType === 'pie') {
              state.xAxisData = dataRows.map(
                (row: any, i: number) => row[xAxis.name],
              )
              state.yAxisData = dataRows.map(
                (row: any, i: number) => row[yAxis.name],
              )
            } else {
              state.xAxisData = dataRows.map(
                (row: any, i: number) => row[xAxis.name],
              )
              state.yAxisData = dataRows.map(
                (row: any, i: number) => row[yAxis.name],
              )
            }
          }
        }
        state.chartType = chartType
        state.columns = dataColumns
        state.rows = rows
        state.filtered = filtered
        state.queryName = queryName
        state.reportName = reportName
        state.reportId = reportId?.toString() || ''
      })
  },
})

export const {
  toggleCheckbox,
  toggleGroupFieldsCheckbox,
  handleGroupFilterChange,
  toggleVisualizeReportCheckbox,
  updateChartType,
  updateAxis,
  initializeStore,
} = listSlice.actions

export default listSlice.reducer
