import React, { useMemo } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import Spinner from '../../../components/Spinner'
import PageLayout from '../../../layout/PageLayout'
import SubmitButton from '../../../components/SubmitButton'
import BlueSkyFeeDetailForm from './BlueSkyFeeDetailForm'
import CreateBlueSkyFeeDetail from './CreateBlueSkyFeeDetail'
import api from '../../../APIs/private'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { convertDateToPT } from '../../../utils/formatUtils/utils'

const getBlueSkyFee = async (id: string) => {
  const response = await api.get(`/queries/series_blue_sky_fees/${id}`)
  return response?.data?.rows?.[0] || {}
}

const getBlueSkyFeeReports = async (id: string, state: string) => {
  const response = await api.post('/queries/blue_sky_fees/', {
    standardFeePaid: {},
    lateFeePaid: {},
    invoiceNumber: {},
    filingDate: {},
    state: { filterLike: state },
    seriesId: { filterMin: +id, filterMax: +id },
    blueSkyFeeId: {},
  })
  return response?.data?.rows || []
}

const saveBlueSkyFee = async (id: string, data: any) => {
  const filingDate = convertDateToPT(data?.filingDate)
  await api.post(`/queries/series_blue_sky_fees/${id}`, {
    data,
    filingDate,
  })
}

const BlueSkyFeeDetail: React.FC = () => {
  const queryClient = useQueryClient()
  const { id }: any = useParams()
  const methods = useForm()

  const { handleSubmit, reset, setValue } = methods

  const { isInitialLoading, data: series } = useQuery({
    queryFn: async () => getBlueSkyFee(id),
    queryKey: ['series_blue_sky_fees', id],
    enabled: +id > -1,
  })

  const { isInitialLoading: blueSkyFeeReportsLoading, data: reports } =
    useQuery({
      queryFn: async () =>
        getBlueSkyFeeReports(series?.seriesId, series?.state),
      queryKey: ['series_blue_sky_fees_reports', id],
      enabled: !!series?.seriesId,
    })

  const seriesBlueSkyFee = useMemo(
    () => ({ ...series, reports }),
    [series, reports],
  )

  useEffect(() => {
    reset(seriesBlueSkyFee)
    if (!seriesBlueSkyFee?.filingDate) {
      setValue('filingDate', '')
    }
  }, [seriesBlueSkyFee, reset, setValue])

  const handleBlueSkySave = useMutation({
    mutationFn: async (data) => await saveBlueSkyFee(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['series_blue_sky_fees', id] })
      queryClient.invalidateQueries({
        queryKey: ['series_blue_sky_fees_reports', id],
      })
    },
  })

  if (isInitialLoading || blueSkyFeeReportsLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  if (+id === -1) {
    return <CreateBlueSkyFeeDetail />
  }

  const { seriesId, seriesName } = series || {}

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleBlueSkySave.mutate)}>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h3>{seriesName?.replace('Linqto Liquidshares LLC -', '')}</h3>
              <h4>Series No. {seriesId}</h4>
            </div>
            <SubmitButton loading={handleBlueSkySave.isLoading} text="Save" />
          </div>
          <BlueSkyFeeDetailForm seriesBlueSkyFee={seriesBlueSkyFee} />
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default BlueSkyFeeDetail
