import React, { useCallback, useMemo, useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import {
  getOrder,
  getOrderTransactions,
  resetError,
  updateOrder,
} from '../../features/ordersSlice'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import { renderStatus } from '../../utils'
import Note from '../../components/Note'
import Document from '../../components/Document'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroupText,
} from '@coreui/react-pro'
import NewTab from '../../assets/new-tab.svg'
import Alert from '../../components/Alert'
import Checkbox from '../../components/Checkbox'
import { formatToPT } from '../../utils/formatUtils/utils'
import SubmitButton from '../../components/SubmitButton'
import GenericTable from '../../components/GenericTable'
import Select from '../assets/components/Select'
import { debounce } from 'lodash'
import { getSerieses } from '../../features/seriesSlice'
import { components } from 'react-select'

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}

const OrderDetail: React.FC = () => {
  const history = useHistory()
  const { id }: any = useParams()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const me = useAppSelector((state: RootState) => state.user)
  const { order, error, errorMessage, transactions, transactionsColumns } =
    useAppSelector((state: RootState) => state.orders)
  const [note, setNote] = useState<string>('')
  const [orderStringNotes, setOrderStringNotes] = useState<any>([])

  const methods = useForm()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    watch,
    control,
    setError,
    setValue,
  } = methods

  const { fields, prepend, remove } = useFieldArray({ control, name: 'notes' })

  useEffect(() => {
    setOrderStringNotes(order?.stringNotes)
  }, [order])

  useEffect(() => {
    const getEntityById = async () => {
      dispatch(resetError())
      await dispatch(getOrder({ id }))
      await dispatch(getOrderTransactions({ id }))
      setLoading(false)
    }
    getEntityById()
  }, [id, dispatch])

  useEffect(() => {
    reset(order)
    if (order?.entityId) {
      setValue('purchaseAs', order?.entityId?.toString())
    } else {
      setValue('purchaseAs', order?.userId?.toString())
    }
  }, [order, reset, setValue])

  const onSubmit = async (data: any) => {
    if (
      errors.cancelReason ||
      (watch('cancelReason') === 'NONE' && watch('status') === 'CANCELED')
    ) {
      setError(
        'cancelReason',
        {
          type: 'required',
          message: 'This field is required.',
        },
        { shouldFocus: true },
      )
      return
    }
    setBtnLoading(true)

    if (fields && fields.length) {
      fields.forEach((note: any) => delete note.updated)
    }
    const response = await dispatch(
      updateOrder({ id, data: { ...order, ...data, notes: orderStringNotes } }),
    )
    if (response && response.payload === 200) {
      dispatch(resetError())
      await dispatch(getOrder({ id }))
    }
    setBtnLoading(false)
  }

  const updateOrderWithNote = () => {
    let newNotes = orderStringNotes
    newNotes =
      orderStringNotes !== undefined
        ? `@@@${me.firstName}$$$${note}%%%${Date.now()}` + orderStringNotes
        : `@@@${me.firstName}$$$${note}%%%${Date.now()}`
    setOrderStringNotes(newNotes)
    setNote('')
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
  }

  const deleteNote = (note: any, i: number) => {
    remove(i)
    const newNotesArr = orderStringNotes?.split('@@@')
    const filteredNewNotesArr = newNotesArr.filter(
      (n: any) => !n.includes(note.time.toString().slice(0, -1)),
    )
    const newRemovedNotes = filteredNewNotesArr.join('@@@')
    setOrderStringNotes(newRemovedNotes)
  }

  const handlePurchaseAs = (value: any) => {
    setValue('purchaseAs', value, { shouldDirty: true })
    if (+value === userId) {
      setValue('entityId', null)
    } else {
      setValue('entityId', value)
    }
  }

  const fetchData = useCallback(
    async (e: string) => {
      const data = {
        queryParams: JSON.stringify({
          seriesId: {},
          companyId: {
            filterMin: order.companyId,
            filterMax: order.companyId,
          },
          availableOwners: { filterMax: 98 },
          name: {},
          freeTextSearch: e,
          length: 10,
        }),
      }
      if (e.length > 0) {
        const response = await dispatch(getSerieses({ found: data }))
        const arr = response.payload?.rows || []
        if (arr && arr.length) {
          const a = arr.map((a: any) => {
            return {
              id: a.seriesId,
              value: a.name,
              label: a.name,
            }
          })
          return a
        }
      }
    },
    [dispatch, order.companyId],
  )

  const loadSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchData(inputValue).then((options: any) => callback(options))
      }, 500),
    [fetchData],
  )

  const handleChange = (value: any) => {
    if (value) {
      setValue(
        'data',
        { id: value.id, label: value.value, value: value.value },
        { shouldDirty: true },
      )
      setValue('series', value.value, { shouldDirty: true })
      setValue('seriesName', value.value, { shouldDirty: true })
      setValue('seriesId', value.id, { shouldDirty: true })
    }
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  const {
    orderId,
    status,
    accreditedStatus,
    orderCreatedAt,
    orderExpiresAt,
    paymentType,
    shares,
    sharePrice,
    price,
    user,
    entities,
    userId,
    documents,
    adjustedPrice,
    cancelReason,
    riaName,
    releaseSharesAt,
    linqtoBucks,
    promoId,
    discountAmount,
    reversingOrderId,
    credits,
    tradeId,
    email,
    upholdEmail,
  } = order

  return (
    <FormProvider {...methods}>
      <PageLayout>
        {error && errorMessage && (
          <Alert
            color="danger"
            visible={error}
            onClose={() => dispatch(resetError())}
            text={errorMessage}
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between">
            <h3>
              Order No.{orderId} - <a href={`/users/${userId}`}>{user}</a>
            </h3>
            <SubmitButton loading={btnLoading} text="Save" />
          </div>
          <h4>Order Information</h4>
          <div className="row g-3 mb-4">
            {isDirty &&
              watch('status') === 'CANCELED' &&
              status !== 'CANCELED' &&
              paymentType === 'UPHOLD' ? (
              <div className="col-md-3">
                <CFormLabel>OTP Token</CFormLabel>
                <CFormInput
                  {...register('otpToken', {
                    required: 'This field is required.',
                  })}
                />
                {errors.otpToken && (
                  <span className="text-danger">{errors.otpToken.message}</span>
                )}
              </div>
            ) : null}
            {watch('status') === 'CANCELED' && cancelReason !== '' ? (
              <div className="col-md-3">
                <CFormLabel>Cancellation Reason</CFormLabel>
                <CFormSelect
                  disabled={
                    btnLoading ||
                    (Date.now() > releaseSharesAt && status === 'CANCELED')
                  }
                  {...register('cancelReason', {
                    required: 'This field is required.',
                  })}
                >
                  <option disabled value=""></option>
                  <option value="NOT_ACCREDITED_INVESTOR">
                    Not Accredited Investor
                  </option>
                  <option value="INPUT_ERROR">Input Error</option>
                  <option value="CHANGED_MIND">Changed Mind</option>
                  <option value="NO_PAYMENT_RECIEVED">
                    No Payment Received
                  </option>
                  <option value="ABUSER">Abuser</option>
                  <option value="ORDER_EXPIRED">Order Expired</option>
                  <option value="OTHER">Other</option>
                </CFormSelect>
                {errors.cancelReason && (
                  <span className="text-danger">
                    {errors.cancelReason.message}
                  </span>
                )}
              </div>
            ) : null}
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md-3">
              <CFormLabel>Order Status</CFormLabel>
              <CFormSelect
                disabled={
                  btnLoading ||
                  (Date.now() > releaseSharesAt && status === 'CANCELED')
                }
                defaultValue={status}
                {...register('status', { required: 'This field is required.' })}
                name="status"
              >
                {renderStatus(status, accreditedStatus, order)}
              </CFormSelect>
              {errors.status && (
                <span className="text-danger">{errors.status.message}</span>
              )}
            </div>
            <div className="col-md-3">
              <CFormLabel>Order Created At (Pacific Time)</CFormLabel>
              <CInputGroupText>
                {formatToPT(orderCreatedAt, 'MM/DD/YYYY hh:mm a')}
              </CInputGroupText>
            </div>
            <div className="col-md-3">
              <CFormLabel>Order Expires At (Pacific Time)</CFormLabel>
              <CInputGroupText>
                {formatToPT(orderExpiresAt, 'MM/DD/YYYY hh:mm a')}
              </CInputGroupText>
            </div>
            <div className="col-md-3">
              <CFormLabel>Payment Type</CFormLabel>
              <CInputGroupText>{paymentType}</CInputGroupText>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md-3">
              <CFormLabel>Shares</CFormLabel>
              <CInputGroupText>{shares}</CInputGroupText>
            </div>
            <div className="col-md-3">
              <CFormLabel>Share Price</CFormLabel>
              <CInputGroupText>
                $
                {sharePrice.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </CInputGroupText>
            </div>
            <div className="col-md-3">
              <CFormLabel>Price</CFormLabel>
              <CInputGroupText>
                $
                {price.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                {price !== adjustedPrice && adjustedPrice > 0 ? (
                  <span style={{ paddingLeft: '10px' }}>
                    (Adj: $
                    {adjustedPrice.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    )
                  </span>
                ) : null}
              </CInputGroupText>
            </div>
            <div className="col-md-3">
              <CFormLabel>Linqto Bucks used</CFormLabel>
              <CFormInput
                disabled
                value={
                  linqtoBucks?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || '0.00'
                }
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Trade ID</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={tradeId}
                query="trades"
                id={tradeId}
              />
            </div>
            <div className="col-md-3 offset-md-3">
              <CFormLabel>Purchase Credits</CFormLabel>
              <CFormInput
                disabled
                value={
                  credits?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || '0.00'
                }
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Discount applied</CFormLabel>
              <CFormInput
                disabled
                value={
                  discountAmount?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || '0.00'
                }
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Promo ID</CFormLabel>
              <CFormInput disabled value={promoId} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Email</CFormLabel>
              <CFormInput disabled value={email} />
            </div>
            {!!upholdEmail && (
              <div className="col-md-3">
                <CFormLabel>Uphold Email</CFormLabel>
                <CFormInput disabled value={upholdEmail} />
              </div>
            )}
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md-3">
              <CFormLabel>Purchase As</CFormLabel>
              <div className="d-flex align-items-center">
                <CFormSelect
                  disabled={btnLoading}
                  {...register('purchaseAs')}
                  onChange={(e: any) => handlePurchaseAs(e.target.value)}
                >
                  {user && <option value={userId}>{user}</option>}
                  {entities &&
                    entities.length &&
                    entities?.map((o: any, i: number) => (
                      <option key={i} value={o.entityId}>
                        {o.name}
                      </option>
                    ))}
                </CFormSelect>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/${+watch('purchaseAs') === userId ? 'users' : 'entities'
                    }/${watch('purchaseAs')}`}
                >
                  <img
                    className="ms-2 hover"
                    alt="New-Tab"
                    src={NewTab}
                    height={18}
                    width={18}
                  />
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <CFormLabel>Series</CFormLabel>
              <Select
                readOnly={btnLoading}
                options={Option}
                loadSuggestedOptions={loadSuggestedOptions}
                handleChange={handleChange}
                watchValue={watch('series')}
                query="serieses"
                id={watch('seriesId')}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Blue Sky State</CFormLabel>
              <CFormInput {...register('blueSkyState')} disabled={btnLoading} />
            </div>
            {reversingOrderId && (
              <div className="col-md-3">
                <CFormLabel>Reversal Order Id</CFormLabel>
                <Select
                  readOnly
                  options={Option}
                  watchValue={reversingOrderId}
                  query="orders"
                  id={reversingOrderId}
                  ref={null}
                />
              </div>
            )}
            {riaName && (
              <div className="col-md-3">
                <CFormLabel>Executed By</CFormLabel>
                <CFormInput disabled value={riaName} />
              </div>
            )}
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md-3">
              <CFormLabel>Promotion Amount</CFormLabel>
              <CFormInput
                type="number"
                {...register('promotionAmount')}
                disabled={btnLoading}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Promotion Type</CFormLabel>
              <CFormInput
                type="text"
                {...register('promotionType')}
                disabled={btnLoading}
              />
            </div>
            <div className="col-md-3">
              <Checkbox
                btnLoading={btnLoading}
                registerValue="promotionFulfilled"
                formLabel="Promotion Fulfilled"
              />
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col">
                <h4>Notes</h4>
                <div className="col-md-12 d-flex align-items-end justify-content-between">
                  <div className="d-flex flex-column w-100 me-2">
                    <CFormLabel>Add Note</CFormLabel>
                    <CFormInput
                      disabled={btnLoading}
                      value={note}
                      onChange={(e: any) => setNote(e.target.value)}
                    />
                  </div>
                  <CButton
                    disabled={!note}
                    type="button"
                    onClick={() => updateOrderWithNote()}
                  >
                    Add
                  </CButton>
                </div>
                {fields && fields.length
                  ? fields.map((note: any, i: number) => (
                    <Note
                      note={note}
                      key={i}
                      index={i}
                      deleteNote={() => deleteNote(note, i)}
                    />
                  ))
                  : null}
              </div>
              <div className="col">
                <h4>Documents</h4>
                {documents &&
                  documents.length &&
                  documents.map(({ name, url, updatedAt }: any, i: number) => (
                    <Document
                      key={i}
                      name={name}
                      url={url}
                      createdAt={updatedAt}
                      loading={btnLoading}
                    />
                  ))}
              </div>
              <div>
                <h4>Transactions</h4>
                <GenericTable
                  items={transactions || []}
                  columns={transactionsColumns}
                  handleClick={(item: any) =>
                    history.push(`/transactions/${item.transactionId}`)
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </PageLayout>
    </FormProvider>
  )
}

export default OrderDetail
