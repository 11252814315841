import { CButton, CFormLabel, CFormTextarea } from '@coreui/react-pro'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'

const FAQs: React.FC = () => {
  const { register, control } = useFormContext()
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )
  const { fields: faqs, append: appendFAQs } = useFieldArray({
    control,
    name: 'companyDetail.faqs',
  })

  return (
    <div className="g-3 mb-4">
      <div className="col-sm">
        <div className="d-flex justify-content-end mt-2">
          <CButton
            size="sm"
            onClick={() => appendFAQs({ text: '', title: '' })}
            disabled={btnLoading}
          >
            Add FAQ
          </CButton>
        </div>
        <div className="mb-5">
          {faqs.map((field: any, index: number) => (
            <>
              <h5>FAQ #{index + 1}</h5>
              <div
                key={field.id}
                className="d-flex align-items-center mb-4 gap-2"
              >
                <div className="col-md-6">
                  <CFormLabel>Title</CFormLabel>
                  <CFormTextarea
                    disabled={btnLoading}
                    {...register(`companyDetail.faqs.${index}.title`)}
                  />
                </div>
                <div className="col-md-6">
                  <CFormLabel>Text</CFormLabel>
                  <CFormTextarea
                    disabled={btnLoading}
                    {...register(`companyDetail.faqs.${index}.text`)}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FAQs
