import React, { FC, useEffect, useState } from 'react'
import PageLayout from '../../layout/PageLayout'
import { useParams } from 'react-router-dom'
import SubmitButton from '../../components/SubmitButton'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import {
  CFormInput,
  CFormLabel,
  CFormSelect,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CLoadingButton
} from '@coreui/react-pro'
import Note from '../../components/Note'
import privateAPI from '../../APIs/private'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import Spinner from '../../components/Spinner'
import { Column } from '../../types/General'
import {
  formatDecimal,
  formatToPT,
  toCamalCase,
} from '../../utils/formatUtils/utils'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import Select from '../assets/components/Select'
import { getUpholdAccess } from '../upholdAccesses/UpholdAccessDetail'

type Row = {
  amount: number
  cashAccountId: number
  cashAccountOwner: string
  cashPaymentOrderId: number
  createdAt: Date
  rowCount: number
  status: string
  type: string
  notes: string
  cardId: string
  cardLabel: string
  cardCurrency: string
  upholdAccessId: number
  upholdBusinessCardBalance: string
  upholdWithdrawId: number
  upholdWithdrawStatus: string
  upholdCashAccountId: number
  upholdCashAccountOwner?: string
}

type Response = {
  data: {
    rows: Row[]
    columns: Column[]
  }
}

const getCashPaymentOrder = async (id: string) => {
  const response: Response = await privateAPI.get(
    `/queries/cash_payment_orders/${id}`,
  )
  const data = response?.data?.rows?.[0] || undefined
  if (data) {
    if (data && data.notes) {
      data.notes = JSON.parse(data.notes)
    }
  }
  return data
}
const saveCashPaymentOrder = async (id: string, data: any) => {
  const response: Response = await privateAPI.post(
    `/queries/cash_payment_orders/${id}`,
    data,
  )
  return response?.data?.rows?.[0] || undefined
}

const approveUpholdWithdraw = async (
  upholdWithdrawId: number,
  otpToken: string,
) => {
  try {
    await privateAPI.post(
      `/upholdCashAccountWithdraw/${upholdWithdrawId}/withdrawToUphold`,
      { otpToken },
    )
  } catch (err: any) {
    throw new Error(err?.response?.data?.error)
  }
}

const CashPaymentOrderDetail: FC = () => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const [note, setNote] = useState('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')
  const me = useAppSelector((state: RootState) => state.user)
  const methods = useForm()
  const { register, watch, reset, handleSubmit, control } = methods

  const { data, isLoading } = useQuery({
    queryKey: ['cash_payment_order', id],
    queryFn: async () => await getCashPaymentOrder(id),
    enabled: +id > 0,
    retry: false,
  })

  const {
    cardLabel,
    cardCurrency,
    upholdBusinessCardBalance,
    upholdAccessId,
    upholdWithdrawId,
    upholdWithdrawStatus,
    upholdCashAccountId,
    upholdCashAccountOwner
  } = data || {}

  const { data: upholdAccessData } = useQuery({
    queryKey: ['cash_payment_order_uphold_accesses', upholdAccessId],
    queryFn: async () => await getUpholdAccess(upholdAccessId),
    enabled: !!upholdAccessId,
    retry: false,
  })

  const { fields, prepend, remove } = useFieldArray({ control, name: 'notes' })

  useEffect(() => reset(data), [data, reset])

  const saveCashPaymentOrderMutation = useMutation({
    mutationFn: async (data: any) => await saveCashPaymentOrder(id, data),
    onSuccess: async () =>
      queryClient.invalidateQueries(['cash_payment_order', id]),
  })

  const sendUpholdWithdrawOtp = useMutation({
    mutationFn: async () =>
      await approveUpholdWithdraw(upholdWithdrawId || 0, value),
    onSuccess: () => {
      queryClient.invalidateQueries(['cash_payment_order', id])
      setIsOpen(false)
    },
  })

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  const onSubmit = async (data: any) => {
    if (fields && fields.length) {
      fields.forEach((note: any) => delete note.updated)
    }
    data.notes = JSON.stringify(fields)
    saveCashPaymentOrderMutation.mutate(data)
  }

  if (isLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between">
            <h3>Cash Payment Order No. {id}</h3>
            <SubmitButton
              loading={saveCashPaymentOrderMutation.isLoading}
              text="Save"
            />
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>Approval Status</CFormLabel>
              <CFormSelect
                {...register('status')}
                disabled={data?.status !== 'IRM_NEEDS_APPROVAL'}
              >
                {data?.status !== 'IRM_NEEDS_APPROVAL' ? (
                  <option value={data?.status}>
                    {toCamalCase(data?.status?.replace('_', ' ') || '')}
                  </option>
                ) : (
                  <>
                    <option value="APPROVED">Approved</option>
                    <option value="IRM_REJECTED">Rejected</option>
                    <option value="IRM_NEEDS_APPROVAL">Needs Approval</option>
                  </>
                )}
              </CFormSelect>
            </div>
            <div className="col-md-3">
              <CFormLabel>Date</CFormLabel>
              <CFormInput
                disabled
                value={formatToPT(watch('createdAt'), 'MM/DD/YY')}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Amount</CFormLabel>
              <CFormInput
                disabled
                value={formatDecimal(watch('amount') || 0)}
              />
            </div>
            {!upholdAccessId && (
              <>
                <div className="col-md-3">
                  <CFormLabel>Total Completed Deposits</CFormLabel>
                  <CFormInput
                    disabled
                    value={formatDecimal(
                      watch('totalCompletedDepositsAmount') || 0,
                    )}
                  />
                </div>
                <div className="col-md-3">
                  <CFormLabel>Type</CFormLabel>
                  <CFormInput disabled {...register('type')} />
                </div>
                <div className="col-md-3">
                  <CFormLabel>Destination Account</CFormLabel>
                  <Select
                    readOnly
                    watchValue={watch('cashAccountOwner')}
                    query="cash_external_accounts"
                    id={watch('destinationAccountId')}
                    ref={null}
                  />
                </div>
              </>
            )}
            <div className="col-md-3">
              <CFormLabel>Cash Account</CFormLabel>
              <Select
                readOnly
                watchValue={watch('cashAccountOwner')}
                query="cash_accounts"
                id={watch('cashAccountId')}
                ref={null}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>User</CFormLabel>
              <Select
                readOnly
                watchValue={watch('cashAccountOwner')}
                query="users"
                id={watch('userId')}
                ref={null}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-md-12 d-flex align-items-end justify-content-between">
              <div className="d-flex flex-column w-100 me-2">
                <h4>Notes</h4>
                <CFormInput
                  placeholder="Add note..."
                  disabled={saveCashPaymentOrderMutation.isLoading}
                  value={note}
                  onChange={(e: any) => setNote(e.target.value)}
                />
              </div>
              <CButton disabled={!note} type="button" onClick={updateNote}>
                Add
              </CButton>
            </div>
            {fields && fields.length
              ? fields.map((note: any, i: number) => (
                  <Note
                    index={note.id}
                    key={note.id}
                    note={note}
                    deleteNote={() => remove(i)}
                  />
                ))
              : null}
          </div>
          {!!upholdAccessId && (
            <>
              <div className="d-flex justify-content-between mt-5">
                <h3>Uphold Info</h3>
                {(upholdWithdrawStatus === 'NEEDS_REVIEW' ||
                  upholdWithdrawStatus === 'ERROR') &&
                  watch('status') === 'IRM_NEEDS_APPROVAL' && (
                    <CButton onClick={() => setIsOpen(!isOpen)}>
                      Transfer
                    </CButton>
                  )}
              </div>
              <div className="row g-3 mb-4">
                <div className="col-md-3">
                  <CFormLabel>Uphold Withdraw Status</CFormLabel>
                  <CFormSelect
                    {...register('upholdWithdrawStatus')}
                    disabled={upholdWithdrawStatus === 'COMPLETED'}
                  >
                    <>
                      <option value="NEEDS_REVIEW">Needs Review</option>
                      <option value="COMPLETED">Completed</option>
                      <option value="REJECTED">Rejected</option>
                      <option value="ERROR">Error</option>
                    </>
                  </CFormSelect>
                </div>
                <div className="col-md-3">
                  <CFormLabel>Uphold AML Status</CFormLabel>
                  <CFormSelect {...register('upholdAmlStatus')} disabled>
                    <>
                      <option value="NEEDS_REVIEW">Needs Review</option>
                      <option value="APPROVED">Approved</option>
                      <option value="IRM_REJECTED">Rejected</option>
                      <option value="UNKNOWN">Unknown</option>
                      <option value="CS_NEEDS_REVIEW">CS Needs Review</option>
                    </>
                  </CFormSelect>
                </div>
                <div className="col-md-3">
                  <CFormLabel>Destination Card Label</CFormLabel>
                  <CFormInput disabled value={cardLabel} />
                </div>
                <div className="col-md-3">
                  <CFormLabel>Destination Card Currency</CFormLabel>
                  <CFormInput disabled value={cardCurrency} />
                </div>
                <div className="col-md-3">
                  <CFormLabel>Uphold Access</CFormLabel>
                  <Select
                    readOnly
                    watchValue={upholdAccessData?.legalName}
                    query="uphold_accesses"
                    id={upholdAccessId}
                    ref={null}
                  />
                </div>
                <div className="col-md-3">
                  <CFormLabel>Uphold Cash Account Owner</CFormLabel>
                  <Select
                    readOnly
                    watchValue={upholdCashAccountOwner}
                    query="cash_accounts"
                    id={upholdCashAccountId}
                    ref={null}
                  />
                </div>
                <div className="col-md-3">
                  <CFormLabel>Uphold Completed Deposits</CFormLabel>
                  <CFormInput
                    disabled
                    value={formatDecimal(
                      watch('totalCompletedUpholdDepositsAmount') || 0,
                    )}
                  />
                </div>
              </div>
            </>
          )}
          {upholdAccessId && (
            <div className="mt-5">
              <h3>Linqto Balance</h3>
              <div className="col-md-3">
                <CFormLabel>Uphold Business Balance</CFormLabel>
                <CFormInput
                  disabled
                  value={formatDecimal(
                    upholdBusinessCardBalance ? +upholdBusinessCardBalance : 0,
                  )}
                />
              </div>
            </div>
          )}
        </form>
      </FormProvider>
      <CModal visible={isOpen}>
        <CModalBody>
          <CFormLabel>Enter OTP for Uphold Withdraw</CFormLabel>
          <CFormInput
            className="mt-4"
            type="text"
            placeholder="Password"
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            maxLength={6}
          />
        </CModalBody>
        <CModalFooter>
          <CButton
            color="white"
            className="float-end"
            onClick={() => setIsOpen(false)}
          >
            Close
          </CButton>
          <CLoadingButton
            type="submit"
            className="btn"
            disabled={sendUpholdWithdrawOtp.isLoading}
            loading={sendUpholdWithdrawOtp.isLoading}
            onClick={sendUpholdWithdrawOtp.mutate}
          >
            Confirm
          </CLoadingButton>
        </CModalFooter>
      </CModal>
    </PageLayout>
  )
}

export default CashPaymentOrderDetail
