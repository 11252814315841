import React from 'react'
import moment from 'moment'
import CIcon from '@coreui/icons-react'
import { cilX } from '@coreui/icons'

moment.suppressDeprecationWarnings = true

interface NoteProps {
  note: {
    firstName: string
    note: string
    time: number
    updated?: boolean
    notes?: any
  }
  index: number
  deleteNote?: (note: any, i: number) => void
}

const Note: React.FC<NoteProps> = ({ note, index, deleteNote }: NoteProps) => {
  if (!note.updated) {
    return (
      <div className="bg-cobalt text-white rounded my-2 py-1 px-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div className="first-name">{note.firstName}</div>
            <div className="notes">{note.notes || note.note}</div>
          </div>
          <div className="time">{moment(+note.time).format('MMM DD')}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="my-2 py-1 px-2 rounded bg-indigo text-white d-flex align-items-center justify-content-between">
      <div className="d-flex">
        <div className="first-name">{note.firstName}</div>
        <div className="notes">{note.notes || note.note}</div>
      </div>
      <div className="d-flex">
        <div className="time">{moment(+note.time).format('MMM DD')}</div>
        {deleteNote && (
          <CIcon
            style={{ margin: '3px 0 0 10px' }}
            icon={cilX}
            onClick={() => deleteNote(note, index)}
          />
        )}
      </div>
    </div>
  )
}

export default Note
