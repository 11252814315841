import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { RootState } from '../../store'
import PageLayout from '../../layout/PageLayout'
import { CFormInput, CFormLabel, CLoadingButton } from '@coreui/react-pro'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import { createTeamMember, resetError } from '../../features/teamMembersSlice'
import Alert from '../../components/Alert'
import { useHistory } from 'react-router-dom'

const CreateTeamMember: React.FC = () => {
  const history = useHistory()
  const [uploadedLogoUrl, setUploadedLogoUrl] = useState<string>('')
  const [btnLoading, setBtnLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { error, errorMessage } = useAppSelector(
    (state: RootState) => state.team_members,
  )

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm()

  const handleFileChange = (e: any) => {
    const files = e.target.files
    const reader = new FileReader()

    if (files.length > 0) {
      reader.readAsDataURL(files[0])

      reader.onload = (e: any) => {
        setUploadedLogoUrl(e.target.result)
        setValue('pictureUrl', e.target.result, { shouldDirty: true })
      }
    }
  }

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    const res = await dispatch(createTeamMember({ data: { ...data } }))
    if (res && res.payload === 200) {
      setBtnLoading(false)
      setUploadedLogoUrl('')
      reset()
      alert('User has been created.')
      history.goBack()
    } else {
      setBtnLoading(false)
    }
  }

  return (
    <PageLayout>
      {error && errorMessage && (
        <Alert
          color="danger"
          visible={error}
          onClose={() => dispatch(resetError())}
          text={errorMessage}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between">
          <h3>Create Team Member</h3>
          <div className="d-flex">
            <CLoadingButton
              disabled={!isDirty}
              loading={btnLoading}
              className="btn-success mx-2"
              type="submit"
            >
              Save
            </CLoadingButton>
          </div>
        </div>
        <h4>Team Member Information</h4>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel className="form-label">First Name</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('firstName', {
                required: 'This field is required.',
              })}
            />
            {errors.firstName && (
              <span className="text-danger">{errors.firstName.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Last Name</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('lastName', {
                required: 'This field is required.',
              })}
            />
            {errors.lastName && (
              <span className="text-danger">{errors.lastName.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Board Member Title</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('boardMemberTitle')}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Team Member Title</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('teamMemberTitle')}
            />
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>Bio</CFormLabel>
            <textarea
              disabled={btnLoading}
              className="form-control"
              rows={3}
              {...register('bio', { required: 'This field is required.' })}
            ></textarea>
            {errors.bio && (
              <span className="text-danger">{errors.bio.message}</span>
            )}
          </div>
          <div className="d-flex align-items-start col-md-3">
            <div className="w-100">
              <CFormLabel>Logo</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={(e: any) => handleFileChange(e)}
              />
            </div>
            <img
              style={{
                marginTop: '1.9rem',
                maxWidth: '100px',
                maxHeight: '40px',
              }}
              className="ms-3 rounded"
              alt="logo-url"
              src={uploadedLogoUrl}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Order Index</CFormLabel>
            <CFormInput
              type="number"
              disabled={btnLoading}
              {...register('orderIndex', {
                required: 'This field is required.',
              })}
            />
            {errors.orderIndex && (
              <span className="text-danger">{errors.orderIndex.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Advisor Title</CFormLabel>
            <CFormInput disabled={btnLoading} {...register('advisorTitle')} />
          </div>
        </div>
        <div className="col g-3 mb-4">
          <div style={{ width: 'fit-content', marginBottom: '10px' }}>
            <div className="form-check hover">
              <input
                disabled={btnLoading}
                className="form-check-input hover"
                type="checkbox"
                {...register('isAdvisor')}
                id="isAdvisor"
              />
              <label className="form-check-label hover" htmlFor={'isAdvisor'}>
                Is Advisor
              </label>
            </div>
          </div>
          <div style={{ width: 'fit-content', marginBottom: '10px' }}>
            <div className="form-check hover">
              <input
                disabled={btnLoading}
                className="form-check-input hover"
                type="checkbox"
                {...register('isBoardMember')}
                id="isBoardMember"
              />
              <label
                className="form-check-label hover"
                htmlFor={'isBoardMember'}
              >
                Is Board Member
              </label>
            </div>
          </div>
          <div style={{ width: 'fit-content', marginBottom: '10px' }}>
            <div className="form-check hover">
              <input
                disabled={btnLoading}
                className="form-check-input hover"
                type="checkbox"
                {...register('isLeadership')}
                id="isLeadership"
              />
              <label
                className="form-check-label hover"
                htmlFor={'isLeadership'}
              >
                Is Leadership
              </label>
            </div>
          </div>
          <div style={{ width: 'fit-content', marginBottom: '10px' }}>
            <div className="form-check hover">
              <input
                disabled={btnLoading}
                className="form-check-input hover"
                type="checkbox"
                {...register('isTeamMember')}
                id="isTeamMember"
              />
              <label
                className="form-check-label hover"
                htmlFor={'isTeamMember'}
              >
                Is Team Member
              </label>
            </div>
          </div>
        </div>
      </form>
    </PageLayout>
  )
}

export default CreateTeamMember
