import { CNav, CNavItem, CNavLink } from '@coreui/react-pro'
import React, { Dispatch, SetStateAction } from 'react'
interface TabContainerProps {
  tabs: string[]
  active: string
  setActive: Dispatch<SetStateAction<string>>
}

const TabContainer: React.FC<TabContainerProps> = ({
  tabs,
  active,
  setActive,
}: TabContainerProps) => {
  const handleTabChange = (tag: any) => setActive(tag)
  return (
    <CNav variant="tabs">
      {tabs.map((tag, i) => (
        <CNavItem className="hover" key={i}>
          <CNavLink
            active={active === tag}
            onClick={() => handleTabChange(tag)}
            className={`${active !== tag && 'inactive'}`}
          >
            {tag}
          </CNavLink>
        </CNavItem>
      ))}
    </CNav>
  )
}

export default TabContainer
