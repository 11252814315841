import React from 'react'
import { AppHeader, AppSidebar } from '../components'

const PageLayout: React.FC = ({ children }: any) => {
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">{children}</div>
      </div>
    </div>
  )
}

export default PageLayout
