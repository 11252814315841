import React, { useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import WarningModal from '../../components/WarningModal'
import TagDetailForm from './TagDetailForm'
import { useQuery, useMutation } from '@tanstack/react-query'
import privateAPI from '../../APIs/private'

const getTag: any = async (id: number) => {
  const { data } = await privateAPI.get(`/queries/tags/${id}`)
  return data?.rows?.[0]
}

const updateTag: any = async (id: number, data: any) => {
  const response = await privateAPI.post(`/queries/tags/${id}`, data)
  return response.data
}

const deleteTag: any = async (id: number) =>
  await privateAPI.delete(`/queries/tags/${id}`)

const createTag: any = async (name: string) =>
  await privateAPI.post('/queries/tags/-1', name)

const TagDetail: React.FC = () => {
  const { id }: any = useParams()
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const methods = useForm()
  const { reset } = methods

  const { data, isInitialLoading, error } = useQuery({
    queryKey: ['tags', id],
    queryFn: () => getTag(id),
    enabled: +id > 0,
  })

  const handleUpdateTag = useMutation({
    mutationFn: async (data: { name: string }) => await updateTag(id, data),
    onSuccess: () => history.goBack(),
  })

  const handleDeleteTag = useMutation({
    mutationFn: async () => await deleteTag(id),
    onSuccess: () => history.goBack(),
  })

  const handleCreateTag = useMutation({
    mutationFn: async (name: string) => createTag(name),
    onSuccess: () => history.goBack(),
  })

  useEffect(() => reset(data), [reset, data])

  const onSubmit = async (data: any) =>
    handleUpdateTag.mutate({ name: data.newName })

  if (error) {
    return (
      <PageLayout>
        <WarningModal
          header="Tag Name Exists"
          body="There was an error creating/updating the tag. Please try again with another name."
          proceed="Okay"
          proceedAction={() => setOpen(false)}
          open={open}
        />
      </PageLayout>
    )
  }

  if (isInitialLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  let title = ''

  if (+id === -1) {
    title = 'Create Tag'
  } else {
    title = `Tag No. ${id}`
  }

  return (
    <PageLayout>
      {open && (
        <WarningModal
          header="Are You Sure?"
          body="Are you sure you want to delete this tag? This will also remove the tags for all the users who have this tag."
          abandon="Not Now"
          proceed="Confirm"
          abandonAction={() => setOpen(false)}
          proceedAction={handleDeleteTag.mutate}
          open={open}
          loading={handleDeleteTag.isLoading}
        />
      )}
      <FormProvider {...methods}>
        <h2>{title}</h2>
        <TagDetailForm
          id={id}
          onSubmit={+id === -1 ? handleCreateTag.mutate : onSubmit}
          btnLoading={handleUpdateTag.isLoading}
          setOpen={setOpen}
          data={data}
        />
      </FormProvider>
    </PageLayout>
  )
}

export default TagDetail
