import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import { formatTableRenderers } from '../utils/formatUtils/utils'

interface OffersState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  offer: any
  error: boolean
  errorMessage: string
  transactions: any[]
  transactionColumns: any[]
  sellOffer: any
}

const initialState: OffersState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  offer: {},
  error: false,
  errorMessage: '',
  transactions: [],
  transactionColumns: [],
  sellOffer: null,
}

export const getOffer = createAsyncThunk(
  'getOffer',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.get(`/queries/offers/${id}`)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getSellOffer = createAsyncThunk(
  'getSellOffer',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.get(`/queries/sell_offers/${id}`)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const saveOffer = createAsyncThunk(
  'saveOffer',
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/queries/sell_offers/${id}`, data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const saveSellOffer = createAsyncThunk(
  'saveSellOffer',
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/queries/sell_offers/${id}`, data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const createOffer = createAsyncThunk(
  'createOffer',
  async ({ data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post('/queries/offers/-1', data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const deleteOffer = createAsyncThunk(
  'deleteOffer',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      await privateAPI.delete(`/queries/offers/${id}`)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getOfferTransactions = createAsyncThunk(
  'getOfferTransactions',
  async ({ id }: any) => {
    const data = {
      accreditedCountry: {},
      addressCountry: {},
      companyName: {},
      createdAt: {},
      defaultInclude: true,
      doneAt: {},
      groupFields: false,
      price: {},
      sharePrice: {},
      shares: {},
      status: {},
      userEmail: {},
      attritionCause: {},
      userId: {},
      userProfileId: {},
      offerId: { include: false, filterValues: [+id] },
      sellOrderId: {},
    }

    try {
      const response = await privateAPI.post('/queries/transactions', data)
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = false
      state.errorMessage = ''
    },
    resetOffer: (state) => {
      state.offer = {}
    },
    resetSellOffer: (state) => {
      state.sellOffer = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOffer.fulfilled, (state, { payload }: any) => {
        const offer = payload?.rows?.[0] || {}
        state.offer = offer
        try {
          state.offer.notes = JSON.parse(offer.notes)
        } catch (e) {
          state.offer.notes = []
        }
      })
      .addCase(getSellOffer.fulfilled, (state, { payload }: any) => {
        const sellOffer = payload?.rows?.[0] || {}
        state.sellOffer = sellOffer
        try {
          state.sellOffer.notes = JSON.parse(sellOffer.notes)
        } catch (e) {
          state.sellOffer.notes = []
        }
      })
      .addCase(getOfferTransactions.fulfilled, (state, { payload }: any) => {
        if (payload) {
          const { rows, columns } = payload || {}
          const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
            rows,
            columns,
          )
          state.sellOffer = { ...state.sellOffer, transactions: dataRows }
          state.transactionColumns = dataColumns || []
        }
      })
      .addCase(createOffer.rejected, (state) => {
        state.error = true
        state.errorMessage = 'There was an error creating this offer.'
      })
      .addCase(saveOffer.rejected, (state) => {
        state.error = true
        state.errorMessage = 'There was an error saving this offer.'
      })
      .addCase(deleteOffer.rejected, (state) => {
        state.error = true
        state.errorMessage = 'There was an error deleting this offer.'
      })
  },
})

export const { resetError, resetOffer, resetSellOffer } = offersSlice.actions

export default offersSlice.reducer
