import { CButton, CCollapse, CFormInput, CFormLabel } from '@coreui/react-pro'
import React, { useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import Note from '../../../components/Note'

const UserNotes: React.FC<any> = ({ control, btnLoading, isVisible }) => {
  const me = useAppSelector((state: RootState) => state.user)
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'notes',
  })
  const [note, setNote] = useState<string>('')


  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  return (
    <div style={{ paddingLeft: 20 }}>
      <CCollapse visible={isVisible}>
        <div className="col-md-6">
          <div className="col-md-12 d-flex align-items-end justify-content-between">
            <div className="d-flex flex-column w-100 me-2">
              <CFormLabel>Add Note</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                value={note}
                onChange={(e: any) => setNote(e.target.value)}
              />
            </div>
            <CButton disabled={!note} type="button" onClick={updateNote}>
              Add
            </CButton>
          </div>
          {fields?.length
            ? fields.map((note: any, i: number) => (
              <Note
                index={note.id}
                key={note.id}
                note={note}
                deleteNote={() => remove(i)}
              />
            ))
            : null}
        </div>
      </CCollapse>
    </div>
  )
}
export default UserNotes