import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import WarningModal from '../../components/WarningModal'
import RIAFirmDetailForm from './RIAFirmDetailForm'
import {
  createRIAFirm,
  getRIAFirm,
  getRIALinkedAdvisors,
  updateRIAFirm,
  resetRIAFirm,
} from '../../features/riaSlice'
import { getCountries } from '../../features/commonSlice'
import { uploadDocs } from '../../features/usersSlice'
import LinkItems from '../../components/LinkItem/LinkItems'

const RIAFirmDetail: React.FC = () => {
  const { id }: any = useParams()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useAppDispatch()
  const { riaFirm } = useAppSelector((state: RootState) => state.ria)
  const methods = useForm()
  const history = useHistory()

  const { reset } = methods

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getCountries())
      if (+id !== -1) {
        setLoading(true)
        const response = await dispatch(getRIAFirm({ id }))
        const riaFirm = response?.payload?.rows[0] || {}
        if (riaFirm && riaFirm.riaFirmId) {
          await dispatch(getRIALinkedAdvisors({ id: riaFirm.riaFirmId }))
        }
        await dispatch(getCountries())
        setLoading(false)
      } else {
        setLoading(false)
      }
      setLoading(false)
    }
    fetchData()
  }, [id, dispatch])

  useEffect(() => {
    reset(riaFirm)
  }, [reset, riaFirm])

  useEffect(() => {
    return () => {
      dispatch(resetRIAFirm())
    }
  }, [dispatch])

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    const res = await dispatch(updateRIAFirm({ id, data }))
    if (res.payload !== 200) {
      setError(true)
    } else {
      await dispatch(getRIAFirm({ id }))
    }
    setBtnLoading(false)
  }

  const performCreateRIAFirm = async (data: any) => {
    setBtnLoading(true)
    const res = await dispatch(createRIAFirm({ data }))
    setBtnLoading(false)
    if (res.payload === 500) {
      setError(true)
    } else {
      alert('RIA Firm Created!')
      history.goBack()
    }
  }

  const uploadDoc = async (e: any) => {
    if (e.target && e.target.files?.length) {
      setBtnLoading(true)
      await dispatch(
        uploadDocs({
          acceptedFile: e.target.files[0],
          fileName: e.target.files[0].name,
          type: 'RIA_FIRM',
          userId: id,
        }),
      )
      await dispatch(getRIAFirm({ id }))
      setBtnLoading(false)
    }
  }

  if (error) {
    return (
      <PageLayout>
        <WarningModal
          header="Error Updating RIA Firm"
          body="There was an error updating the RIA Firm. Please try again."
          proceed="Okay"
          proceedAction={() => setError(false)}
          open={error}
        />
      </PageLayout>
    )
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  const { linkedAdvisors } = riaFirm || {}

  return (
    <FormProvider {...methods}>
      <PageLayout>
        <RIAFirmDetailForm
          uploadDoc={uploadDoc}
          btnLoading={btnLoading}
          onSubmit={+id === -1 ? performCreateRIAFirm : onSubmit}
          id={id}
        />
        {linkedAdvisors && linkedAdvisors.length ? (
          <div className="mt-4">
            <h3>Linked Advisors</h3>
            <LinkItems
              setBtnLoading={setBtnLoading}
              users={linkedAdvisors}
              type="ria-advisors"
            />
          </div>
        ) : null}
      </PageLayout>
    </FormProvider>
  )
}

export default RIAFirmDetail
