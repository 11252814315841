import { CFormInput, CFormLabel, CLoadingButton, CFormSelect, CButton } from '@coreui/react-pro'
import React, { useCallback, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Select from '../assets/components/Select'
import { components } from 'react-select'
import { debounce } from 'lodash'
import { useAppDispatch } from '../../features/hooks'
import { getCompanies } from '../../features/companiesSlice'
import { DiscountFormValues } from './discount-types'
import DatePicker from 'react-datepicker'
import Checkbox from '../../components/Checkbox'
import CIcon from '@coreui/icons-react'
import { cilCopy } from '@coreui/icons'
import GenericTable from '../../components/GenericTable'
import { SelectValue, SegmentListResponse } from './discount-types'
import { getSegmentList } from '../../utils/commonActions'

interface DiscountDetailFormProps {
  onSubmit: any
  discount: DiscountFormValues
  loading: boolean
  header: string
  discountCompaniesColumns: any
  discountCompanies: any
  discountTiersColumns: any
  discountTiers: any,
  setDiscountCompany: any
  setDiscountTier: any
  setShowDiscountCompany: any
  setShowDiscountTier: any
}

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}

const DiscountDetail: React.FC<DiscountDetailFormProps> = ({
  onSubmit,
  discount,
  loading,
  header,
  discountCompaniesColumns,
  discountCompanies,
  discountTiersColumns,
  discountTiers,
  setDiscountCompany,
  setDiscountTier,
  setShowDiscountTier,
  setShowDiscountCompany
}: DiscountDetailFormProps) => {
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    setValue,
    watch,
  } = useFormContext()

  const discountType = watch('type') || 'PERCENT'
  const fetchData = useCallback(
    async (e: string) => {
      const data = {
        queryParams: JSON.stringify({
          companyId: {},
          name: {},
          freeTextSearch: e,
          length: 10,
        }),
      }
      if (e.length > 0) {
        const response = await dispatch(getCompanies({ found: data }))

        const arr = response.payload?.rows || []
        return arr.map((a: { companyId: string; name: string }) => {
          return {
            id: a.companyId,
            value: a.name,
            label: a.name,
          }
        })
      }
    },
    [dispatch],
  )

  const loadSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchData(inputValue).then((options: SelectValue) => callback(options))
      }, 500),
    [fetchData],
  )

  const handleCompanyChange = (value: SelectValue) => {
    if (value) {
      setValue('companyName', value.value, { shouldDirty: true })
      setValue('forCompanyId', value.id, { shouldDirty: true })
    }
  }

  const fetchSegmentData = useCallback(
    async (e: string) => {
      const data = {
        segmentId: {},
        name: {},
        freeTextSearch: e,
        length: 10,
      }

      if (e.length > 0) {
        const response = await getSegmentList(data)
        if (response && response.length) {
          const options: SelectValue[] | undefined = response.map(
            (a: SegmentListResponse) => {
              return {
                id: a.segmentId,
                value: a.name,
                label: a.name
              }
            },
          )
          return options
        }
      }
    },
    [],
  )

  const loadSegmentSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchSegmentData(inputValue).then((options: SelectValue[] | undefined) => callback(options))
      }, 500),
    [fetchSegmentData],
  )

  const handleSegmentChange = (value: SelectValue) => {
    if (value) {
      setValue('segmentName', value.value, { shouldDirty: true })
      setValue('segmentId', value.id, { shouldDirty: true })
    }
  }

  const handlePercentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setValue('percent', value, { shouldDirty: true })
  }

  const {
    forCompanyId,
    maxApplicationPerUser,
    promoCode,
    amount,
    segmentName,
    segmentId,
    companyName,
    note,
    companyPromoLinks
  } = discount || {}

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between">
        <h3>{header}</h3>
        <div className="d-flex">
          <CLoadingButton
            disabled={!isDirty || loading}
            loading={loading}
            className="btn-success mx-2"
            type="submit"
          >
            Save
          </CLoadingButton>
        </div>
      </div>
      <h4>Discount Information</h4>
      <div className="row g-3 mb-4">
        <div className="col-md-4">
          <CFormLabel className="form-label">Title</CFormLabel>
          <CFormInput
            disabled={loading}
            {...register('note', {
              required: 'This field is required.',
            })}
            defaultValue={note || ''}
          />
          {errors.note && (
            <span className="text-danger">{errors.note.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <CFormLabel>Type</CFormLabel>
          <CFormSelect
            {...register('type')}
          >
            <option value="PERCENT">Percent</option>
            <option value="FLAT_RATE">Flat Rate</option>
          </CFormSelect>
        </div>

        {discountType === 'FLAT_RATE' && <div className="col-md-4">
          <CFormLabel className="form-label">Amount</CFormLabel>
          <CFormInput
            type="number"
            disabled={loading}
            {...register('amount')}
            defaultValue={amount || 0}
          />
          {errors.amount && (
            <span className="text-danger">{errors.amount.message}</span>
          )}
        </div>
        }
        {discountType === 'PERCENT' && <div className="col-md-4">
          <CFormLabel className="form-label">Percent</CFormLabel>
          <Controller
            name="percent"
            rules={{
              max: 0.99,
              min: 0
            }}
            render={({ field: { value } }) => (
              <CFormInput
                onChange={handlePercentChange}
                value={value ?? ''}
                type="number"
                disabled={loading}
              />
            )}
          />
          {errors.percent && (
            <span className="text-danger">This field is a decimal number.</span>
          )}
        </div>}

        <div className="col-md-4">
          <CFormLabel className="form-label">Max Application Per User</CFormLabel>
          <CFormInput
            type="number"
            disabled={loading}
            {...register('maxApplicationPerUser')}
            defaultValue={maxApplicationPerUser || 0}
          />
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Start At</CFormLabel>
          <Controller
            name='startAt'
            render={({ field: { onChange, value } }) => (
              <DatePicker
                selected={value}
                className="form-control"
                onChange={onChange}
                disabled={loading}
                showTimeSelect
                dateFormat="MM/dd/yyyy h:mm aa"
              />
            )}
          />
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Finish At</CFormLabel>
          <Controller
            name='finishAt'
            render={({ field: { onChange, value } }) => (
              <DatePicker
                selected={value}
                className="form-control"
                onChange={onChange}
                disabled={loading}
                showTimeSelect
                dateFormat="MM/dd/yyyy h:mm aa"
              />
            )}
          />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-4">
          <CFormLabel className="form-label">Company</CFormLabel>
          <div className="d-flex flex-row">
            <Select
              options={Option}
              loadSuggestedOptions={loadSuggestedOptions}
              handleChange={(value: SelectValue) => handleCompanyChange(value)}
              watchValue={watch('companyName') ?? companyName}
              query="companies"
              id={watch('forCompanyId') ?? forCompanyId}
              readOnly={loading}
            />
          </div>
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Segment</CFormLabel>
          <div className="d-flex flex-row">
            <Select
              options={Option}
              loadSuggestedOptions={loadSegmentSuggestedOptions}
              handleChange={(value: SelectValue) => handleSegmentChange(value)}
              watchValue={watch('segmentName') ?? segmentName}
              query="segments"
              id={watch('segmentId') ?? segmentId}
              readOnly={loading}
              {...register('segmentId', { required: 'This field is required' })}
            />
          </div>
          {errors.segmentId && (
            <span className="text-danger">{errors.segmentId.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Promo Code</CFormLabel>
          <CFormInput
            disabled={loading}
            {...register('promoCode')}
            defaultValue={promoCode || ''}
          />
        </div>
        <div className="col-md-3">
          <Checkbox
            formLabel="Is Active"
            registerValue="isActive"
            btnLoading={false}
          />
        </div>
      </div>
      {Object.keys(discount).length !== 0 &&
        <>
          {companyPromoLinks.length > 0 &&
            <>
              <h4 className='mt-5'>Discount Links</h4>
              <div className="col-md-6 w-100">
                {companyPromoLinks?.map((url: any, i: number) => (
                  <div key={i}>
                    <a href={url}>{url}</a> <span style={{ cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(url)}><CIcon icon={cilCopy} /></span>
                  </div>
                ))
                }
              </div>
            </>
          }
          <h4 className='mt-5'>Discount Companies</h4>
          <CButton onClick={() => setShowDiscountCompany(true)}>Add Discount Company</CButton>
          <GenericTable
            handleClick={(item: any) => {
              setDiscountCompany(item)
              setShowDiscountCompany(true)
            }}
            columns={discountCompaniesColumns}
            items={discountCompanies || []}
          />
          <h4 className='mt-5'>Discount Tiers</h4>
          <CButton onClick={() => setShowDiscountTier(true)}>Add Discount Tier</CButton>
          <GenericTable
            handleClick={(item: any) => {
              setDiscountTier(item)
              setShowDiscountTier(true)
            }}
            columns={discountTiersColumns}
            items={discountTiers || []}
          />
        </>}
    </form>
  )
}

export default DiscountDetail
