import { CFormInput } from '@coreui/react-pro'
import React from 'react'
import Select from 'react-select'
import Null from './Null'
import FilterNotEqual from './FilterNotEqual'

const baseStyles = {
  control: {
    borderRadius: '7px',
    boxShadow: 'none',
    margin: '10px 0',
    minWidth: '300px',
    maxWidth: '300px',
  },
  menu: {
    marginTop: 0,
    padding: 0,
    zIndex: 999,
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  group: {
    padding: 0,
  },
}

// custom styles for Select option
const customStyles = {
  container: (base: any) => ({
    ...base,
    minWidth: '300px',
    maxWidth: '300px',
    marginRight: '15px',
  }),
  control: (base: any) => ({
    ...base,
    ...baseStyles.control,
  }),
  menu: (base: any) => ({
    ...base,
    ...baseStyles.menu,
  }),
  menuList: (base: any) => ({
    ...base,
    ...baseStyles.menuList,
  }),
  group: (base: any) => ({
    ...base,
    ...baseStyles.group,
  }),
  input: (base: any) => ({
    ...base,
    visibility: 'visible',
  }),
}

const MIN_MAX_OPTIONS = [
  {
    label: 'Equal To',
    value: 'filterIsEqual',
  },
  {
    label: 'Less than or equal to',
    value: 'filterMax',
  },
  {
    label: 'Greater than or equal to',
    value: 'filterMin',
  },
]

const Number: any = ({ filter, filters, setFilters }: any) => {
  const {
    input: { value, filter: filterType },
  } = filter || {}

  const handleChange = (e: any) => {
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      if (e.value) {
        arr[idx].input.filter = e.value
      } else {
        arr[idx] = {
          ...arr[idx],
          input: { ...arr[idx].input, value: e.target.value },
        }
      }
      setFilters([...arr])
    }
  }

  const handleNullChange = (e: any) => {
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      arr[idx] = { ...arr[idx], filterIsNull: e.target.checked, input: {} }
      setFilters([...arr])
    }
  }

  const handleNotEqualCheckbox = (e: any) => {
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      arr[idx] = {
        ...arr[idx],
        input: {
          filter: 'filterValuesNotChecked',
          filterValuesNotChecked: e.target.checked,
          value: !e.target.checked ? 0 : arr[idx]?.input?.value,
        },
      }
      setFilters([...arr])
    }
  }

  return (
    <div className="d-flex align-items-center">
      {!filter.filterIsNull && (
        <>
          <CFormInput
            className="me-2"
            value={value}
            onChange={handleChange}
            type="text"
          />
          {!filter?.input?.filterValuesNotChecked && (
            <Select
              styles={customStyles}
              options={MIN_MAX_OPTIONS}
              onChange={handleChange}
              value={MIN_MAX_OPTIONS.find((item) => item.value === filterType)}
              defaultValue={MIN_MAX_OPTIONS.find(
                (item) => item.value === 'filterMax',
              )}
            />
          )}
        </>
      )}
      <div className="d-flex">
        <Null
          handleNullChange={handleNullChange}
          filterIsNull={filter.filterIsNull}
        />
        <FilterNotEqual
          handleChange={handleNotEqualCheckbox}
          value={filter?.input?.filterValuesNotChecked}
        />
      </div>
    </div>
  )
}

export default Number
