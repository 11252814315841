import React from 'react'
import { CChart } from '@coreui/react-chartjs'

const BarChart: any = ({ xAxisData, yAxis, yAxisData }: any) => {
  return (
    <CChart
      type="bar"
      data={{
        labels: xAxisData,
        datasets: [
          {
            label: yAxis?.label,
            backgroundColor: '#077187',
            data: yAxisData,
          },
        ],
      }}
    />
  )
}

export default BarChart
