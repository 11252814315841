import { CFormText } from '@coreui/react-pro'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface FormatNumberProps {
  watchValue: string
  isMoney: boolean
}

const FormatNumber: React.FC<FormatNumberProps> = ({
  watchValue,
  isMoney,
}: FormatNumberProps) => {
  const { watch } = useFormContext()
  return (
    <CFormText className="text-end">
      {watch(watchValue) ? (
        <>
          {isMoney ? '$' : null}
          {watch(watchValue)
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </>
      ) : null}
    </CFormText>
  )
}

export default FormatNumber
