import {
  CButton,
  CCollapse,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
} from '@coreui/react-pro'
import moment from 'moment'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import { formatDecimal } from '../../../utils/formatUtils/utils'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronRight } from '@coreui/icons'
import { saveSeriesFilings } from '../../../features/seriesSlice'
import { useParams } from 'react-router-dom'

type SeriesDetailFormProps = {
  loading: boolean
  btnLoading: boolean
  setBtnLoading: any
}

const SeriesDetailForm: React.FC<SeriesDetailFormProps> = ({
  loading,
  btnLoading,
  setBtnLoading,
}: SeriesDetailFormProps) => {
  const { id }: any = useParams()
  const dispatch = useAppDispatch()
  const [toggle, setToggle] = useState(false)
  const { series } = useAppSelector((state: RootState) => state.serieses)
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useFormContext()

  const { fields, append } = useFieldArray({
    control,
    name: 'filings',
  })

  const handleCreateFiling = () => {
    setToggle(true)
    append({ filingIdentifier: '', filingDate: null })
  }

  const handleSave = async (data: any, index: number) => {
    const filing = data?.filings?.[index] || {}
    if (filing) {
      setBtnLoading(true)
      await dispatch(saveSeriesFilings({ ...filing, seriesId: +id }))
      setBtnLoading(false)
    }
  }

  const {
    firstSaleDate,
    investorStates,
    minOrderAmount,
    proceeds,
    commission,
    blueSkyFees,
    totalSold,
    totalOffering,
    usAccreditedInvestors,
    usRetailInvestors,
  } = series || {}

  const investorStatesArr = investorStates?.replaceAll(' | ', ', ')

  return (
    <>
      <div className="row g-3 mb-4">
        <div className="col md-3">
          <CFormLabel>Date of First Sale</CFormLabel>
          <CFormInput
            disabled
            value={moment(firstSaleDate).format('MM/DD/YY')}
          />
        </div>
        <div className="col md-3">
          <CFormLabel>Minimum Order Amount</CFormLabel>
          <CFormInput value={formatDecimal(minOrderAmount)} disabled />
        </div>
        <div className="col md-3">
          <CFormLabel>Total Offering Amount</CFormLabel>
          <CFormInput value={formatDecimal(totalOffering)} disabled />
        </div>
        <div className="col md-3">
          <CFormLabel>Total Amount Sold</CFormLabel>
          <CFormInput value={formatDecimal(totalSold)} disabled />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col md-3">
          <CFormLabel>Total Number of Investors</CFormLabel>
          <CFormInput {...register('totalInvestors')} disabled />
        </div>
        <div className="col md-3">
          <CFormLabel>Number of US Investors</CFormLabel>
          <CFormInput {...register('usInvestors')} disabled />
        </div>
        <div className="col md-3">
          <CFormLabel>Number of Foreign Investors</CFormLabel>
          <CFormInput {...register('foreignInvestors')} disabled />
        </div>
        <div className="col md-3">
          <CFormLabel>Finders Fee</CFormLabel>
          <CFormInput value={formatDecimal(commission)} disabled />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col md-3">
          <CFormLabel>Use of Proceeds</CFormLabel>
          <CFormInput value={formatDecimal(proceeds)} disabled />
        </div>
        <div className="col md-3">
          <CFormLabel>States with Investors</CFormLabel>
          <CFormTextarea value={investorStatesArr} disabled rows={1} />
        </div>
        <div className="col md-3">
          <CFormLabel>Sum of Blue Sky Fees</CFormLabel>
          <CFormInput value={formatDecimal(blueSkyFees)} disabled />
        </div>
        <div className="col md-3">
          <CFormLabel>Status of Company</CFormLabel>
          <CFormInput {...register('companyStatus')} disabled />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel>EFDID Number</CFormLabel>
          <CFormInput {...register('efdid')} type="number" disabled={loading} />
        </div>
        <div className="col-md-3">
          <CFormLabel>EIN</CFormLabel>
          <CFormInput {...register('ein')} type="text" disabled={loading} />
        </div>
        <div className="col-md-3">
          <CFormLabel>US Accredited Investors</CFormLabel>
          <CFormInput value={usAccreditedInvestors} disabled />
        </div>
        <div className="col-md-3">
          <CFormLabel>US Retail Investors</CFormLabel>
          <CFormInput value={usRetailInvestors} disabled />
        </div>
      </div>
      <div className="row mb-4 mt-5">
        <div>
          <>
            <CButton
              onClick={() => setToggle(!toggle)}
              color="link"
              className="pl-0 ml-0"
            >
              Filings
              <CIcon
                className="ml-4"
                icon={toggle ? cilChevronBottom : cilChevronRight}
                style={{ marginLeft: 8 }}
              />
            </CButton>
            <CButton className="ms-4" onClick={handleCreateFiling}>
              Create Filing
            </CButton>
          </>
        </div>
        <CCollapse visible={toggle}>
          {fields && fields.length
            ? fields.map((item, index) => (
                <div
                  className="d-flex align-items-end row mb-2 mt-4"
                  key={item.id}
                >
                  <div className="col md-3">
                    <CFormLabel>Filing Identifier</CFormLabel>
                    <CFormInput
                      {...register(`filings.${index}.filingIdentifier`)}
                      disabled={btnLoading}
                    />
                    {errors && errors[`filings.${index}.filingIdentifier`] && (
                      <span className="text-danger">
                        Please enter a valid Filing Identifier.
                      </span>
                    )}
                  </div>
                  <div className="col-md-3">
                    <CFormLabel>Filing Identifier Type</CFormLabel>
                    <CFormSelect {...register(`filings.${index}.filingIdentifierType`)}>
                      <option value='UNKNOWN'>Unknown</option>
                      <option value='ACCESSION'>Accession</option>
                      <option value='FORM_D'>Form D</option>
                      <option value='FORM_5123'>Form D 5123</option>
                    </CFormSelect>
                  </div>
                  <div className="col md-3">
                    <CFormLabel>Filing Date</CFormLabel>
                    <Controller
                      name={`filings.${index}.filingDate`}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          selected={value}
                          className="form-control"
                          onChange={onChange}
                          disabled={btnLoading}
                        />
                      )}
                    />
                  </div>
                  <div className="col md-3">
                    <CFormLabel>Note</CFormLabel>
                    <CFormInput
                      {...register(`filings.${index}.note`)}
                      disabled={btnLoading}
                    />
                  </div>
                  <CLoadingButton
                    loading={btnLoading}
                    className="col-md-1"
                    onClick={handleSubmit((data) => handleSave(data, index))}
                  >
                    Save
                  </CLoadingButton>
                </div>
              ))
            : null}
        </CCollapse>
      </div>
    </>
  )
}

export default SeriesDetailForm
