import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'

interface TeamMemberState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  teamMember: any
  error: boolean
  errorMessage: string
}

const initialState: TeamMemberState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  teamMember: {},
  error: false,
  errorMessage: '',
}

export const getTeamMember = createAsyncThunk(
  'getTeamMember',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.get(`/queries/team_members/${id}`)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const saveTeamMember = createAsyncThunk(
  'saveTeamMember',
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.post(
        `/queries/team_members/${id}`,
        data,
      )
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const createTeamMember = createAsyncThunk(
  'createTeamMember',
  async ({ data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post('/queries/team_members/-1', data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const deleteTeamMember = createAsyncThunk(
  'deleteTeamMember',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      await privateAPI.delete(`/queries/team_members/${id}`)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const teamMembersSlice = createSlice({
  name: 'team_members',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = false
      state.errorMessage = ''
    },
    resetTeamMember: (state) => {
      state.teamMember = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTeamMember.fulfilled, (state, { payload }: any) => {
      state.teamMember = payload.rows[0]
    })
    builder.addCase(createTeamMember.rejected, (state) => {
      state.error = true
      state.errorMessage = 'There was an error creating this user.'
    })
    builder.addCase(saveTeamMember.rejected, (state) => {
      state.error = true
      state.errorMessage = 'There was an error saving this user.'
    })
    builder.addCase(deleteTeamMember.rejected, (state) => {
      state.error = true
      state.errorMessage = 'There was an error deleting this user.'
    })
  },
})

export const { resetError, resetTeamMember } = teamMembersSlice.actions

export default teamMembersSlice.reducer
