import React from 'react'
import IndividualFundingRound from './IndividualFundingRound'

const FundingRound: any = ({ fields, remove }: any) => {
  return (
    <div className="row g-3 mb-4">
      {fields.map((field: any, index: number) => (
        <IndividualFundingRound
          key={field.id}
          field={field}
          index={index}
          remove={remove}
        />
      ))}
    </div>
  )
}

export default FundingRound
