import React from 'react'
import { useHistory, useParams } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import GenericTable from '../../components/GenericTable'
import { useQuery } from '@tanstack/react-query'
import privateAPI from '../../APIs/private'
import { formatTableRenderers } from '../../utils/formatUtils/utils'

const getTrade: any = async (id: number) => {
  const response = await privateAPI.get(`/queries/trades/${id}`)
  return response?.data?.rows?.[0]
}

const getTradeBuyOrders: any = async (id: number) => {
  const data = {
    orderId: {},
    companyName: {},
    shares: {},
    sharePrice: {},
    price: {},
    status: {},
    tradeId: { include: false, filterValues: [+id] }
  }
  const response = await privateAPI.post(
    '/queries/orders',
    data
  )
  const { rows, columns } = formatTableRenderers(
    response?.data?.rows,
    response?.data?.columns,
  )
  return { buyOrders: rows, buyOrderColumns: columns}
}

const getTradeSellOrders: any = async (id: number) => {
  const data = {
    sellOrderId:{},
    companyName: {},
    shares:{},
    sharePrice:{},
    price: {},
    status:{},
    tradeId:{ include: false, filterValues: [+id] }
  }
  const response = await privateAPI.post(
    '/queries/sell_orders',
    data
  )
  const { rows, columns } = formatTableRenderers(
    response?.data?.rows,
    response?.data?.columns,
  )
  return { sellOrders: rows, sellOrderColumns: columns}
}

const TradeDetail: React.FC = () => {
  const history = useHistory()
  const { id }: any = useParams()

  const { data: tradeData, isInitialLoading } = useQuery({
    queryKey: ['trades', id],
    queryFn: () => getTrade(id),
    enabled: +id > 0,
  })

  const { data: buyOrderData } = useQuery({
    queryKey: ['buy_orders', id],
    queryFn: () => getTradeBuyOrders(id),
    enabled: +id > 0,
  })

  const { data: sellOrderData } = useQuery({
    queryKey: ['sell_orders', id],
    queryFn: () => getTradeSellOrders(id),
    enabled: +id > 0,
  })

  const { tradeId, userId } = tradeData || {}
  const { buyOrders, buyOrderColumns } = buyOrderData || {}
  const { sellOrders, sellOrderColumns } = sellOrderData || {}

  if (isInitialLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
      <PageLayout>
          <div className="d-flex justify-content-between">
            <h3>
              Trade No.{tradeId} - <a href={`/users/${userId}`}>User ID: {userId}</a>
            </h3>
          </div>
          <h4>Trade Information</h4>
          <div>
            <div className="row">
              <div className="col">
                <h4>Buy Orders</h4>
                <GenericTable
                  items={buyOrders || []}
                  columns={buyOrderColumns}
                  handleClick={(item: any) =>
                    history.push(`/orders/${item.orderId}`)
                  }
                />
              </div>
              <div>
                <h4>Sell Orders</h4>
                <GenericTable
                  items={sellOrders || []}
                  columns={sellOrderColumns}
                  handleClick={(item: any) =>
                    history.push(`/sell_orders/${item.sellOrderId}`)
                  }
                />
              </div>
            </div>
          </div>
      </PageLayout>
  )
}

export default TradeDetail
