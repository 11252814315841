import React from 'react'
import { useDispatch } from 'react-redux'
import { CCloseButton, CSidebar, CSidebarHeader } from '@coreui/react-pro'
import { toggle } from '../features/sidebarSlice'

const AppAside = () => {
  const dispatch = useDispatch()

  return (
    <CSidebar
      colorScheme="light"
      size="lg"
      overlaid
      placement="end"
      visible={true}
      onVisibleChange={(visible) => {
        dispatch(toggle())
      }}
    >
      <CSidebarHeader className="bg-transparent">
        <CCloseButton
          className="float-end"
          onClick={() => dispatch(toggle())}
        />
      </CSidebarHeader>
    </CSidebar>
  )
}

export default React.memo(AppAside)
