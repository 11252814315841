import React, { useState, useEffect, useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
// components
import PageLayout from '../../layout/PageLayout'
import WarningModal from '../../components/WarningModal'
import Spinner from '../../components/Spinner'
import privateAPI from '../../APIs/private'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import AssetDetailForm from './AssetDetailForm'
import { AssetFormValues } from './asset-types'

const getAsset = async (id: string) => {
  const response = await privateAPI.get(`/queries/assets/${id}`)
  return response.data
}

const createAsset = async (body: AssetFormValues) =>
  await privateAPI.post('/queries/assets/-1', body)

const saveAsset = async (id: string, body: AssetFormValues) =>
  await privateAPI.post(`/queries/assets/${id}`, body)

const deleteAsset = async (id: string) =>
  await privateAPI.delete(`/queries/assets/${id}`)

const getAddSharesToPool = async (id: string, companyId: number) => {
  await privateAPI.get(`/company/${companyId}/addSharesToPool?assetId=${id}&keepPpsTheSame=true`)
}

const AssetDetail: React.FC = () => {
  const queryClient = useQueryClient()
  const methods = useForm<AssetFormValues>()
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const { reset } = methods

  const { data, isInitialLoading } = useQuery({
    queryKey: ['assets', id],
    queryFn: () => getAsset(id),
    enabled: +id > 0,
  })
  const asset = useMemo(() => data?.rows?.[0] || {}, [data?.rows])

  const saveAssetMutation = useMutation({
    mutationFn: async (data: AssetFormValues) => await saveAsset(id, data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['assets', id] }),
  })

  const createAssetMutation = useMutation({
    mutationFn: async (data: AssetFormValues) => await createAsset(data),
  })

  const deleteAssetMutation = useMutation({
    mutationFn: async () => {
      await deleteAsset(id)
      history.goBack()
    },
  })

  const addSharesMutation = useMutation({
    mutationFn: async () => await getAddSharesToPool(id, asset.companyId),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['assets', id] }),
  })


  useEffect(() => reset(asset), [asset, reset])

  const { isLoading: saveLoading } = saveAssetMutation
  const { isLoading: deleteLoading } = deleteAssetMutation
  const { isLoading: createLoading } = createAssetMutation

  if (isInitialLoading || deleteLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  const { companyName } = asset || {}

  return (
    <PageLayout>
      {deleteModal && (
        <WarningModal
          header={`Delete asset: "${companyName}"?`}
          body="Performing this action will permanently erase this asset."
          proceed="Yes"
          abandon="No"
          proceedAction={deleteAssetMutation.mutate}
          abandonAction={() => setDeleteModal(false)}
          open={deleteModal}
        />
      )}
      <FormProvider {...methods}>
        <AssetDetailForm
          header={+id > 0 ? `Asset No. ${id} - ${companyName}` : 'Create Asset'}
          onSubmit={
            +id > 0 ? saveAssetMutation.mutate : createAssetMutation.mutate
          }
          loading={+id > 0 ? saveLoading : createLoading}
          asset={asset}
          onAddSharesToPool={addSharesMutation.mutate}
        />
      </FormProvider>
    </PageLayout>
  )
}

export default AssetDetail
