import { CFormLabel } from '@coreui/react-pro'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import ListFields from './ListFields'

const CustomersMarket: React.FC = () => {
  const {
    fields: keyFacts,
    remove: removeKeyFacts,
    append: appendKeyFacts,
  } = useFieldArray({
    name: 'companyDetail.keyFacts',
  })

  const {
    fields: keyCustomers,
    remove: removeKeyCustomers,
    append: appendKeyCustomers,
  } = useFieldArray({
    name: 'companyDetail.customerFacts',
  })

  const {
    fields: marketFacts,
    remove: removeMarketFacts,
    append: appendMarketFacts,
  } = useFieldArray({
    name: 'companyDetail.marketFacts',
  })

  const {
    fields: marketPositionFacts,
    remove: removeMarketPositionFacts,
    append: appendMarketPositionFacts,
  } = useFieldArray({
    name: 'companyDetail.marketPositionFacts',
  })

  return (
    <div className="row g-3 mb-4">
      <h3>Customers & Market</h3>
      <div className="col-sm mt-0">
        <CFormLabel>Customers</CFormLabel>
        <ListFields
          items={keyCustomers}
          add={() => appendKeyCustomers({ fact: '' })}
          remove={removeKeyCustomers}
          type="customerFacts"
        />
      </div>
      <div className="col-sm mt-0">
        <CFormLabel>Size Of Market</CFormLabel>
        <ListFields
          items={marketFacts}
          add={() => appendMarketFacts({ fact: '' })}
          remove={removeMarketFacts}
          type="marketFacts"
        />
      </div>
      <div className="col-sm mt-0">
        <CFormLabel>Market Position</CFormLabel>
        <ListFields
          items={marketPositionFacts}
          add={() => appendMarketPositionFacts({ fact: '' })}
          remove={removeMarketPositionFacts}
          type="marketPositionFacts"
        />
      </div>
      <div className="col-sm mt-0">
        <CFormLabel>Key Facts</CFormLabel>
        <ListFields
          items={keyFacts}
          add={() => appendKeyFacts({ fact: '' })}
          remove={removeKeyFacts}
          type="keyFacts"
        />
      </div>
    </div>
  )
}

export default CustomersMarket
