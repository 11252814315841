import React, { useCallback } from 'react'
import GenericTable from '../../../components/GenericTable'
import { useAppDispatch } from '../../../features/hooks'
import useFetchUserData from '../../../hooks/useFetchUserData'
import { getUserOrders } from '../../../features/usersSlice'
import Loading from './Loading'

interface UserBuyOrdersProps {
  id: string
  items: any[]
  columns: any[]
  handleClick: (item: any) => void
}

const UserBuyOrders: React.FC<UserBuyOrdersProps> = ({
  id,
  items,
  columns,
  handleClick,
}: UserBuyOrdersProps) => {
  const dispatch = useAppDispatch()
  const loading = useFetchUserData(
    useCallback(() => dispatch(getUserOrders({ id })), [dispatch, id]),
  )

  if (loading) return <Loading />
  return (
    <GenericTable items={items} columns={columns} handleClick={handleClick} />
  )
}

export default UserBuyOrders
