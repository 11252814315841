import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import { getPublicReports } from '../../features/userSlice'
import PageLayout from '../../layout/PageLayout'
import { RootState } from '../../store'
import PublicReports from './partials/PublicReports'

const OPTIONS = [
  { label: 'Assets', value: 'assets' },
  { label: 'Broker Infos', value: 'broker_infos' },
  {
    label: 'Linqto Buck Transactions',
    value: 'linqto_buck_transactions',
  },
  { label: 'Companies', value: 'companies' },
  { label: 'Company Interest', value: 'company_interest' },
  { label: 'Entities', value: 'entities' },
  { label: 'Offers', value: 'offers' },
  { label: 'Orders', value: 'orders' },
  { label: 'Originations', value: 'originations' },
  {
    label: 'Sell to Linqto Requests',
    value: 'origination_sell_to_linqto_requests',
  },
  { label: 'Serieses', value: 'serieses' },
  { label: 'Tags', value: 'tags' },
  { label: 'Team Members', value: 'team_members' },
  { label: 'Transactions', value: 'transactions' },
  { label: 'Users', value: 'users' },
]

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch()
  const { publicReports, favoriteReports } = useAppSelector(
    (state: RootState) => state.user,
  )

  useEffect(() => {
    const fetchPublicReports = async () => {
      await dispatch(getPublicReports())
    }
    fetchPublicReports()
  }, [dispatch])

  return (
    <PageLayout>
      <div className="container m-0 h-100">
        <div className="row w-100">
          <div className="col">
            <PublicReports
              title="Public Reports"
              reports={publicReports}
              options={OPTIONS}
            />
          </div>
          <div className="col">
            <PublicReports title="Favorite Reports" reports={favoriteReports} />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Dashboard
