import React, { useCallback } from 'react'
import GenericTable from '../../../components/GenericTable'
import { useAppDispatch } from '../../../features/hooks'
import useFetchUserData from '../../../hooks/useFetchUserData'
import { getUserHoldings } from '../../../features/usersSlice'
import Loading from './Loading'

interface UserHoldingsProps {
  id: string
  holdings: any[]
  holdingsColumns: any[]
}

const UserHoldings: React.FC<UserHoldingsProps> = ({
  holdings,
  holdingsColumns,
  id,
}: UserHoldingsProps) => {
  const dispatch = useAppDispatch()
  const loading = useFetchUserData(
    useCallback(() => dispatch(getUserHoldings({ id })), [dispatch, id]),
  )

  if (loading) return <Loading />
  return <GenericTable items={holdings} columns={holdingsColumns} />
}

export default UserHoldings
