import React from 'react'
import { CAlert } from '@coreui/react-pro'

interface AlertProps {
  visible: boolean
  onClose: () => any
  text: string
  color: string
}

const Alert: React.FC<AlertProps> = ({
  visible,
  onClose,
  text,
  color,
}: AlertProps) => {
  return (
    <CAlert color={color} dismissible visible={visible} onClose={onClose}>
      {text}
    </CAlert>
  )
}

export default Alert
