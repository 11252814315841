import { CButton } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { resetThrottle } from '../../../../features/usersSlice'
import { useAppDispatch, useAppSelector } from '../../../../features/hooks'
import { RootState } from '../../../../store'

interface UserMFAProps {
  type: string
  LABEL: any
  isUsers?: boolean
  handleEnableUserMFaEmail?: () => void
}

const UserMFA: React.FC<UserMFAProps> = ({
  type,
  LABEL,
  isUsers = false,
  handleEnableUserMFaEmail,
}: UserMFAProps) => {
  const {
    user: { userId, userThrottles },
  } = useAppSelector((state: RootState) => state.users)
  const dispatch = useAppDispatch()
  const [needsThrottleReset, setNeedsThrottleReset] = useState(false)
  const emailMFAThrottle = userThrottles.find(
    (obj: any) => obj.name === 'SIGNIN_MFA_SEND_EMAIL',
  )

  useEffect(() => {
    if (emailMFAThrottle?.blocks > 0) {
      setNeedsThrottleReset(true)
    }
  }, [emailMFAThrottle?.blocks])

  const resetEmailThrottle = async () => {
    await dispatch(
      resetThrottle({ id: userId, throttle: emailMFAThrottle?.name }),
    )
    setNeedsThrottleReset(!needsThrottleReset)
  }

  if (isUsers) {
    return (
      <div>
        {LABEL[type]} - <span className="fw-bold">Enabled</span>
        {LABEL[type] === 'Email' && needsThrottleReset ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '5px 0',
            }}
          >
            <CButton onClick={resetEmailThrottle}>
              Reset Email MFA Throttle
            </CButton>
            <p>
              <span className="fw-bold">Email Throttle Limit: </span>
              {emailMFAThrottle.limit}
            </p>
            <p>
              <span className="fw-bold">Email MFA Uses: </span>
              {emailMFAThrottle.uses}
            </p>
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div>
      {type === 'EMAIL' ? (
        <label
          className="d-flex align-items-center"
          style={{ width: 'fit-content' }}
        >
          {LABEL[type]}
          <div className="ms-2">
            <CButton onClick={handleEnableUserMFaEmail}>Set up</CButton>
          </div>
        </label>
      ) : (
        <label>
          {LABEL[type]} - <span className="fw-bold">Disabled</span>
        </label>
      )}
    </div>
  )
}

export default UserMFA
