import { CButton, CFormInput, CFormLabel } from '@coreui/react-pro'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import Select from 'react-select'
import { Controller, useFormContext } from 'react-hook-form'
import useDebounce from '../../../hooks/useDebounce'
import { getUsers } from '../../../features/usersSlice'
import NewTab from '../../../assets/new-tab.svg'
import { formatDecimal } from '../../../utils/formatUtils/utils'
import GenericTable from '../../../components/GenericTable'
import { getUserLinqtoBucks } from '../../../features/rewardsSlice'
// import Spinner from '../../../components/Spinner'
import useFetchUserData from '../../../hooks/useFetchUserData'

const filterOptions = (option: any, input: any) => {
  if (option) {
    return (
      option.label.toLowerCase().includes(input?.toLowerCase()) ||
      option.label === 'Needs Manual Match' ||
      option.label === "Couldn't Find Manual Match"
    )
  }
}

const LinqtoBucks: any = ({ setOpenTransaction }: any) => {
  const dispatch = useAppDispatch()
  const [disabled, setDisabled] = useState(false)
  const { user, rows } = useAppSelector((state: RootState) => state.users)
  const { linqtoBucksRows, linqtoBucksColumns } = useAppSelector(
    (state: RootState) => state.rewards,
  )
  const { control, reset, setValue, watch } = useFormContext()
  const loading = useFetchUserData(
    useCallback(
      () => dispatch(getUserLinqtoBucks({ userId: user.userId })),
      [dispatch, user.userId],
    ),
  )

  useEffect(() => {
    reset(user)
    if (
      (user?.referredByStatus === 'MANUAL_MATCH_SUCCEEDED' ||
        user?.referredByStatus === 'AUTO_MATCH_SUCCEEDED') &&
      user?.referredByUserId
    ) {
      setDisabled(true)
    }
  }, [reset, user])

  const getUserOptions = useDebounce((e: any) => {
    const data = {
      queryParams: JSON.stringify({
        includeTotals: false,
        accreditedStatus: { freeTextSearchType: 'NONE' },
        city: { freeTextSearchType: 'NONE' },
        completedTotal: { freeTextSearchType: 'NONE' },
        country: { freeTextSearchType: 'NONE' },
        curPage: 0,
        email: { freeTextSearchType: 'STARTS_WITH' },
        firstName: { freeTextSearchType: 'NONE' },
        freeTextSearch: e,
        fullName: { freeTextSearchType: 'STARTS_WITH' },
        hasGraph: false,
        lastAccessedAt: { freeTextSearchType: 'NONE' },
        lastName: { freeTextSearchType: 'STARTS_WITH' },
        length: 10,
        orderAsc: false,
        orderColumnName: '',
        reportId: 0,
        reportName: '',
        scrollTop: 0,
        start: 0,
        state:  { freeTextSearchType: 'NONE' },
        street1:  { freeTextSearchType: 'NONE' },
        street2:  { freeTextSearchType: 'NONE' },
        userCreatedAt:  { freeTextSearchType: 'NONE' },
        userId: { freeTextSearchType: 'EXACT' },
        zip: { freeTextSearchType: 'NONE' },
      }),
    }
    if (e.length > 0) {
      dispatch(getUsers({ found: data }))
    }
  }, 250)

  const handleChange = (val: any, onChange: any) => {
    setValue('referredByUserId', val.userId)
    setValue(
      'referredByStatus',
      !val?.value?.includes('FAILED') ? 'MANUAL_MATCH_SUCCEEDED' : val.value,
    )
    if (val?.userId) {
      setValue('referredByUserId', val.userId)
    }
    onChange(
      val.value === 'AUTO_MATCH_FAILED' || val.value === 'MANUAL_MATCH_FAILED'
        ? ''
        : val.value,
    )
  }

  let dropdownUsers = [...rows]
  if (dropdownUsers && dropdownUsers.length) {
    dropdownUsers = [
      ...dropdownUsers.map((user: any) => {
        return Object.assign({}, user, {
          label: `${user.fullName} (id: ${user.userId})`,
          value: `${user.fullName}`,
        })
      }),
    ]
  }
  dropdownUsers.unshift(
    { label: 'Needs Manual Match', value: 'AUTO_MATCH_FAILED' },
    { label: "Couldn't Find Manual Match", value: 'MANUAL_MATCH_FAILED' },
  )

  const { linqtoBucks, referredByUserName, referredByName } = user || {}

  if (loading) return <h1>Loading...</h1>

  return (
    <div className="mt-4">
      <div className="row g-3">
        <div className="col-md-3">
          <CFormLabel>User Referrer Input</CFormLabel>
          <CFormInput disabled value={referredByName} />
        </div>

        <div className="col-md-3">
          <CFormLabel>Linqto Bucks</CFormLabel>
          <CFormInput disabled value={formatDecimal(linqtoBucks)} />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <div className="col-md-11">
            <CFormLabel>Referrer</CFormLabel>
            <Controller
              control={control}
              name="referredByUserName"
              defaultValue={referredByUserName}
              render={({ field: { onChange, value } }) => (
                <Select
                  isDisabled={disabled}
                  filterOption={filterOptions}
                  classNamePrefix="addl-class"
                  options={dropdownUsers}
                  value={
                    value
                      ? {
                          label: `${value}: ${watch('referredByUserId')}`,
                          value,
                        }
                      : {
                          label:
                            watch('referredByStatus') === 'AUTO_MATCH_FAILED'
                              ? 'Needs Manual Match'
                              : watch('referredByStatus') ===
                                'MANUAL_MATCH_FAILED'
                              ? "Couldn't Find Manual Match"
                              : '',
                          value: watch('referredByStatus'),
                        }
                  }
                  onChange={(val: any) => handleChange(val, onChange)}
                  onInputChange={(e: any) => getUserOptions(e)}
                />
              )}
            />
          </div>
          {watch('referredByUserName') && (
            <div style={{ margin: '1.5rem 0 0 1.25rem' }}>
              <img
                className="hover"
                alt="New-Tab"
                src={NewTab}
                height={18}
                width={18}
                onClick={() =>
                  window.open(
                    `/users/${watch('referredByUserId')}`,
                    '_blank',
                    'noopener,noreferrer',
                  )
                }
              />
            </div>
          )}
        </div>
        <div className="col-md-3 d-flex flex-column">
          <CFormLabel>Add Transaction</CFormLabel>
          <CButton onClick={() => setOpenTransaction(true)}>Add</CButton>
        </div>
      </div>
      <div className="row g-3">
        <div>
          <GenericTable items={linqtoBucksRows} columns={linqtoBucksColumns} />
        </div>
      </div>
    </div>
  )
}

export default LinqtoBucks
