import React, { useState, FormEvent } from 'react'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CFormLabel,
  CFormInput,
  CFormTextarea
} from '@coreui/react-pro'
import { useAppDispatch } from '../features/hooks'
import { transferFunds } from '../features/cashAccountSlice'

interface TransferFundswModalProps {
  handleClose: (arg: boolean) => void
  cashAccountId: number
}

const TransferFundaModal: React.FC<TransferFundswModalProps> = ({ handleClose, cashAccountId }: TransferFundswModalProps) => {
  const dispatch = useAppDispatch()
  const [note, setNote] = useState<string>('')
  const [amount, setAmount] = useState<number | string>('')
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)


  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(false)
    const obj = { amount: Number(amount).toFixed(2), note }
    setLoading(true)
    const { payload } = await dispatch(
      transferFunds({
        id: cashAccountId,
        data: obj,
      }),
    )
    setLoading(false)
    if (payload === 200) {
      window.location.reload()
    } else {
      setError(true)
    }
  }

  return (
    <CModal
      visible
      alignment="center"
      size="sm"
      onClose={() => handleClose(false)}
    >
      <CModalHeader>
        <CModalTitle>Transfer Funds</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="d-flex align-items-center justify-content-center hover"></div>
        <form onSubmit={onSubmit}>
          <>
            <div className="mb-4">
              <CFormLabel>Amount</CFormLabel>
              <CFormInput
                disabled={loading}
                type="number"
                step=".01"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder='0.00'
              />
            </div>
            <div className="mb-4">
              <CFormLabel>Note</CFormLabel>
              <CFormTextarea
                disabled={loading}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </>
          {error && (
            <p className="text-danger mb-4">
              There was an error submitting your request. Please try again
              later.
            </p>
          )}
          <CModalFooter className="px-0 m-0 flex-nowrap">
            <CButton className="mr-4 w-50" color="danger" onClick={() => handleClose(false)}>
              Close
            </CButton>
            <CButton
              className="mr-4 w-50"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Transfer
            </CButton>
          </CModalFooter>
        </form>
      </CModalBody>
    </CModal>
  )
}

export default TransferFundaModal
