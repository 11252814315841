import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'

interface EntityState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  entity: any
}

const initialState: EntityState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  entity: {},
}

export const getEntity = createAsyncThunk('getEntity', async ({ id }: any) => {
  try {
    const response = await privateAPI.get(`/queries/entities/${id}`)
    return response.data
  } catch (e) {
    console.log('error getting entity', e)
  }
})

export const createEntity = createAsyncThunk(
  'createEntity',
  async ({ data }: any) => {
    try {
      await privateAPI.post('/queries/entities/-1', data)
    } catch (e) {
      console.log('error creating entity', e)
    }
  },
)

export const regenerateTransactionDocuments = createAsyncThunk(
  'regenerateTransactionDocuments',
  async ({ id }: any) => {
    try {
      await privateAPI.post(
        `/queries/ENTITIES/${id}/regenerateTransactionDocuments`,
      )
    } catch (e) {
      console.log('error regen documents: ', e)
    }
  },
)

export const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEntity.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.entity = payload.rows[0]
        }
      })
      .addCase(getEntity.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(
        regenerateTransactionDocuments.fulfilled,
        (state, { payload }: any) => {
          if (payload) {
            state.columns = payload.columns
            state.entity = payload.rows[0]
          }
        },
      )
      .addCase(regenerateTransactionDocuments.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
  },
})

export default entitySlice.reducer
