import {
  CButton,
  CCollapse,
  CFormInput,
  CFormLabel,
  CLoadingButton,
} from '@coreui/react-pro'
import moment from 'moment'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import useQuery from '../../../hooks/useQuery'
import { formatDecimal } from '../../../utils/formatUtils/utils'
import { cilChevronBottom, cilChevronRight } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useHistory, useParams } from 'react-router-dom'
import api from '../../../APIs/private'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const saveBlueSkyFeeReports = async (data: any) =>
  await api.post(`/queries/blue_sky_fees/${data.blueSkyFeeId || '-1'}`, data)

type BlueSkyFeeDetailFormProps = {
  seriesBlueSkyFee: any
}

const BlueSkyFeeDetailForm: React.FC<BlueSkyFeeDetailFormProps> = ({
  seriesBlueSkyFee,
}: BlueSkyFeeDetailFormProps) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { id }: any = useParams()
  const [toggle, setToggle] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext()
  const query = useQuery()
  const state = query.get('state')
  const seriesId = query.get('seriesId')
  const totalSold = query.get('totalSold')
  const efdid = query.get('efdid')
  const firstSaleDateParam = query.get('firstSaleDate')
  const totalInvestors = query.get('totalInvestors')

  const { fields, append } = useFieldArray({
    control,
    name: 'reports',
  })

  const handleBlueSkyFeeReportsSave = useMutation({
    mutationFn: async ({ data, index }: any) => {
      const filing = data?.reports?.[index] || {}
      const { seriesId: filingSeriesId, state: filingState } = data || {}
      await saveBlueSkyFeeReports({
        ...filing,
        seriesId: filingSeriesId || seriesId,
        state: filingState || state,
      })
    },
    onSuccess: () => {
      if (state && seriesId) {
        history.goBack()
      }
      queryClient.invalidateQueries({ queryKey: ['series_blue_sky_fees', id] })
      queryClient.invalidateQueries({
        queryKey: ['series_blue_sky_fees_reports', id],
      })
    },
  })

  const handleCreateBlueSky = () => {
    setToggle(true)
    append({
      standardFeePaid: '',
      lateFeePaid: '',
      invoiceNumber: '',
      filingDate: null,
    })
  }

  const { firstSaleDate, totalAmountSold } = seriesBlueSkyFee || {}

  const loading = handleBlueSkyFeeReportsSave.isLoading

  return (
    <>
      <div className="row g-3 mb-4">
        <div className="col md-3">
          <CFormLabel>EFDID</CFormLabel>
          {efdid ? (
            <CFormInput value={efdid} disabled />
          ) : (
            <CFormInput {...register('efdid')} disabled />
          )}
        </div>
        <div className="col md-3">
          <CFormLabel>State</CFormLabel>
          {state ? (
            <CFormInput value={state} disabled />
          ) : (
            <CFormInput {...register('state')} disabled />
          )}
        </div>
        <div className="col md-3">
          <CFormLabel>Date of First Sale</CFormLabel>
          {firstSaleDateParam ? (
            <CFormInput
              disabled
              value={moment(firstSaleDateParam).format('MM/DD/YY')}
            />
          ) : (
            <CFormInput
              disabled
              value={moment(firstSaleDate).format('MM/DD/YY')}
            />
          )}
        </div>
        <div className="col md-3">
          <CFormLabel>Total Number of Investors</CFormLabel>
          {totalInvestors ? (
            <CFormInput value={totalInvestors} disabled />
          ) : (
            <CFormInput {...register('totalInvestors')} disabled />
          )}
        </div>
        <div className="col md-3">
          <CFormLabel>Total Amount Sold</CFormLabel>
          {totalSold ? (
            <CFormInput value={formatDecimal(+totalSold)} disabled />
          ) : (
            <CFormInput value={formatDecimal(+totalAmountSold)} disabled />
          )}
        </div>
      </div>
      <CButton
        onClick={() => setToggle(!toggle)}
        color="link"
        className="mt-4 pl-0 ml-0"
      >
        Reports
        <CIcon
          className="ml-4"
          icon={toggle ? cilChevronBottom : cilChevronRight}
          style={{ marginLeft: 8 }}
        />
      </CButton>
      <CButton className="ms-4" onClick={handleCreateBlueSky}>
        Create Report
      </CButton>
      <CCollapse visible={toggle}>
        {fields && fields.length
          ? fields.map((item, index) => (
              <div
                className="d-flex align-items-start row mb-2 mt-4"
                key={item.id}
              >
                <div className="col">
                  <CFormLabel>Filing Date</CFormLabel>
                  <Controller
                    rules={{ required: 'Required.' }}
                    name={`reports.${index}.filingDate`}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        selected={value}
                        className="form-control"
                        onChange={onChange}
                        disabled={loading}
                      />
                    )}
                  />
                  {errors.reports?.[index]?.filingDate && (
                    <span className="text-danger">
                      {errors.reports?.[index]?.filingDate?.message}
                    </span>
                  )}
                </div>
                <div className="col">
                  <CFormLabel>Standard Fee Paid</CFormLabel>
                  <CFormInput
                    {...register(`reports.${index}.standardFeePaid`, {
                      required: 'Required.',
                    })}
                    disabled={loading}
                  />
                  {errors.reports?.[index]?.standardFeePaid && (
                    <span className="text-danger">
                      {errors.reports?.[index]?.standardFeePaid?.message}
                    </span>
                  )}
                </div>
                <div className="col">
                  <CFormLabel>Late Fee Paid</CFormLabel>
                  <CFormInput
                    {...register(`reports.${index}.lateFeePaid`)}
                    disabled={loading}
                  />
                </div>
                <div className="col">
                  <CFormLabel>Invoice Number</CFormLabel>
                  <CFormInput
                    {...register(`reports.${index}.invoiceNumber`, {
                      required: 'Required.',
                    })}
                    disabled={loading}
                  />
                  {errors.reports?.[index]?.invoiceNumber && (
                    <span className="text-danger">
                      {errors.reports?.[index]?.invoiceNumber?.message}
                    </span>
                  )}
                </div>
                <CLoadingButton
                  loading={loading}
                  style={{ marginTop: '30px' }}
                  className="col-md-1"
                  onClick={handleSubmit((data) =>
                    handleBlueSkyFeeReportsSave.mutate({ data, index }),
                  )}
                >
                  Save
                </CLoadingButton>
              </div>
            ))
          : null}
      </CCollapse>
    </>
  )
}

export default BlueSkyFeeDetailForm
