import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  // CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CButton,
} from '@coreui/react-pro'
import { cilMenu } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

// import { AppBreadcrumb } from './index'
import { logo } from '../assets/brand/logo'
import { toggle } from '../features/sidebarSlice'
import { signOut } from '../features/userSlice'

const AppHeader = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={() => dispatch(toggle())}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none">
          <CIcon icon={logo} height={48} />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CButton
          color="primary"
          variant="ghost"
          className="float-end"
          onClick={() => dispatch(signOut({ history }))}
        >
          Sign Out
        </CButton>
      </CContainer>
      {/* <CHeaderDivider /> */}
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
  )
}

export default AppHeader
