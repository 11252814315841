import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import privateAPI from '../../APIs/private'
import UpholdAccessDetailForm from './UpholdAccessDetailForm'
import { getUser, uploadDocs } from '../../utils/commonActions'

export const getUpholdAccess: any = async (id: number) => {
  const response = await privateAPI.get(`/queries/uphold_accesses/${id}`)
  const obj = response?.data?.rows?.[0]
  return { ...obj, notes: obj?.notes ? JSON.parse(obj.notes) : undefined }
}

const updateUpholdAccess: any = async (id: number, data: any) => {
  const response = await privateAPI.post(`/queries/uphold_accesses/${id}`, data)
  return response.data
}

const UpholdAccessDetail: React.FC = () => {
  const { id }: any = useParams()
  const queryClient = useQueryClient()
  const methods = useForm()
  const { reset } = methods

  const { data: upholdAccessData, isInitialLoading } = useQuery({
    queryKey: ['uphold_accesses', id],
    queryFn: () => getUpholdAccess(id),
    enabled: +id > 0,
  })

  const { userId } = upholdAccessData || {}

  const { data: user } = useQuery({
    queryKey: ['uphold_accesses_users', id],
    queryFn: () => getUser(userId),
    enabled: +userId > 0,
  })

  const handleUpdateUpholdAccess = useMutation({
    mutationFn: async (data: any) => {
      if (data.notes && data.notes.length) {
        data.notes.forEach((note: any) => delete note.updated)
      }
      data.notes = JSON.stringify(data.notes)
      await updateUpholdAccess(id, data)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['uphold_accesses', id],
      }),
  })

  useEffect(() => {
    reset(upholdAccessData)
  }, [reset, upholdAccessData])

  const uploadDocsMutation = useMutation({
    mutationFn: async ({
      e,
      type,
    }: {
      e: React.ChangeEvent<HTMLInputElement>
      type: string
    }) => {
      await uploadDocs({
        acceptedFile: e?.target?.files?.[0],
        fileName: e?.target?.files?.[0].name,
        type,
        upholdAccessId: id,
      })
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['uphold_accesses', id],
      }),
  })

  const btnLoading =
    uploadDocsMutation.isLoading || handleUpdateUpholdAccess.isLoading

  if (isInitialLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <UpholdAccessDetailForm
          onSubmit={handleUpdateUpholdAccess.mutate}
          btnLoading={btnLoading}
          fileSelectedHandler={(
            e: React.ChangeEvent<HTMLInputElement>,
            type: string,
          ) => uploadDocsMutation.mutate({ e, type })}
          upholdAccessData={upholdAccessData}
          handleUpdateUpholdAccess={handleUpdateUpholdAccess}
          user={user}
        />
      </FormProvider>
    </PageLayout>
  )
}

export default UpholdAccessDetail
