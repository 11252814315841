import React from 'react'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CFormTextarea,
} from '@coreui/react-pro'
import { useFormContext } from 'react-hook-form'
import FormatNumber from './FormatNumber'
import CIcon from '@coreui/icons-react'
import { cilMinus } from '@coreui/icons'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'

interface ListValuationOverTimeProps {
  fields: any[]
  remove: (index: number) => any
}

const ListValuationOverTime: React.FC<ListValuationOverTimeProps> = ({
  fields,
  remove,
}: ListValuationOverTimeProps) => {
  const { register } = useFormContext()
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )
  return (
    <>
      {fields.map((field: any, index: number) => (
        <div key={field.id} className="d-flex align-items-start gap-2">
          <div className="col-sm">
            <CFormLabel>Year</CFormLabel>
            <CFormInput
              type="number"
              min="1"
              max="9999"
              {...register(`companyDetail.valuationOverTime.${index}.year`)}
              disabled={btnLoading}
            />
          </div>
          <div className="col-sm">
            <div className="w-100 mb-2">
              <CFormLabel>Amount</CFormLabel>
              <CFormInput
                type="number"
                {...register(`companyDetail.valuationOverTime.${index}.amount`)}
                disabled={btnLoading}
              />
              <FormatNumber
                watchValue={`companyDetail.valuationOverTime.${index}.amount`}
                isMoney
              />
            </div>
          </div>
          <div className="col-sm">
            <CFormLabel>Valuation Source</CFormLabel>
            <CFormTextarea
              rows={1}
              style={{ marginRight: '10px', marginBottom: '10px' }}
              {...register(
                `companyDetail.valuationOverTime.${index}.valuationSource`,
              )}
              disabled={btnLoading}
            />
          </div>
          <CButton
            className="bg-danger ms-2 rounded-circle"
            size="sm"
            style={{ marginTop: '30px' }}
            onClick={() => remove(index)}
            disabled={btnLoading}
          >
            <CIcon icon={cilMinus} />
          </CButton>
        </div>
      ))}
    </>
  )
}

export default ListValuationOverTime
