import {
  CButton,
  CFormInput,
  CFormLabel,
  CFormTextarea,
} from '@coreui/react-pro'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import CIcon from '@coreui/icons-react'
import { cilMinus } from '@coreui/icons'

const News: React.FC = () => {
  const { register, control } = useFormContext()
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )
  const {
    fields: news,
    append: appendNews,
    remove: removeNews,
  } = useFieldArray({
    control,
    name: 'companyDetail.news',
  })

  return (
    <div className="g-3 mb-4">
      <div className="col-sm">
        <div className="d-flex mt-2">
          <CButton
            size="sm"
            onClick={() => appendNews({ text: '', title: '' })}
            disabled={btnLoading || news?.length === 3}
          >
            Add News
          </CButton>
        </div>
        <div className="mb-5">
          {news.map((field: any, index: number) => (
            <div key={field.id} className="row g-1 mb-5 mt-4">
              <div className="d-flex align-items-center">
                <h4 className="m-0">Insight #{index + 1}</h4>
                <CButton
                  className="bg-danger ms-2 rounded-circle"
                  onClick={() => removeNews(index)}
                >
                  <CIcon icon={cilMinus} />
                </CButton>
              </div>
              <div className="col-md-6">
                <CFormLabel>Title</CFormLabel>
                <CFormTextarea
                  disabled={btnLoading}
                  {...register(`companyDetail.news.${index}.title`)}
                />
              </div>
              <div className="col-md-6">
                <CFormLabel>Text</CFormLabel>
                <CFormTextarea
                  disabled={btnLoading}
                  {...register(`companyDetail.news.${index}.text`)}
                />
              </div>
              <div className="col-md-3">
                <CFormLabel>Source</CFormLabel>
                <CFormInput
                  disabled={btnLoading}
                  {...register(`companyDetail.news.${index}.source`)}
                />
              </div>
              <div className="col-md-3">
                <CFormLabel>Source Link</CFormLabel>
                <CFormInput
                  disabled={btnLoading}
                  {...register(`companyDetail.news.${index}.sourceLink`)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default News
