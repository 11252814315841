import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'

interface AssetState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  asset: any
  error: boolean
  errorMessage: string
}

const initialState: AssetState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  asset: {},
  error: false,
  errorMessage: '',
}

export const getAsset = createAsyncThunk(
  'getAsset',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.get(`/queries/assets/${id}`)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getAssets = createAsyncThunk(
  'getAssets',
  async ({ found }: any) => {
    const params = found.queryParams
    try {
      const response = await privateAPI.post(
        '/queries/assets',
        JSON.parse(params),
      )
      return response.data
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
)

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = false
      state.errorMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAsset.fulfilled, (state, { payload }: any) => {
        state.asset = payload.rows[0]
      })
      .addCase(getAssets.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.filtered = payload.filtered
          state.rows = payload.rows
        }
      })
  },
})

export const { resetError } = assetsSlice.actions

export default assetsSlice.reducer
