import React from 'react'
import { CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import { useFormContext } from 'react-hook-form'
import SubmitButton from '../../components/SubmitButton'

const BankAccountDetailForm: React.FC<any> = ({ id, onSubmit, loading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between">
        <h3>{id === '-1' ? 'Create Bank Account' : 'Bank Account No' + id}</h3>
        <SubmitButton loading={loading} text="Create" />
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel>Account Nickname</CFormLabel>
          <CFormInput
            {...register('accountNickname', {
              required: 'This field is required.',
            })}
          />
          {errors.accountNickname && (
            <span className="text-danger">
              {errors.accountNickname.message}
            </span>
          )}
        </div>
        <div className="col-md-3">
          <CFormLabel>Bank Country</CFormLabel>
          <CFormSelect
            {...register('bankCountry', {
              required: 'This field is required.',
            })}
          >
            <option value="aba">United States</option>
            <option value="ca_cpa">Canada</option>
            <option value="au_bsb">Australia</option>
            <option value="gb_sort_code">United Kingdom and Ireland</option>
            <option value="eu">European Union</option>
            <option value="swift">International</option>
          </CFormSelect>
          {errors.bankCountry && (
            <span className="text-danger">{errors.bankCountry.message}</span>
          )}
        </div>
        <div className="col-md-3">
          <CFormLabel>Account Type</CFormLabel>
          <CFormSelect
            {...register('accountType', {
              required: 'This field is required.',
            })}
          >
            <option value="checking">Checkings</option>
            <option value="savings">Savings</option>
          </CFormSelect>
          {errors.accountType && (
            <span className="text-danger">{errors.accountType.message}</span>
          )}
        </div>
        <div className="col-md-3">
          <CFormLabel>Account Number</CFormLabel>
          <CFormInput type="number" {...register('accountNumber')} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Routing Number</CFormLabel>
          <CFormInput type="number" {...register('routingNumber')} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Address</CFormLabel>
          <CFormInput disabled {...register('address')} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Tax ID</CFormLabel>
          <CFormInput disabled {...register('taxId')} />
        </div>
        <div className="col-md-3">
          <CFormLabel>SWIFT/BIC Number</CFormLabel>
          <CFormInput {...register('swiftBIC')} />
        </div>
        <div className="col-md-3">
          <CFormLabel>IBAN</CFormLabel>
          <CFormInput {...register('iban')} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Sort Code</CFormLabel>
          <CFormInput {...register('sortCode')} />
        </div>
      </div>
    </form>
  )
}

export default BankAccountDetailForm
