import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import { formatTableRenderers } from '../utils/formatUtils/utils'
import { breakNotes } from '../utils/formatUtils/utils'

interface UsersState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  order: any
  orders: any[]
  error: boolean
  errorMessage: string
  transactions: any[]
  transactionsColumns: any[]
}

const initialState: UsersState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  order: {},
  orders: [],
  error: false,
  errorMessage: '',
  transactions: [],
  transactionsColumns: [],
}

export const getOrderTransactions = createAsyncThunk(
  'getOrderTransactions',
  async ({ id }: any) => {
    const data = {
      companyName: {},
      createdAt: {},
      doneAt: {},
      price: {},
      sharePrice: {},
      shares: {},
      status: {},
      orderId: { filterValues: [+id] },
      sellOrderId: {},
      transactionId: {},
    }

    try {
      const response = await privateAPI.post('/queries/transactions', data)
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getOrderReports = createAsyncThunk('getOrderReports', async () => {
  try {
    const response = await privateAPI.get('/queries/ORDERS/reports')
    return response.data
  } catch (e) {
    console.log('error getting order reports: ', e)
  }
})

export const getOrders = createAsyncThunk(
  'getOrders',
  async ({ found }: any) => {
    const params = found.queryParams
    try {
      const response = await privateAPI.post(
        '/queries/orders',
        JSON.parse(params),
      )
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getOrder = createAsyncThunk('getOrder', async ({ id }: any) => {
  try {
    const response = await privateAPI.get(`/queries/orders/${id}`)
    return response.data
  } catch (e) {
    console.log('error getting ordre: ', e)
  }
})

export const updateOrder = createAsyncThunk(
  'updateOrder',
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/queries/orders/${id}`, data)
      return 200
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  },
)

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = false
      state.errorMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderReports.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.reports = [...payload]
        }
      })
      .addCase(getOrderReports.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(getOrders.fulfilled, (state, { payload }: any) => {
        const { columns, filtered, rows } = payload || {}
        const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
          rows,
          columns,
        )

        state.columns = dataColumns
        state.rows = dataRows
        state.filtered = filtered
      })
      .addCase(getOrders.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(getOrder.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          const notes = breakNotes(payload.rows[0]?.notes) || []
          const stringNotes = payload.rows[0]?.notes || ''
          state.order = { ...payload.rows[0], notes, stringNotes }
        }
      })
      .addCase(getOrder.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(updateOrder.rejected, (state, action) => {
        const { payload } = action || {}
        if (payload) {
          const { error } = payload as any
          if (error) {
            switch (error) {
              case 'UPHOLD_OTP_REQUIRED': {
                state.error = true
                state.errorMessage = 'Please enter your Uphold OTP Token.'
                break
              }
              case 'UPHOLD_UNKNOWN_ERROR': {
                state.error = true
                state.errorMessage = 'Uphold returned an error.'
                break
              }
              case 'UPHOLD_OTP_INCORRECT': {
                state.error = true
                state.errorMessage = 'Please enter a valid Uphold OTP Token.'
                break
              }
              default: {
                state.error = true
                state.errorMessage = 'There was an error saving this order.'
              }
            }
          }
        }
      })
      .addCase(getOrderTransactions.fulfilled, (state, { payload }) => {
        const { rows, columns } = payload
        const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
          rows,
          columns,
        )
        state.transactionsColumns = dataColumns
        state.transactions = dataRows
      })
  },
})

export const { resetError } = ordersSlice.actions

export default ordersSlice.reducer
