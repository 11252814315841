import { configureStore } from '@reduxjs/toolkit'
import sidebarReducer from './features/sidebarSlice'
import userReducer from './features/userSlice'
import sellRequestsReducer from './features/sellRequestsSlice'
import originationCompaniesReducer from './features/originationCompaniesSlice'
import ordersReducer from './features/ordersSlice'
import entityReducer from './features/entitySlice'
import usersReducer from './features/usersSlice'
import rewardsReducer from './features/rewardsSlice'
import brokerReducer from './features/brokerSlice'
import transactionReducer from './features/transactionSlice'
import listReducer from './features/listsSlice'
import companyReducer from './features/companiesSlice'
import teamMemberReducer from './features/teamMembersSlice'
import offersReducer from './features/offersSlice'
import assetReducer from './features/assetsSlice'
import commonReducer from './features/commonSlice'
import seriesReducer from './features/seriesSlice'
import riaReducer from './features/riaSlice'
import cashAccountReducer from './features/cashAccountSlice'
import sellOrderReducer from './features/sellOrderSlice'

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    user: userReducer,
    origination_sell_to_linqto_requests: sellRequestsReducer,
    origination_companies: originationCompaniesReducer,
    orders: ordersReducer,
    entities: entityReducer,
    users: usersReducer,
    rewards: rewardsReducer,
    broker: brokerReducer,
    transactions: transactionReducer,
    list: listReducer,
    companies: companyReducer,
    team_members: teamMemberReducer,
    offers: offersReducer,
    assets: assetReducer,
    common: commonReducer,
    serieses: seriesReducer,
    ria: riaReducer,
    cashAccount: cashAccountReducer,
    sellOrders: sellOrderReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
