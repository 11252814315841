import React, { useState } from 'react'
import { useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { useParams } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CLoadingButton,
} from '@coreui/react-pro'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import {
  deleteTeamMember,
  getTeamMember,
  resetError,
  resetTeamMember,
  saveTeamMember,
} from '../../features/teamMembersSlice'
import history from '../../history'
import CreateTeamMember from './CreateTeamMember'
import WarningModal from '../../components/WarningModal'
import Alert from '../../components/Alert'

const TeamMemberDetail: React.FC = () => {
  const { id }: any = useParams()
  const [loading, setLoading] = useState(true)
  const [uploadedLogoUrl, setUploadedLogoUrl] = useState<string>('')
  const [btnLoading, setBtnLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { teamMember, error, errorMessage } = useAppSelector(
    (state: RootState) => state.team_members,
  )
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setValue,
  } = useForm()

  const { dirtyFields } = useFormState({ control })

  useEffect(() => {
    const getEntityById = async () => {
      if (+id !== -1) {
        await dispatch(getTeamMember({ id }))
      }
      setLoading(false)
    }
    getEntityById()
    return () => {
      dispatch(resetTeamMember())
      dispatch(resetError())
    }
  }, [id, dispatch])

  useEffect(() => {
    reset(teamMember)
  }, [teamMember, reset])

  const handleFileChange = (e: any) => {
    const files = e.target.files
    const reader = new FileReader()

    if (files.length > 0) {
      reader.readAsDataURL(files[0])

      reader.onload = (e: any) => {
        setUploadedLogoUrl(e.target.result)
        setValue('pictureUrl', e.target.result, { shouldDirty: true })
      }
    }
  }

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    if (uploadedLogoUrl && uploadedLogoUrl.length) {
      data.pictureUrl = uploadedLogoUrl
    }
    await dispatch(saveTeamMember({ id, data: { ...data } }))
    await dispatch(getTeamMember({ id }))
    setBtnLoading(false)
    setUploadedLogoUrl('')
  }

  const deleteUser = async () => {
    setLoading(true)
    const res: any = await dispatch(deleteTeamMember({ id }))
    if (res && res.payload === 200) {
      alert('User has been deleted')
      history.goBack()
    } else {
      setDeleteModal(false)
    }
    setLoading(false)
  }

  const openInNewTab = (url: string) => {
    const newTab = window.open()
    if (newTab) {
      newTab.document.write(`<img src=${url}></img>`)
    }
  }

  const {
    teamMemberId,
    firstName,
    lastName,
    bio,
    boardMemberTitle,
    isAdvisor,
    isBoardMember,
    isLeadership,
    isTeamMember,
    teamMemberTitle,
    pictureUrl,
    orderIndex,
    advisorTitle,
  } = teamMember || {}

  if (+id === -1) {
    return <CreateTeamMember />
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      {error && errorMessage && (
        <Alert
          color="danger"
          visible={error}
          onClose={() => dispatch(resetError())}
          text={errorMessage}
        />
      )}
      {deleteModal && (
        <WarningModal
          header={`Delete user "${firstName} ${lastName}"?`}
          body="Performing this action will permanently erase this user."
          proceed="Yes"
          abandon="No"
          proceedAction={() => deleteUser()}
          abandonAction={() => setDeleteModal(false)}
          open={deleteModal}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between">
          <h3>
            Team No.{teamMemberId} - {firstName} {lastName}
          </h3>
          <div className="d-flex">
            <CButton
              className="btn-danger mx-2"
              onClick={() => setDeleteModal(true)}
            >
              Delete
            </CButton>
            <CLoadingButton
              disabled={!isDirty || Object.keys(dirtyFields).length === 0}
              loading={btnLoading}
              className="btn-success mx-2"
              type="submit"
            >
              Save
            </CLoadingButton>
          </div>
        </div>
        <h4>Team Member Information</h4>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel className="form-label">First Name</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('firstName', {
                required: 'This field is required.',
              })}
              defaultValue={firstName || ''}
            />
            {errors.firstName && (
              <span className="text-danger">{errors.firstName.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Last Name</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('lastName', {
                required: 'This field is required.',
              })}
              defaultValue={lastName || ''}
            />
            {errors.lastName && (
              <span className="text-danger">{errors.lastName.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Board Member Title</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('boardMemberTitle')}
              defaultValue={boardMemberTitle || ''}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Team Member Title</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('teamMemberTitle')}
              defaultValue={teamMemberTitle || ''}
            />
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>Bio</CFormLabel>
            <textarea
              disabled={btnLoading}
              className="form-control"
              rows={3}
              {...register('bio', { required: 'This field is required.' })}
              defaultValue={bio || ''}
            ></textarea>
            {errors.bio && (
              <span className="text-danger">{errors.bio.message}</span>
            )}
          </div>
          <div className="d-flex align-items-start col-md-3">
            <div className="w-100">
              <CFormLabel>Logo</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                type="file"
                accept=".png,.jpg"
                onChange={(e: any) => handleFileChange(e)}
              />
            </div>
            <div onClick={() => openInNewTab(uploadedLogoUrl || pictureUrl)}>
              <img
                style={{
                  marginTop: '1.9rem',
                  maxWidth: '100px',
                  maxHeight: '40px',
                }}
                className="ms-3 rounded"
                alt="logo-url"
                src={uploadedLogoUrl || pictureUrl}
              />
            </div>
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Order Index</CFormLabel>
            <CFormInput
              type="number"
              disabled={btnLoading}
              {...register('orderIndex', {
                required: 'This field is required.',
              })}
              defaultValue={orderIndex || ''}
            />
            {errors.orderIndex && (
              <span className="text-danger">{errors.orderIndex.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel className="form-label">Advisor Title</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('advisorTitle')}
              defaultValue={advisorTitle || ''}
            />
          </div>
        </div>
        <div className="col g-3 mb-4">
          <div style={{ width: 'fit-content', marginBottom: '10px' }}>
            <div className="form-check hover">
              <input
                disabled={btnLoading}
                className="form-check-input hover"
                type="checkbox"
                {...register('isAdvisor')}
                defaultChecked={isAdvisor}
                id="isAdvisor"
              />
              <label className="form-check-label hover" htmlFor={'isAdvisor'}>
                Is Advisor
              </label>
            </div>
          </div>
          <div style={{ width: 'fit-content', marginBottom: '10px' }}>
            <div className="form-check hover">
              <input
                disabled={btnLoading}
                className="form-check-input hover"
                type="checkbox"
                {...register('isBoardMember')}
                defaultChecked={isBoardMember}
                id="isBoardMember"
              />
              <label
                className="form-check-label hover"
                htmlFor={'isBoardMember'}
              >
                Is Board Member
              </label>
            </div>
          </div>
          <div style={{ width: 'fit-content', marginBottom: '10px' }}>
            <div className="form-check hover">
              <input
                disabled={btnLoading}
                className="form-check-input hover"
                type="checkbox"
                {...register('isLeadership')}
                defaultChecked={isLeadership}
                id="isLeadership"
              />
              <label
                className="form-check-label hover"
                htmlFor={'isLeadership'}
              >
                Is Leadership
              </label>
            </div>
          </div>
          <div style={{ width: 'fit-content', marginBottom: '10px' }}>
            <div className="form-check hover">
              <input
                disabled={btnLoading}
                className="form-check-input hover"
                type="checkbox"
                {...register('isTeamMember')}
                defaultChecked={isTeamMember}
                id="isTeamMember"
              />
              <label
                className="form-check-label hover"
                htmlFor={'isTeamMember'}
              >
                Is Team Member
              </label>
            </div>
          </div>
        </div>
      </form>
    </PageLayout>
  )
}

export default TeamMemberDetail
