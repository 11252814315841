import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import { formatTableRenderers } from '../utils/formatUtils/utils'

interface UsersState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  loading: boolean
  sellRequest: any
}

const initialState: UsersState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  loading: false,
  sellRequest: {},
}

export const getSellRequest = createAsyncThunk(
  'getSellRequest',
  async ({ id }: any) => {
    try {
      const response = await privateAPI.get(
        `/queries/ORIGINATION_SELL_TO_LINQTO_REQUESTS/${id}`,
      )
      return response.data
    } catch (e) {
      console.log(e)
    }
  },
)

export const saveSellRequest = createAsyncThunk(
  'saveSellRequest',
  async ({ id, data }: any) => {
    try {
      const response = await privateAPI.post(
        `/queries/ORIGINATION_SELL_TO_LINQTO_REQUESTS/${id}`,
        data,
      )
      return response.data
    } catch (e) {
      console.log(e)
    }
  },
)

export const getSellRequests = createAsyncThunk(
  'getSellRequests',
  async ({ found }: any) => {
    const params = found.queryParams
    try {
      const response = await privateAPI.post(
        '/queries/ORIGINATION_SELL_TO_LINQTO_REQUESTS',
        JSON.parse(params),
      )
      return response.data
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
)

export const getSellRequestReports = createAsyncThunk(
  'getSellRequestReports',
  async () => {
    try {
      const response = await privateAPI.get(
        '/queries/ORIGINATION_SELL_TO_LINQTO_REQUESTS/reports',
      )
      return response.data
    } catch (e) {
      console.log('e', e)
    }
  },
)

export const origination_sell_to_linqto_requests = createSlice({
  name: 'origination_sell_to_linqto_requests',
  initialState,
  reducers: {
    addNote: (state, { payload }: any) => {
      state.sellRequest.notes = JSON.stringify(payload)
    },
    removeNote: (state, { payload }: any) => {
      state.sellRequest.notes = JSON.stringify(payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSellRequestReports.pending, (state) => {
        state.loading = true
      })
      .addCase(getSellRequestReports.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.reports = [...payload]
        }
        state.loading = false
      })
      .addCase(getSellRequestReports.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
      .addCase(getSellRequest.pending, (state) => {
        state.loading = true
      })
      .addCase(getSellRequest.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.filtered = payload.filtered
          state.sellRequest = payload.rows[0]
        }
        state.loading = false
      })
      .addCase(getSellRequest.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
      .addCase(saveSellRequest.pending, (state) => {
        state.loading = true
      })
      .addCase(saveSellRequest.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.sellRequest = payload.rows[0]
        }
        state.loading = false
      })
      .addCase(saveSellRequest.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
      .addCase(getSellRequests.pending, (state) => {
        state.loading = true
      })
      .addCase(getSellRequests.fulfilled, (state, { payload }: any) => {
        const { columns, filtered, rows } = payload || {}
        const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
          rows,
          columns,
        )

        state.columns = dataColumns
        state.rows = dataRows
        state.filtered = filtered
        state.loading = false
      })
      .addCase(getSellRequests.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
  },
})

export const { addNote, removeNote } =
  origination_sell_to_linqto_requests.actions

export default origination_sell_to_linqto_requests.reducer
