import React from 'react'
import { CButton, CFormInput, CFormLabel } from '@coreui/react-pro'
import { useFormContext } from 'react-hook-form'
import FormatNumber from './FormatNumber'
import { cilMinus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Competitors } from '../../../types/Competitors'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'

interface CompetitiveLandscapeProps {
  fields: Competitors[]
  remove: (index: number) => any
}

const CompetitiveLandscape: React.FC<CompetitiveLandscapeProps> = ({
  fields,
  remove,
}: CompetitiveLandscapeProps) => {
  const { register } = useFormContext()
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )
  return (
    <>
      {fields.map((field: any, index: number) => (
        <div key={field.id} className="d-flex align-items-center">
          <div className="w-100">
            <div className="row g-3 mb-4">
              <div className="col-md-3">
                <CFormLabel>Company Name</CFormLabel>
                <CFormInput
                  {...register(
                    `companyDetail.competitors.${index}.companyName`,
                  )}
                  disabled={btnLoading}
                />
              </div>
              <div className="col-md-3">
                <CFormLabel>City</CFormLabel>
                <CFormInput
                  {...register(
                    `companyDetail.competitors.${index}.headquarters.city`,
                  )}
                  disabled={btnLoading}
                />
              </div>
              <div className="col-md-3">
                <CFormLabel>State</CFormLabel>
                <CFormInput
                  {...register(
                    `companyDetail.competitors.${index}.headquarters.state`,
                  )}
                  disabled={btnLoading}
                />
              </div>
              <div className="col-md-3">
                <CFormLabel>Country</CFormLabel>
                <CFormInput
                  {...register(
                    `companyDetail.competitors.${index}.headquarters.country`,
                  )}
                  disabled={btnLoading}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm">
                <div className="w-100 mb-2">
                  <CFormLabel>Valued At</CFormLabel>
                  <CFormInput
                    type="number"
                    {...register(`companyDetail.competitors.${index}.value`)}
                    disabled={btnLoading}
                  />
                  <FormatNumber
                    watchValue={`companyDetail.competitors.${index}.value`}
                    isMoney
                  />
                </div>
              </div>
              <div className="col-sm">
                <CFormLabel>Value Footnote</CFormLabel>
                <CFormInput
                  {...register(
                    `companyDetail.competitors.${index}.valueFootnote`,
                  )}
                  disabled={btnLoading}
                />
              </div>
              <div className="col-sm">
                <div className="w-100 mb-2">
                  <CFormLabel>Revenue</CFormLabel>
                  <CFormInput
                    type="number"
                    {...register(`companyDetail.competitors.${index}.revenue`)}
                    disabled={btnLoading}
                  />
                  <FormatNumber
                    watchValue={`companyDetail.competitors.${index}.revenue`}
                    isMoney
                  />
                </div>
              </div>
              <div className="col-sm">
                <CFormLabel>Revenue Footnote</CFormLabel>
                <CFormInput
                  {...register(
                    `companyDetail.competitors.${index}.revenueFootnote`,
                  )}
                  disabled={btnLoading}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="col-sm">
              <CButton
                className="bg-danger ms-2 rounded-circle"
                size="sm"
                style={{ float: 'right' }}
                onClick={() => remove(index)}
                disabled={btnLoading}
              >
                <CIcon icon={cilMinus} />
              </CButton>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default CompetitiveLandscape
