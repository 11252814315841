import React, { useMemo } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import FAQDetailForm from './FAQDetailForm'
import api from '../../APIs/private'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

type FAQ = {
  faqId: number
  faqListId: number
  orderIndex: number
  question: string
  answer: string
  rowCount: number
}

type FAQList = {
  faqListId: number
  url: string
  title: string
  rowCount: number
}

type FormValues = {
  faqId: number
  faqListId: number
  orderIndex: number
  question: string
  answer: string
  rowCount: number
  url: string
  title: string
}

const getFAQ = async (id: string): Promise<FAQ> => {
  const response = await api.get(`/queries/faqs/${id}`)
  const faq: FAQ = response?.data?.rows?.[0]
  return faq
}

const getFAQList = async (id: number | undefined) => {
  const response = await api.get(`/queries/faq_lists/${id}`)
  const faqList: FAQList = response?.data?.rows?.[0]
  return faqList
}

const updateFAQ = async (id: string, data: FormValues) =>
  await api.post(`/queries/faqs/${id}`, data)

const createFAQ = async (data: FormValues) => {
  await api.post('/queries/faqs/-1', data)
}

const deleteFAQ = async (id: string) => {
  await api.delete(`/queries/faqs/${id}`)
}

const FAQDetail: any = () => {
  const methods = useForm<FormValues>()
  const history = useHistory()
  const { id }: any = useParams()
  const queryClient = useQueryClient()

  const { data: faqData, isInitialLoading: faqLoading } = useQuery({
    queryFn: () => getFAQ(id),
    queryKey: ['faqs', id],
    enabled: +id !== -1,
  })

  const faqListId: number | undefined = faqData?.faqListId

  const { data: faqListData, isInitialLoading: faqListLoading } = useQuery({
    queryFn: () => getFAQList(faqListId),
    queryKey: ['faq_lists', faqListId],
    enabled: !!faqListId,
  })

  const faq: any = useMemo(() => {
    return { ...faqData, ...faqListData }
  }, [faqData, faqListData])

  const updateFAQMutation = useMutation({
    mutationFn: async (data: FormValues) => await updateFAQ(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['faqs', id] })
      queryClient.invalidateQueries({
        queryKey: ['faq_lists', faqListId],
      })
    },
  })

  const createFAQMutation = useMutation({
    mutationFn: async (data: FormValues) => await createFAQ(data),
    onSuccess: () => history.goBack(),
  })

  const deleteFAQMutation = useMutation({
    mutationFn: async () => await deleteFAQ(id),
    onSuccess: () => history.goBack(),
  })

  useEffect(() => methods.reset(faq), [methods, faq])

  const loading = faqLoading || faqListLoading

  const btnLoading =
    updateFAQMutation.isLoading ||
    createFAQMutation.isLoading ||
    deleteFAQMutation.isLoading

  const onSubmit =
    +id === -1 ? createFAQMutation.mutate : updateFAQMutation.mutate

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <FormProvider {...methods}>
      <PageLayout>
        <FAQDetailForm
          btnLoading={btnLoading}
          deleteFAQ={deleteFAQMutation.mutate}
          onSubmit={onSubmit}
          id={id}
          faq={faq}
        />
      </PageLayout>
    </FormProvider>
  )
}

export default FAQDetail
