import React from 'react'
import PDF from '../assets/pdf.svg'
import {
  formatToPT,
} from '../utils/formatUtils/utils'
interface DocumentProps {
  name: string
  url: string
  createdAt: number
  loading?: boolean
}

const Document: React.FC<DocumentProps> = ({
  name,
  url,
  createdAt,
  loading,
}: DocumentProps) => {
  return (
    <div
      className="mb-2 col-md-12 d-flex justify-content-between align-items-center py-2 px-1 rounded border border-primary"
      style={{ cursor: 'pointer', pointerEvents: loading ? 'none' : 'auto' }}
      onClick={() => window.open(url)}
    >
      <div>
        <img alt="PDF" src={PDF} height={20} width={20} />
        <span className="ms-2 text-overflow">{name}</span>
      </div>
      {createdAt ? <span className="me-2">Uploaded at:{formatToPT(createdAt, 'MM/DD/YY hh:mm:ss a')}</span> : null}
    </div>
  )
}

export default Document
