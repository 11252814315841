import { CFormInput } from '@coreui/react-pro'
import React from 'react'
import Null from './Null'
import FilterNotEqual from './FilterNotEqual'

const String: any = ({ filter, filters, setFilters }: any) => {
  const { input } = filter

  const onChange = (e: any) => {
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      arr[idx] = { ...arr[idx], input: e.target.value }
      setFilters([...arr])
    }
  }

  const handleNullChange = (e: any) => {
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      arr[idx] = {
        ...arr[idx],
        filterIsNull: e.target.checked,
        input: '',
        filterValuesNotChecked: false,
      }
      setFilters([...arr])
    }
  }

  const handleNotEqualCheckbox = (e: any) => {
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      arr[idx] = {
        ...arr[idx],
        filterValuesNotChecked: e.target.checked,
        input: arr[idx]?.input || '',
      }
      setFilters([...arr])
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      {!filter.filterIsNull && <CFormInput value={input} onChange={onChange} />}
      <div className="d-flex">
        <Null
          handleNullChange={handleNullChange}
          filterIsNull={filter.filterIsNull}
        />
        <FilterNotEqual
          handleChange={handleNotEqualCheckbox}
          value={filter?.filterValuesNotChecked}
        />
      </div>
    </div>
  )
}

export default String
