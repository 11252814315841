import React from 'react'
import { useHistory } from 'react-router-dom'
import GenericTable from '../../../components/GenericTable'
import privateAPI from '../../../APIs/private'

const cashAccountsColumns = [
  {
    key: 'cashAccountId',
    name: 'cashAccountId',
    label: 'Cash Account Id',
    type: 'Number',
    renderer: 'Id',
  },
  {
    key: 'userId',
    name: 'userId',
    label: 'User Id',
    type: 'Number',
    renderer: 'Id',
  },
  {
    key: 'ownerName',
    name: 'ownerName',
    label: 'Owner Name',
    type: 'String',
  },
  {
    key: 'normalBalanceType',
    name: 'normalBalanceType',
    label: 'Normal Balance Type',
    type: 'String',
  },
  {
    key: 'availableBalance',
    name: 'availableBalance',
    label: 'Available Balance',
    type: 'Number',
    renderer: 'Number',
  },
  {
    key: 'cashCreateLink',
    name: 'cashCreateLink',
    label: 'Cash Create',
    type: 'Button',
    renderer: 'Button',
  },
]

interface UserCashAccountsProps {
  cashAccounts: any[]
  setLoading: (value: boolean) => void
}

const UserCashAccounts: React.FC<UserCashAccountsProps> = ({
  cashAccounts,
  setLoading,
}: UserCashAccountsProps) => {
  const history = useHistory()

  const handleCashCreateLink = async (row: any) => {
    const { cashCreateLink } = row || {}
    if (cashCreateLink) {
      try {
        setLoading(true)
        await privateAPI.post(cashCreateLink?.substr(21))
        window.location.reload()
      } catch (e) {
        return 500
      }
    }
  }

  return (
    <GenericTable
      items={cashAccounts}
      columns={cashAccountsColumns}
      handleClick={(item: any) =>
        history.push(`/cash_accounts/${item.cashAccountId}`)
      }
      handleButtonClick={handleCashCreateLink}
    />
  )
}

export default UserCashAccounts
