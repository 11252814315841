import React from 'react'
import { CFormLabel } from '@coreui/react-pro'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import Select from '../assets/components/Select'

const SellOrderDetailForm: React.FC<any> = () => {
  const { transaction } = useAppSelector(
    (state: RootState) => state.transactions,
  )
  const { user } = useAppSelector((state: RootState) => state.users)
  const { offer } = useAppSelector((state: RootState) => state.offers)

  const {
    transactionId,
    companyId,
    companyName,
    offerId,
    buyerUserId,
    buyerUserLegalName,
    buyerEntityId,
    buyerEntityName,
    sellerUserId,
    sellerName,
    sellerEntityId,
    sellerEntityName,
    orderId,
    sellOrderId,
    fromAssetId,
    fromAssetName,
    fromOrderId,
    fromSeriesId,
    fromSeriesName,
    toSeriesId,
    toSeriesName,
    proxyUserId,
  } = transaction || {}

  const { fullName } = user || {}
  const { companyName: offerCompanyName } = offer || {}

  return (
    <>
      <form>
        <div className="d-flex justify-content-between">
          <h3>Transaction No. {transactionId}</h3>
        </div>
        <div className="row g-3 mb-4">
          {companyId && (
            <div className="col-md-3">
              <CFormLabel>Company</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={companyName}
                query="companies"
                id={companyId}
                ref={null}
              />
            </div>
          )}
          {offerId && (
            <div className="col-md-3">
              <CFormLabel>Sell Offer</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={offerCompanyName}
                query="offers"
                id={offerId}
                ref={null}
              />
            </div>
          )}
          {buyerUserId && (
            <div className="col-md-3">
              <CFormLabel>Buyer User</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={buyerUserLegalName}
                query="users"
                id={buyerUserId}
                ref={null}
              />
            </div>
          )}
          {buyerEntityId && (
            <div className="col-md-3">
              <CFormLabel>Buyer Entity</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={buyerEntityName}
                query="entities"
                id={buyerEntityId}
                ref={null}
              />
            </div>
          )}
          {sellerUserId && (
            <div className="col-md-3">
              <CFormLabel>Seller User</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={sellerName}
                query="users"
                id={sellerUserId}
                ref={null}
              />
            </div>
          )}
          {sellerEntityId && (
            <div className="col-md-3">
              <CFormLabel>Seller Entity</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={sellerEntityName}
                query="entities"
                id={sellerEntityId}
                ref={null}
              />
            </div>
          )}
          {orderId && (
            <div className="col-md-3">
              <CFormLabel>Order</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={'Order'}
                query="orders"
                id={orderId}
                ref={null}
              />
            </div>
          )}
          {sellOrderId && (
            <div className="col-md-3">
              <CFormLabel>Sell Order</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={'Sell Order'}
                query="sell_orders"
                id={sellOrderId}
                ref={null}
              />
            </div>
          )}
          {fromAssetId && (
            <div className="col-md-3">
              <CFormLabel>From Asset</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={fromAssetName}
                query="assets"
                id={fromAssetId}
                ref={null}
              />
            </div>
          )}
          {fromOrderId && (
            <div className="col-md-3">
              <CFormLabel>From Order</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={'Order'}
                query="orders"
                id={fromOrderId}
                ref={null}
              />
            </div>
          )}
          {fromSeriesId && (
            <div className="col-md-3">
              <CFormLabel>From Series</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={fromSeriesName}
                query="series_formd_infos"
                id={fromSeriesId}
                ref={null}
              />
            </div>
          )}
          {toSeriesId && (
            <div className="col-md-3">
              <CFormLabel>To Series</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={toSeriesName}
                query="series_formd_infos"
                id={toSeriesId}
                ref={null}
              />
            </div>
          )}
          {proxyUserId && (
            <div className="col-md-3">
              <CFormLabel>Proxy User</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={fullName}
                query="users"
                id={proxyUserId}
                ref={null}
              />
            </div>
          )}
        </div>
      </form>
    </>
  )
}

export default SellOrderDetailForm
