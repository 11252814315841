import React from 'react'
import GenericTable from '../../../components/GenericTable'
import privateAPI from '../../../APIs/private'
import { useQuery } from '@tanstack/react-query'
import { formatTableRenderers } from '../../../utils/formatUtils/utils'

interface RecurringInvestmentsProps {
  id: number,
  handleClick: (item: any) => void
}

const getRecurringInvestments: any = async (id: number) => {
  const data = {
    userId: { filterValues: [+id] },
    recurringEventId: {},
    entityId: {},
    startDate: {},
    frequency: {},
    isActive: {},
    nextOccurance: {}
  }
  const response = await privateAPI.post(
    '/queries/RECURRING_EVENTS',
    data
  )
  const { rows, columns } = formatTableRenderers(
    response?.data?.rows,
    response?.data?.columns,
  )
  // filter out user id column
  const filteredColumns = columns.filter((col: any) => col.name !== 'userId')

  return { recurringRows: rows, recurringColumns: filteredColumns }
}

const RecurringInvestments: React.FC<RecurringInvestmentsProps> = ({ id, handleClick }: RecurringInvestmentsProps) => {

  const { data, isInitialLoading } = useQuery({
    queryKey: ['recurring_events', id],
    queryFn: () => getRecurringInvestments(id),
    enabled: +id > 0,
  })

  if (isInitialLoading) return <h1>Loading...</h1>;
  const { recurringRows, recurringColumns } = data || {}

  return (
    <div className="mt-4">
      <div className="row g-3">
        <div>
          <GenericTable items={recurringRows} columns={recurringColumns} handleClick={handleClick} />
        </div>
      </div>
    </div>
  );
};
export default RecurringInvestments