import { CModal, CModalBody } from '@coreui/react-pro'
import React from 'react'
import Spinner from '../../../components/Spinner'

const PlaidDataModal: React.FC<any> = ({ data, handleClose, loading }) => {
  return (
    <CModal visible alignment="center" size="xl" onClose={handleClose}>
      <CModalBody>
        {loading ? (
          <Spinner />
        ) : data ? (
          <pre>{JSON.stringify(data, null, 2)}</pre>
        ) : (
          'There is no Plaid data for this user'
        )}
      </CModalBody>
    </CModal>
  )
}

export default PlaidDataModal
