import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import BankAccountDetailForm from './BankAccountDetailForm'
import PageLayout from '../../layout/PageLayout'
import { useHistory, useParams } from 'react-router-dom'
import useQuery from '../../hooks/useQuery'
import { useMutation } from '@tanstack/react-query'
import api from '../../APIs/private'

export type BankAccountDetailFormValues = {
  accountNickname: string
  bankCountry: string
  accountType: string
  accountNumber?: string
  iban?: string
  routingNumber?: string
  sortCode?: string
  cashAccountId: number
}

const createBankAccount = async (data: BankAccountDetailFormValues) => {
  const response = await api.post(`/cashExternalAccount/create`, data)
  return response.data
}

const BankAccountDetail: React.FC<any> = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const query = useQuery()
  const cashAccountId = query.get('cashAccountId')

  const methods = useForm<BankAccountDetailFormValues>()

  const createBankAccountHandler = useMutation({
    mutationFn: async (data: BankAccountDetailFormValues) => {
      const obj = { ...data, cashAccountId: +cashAccountId }
      return await createBankAccount(obj)
    },
    onSuccess: (id) => history.push(`/cash_external_accounts/${id}`),
  })

  return (
    <FormProvider {...methods}>
      <PageLayout>
        <BankAccountDetailForm
          id={id}
          onSubmit={createBankAccountHandler.mutate}
          loading={createBankAccountHandler.isLoading}
        />
      </PageLayout>
    </FormProvider>
  )
}

export default BankAccountDetail
