import React, { useEffect, useState, FormEvent } from 'react'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CFormText,
} from '@coreui/react-pro'
import { useAppDispatch, useAppSelector } from '../features/hooks'
import { RootState } from '../store'
import { formatDecimal } from '../utils/formatUtils/utils'
import { initiateWithdraw } from '../features/cashAccountSlice'

interface WithdrawModalProps {
  handleClose: () => void
  cashExternalAccount: any
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({ handleClose, cashExternalAccount }: any) => {
  const dispatch = useAppDispatch()
  const [withdrawMethod, setWithdrawMethod] = useState<string>('')
  const [bankName, setBankName] = useState<string>('')
  const [amount, setAmount] = useState<number | string>('')
  const [disabled, setDisabled] = useState<boolean>(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { cashAccount } = useAppSelector(
    (state: RootState) => state.cashAccount,
  )

  useEffect(() => {
    if (
      !withdrawMethod ||
      !bankName ||
      +amount > cashAccount?.balance ||
      +amount === 0
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [withdrawMethod, bankName, amount, cashAccount?.balance])

  useEffect(() => {
    if (cashExternalAccount) {
      setWithdrawMethod(
        cashExternalAccount.achWithdrawAllowed
          ? 'ACH'
          : cashExternalAccount.achDepositAllowed
            ? 'WIRE'
            : cashExternalAccount.action_CreateWithdraw
              ? ''
              : '',
      )
      setBankName(cashExternalAccount.bankName)
    }
  }, [cashExternalAccount])

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(false)
    const obj = {
      bankName,
      amount: +Number(amount).toFixed(2),
      type: withdrawMethod,
    }
    setLoading(true)
    const { payload } = await dispatch(
      initiateWithdraw({
        id: cashExternalAccount.cashExternalAccountId,
        data: obj,
      }),
    )
    setLoading(false)
    if (payload === 200) {
      window.location.reload()
    } else {
      setError(true)
    }
  }

  const { balance } = cashAccount || {}

  return (
    <CModal
      visible
      alignment="center"
      size="sm"
      onClose={() => handleClose(false)}
    >
      <CModalHeader>
        <CModalTitle>ACH Withdraw</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="d-flex align-items-center justify-content-center hover"></div>
        <form onSubmit={onSubmit}>
          <>
            <div className="mb-4">
              <CFormLabel>Withdraw Method</CFormLabel>
              <CFormSelect
                disabled={loading}
                value={withdrawMethod}
                onChange={(e) => setWithdrawMethod(e.target.value)}
              >
                <option value="">None</option>
                <option value="ACH">ACH</option>
                <option value="WIRE">Wire</option>
              </CFormSelect>
            </div>
            <div className="mb-4">
              <CFormLabel>Selected Bank Account</CFormLabel>
              <CFormInput
                disabled
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <CFormLabel>Amount</CFormLabel>
              <CFormInput
                disabled={loading}
                type="number"
                step=".01"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <CFormText className="text-end">
                {amount &&
                  `$${amount
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
              </CFormText>
              {+amount > balance && (
                <span className="text-danger">
                  Amount must be less than balance. ({formatDecimal(+balance)})
                </span>
              )}
            </div>
          </>
          {error && (
            <p className="text-danger mb-4">
              There was an error submitting your request. Please try again
              later.
            </p>
          )}
          <CModalFooter className="px-0 m-0 flex-nowrap">
            <CButton className="mr-4 w-50" color="danger" onClick={handleClose}>
              Close
            </CButton>
            <CButton
              className="mr-4 w-50"
              color="primary"
              type="submit"
              disabled={disabled || loading}
            >
              Submit
            </CButton>
          </CModalFooter>
        </form>
      </CModalBody>
    </CModal>
  )
}

export default WithdrawModal
