import { useState, useEffect } from 'react'

const useFetchUserData: any = (fn: any) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      await fn()
      setLoading(false)
    }
    fetchData()
  }, [fn])

  return loading
}

export default useFetchUserData
