import {
  CButton,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import privateAPI from '../../../APIs/private'

interface RecategorizeDocumentsModalProps {
  abandonAction: () => void
  userId: string,
  open: boolean
  items: any[]
}

const RecategorizeDocumentsModal: React.FC<RecategorizeDocumentsModalProps> = ({
  open,
  abandonAction,
  userId,
  items,
}: RecategorizeDocumentsModalProps) => {
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState("")

  const updateType = async (
    documentId: string,
    type: string
  ) => {
    try {
      await privateAPI.post(`/queries/documents/${documentId}`, { documentId, type })
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    // Check to make sure type not null and is not "UNKNOWN"
    // loop through each item and call an endpoint
    if (items && items.length && (!!type && type !== 'UNKNOWN')) {
      const filteredItems = items?.filter((a) => a._selected === true)
      filteredItems.forEach((filteredItem: any) => {
        updateType(filteredItem.documentId, type);
      })
    }
    setLoading(false)
    // Refresh the user page and set documents tab in focus
    window.history.replaceState({}, '', '/users/' + userId + '?tab=Documents')
    window.location.reload()
    abandonAction()
  }

  return (
    <CModal visible={open} alignment="center" onClose={abandonAction}>
      <CModalHeader className='me-4'>Bulk Recategorize Documents</CModalHeader>
      <form>
        <CModalBody>
          <div className="row g-3 mb-4">
            <CFormLabel>Files:
              <span style={{ paddingLeft: 50 }}>
                {
                  items?.filter((a) => a._selected === true)?.length === 0 && 'No files selected'
                }
              </span>
            </CFormLabel>
            <ul className="list-group g-3" style={{ paddingLeft: 50 }}>
              {
                items?.filter((a) => a._selected === true)?.map((item, i) => <li key={i}>{item?.name}</li>)
              }
            </ul>
          </div>
          <div className="row g-3 mb-4">
            <CFormLabel>Document Type:</CFormLabel>
            <CFormSelect onChange={(e: any) => setType(e.target.value)}>
              <option value="UNKNOWN">-- Select Type --</option>
              <option value="USER_VIEW">Accreditation Documents</option>
              <option value="FINANCIAL_ADVISOR_VIEW">Financial Advisor Documents</option>
              <option value="INTERNAL">Internal Documents</option>
              <option value="TAX_WITHHOLDING_AND_REPORTING">Tax Withholding and Reporting Documents</option>
              <option value="USER_TAX">User Tax Documents</option>
              <option value="USER_UPLOADED">User Uploaded Documents</option>
            </CFormSelect>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton className="btn-light" onClick={abandonAction}>
            Cancel
          </CButton>
          <CLoadingButton disabled={items?.filter((a) => a._selected === true).length === 0 || !type || type === 'UNKNOWN'} loading={loading} type="button" onClick={onSubmit}>
            Submit
          </CLoadingButton>
        </CModalFooter>
      </form>
    </CModal>
  )
}

export default RecategorizeDocumentsModal
