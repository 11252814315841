import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import { formatTableRenderers } from '../utils/formatUtils/utils'

interface UsersState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  loading: boolean
  linqtoBucksRows: any[]
  linqtoBucksColumns: any[]
}

const initialState: UsersState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  loading: false,
  linqtoBucksRows: [],
  linqtoBucksColumns: [],
}

export const getUserLinqtoBucks = createAsyncThunk(
  'getUserLinqtoBucks',
  async ({ userId }: any) => {
    const data = {
      userId: {
        filterValues: [userId],
      },
      amount: {},
      note: {},
      reason: {},
      createdAt: { sortIndex: 2, sortAsc: false },
    }
    try {
      const response = await privateAPI.post(
        '/queries/linqto_buck_transactions',
        data,
      )
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const addTransaction = createAsyncThunk(
  'addTransaction',
  async ({ userId, data: { amount, note } }: any) => {
    try {
      const response = await privateAPI.post(
        '/queries/linqto_buck_transactions/-1',
        { userId, amount, note },
      )

      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const rewardsReducer = createSlice({
  name: 'rewards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserLinqtoBucks.fulfilled, (state, { payload }: any) => {
        if (payload) {
          const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
            payload?.rows,
            payload?.columns,
          )
          state.linqtoBucksRows = dataRows
          state.linqtoBucksColumns = dataColumns
        }
      })
      .addCase(getUserLinqtoBucks.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
  },
})

export default rewardsReducer.reducer
