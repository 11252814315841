import React from 'react'
import { useFormContext } from 'react-hook-form'

interface Style {
  width: string
  marginBottom: string
}

interface CheckboxProps {
  btnLoading: boolean
  registerValue: string
  formLabel: string
  style?: Style
}

const Checkbox: React.FC<CheckboxProps> = ({
  btnLoading,
  registerValue,
  formLabel,
  style,
}: CheckboxProps) => {
  const { register } = useFormContext()

  return (
    <div style={style ? style : { width: 'auto' }}>
      <div className="form-check hover">
        <input
          disabled={btnLoading}
          className="form-check-input hover"
          type="checkbox"
          {...register(registerValue)}
          id={registerValue}
        />
        <label className="form-check-label hover" htmlFor={registerValue}>
          {formLabel}
        </label>
      </div>
    </div>
  )
}

export default Checkbox
