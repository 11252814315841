import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import WarningModal from '../../../components/WarningModal'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'

interface TabControlProps {
  tab: string
  setTab: Dispatch<SetStateAction<string>>
}

const TabControl: React.FC<TabControlProps> = ({
  tab,
  setTab,
}: TabControlProps) => {
  const [open, setOpen] = useState(false)
  const [tabs, setTabs] = useState([
    'Buy Orders',
    'Sell Orders',
    'Sell Offers',
    'Holdings',
    'Linqto Bucks',
    'Entities',
    'Tags',
    'Documents',
    'Address',
    'User MFA',
    'Cash Accounts',
    'Features',
    'Purchase Credits',
    'Recurring Investments'
  ])
  const {
    user: { linkedClients },
  } = useAppSelector((state: RootState) => state.users)
  const {
    reset,
    formState: { isDirty },
  } = useFormContext()

  useEffect(() => {
    if (
      linkedClients &&
      linkedClients.length >= 0 &&
      !tabs.includes('Linked Clients')
    ) {
      setTabs([...tabs, 'Linked Clients'])
    }
    //eslint-disable-next-line
  }, [linkedClients])

  const handleTabChange = (tag: any) => {
    if (isDirty) {
      setOpen(true)
    } else {
      setTab(tag)
    }
  }

  const resetForm = () => {
    reset()
    setOpen(false)
  }

  return (
    <>
      {open && (
        <WarningModal
          header="Continue Without Saving?"
          body="Are you sure you want to switch tabs? This will remove all updates you have made to this tab."
          abandon="Stay"
          proceed="Discard Changes"
          abandonAction={() => setOpen(false)}
          proceedAction={() => resetForm()}
          open={open}
        />
      )}
      <ul className="nav nav-tabs hover">
        {tabs.map((tag, i) => (
          <li
            className={`nav-item ${tab === tag && 'active'}`}
            key={i}
            onClick={() => handleTabChange(tag)}
          >
            <div className={`nav-link inactive ${tag === tab && 'active'}`}>
              {tag}
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default TabControl
