import React, { useEffect, useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import privateAPI from '../../APIs/private'
// components
import PageLayout from '../../layout/PageLayout'
import Spinner from '../../components/Spinner'
import CustomDealsForm from './CustomDealsForm'
import { CustomDealsValues } from './customDeals-types'

const getCustomDeal = async (id: string) => {
  const response = await privateAPI.get(`/queries/custom_deals/${id}`)
  return response.data
}

const createCustomDeal = async (body: CustomDealsValues) =>
  await privateAPI.post('/queries/custom_deals/-1', body)

const saveCustomDeal = async (id: string, body: CustomDealsValues) =>
  await privateAPI.post(`/queries/custom_deals/${id}`, body)

const CustomDealDetail: React.FC = () => {
  const queryClient = useQueryClient()
  const methods = useForm<CustomDealsValues>()
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const { reset } = methods

  const { data, isInitialLoading } = useQuery({
    queryKey: ['custom_deals', id],
    queryFn: () => getCustomDeal(id),
    enabled: +id > 0,
  })

  const saveCustomDealMutation = useMutation({
    mutationFn: async (data: CustomDealsValues) => {
      // convert totalPrice to number and remove commas
      const obj = { ...data, totalPrice: parseFloat(data?.totalPrice?.toString()?.replace(/,/g, '') || '') }
      await saveCustomDeal(id, obj)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['custom_deals', id] }),
  })

  const createCustomDealMutation = useMutation({
    mutationFn: async (data: CustomDealsValues) => {
      // convert totalPrice to number and remove commas
      const obj = { ...data, totalPrice: parseFloat(data?.totalPrice?.toString()?.replace(/,/g, '') || '') }
      await createCustomDeal(obj)
    },
    onSuccess: () => history.goBack()
  })

  const customDeal = useMemo(() => data?.rows?.[0] || {}, [data?.rows])

  useEffect(() => reset(customDeal), [customDeal, reset])

  const { isLoading: saveLoading } = saveCustomDealMutation
  const { isLoading: createLoading } = createCustomDealMutation

  if (isInitialLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <CustomDealsForm
          header={+id > 0 ? `Custom Deal No. ${id}` : 'Create Custom Deal'}
          onSubmit={
            +id > 0 ? saveCustomDealMutation.mutate : createCustomDealMutation.mutate
          }
          loading={+id > 0 ? saveLoading : createLoading}
          customDeal={customDeal}
        />
      </FormProvider>
    </PageLayout>
  )
}

export default CustomDealDetail
