import { cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CFormInput, CFormLabel } from '@coreui/react-pro'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import FormatNumber from './FormatNumber'
import KeyOfficer from './KeyOfficer'
import ManagementOfficials from './ManagementOfficials'

const Management: React.FC = () => {
  const { register, control } = useFormContext()
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companyDetail.keyInvestors',
  })
  const {
    fields: keyOfficers,
    append: appendKeyOfficers,
    remove: removeKeyOfficers,
  } = useFieldArray({
    control,
    name: 'companyDetail.keyOfficers',
  })

  return (
    <div className="g-3 mb-4">
      <h3>Management</h3>
      <div className="col-md-2">
        <CFormLabel>Number Of Employees</CFormLabel>
        <CFormInput
          type="number"
          {...register('companyDetail.employeeCount')}
          disabled={btnLoading}
        />
        <FormatNumber
          watchValue="companyDetail.employeeCount"
          isMoney={false}
        />
      </div>
      <div className="col-md-3">
        <ManagementOfficials
          fields={fields}
          remove={remove}
          append={append}
          name="keyInvestors"
          label="Key Investors"
        />
      </div>
      <div className="col-sm">
        <div className="d-flex align-items-start">
          <CFormLabel>Key Officers</CFormLabel>
          <CButton
            style={{ margin: '0 10px' }}
            size="sm"
            className="rounded-circle"
            onClick={() => appendKeyOfficers({ name: '' })}
            disabled={btnLoading}
          >
            <CIcon icon={cilPlus} />
          </CButton>
        </div>
        <div className="mb-5">
          {keyOfficers.map((field: any, index: number) => (
            <div
              key={field.id}
              className="d-flex align-items-center mb-4 gap-2"
            >
              <KeyOfficer
                field={field}
                index={index}
                remove={removeKeyOfficers}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Management
