import { CButton } from '@coreui/react-pro'
import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import GenericTable from '../../../components/GenericTable'
import useFetchUserData from '../../../hooks/useFetchUserData'
import { getUserPurchaseCredits } from '../../../features/usersSlice'

interface PurchaseCreditsProps {
  setOpenCredits: any,
  handleClick: (item: any) => void
}

const PurchaseCredits: React.FC<PurchaseCreditsProps> = ({ setOpenCredits, handleClick }: PurchaseCreditsProps) => {
  const dispatch = useAppDispatch()
  const { user, userCreditRows, userCreditColumns } = useAppSelector((state: RootState) => state.users)
  const loading = useFetchUserData(
    useCallback(
      () => dispatch(getUserPurchaseCredits({ userId: user.userId })),
      [dispatch, user.userId],
    ),
  )

  if (loading) return <h1>Loading...</h1>

  // Do not show creditTransactionId column
  const columns = userCreditColumns.filter(col => col.name !== 'creditTransactionId')

  return (
    <div className="mt-4">
      <CButton onClick={() => setOpenCredits(true)}>Add Purchase Credits</CButton>
      <div className="row g-3">
        <div>
          <GenericTable items={userCreditRows} columns={columns} handleClick={handleClick} />
        </div>
      </div>
    </div>
  )
}
export default PurchaseCredits