import React from 'react'
import { CChart } from '@coreui/react-chartjs'

const PieChart: any = ({ xAxisData, yAxis, yAxisData }: any) => {
  return (
    <CChart
      style={{
        margin: 'auto',
        height: '800px',
        width: '800px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      type="doughnut"
      data={{
        labels: yAxisData,
        datasets: [
          {
            backgroundColor: ['#077187', '#ff0000', '#3095AA', '#333333'],
            data: xAxisData,
          },
        ],
      }}
    />
  )
}

export default PieChart
