import { CModal, CModalBody, CNav, CNavItem, CNavLink } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import Spinner from '../../../components/Spinner'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import { getSummarizedPlaidReports } from '../../../features/usersSlice'

const PlaidSummarizedReportModal: React.FC<any> = ({ id, handleClose }) => {
  const dispatch = useAppDispatch()
  const [tab, setTab] = useState('Summarized Bank Income')
  const [loading, setLoading] = useState(true)
  const { summarizedPlaidReports } = useAppSelector((state: RootState) => state.users)
  const { summarizedBankIncome, summarizedDocumentIncomeDataList, assetsReportJsonList, summarizedAssetsReport, summarizedDocReviewIncome } = summarizedPlaidReports || {}

  useEffect(() => {
    (async () => {
      const res = await dispatch(getSummarizedPlaidReports(id))
      const { payload } = res || {}
      const { summarizedBankIncome, summarizedDocReviewIncome, summarizedDocumentIncomeDataList } = payload || {}
      // set default tab based on available report
      if (summarizedBankIncome) {
        setTab('Summarized Bank Income')
      } else if (summarizedDocReviewIncome) {
        setTab('Summarized Doc Review Income')
      } else if (summarizedDocumentIncomeDataList) {
        setTab({ type: 'Document Income', index: 0 })
      }
      setLoading(false)
    })()
  }, [id])

  const renderAssetReport = i => {
    const report = assetsReportJsonList?.[i]
    if (report) {
      return <pre>{JSON.stringify(report, null, 2)}</pre>
    }
  }

  const renderReport = i => {
    let report = ''
    if (i?.type === 'Document Income') {
      report = summarizedDocumentIncomeDataList?.[i?.index]
    } else if (i?.type === 'Asset Report') {
      report = assetsReportJsonList?.[i?.index]
    }
    if (report) {
      return <pre>{JSON.stringify(report, null, 2)}</pre>
    }
  }

  if (loading) {
    return (
      <CModal visible alignment="center">
        <CModalBody>
          <Spinner />
        </CModalBody>
      </CModal>
    )
  }

  return (
    <CModal visible alignment="center" size="xl" onClose={handleClose}>
      <CModalBody>
        {!summarizedBankIncome && !summarizedDocReviewIncome && !summarizedDocumentIncomeDataList?.length && assetsReportJsonList.length === 0 && <h4>No Data Available</h4>}
        <CNav variant="tabs">
          {!!summarizedBankIncome && Object.keys(summarizedBankIncome).length !== 0 && <CNavItem className="hover">
            <CNavLink
              active={tab === 'Summarized Bank Income'}
              onClick={() => setTab('Summarized Bank Income')}
              className={`${tab !== 'Summarized Bank Income' && 'inactive'}`}
            >
              Summarized Bank Income
            </CNavLink>
          </CNavItem>}
          {summarizedDocumentIncomeDataList && summarizedDocumentIncomeDataList.length > 0 && summarizedDocumentIncomeDataList.map((report: any, i: number) => (
            <CNavItem className="hover" key={i}>
              <CNavLink
                active={i === tab?.index && tab?.type === 'Document Income'}
                onClick={() => setTab({ type: 'Document Income', index: i })}
                className={`${+tab !== i && 'inactive'}`}
              >
                Summarized Income Report {i + 1}
              </CNavLink>
            </CNavItem>
          ))}
          {assetsReportJsonList && assetsReportJsonList.length > 0 && assetsReportJsonList.map((report: any, i: number) => (
            <CNavItem className="hover" key={i}>
              <CNavLink
                active={i === tab?.index && tab?.type === 'Asset Report'}
                onClick={() => setTab({ type: 'Asset Report', index: i })}
                className={`${+tab !== i && 'inactive'}`}
              >
                Asset Report {i + 1}
              </CNavLink>
            </CNavItem>
          ))}
          {summarizedAssetsReport && Object.keys(summarizedAssetsReport).length !== 0 && <CNavItem className="hover">
            <CNavLink
              active={tab === 'Summarized Assets'}
              onClick={() => setTab('Summarized Assets')}
              className={`${tab !== 'Summarized Assets' && 'inactive'}`}
            >
              Summarized Assets
            </CNavLink>
          </CNavItem>}
          {summarizedDocReviewIncome && Object.keys(summarizedDocReviewIncome).length !== 0 && <CNavItem className="hover">
            <CNavLink
              active={tab === 'Summarized Doc Review Income'}
              onClick={() => setTab('Summarized Doc Review Income')}
              className={`${tab !== 'Summarized Doc Review Income' && 'inactive'}`}
            >
              Summarized Doc Review Income
            </CNavLink>
          </CNavItem>}
        </CNav>
        {tab === 'Summarized Bank Income' && summarizedBankIncome && <pre>{JSON.stringify(summarizedBankIncome, null, 2)}</pre>}
        {tab === 'Summarized Doc Income' && summarizedDocumentIncomeDataList && <pre>{JSON.stringify(summarizedDocumentIncomeDataList, null, 2)}</pre>}
        {tab === 'Summarized Assets' && summarizedAssetsReport && <pre>{JSON.stringify(summarizedAssetsReport, null, 2)}</pre>}
        {tab === 'Summarized Doc Review Income' && summarizedDocReviewIncome && <pre>{JSON.stringify(summarizedDocReviewIncome, null, 2)}</pre>}
        {typeof tab === 'number' && renderAssetReport(tab)}
        {tab?.type === 'Asset Report' || tab?.type === 'Document Income' ? renderReport(tab) : null}
      </CModalBody>
    </CModal>
  )
}

export default PlaidSummarizedReportModal
