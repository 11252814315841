import {
  CButton,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '../../../features/hooks'
import {
  addTransaction,
  getUserLinqtoBucks,
} from '../../../features/rewardsSlice'
import { getUser } from '../../../features/usersSlice'

interface AddTransactionModalProps {
  header: string
  abandonAction: () => void
  open: boolean
  userId: string
}

const AddTransactionModal: React.FC<AddTransactionModalProps> = ({
  open,
  header,
  abandonAction,
  userId,
}: AddTransactionModalProps) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data: any) => {
    setLoading(true)
    await dispatch(addTransaction({ userId, data }))
    await dispatch(getUser({ id: userId }))
    await dispatch(getUserLinqtoBucks({ userId }))
    setLoading(false)
    abandonAction()
  }

  return (
    <CModal visible={open} alignment="center" onClose={abandonAction}>
      <CModalHeader>{header}</CModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CModalBody>
          <div className="row- g-3 mb-4">
            <div className="col-md-12">
              <CFormLabel>Amount</CFormLabel>
              <CFormInput
                type="number"
                step=".01"
                {...register('amount', { required: 'This field is required' })}
              />
              {errors.amount && (
                <span className="text-danger">{errors.amount.message}</span>
              )}
            </div>
          </div>
          <div className="row- g-3 mb-4">
            <div className="col-md-12">
              <CFormLabel>Notes</CFormLabel>
              <CFormInput
                {...register('note', { required: 'This field is required' })}
              />
              {errors.note && (
                <span className="text-danger">{errors.note.message}</span>
              )}
            </div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton className="btn-danger" onClick={abandonAction}>
            Cancel
          </CButton>
          <CLoadingButton loading={loading} type="submit">
            Add
          </CLoadingButton>
        </CModalFooter>
      </form>
    </CModal>
  )
}

export default AddTransactionModal
