import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { useMutation, useQuery } from '@tanstack/react-query'
import api from '../../APIs/private'
import { getData } from '../../utils/commonActions'
import BrokerDetailForm from './BrokerDetailForm'
import q from '../../hooks/useQuery'

const getBrokerInfo = async (id: string) => {
  const response = await api.get(`/queries/broker_infos/${id}`)
  return response?.data?.rows?.[0]
}

const BrokerDetail: any = () => {
  const query = q()
  const { id }: any = useParams()
  const history = useHistory()
  const createUserId = query.get('userId')
  const methods = useForm()
  const { reset, setValue } = methods

  const { data: brokerInfoData, isInitialLoading: brokerInfoLoading } =
    useQuery({
      queryKey: ['broker_info', id],
      queryFn: async () => await getBrokerInfo(id),
      enabled: +id > 0,
    })

  const { brokerInfoId, userId, entityId } = brokerInfoData || {}

  const { data: userData, isInitialLoading: userLoading } = useQuery({
    queryKey: ['broker_info_user', userId],
    queryFn: async () =>
      await getData('users', {
        userId: { filterMin: userId, filterMax: userId },
        fullName: {},
      }),
    enabled: !!userId,
  })

  const { data: createUserData, isInitialLoading: createUserLoading } =
    useQuery({
      queryKey: ['broker_info_user', createUserId],
      queryFn: async () =>
        await getData('users', {
          userId: { filterMin: createUserId, filterMax: createUserId },
          fullName: {},
        }),
      enabled: !!createUserId,
    })

  const { fullName: createUserFullName } = createUserData || {}

  const { fullName } = userData || {}

  const { data: entityData, isInitialLoading: entityLoading } = useQuery({
    queryKey: ['broker_info_entity', entityId],
    queryFn: async () =>
      await getData('entities', {
        entityId: { filterMin: entityId, filterMax: entityId },
        name: {},
      }),
    enabled: !!entityId,
  })

  const { name } = entityData || {}

  const saveBrokerInfoMutation = useMutation({
    mutationFn: async (data) =>
      await api.post(`/queries/broker_infos/${id}`, data),
  })

  const createBrokerInfoMutation = useMutation({
    mutationFn: async (data: any) => {
      const obj = {
        ...data,
        userId: +createUserId,
      }
      await api.post('/queries/broker_infos/-1', obj)
    },
    onSuccess: () => history.goBack(),
  })

  useEffect(() => {
    reset(brokerInfoData)
  }, [brokerInfoData, reset])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length <= 4) {
      setValue('dtc', value, { shouldDirty: true })
    }
  }

  const loading =
    brokerInfoLoading || userLoading || entityLoading || createUserLoading

  const btnLoading =
    saveBrokerInfoMutation.isLoading || createBrokerInfoMutation.isLoading

  const onSubmit =
    id === '-1'
      ? createBrokerInfoMutation.mutate
      : saveBrokerInfoMutation.mutate

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <BrokerDetailForm
          id={id}
          onSubmit={onSubmit}
          btnLoading={btnLoading}
          userId={userId}
          createUserId={createUserId}
          createUserFullName={createUserFullName}
          entityId={entityId}
          brokerInfoId={brokerInfoId}
          name={name}
          fullName={fullName}
          handleChange={handleChange}
        />
      </FormProvider>
    </PageLayout>
  )
}

export default BrokerDetail
