import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { useAppDispatch } from '../../features/hooks'
import TransactionDetailForm from './TransactionDetailForm'
import {
  getTransaction,
  resetTransaction,
} from '../../features/transactionSlice'
import { getUser, resetUser } from '../../features/usersSlice'
import { getOffer, resetOffer } from '../../features/offersSlice'

const TransactionDetail: React.FC = () => {
  const { id }: any = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const methods = useForm()

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(getTransaction({ id }))
      const data = res?.payload?.rows?.[0] || {}
      if (data) {
        if (data.proxyUserId) await dispatch(getUser({ id: data.proxyUserId }))
        if (data.offerId) await dispatch(getOffer({ id: data.offerId }))
      }
      setLoading(false)
    }
    fetchData()
    return () => {
      dispatch(resetTransaction())
      dispatch(resetUser())
      dispatch(resetOffer())
    }
  }, [id, dispatch])

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <FormProvider {...methods}>
      <PageLayout>
        <TransactionDetailForm />
      </PageLayout>
    </FormProvider>
  )
}

export default TransactionDetail
