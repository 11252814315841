/* eslint-disable no-use-before-define */
import React from 'react'
import { toCamalCase } from '../formatUtils/utils'

const statusDictNotVerified = {
  CANCELED: ['CANCELED', 'PENDING', 'FUNDED'],
  PENDING: ['CANCELED', 'PENDING', 'FUNDED'],
  FUNDED: ['CANCELED', 'PENDING', 'FUNDED'],
  TRANSFERRING: ['CANCELED', 'PENDING', 'FUNDED', 'TRANSFERRING'],
  COMPLETED: ['CANCELED', 'PENDING', 'FUNDED', 'COMPLETED'],
}
const statusDict = {
  CANCELED: ['CANCELED', 'TRANSFERRING', 'COMPLETED'],
  TRANSFERRING: ['CANCELED', 'TRANSFERRING', 'COMPLETED'],
  COMPLETED: ['CANCELED', 'TRANSFERRING', 'COMPLETED'],
  PENDING: ['CANCELED', 'TRANSFERRING', 'COMPLETED', 'PENDING'],
  FUNDED: ['CANCELED', 'TRANSFERRING', 'COMPLETED', 'FUNDED'],
}

const kycStatusDict = {
  NOT_VERIFIED: ['NOT_VERIFIED', 'VERIFIED_MANUAL'],
  VERIFIED_MANUAL: ['NOT_VERIFIED', 'VERIFIED_MANUAL'],
  VERIFIED_AUTO: ['NOT_VERIFIED', 'VERIFIED_MANUAL', 'VERIFIED_AUTO'],
}

const accreditedStatusDict = {
  NOT_VERIFIED: [
    'NOT_VERIFIED',
    'ACCREDITED',
    'UNDER_REVIEW',
    'QUALIFIED_PURCHASER',
    'FAILED',
  ],
  ACCREDITED: [
    'NOT_VERIFIED',
    'ACCREDITED',
    'UNDER_REVIEW',
    'QUALIFIED_PURCHASER',
    'FAILED',
  ],
  QUALIFIED_PURCHASER: [
    'NOT_VERIFIED',
    'ACCREDITED',
    'UNDER_REVIEW',
    'QUALIFIED_PURCHASER',
    'FAILED',
  ],
  FAILED: [
    'NOT_VERIFIED',
    'ACCREDITED',
    'UNDER_REVIEW',
    'QUALIFIED_PURCHASER',
    'FAILED',
  ],
  UNDER_REVIEW: [
    'NOT_VERIFIED',
    'ACCREDITED',
    'UNDER_REVIEW',
    'QUALIFIED_PURCHASER',
    'FAILED',
  ],
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const renderOptions = (user, statusName) => {
  if (user[statusName]) {
    if (statusName === 'kycStatus') {
      return kycStatusDict[user[statusName]].map((status, i) => {
        return (
          <option key={i + status} value={status}>
            {toCamalCase(status.replace('_', ' '))}
          </option>
        )
      })
    } else {
      if (accreditedStatusDict[user[statusName]]) {
        return accreditedStatusDict[user[statusName]].map((status, i) => {
          return (
            <option key={i + status} value={status}>
              {toCamalCase(status.replace('_', ' '))}
            </option>
          )
        })
      }
    }
  }
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const renderStatus = (status, accreditedStatus, order) => {
  if (status === 'COMPLETED') {
    return (
      <option value="COMPLETED" key="COMPLETED">
        Completed
      </option>
    )
  }
  let dictToUse = {}
  if (accreditedStatus === 'NOT_VERIFIED') {
    dictToUse = statusDictNotVerified
  } else {
    dictToUse = statusDict
  }
  if (
    order.paymentType === 'UPHOLD' &&
    (order.status === 'COMPLETED' || order.status === 'CANCELED')
  ) {
    return (
      <React.Fragment>
        {order.status === 'COMPLETED' && (
          <option value="COMPLETED" key="COMPLETED">
            Completed
          </option>
        )}
        <option value="CANCELED" key="CANCELED">
          Canceled
        </option>
      </React.Fragment>
    )
  } else if (status === 'EXITED') {
    return <option value="EXITED">EXITED</option>
  }
  if (Object.keys(dictToUse).length > 0) {
    return dictToUse[order.status]?.map((ele) => {
      return (
        <option value={ele} key={ele}>
          {toCamalCase(ele.replace('_', ' '))}
        </option>
      )
    })
  }
}

export const renderAccTypeOptions = (values) => {
  return values?.map((type) => {
    return (
      <option key={type} value={type}>
        {toCamalCase(type.replace('_', ' '))}
      </option>
    )
  })
}

export const getBadgeColor = (status) => {
  switch (status) {
    case 'Verified':
    case 'Accredited':
    case 'Completed':
    case 'Verified Auto':
    case 'Verified Manual':
    case 'Open':
    case 'Submitted':
    case 'Approved':
    case 'Sent':
      return ['#00EB8C', '#14235A']
    case 'Not Verified':
    case 'Canceled':
    case 'Cancelled':
    case 'Closed':
    case 'Rejected':
    case 'Failed':
    case 'Irm Rejected':
    case 'Denied':
    case 'Declined':
      return ['rgba(255, 0, 0, .15)', '#FF0000']
    case 'Transferring':
    case 'Processing':
    case 'Qualified Purchaser':
    case 'Exited':
    case 'Needs Approval':
    case 'Reversed':
      return ['#C3F890', '#14235A']
    case 'Funded':
    case 'Exiting':
    case 'Unknown':
    case 'Pending Review':
    case 'Under Review':
    case 'Pending Auto Verification':
    case 'Pending Manual Verification':
    case 'Needs_review':
    case 'Needs Review':
    case 'Irm Needs Approval':
    case 'Returned':
      return ['#852EFF', '#FFFFFF']
    case 'Pending':
    case 'Deposit':
    case 'Requested':
    case 'Cs_needs_review':
    case 'Created':
      return ['rgba(255, 200, 34, .5', '#af8301']
    default:
      return 'primary'
  }
}
