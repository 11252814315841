import React, { FC } from 'react'
import {
  getRIALinkedClients,
  getRIAUser,
  updateRIAUserStatus,
} from '../../features/usersSlice'
import { RootState } from '../../store'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import LinkedClient from './LinkItem'

type LinkedClientsProps = {
  setBtnLoading: any
  users: any
  type: string
}

const LinkedClients: FC<LinkedClientsProps> = ({
  setBtnLoading,
  users,
  type,
}: LinkedClientsProps) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.users)

  const unlinkUser = async (userId: number) => {
    setBtnLoading(true)
    const { payload } = await dispatch(getRIAUser({ id: userId }))
    const riaUser =
      payload && payload.rows && payload.rows.length ? payload.rows[0] : null
    if (riaUser) {
      await dispatch(
        updateRIAUserStatus({ ...riaUser, riaUserStatus: 'REJECTED' }),
      )
      await dispatch(getRIALinkedClients({ id: user.userId }))
    }
    setBtnLoading(false)
  }

  if (!users || users.length === 0) {
    return null
  }

  return (
    <>
      {users.map(({ userId, fullName, riaUserStatus }: any, i: number) => (
        <LinkedClient
          key={userId}
          userId={userId}
          fullName={fullName}
          riaUserStatus={riaUserStatus}
          unlinkUser={() => (type === 'users' ? unlinkUser(userId) : null)}
          type={type}
        />
      ))}
    </>
  )
}

export default LinkedClients
