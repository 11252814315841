import React, { useState } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import {
  CFormInput,
  CFormLabel,
  CFormSelect,
  CButton
} from '@coreui/react-pro'
import SubmitButton from '../../components/SubmitButton'
import { formatToPT, formatDecimal } from '../../utils/formatUtils/utils'
import Select from '../assets/components/Select'
import Note from '../../components/Note'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'

const PurchaseCreditsDetailForm: React.FC<any> = ({
  onSubmit,
  btnLoading,
  data
}: any) => {
  const [note, setNote] = useState<string>('')
  const { handleSubmit, register, control } = useFormContext()
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'note',
  })
  const me = useAppSelector((state: RootState) => state.user)
  const { expiresAt, createdAt, orderId, sellOrderId, reason, amount } = data || {}

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-end">
          <SubmitButton loading={btnLoading} text="Save" />
        </div>
        <div>
          <div className="row mb-4">
            <div className="col-md-3">
              <CFormLabel className="form-label">Amount</CFormLabel>
              <CFormInput disabled value={formatDecimal(amount || 0)} />
            </div>
            <div className="col-md-3">
              <CFormLabel className="form-label">Created At </CFormLabel>
              <CFormInput disabled value={formatToPT(createdAt, 'MM/DD/YYYY')} />
            </div>
            <div className="col-md-3">
              <CFormLabel className="form-label">Expires At </CFormLabel>
              <CFormInput disabled value={formatToPT(expiresAt, 'MM/DD/YYYY')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Reason</CFormLabel>
              <CFormSelect
                disabled
                {...register('Reason')}
                defaultValue={reason}
              >
                <option value="SELL">SELL</option>
                <option value="PURCHASE">PURCHASE</option><option value="EXPIRED">EXPIRED</option>
              </CFormSelect>
            </div>
            <div className="col-md-3">
              <CFormLabel>Order Id</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={orderId}
                query="orders"
                id={orderId}
                ref={null}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Sell Order Id</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={sellOrderId}
                query="sell_orders"
                id={sellOrderId}
                ref={null}
              />
            </div>

          </div>
          <div className="d-flex">
            <div className="col-md-6">
              <div className="col-md-12 d-flex align-items-end justify-content-between">
                <div className="d-flex flex-column w-100 me-2">
                  <h4>Notes</h4>
                  <CFormInput
                    placeholder="Add note..."
                    disabled={btnLoading}
                    value={note}
                    onChange={(e: any) => setNote(e.target.value)}
                  />
                </div>
                <CButton disabled={!note} type="button" onClick={updateNote}>
                  Add
                </CButton>
              </div>
              {fields && fields.length
                ? fields.map((note: any, i: number) => (
                  <Note
                    index={note.id}
                    key={note.id}
                    note={note}
                    deleteNote={() => remove(i)}
                  />
                ))
                : null}
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default PurchaseCreditsDetailForm
