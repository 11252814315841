import React from 'react'
import { cilMinus, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CFormInput, CFormLabel } from '@coreui/react-pro'
import { useFormContext } from 'react-hook-form'
import { RootState } from '../../../store'
import { useAppSelector } from '../../../features/hooks'

interface ManagementOfficialsProps {
  fields: any[]
  remove: (index: number) => any
  append: ({ name }: any) => any
  name: string
  label: string
}

const ManagementOfficials: React.FC<ManagementOfficialsProps> = ({
  fields,
  remove,
  append,
  name,
  label,
}: ManagementOfficialsProps) => {
  const { register } = useFormContext()
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )

  return (
    <>
      <div className="d-flex align-items-start mb-4">
        <CFormLabel>{label}</CFormLabel>
        <CButton
          style={{ margin: '0 10px' }}
          size="sm"
          className="rounded-circle"
          onClick={() => append({ name: '' })}
          disabled={btnLoading}
        >
          <CIcon icon={cilPlus} />
        </CButton>
      </div>
      {fields.map((field: any, index: number) => (
        <div key={field.id} className="d-flex align-items-center mb-4">
          <CFormInput
            {...register(`companyDetail.${name}.${index}.name`)}
            disabled={btnLoading}
          />
          <CButton
            disabled={btnLoading}
            size="sm"
            className="bg-danger ms-2 rounded-circle"
            style={{ float: 'right', marginLeft: '10px' }}
            onClick={() => remove(index)}
          >
            <CIcon icon={cilMinus} />
          </CButton>
        </div>
      ))}
    </>
  )
}

export default ManagementOfficials
