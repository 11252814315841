import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import React, { useEffect } from "react"
import PageLayout from "../../layout/PageLayout"
import Spinner from "../../components/Spinner"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import privateAPI from '../../APIs/private'
import SubmitButton from "../../components/SubmitButton"
import { CFormInput, CFormLabel, CFormSelect } from "@coreui/react-pro"
import Select from "../assets/components/Select"
import { getCompanyData } from "../../utils/commonActions"
import { components } from 'react-select'
import Checkbox from "../../components/Checkbox"
import DatePicker from 'react-datepicker'
import useQueryRouter from '../../hooks/useQuery'

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}

const getCompanyMessage: any = async (id: number) => {
  const data = {
    companyMessageId: { filterValues: [id] },
    createdAt: {},
    companyId: {},
    name: {},
    title: {},
    type: {},
    message: {},
    startDate: {},
    endDate: {},
    isActive: {}
  }
  const response = await privateAPI.post('/queries/company_messages', data)
  return response?.data?.rows?.[0]
}

const addNewCompanyMessage = async (body: any) =>
  await privateAPI.post(`/queries/company_messages/-1`, body)

const saveCompanyMessage = async (id: string, body: any) =>
  await privateAPI.post(`/queries/company_messages/${id}`, body)

const CompanyMessagesDetail: React.FC = () => {
  const history = useHistory()
  const { id }: any = useParams()
  const query = useQueryRouter()
  const newMessageCompanyId = query.get('newMessageCompanyId')
  const queryClient = useQueryClient()
  const methods = useForm()
  const { handleSubmit, register, setValue, formState: { errors } } = methods
  const { data: companyMessagesData, isInitialLoading } = useQuery({
    queryKey: ['company_messages', id],
    queryFn: () => getCompanyMessage(id),
    enabled: +id > 0,
  })
  const { name, title, message, type, companyId } = companyMessagesData || {}

  useEffect(() => {
    if (companyMessagesData) {
      setValue('isActive', companyMessagesData.isActive)
      setValue('startDate', companyMessagesData.startDate)
      setValue('endDate', companyMessagesData.endDate)
    }
  }, [setValue, companyMessagesData])

  // Query to fetch company data when editing company message
  const companyQuery = {
    queryKey: ['companies', companyId],
    queryFn: () => getCompanyData(companyId),
    enabled: +companyId > 0,
  }
  // Query to fetch company data for new company message
  const newMessageCompanyQuery = {
    queryKey: ['companies', newMessageCompanyId],
    queryFn: () => getCompanyData(newMessageCompanyId),
    enabled: +newMessageCompanyId > 0,
  }

  // Fetch company data from the companyId or newMessageCompanyId
  const { data: company, isInitialLoading: companyDataLoading } = useQuery(
    newMessageCompanyId ? newMessageCompanyQuery : companyQuery
  )

  // Create new company message
  const createMessageMutation = useMutation({
    mutationFn: async (data: any) => await addNewCompanyMessage(data),
    onSuccess: () => history.goBack()
  })

  // Save edited company message
  const saveMessageMutation = useMutation({
    mutationFn: async (data: any) => await saveCompanyMessage(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['company_messages', id])
    }
  })

  const { isLoading: saveLoading } = saveMessageMutation


  // Submit form data on Save button click
  const onSubmit = async (data: any) => {
    if (+id > 0) {
      saveMessageMutation.mutate(data)
      return
    }
    data.companyId = newMessageCompanyId
    createMessageMutation.mutate(data)
  }

  if (isInitialLoading || companyDataLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <h2>{id > 0 ? `Company Message No. ${id}` : 'Add New Company Message'}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-end">
            <SubmitButton loading={saveLoading} text="Save" />
          </div>
          <div>
            <div className="row mb-4">
              <div className="col-md-3">
                <CFormLabel className="form-label">Name</CFormLabel>
                <CFormInput {...register('name', { required: 'This field is required' })} defaultValue={name} disabled={saveLoading} />
                {errors.name && (
                  <span className="text-danger">{errors.name.message}</span>
                )}
              </div>
              <div className="col-md-3">
                <CFormLabel className="form-label">Title</CFormLabel>
                <CFormInput {...register('title', { required: 'This field is required' })} defaultValue={title} disabled={saveLoading} />
                {errors.title && (
                  <span className="text-danger">{errors.title.message}</span>
                )}
              </div>
              <div className="col-md-3">
                <CFormLabel className="form-label">Message</CFormLabel>
                <textarea
                  className="form-control"
                  rows={4}
                  {...register('message', { required: 'This field is required' })}
                  defaultValue={message}
                  disabled={saveLoading}
                />
                {errors.message && (
                  <span className="text-danger">{errors.message.message}</span>
                )}
              </div>
              <div className="col-md-3">
                <CFormLabel>Type</CFormLabel>
                <CFormSelect
                  defaultValue={type}
                  disabled={saveLoading}
                  {...register('type', {
                    required: 'This field is required.',
                  })}
                >
                  <option value="INFORMATIONAL">INFORMATIONAL</option>
                </CFormSelect>
                {errors.type && (
                  <span className="text-danger">{errors.type.message}</span>
                )}
              </div>
              <div className="col-md-3">
                <CFormLabel className="form-label">Company</CFormLabel>
                <div className="d-flex flex-row">
                  <Select
                    options={Option}
                    query="companies"
                    watchValue={company?.name}
                    id={company?.companyId}
                    readOnly={true} // Company is readonly because messages are for each company
                  />
                </div>
              </div>
              <div className="col-md-4">
                <CFormLabel className="form-label">Start Date</CFormLabel>
                <Controller
                  name='startDate'
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value}
                      className="form-control"
                      onChange={onChange}
                      disabled={saveLoading}
                      showTimeSelect
                      dateFormat="MM/dd/yyyy h:mm aa"
                    />
                  )}
                />
              </div>
              <div className="col-md-4">
                <CFormLabel className="form-label">End Date</CFormLabel>
                <Controller
                  name='endDate'
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value}
                      className="form-control"
                      onChange={onChange}
                      disabled={saveLoading}
                      showTimeSelect
                      dateFormat="MM/dd/yyyy h:mm aa"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-3">
              <Checkbox
                formLabel="Is Active"
                registerValue="isActive"
                btnLoading={false}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </PageLayout >
  )


}
export default CompanyMessagesDetail