import React from 'react'
import AsyncSelect from 'react-select/async'

interface AsyncSelectInputProps {
  loadOptions: () => any
  onChange: (value: any) => any
  value: any
  option: any
  readOnly: boolean
  isClearable: boolean
  placeholder: string
}

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided: any, { isDisabled }: any) => ({
    ...provided,
    backgroundColor: isDisabled ? '#d8dbe0' : '#fff',
    border: isDisabled && '1px solid #b1b7c1',
  }),
  singleValue: (provided: any, { isDisabled }: any) => ({
    ...provided,
    color: isDisabled && 'rgba(44, 56, 74, 0.95)',
  }),
  option: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
}


const AsyncSelectInput: React.FC<AsyncSelectInputProps> = ({
  loadOptions,
  onChange,
  value,
  option,
  readOnly,
  isClearable = false,
  placeholder = ''
}: AsyncSelectInputProps) => {
  return (
    <AsyncSelect
      isDisabled={readOnly}
      components={{
        Option: option,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      isClearable={isClearable}
      defaultOptions
      cacheOptions
      styles={customStyles}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  )
}

export default AsyncSelectInput
