import { CFormSelect } from '@coreui/react-pro'
import React from 'react'

const Boolean: any = ({ filter, filters, setFilters }: any) => {
  const { input } = filter

  const onChange = (e: any) => {
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      arr[idx] = { ...arr[idx], input: JSON.parse(e.target.value) }
      setFilters([...arr])
    }
  }

  return (
    <CFormSelect
      defaultValue={input}
      onChange={onChange}
      className="filter-input"
    >
      <option value="true">True</option>
      <option value="false">False</option>
    </CFormSelect>
  )
}

export default Boolean
