import React, { useState } from 'react'
import { CButton, CFormInput, CFormLabel } from '@coreui/react-pro'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import {
  formatDecimal,
  formatToPT,
  toCamalCase,
} from '../../utils/formatUtils/utils'
import Select from '../assets/components/Select'
import Note from '../../components/Note'
import SubmitButton from '../../components/SubmitButton'
import GenericTable from '../../components/GenericTable'
import { useHistory } from 'react-router'

const SELL_ORDER_COLUMNS = [
  {
    key: 'totalAmount',
    name: 'totalAmount',
    label: 'Total Amount',
    type: 'Number',
    renderer: 'Money',
  },
  {
    key: 'shares',
    name: 'shares',
    label: 'Shares',
    type: 'Number',
    renderer: 'Shares',
  },
  {
    key: 'doneAt',
    name: 'doneAt',
    label: 'Done At',
    type: 'Date',
    renderer: 'Date',
  },
]

const SellOrderDetailForm: React.FC<any> = ({ btnLoading, onSubmit }: any) => {
  const [note, setNote] = useState<string>('')
  const { sellOrder } = useAppSelector((state: RootState) => state.sellOrders)
  const me = useAppSelector((state: RootState) => state.user)
  const { user } = useAppSelector((state: RootState) => state.users)
  const { transactions, transactionsColumns } = useAppSelector(
    (state: RootState) => state.sellOrders,
  )
  const { register, control, handleSubmit } = useFormContext()
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'notes',
  })

  const history = useHistory()

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  const {
    sellOrderId,
    cashAccountId,
    entityId,
    userId,
    createdAt,
    sharePrice,
    shares,
    status,
    subTotal,
    sellerFees,
    costBasis,
    sellerFlatFees,
    purchaseCreditsEarned,
    purchaseCreditsExpirationDate,
    tradeId
  } = sellOrder || {}
  const { totalProceeds, breakdown, offerId } = costBasis || {}
  const { avgCostPerShare, buyOrders, totalCost, totalGain } = breakdown || {}

  const { fullName } = user || {}

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between">
          <h3>Sell Order No. {sellOrderId}</h3>
          <SubmitButton loading={btnLoading} text="Save" />
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>Status</CFormLabel>
            <CFormInput disabled value={toCamalCase(status || '')} />
          </div>
          {userId && (
            <div className="col-md-3">
              <CFormLabel>Owner Name (User)</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={fullName}
                query="users"
                id={userId}
                ref={null}
              />
            </div>
          )}
          {entityId && (
            <div className="col-md-3">
              <CFormLabel>Owner Name (Entity)</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={fullName}
                query="entities"
                id={entityId}
                ref={null}
              />
            </div>
          )}
          <div className="col-md-3">
            <CFormLabel>Created At</CFormLabel>
            <CFormInput disabled value={formatToPT(createdAt, 'MM/DD/YY')} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Company Name</CFormLabel>
            <CFormInput disabled {...register('companyName')} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Share Price</CFormLabel>
            <CFormInput disabled value={formatDecimal(sharePrice || 0)} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Shares</CFormLabel>
            <CFormInput
              disabled
              value={shares?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Subtotal</CFormLabel>
            <CFormInput disabled value={formatDecimal(subTotal || 0)} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Transaction Fees</CFormLabel>
            <CFormInput disabled value={formatDecimal(sellerFees || 0)} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Regulatory Filing Fee</CFormLabel>
            <CFormInput disabled value={formatDecimal(sellerFlatFees || 0)} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Total Proceeds</CFormLabel>
            <CFormInput disabled value={formatDecimal(totalProceeds || 0)} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Trade ID</CFormLabel>
            <Select
                readOnly
                options={Option}
                watchValue={tradeId}
                query="trades"
                id={tradeId}
              />
          </div>
          <div className="col-md-6 d-flex align-items-end">
            <a
              href={`/sell_offers/${offerId}`}
              className="text-decoration-none text-white me-4"
            >
              <CButton>View Sell Offer</CButton>
            </a>
            {cashAccountId && (
              <a
                href={`/cash_accounts/${cashAccountId}`}
                className="text-decoration-none text-white"
              >
                <CButton>View Cash Account</CButton>
              </a>
            )}
          </div>
        </div>
        <h3>Cost Basis</h3>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <CFormLabel>Average Cost Per Share</CFormLabel>
            <CFormInput disabled value={formatDecimal(avgCostPerShare || 0)} />
          </div>
          <div className="col-md-4">
            <CFormLabel>Total Cost</CFormLabel>
            <CFormInput disabled value={formatDecimal(totalCost || 0)} />
          </div>
          <div className="col-md-4">
            <CFormLabel>Total Gain/Loss</CFormLabel>
            <CFormInput disabled value={formatDecimal(totalGain || 0)} />
          </div>
        </div>
        <div className="row g-3 mb-2 mt-2">
          <div className="col-md-4">
            <CFormLabel>Purchase Credits Earned</CFormLabel>
            <CFormInput disabled value={formatDecimal(purchaseCreditsEarned || 0)} />
          </div>
          <div className="col-md-4">
            <CFormLabel>Purchase Credits Expiration Date</CFormLabel>
            <CFormInput disabled value={formatToPT(purchaseCreditsExpirationDate, 'MM/DD/YYYY')} />
          </div>
        </div>
        <div>
          <h4>Buy Orders</h4>
          <GenericTable
            columns={SELL_ORDER_COLUMNS}
            items={buyOrders || []}
            handleClick={(item: any) =>
              history.push(`/orders/${item.buyOrderId}`)
            }
          />
        </div>
        <div>
          <h4>Transactions</h4>
          <GenericTable
            columns={transactionsColumns}
            items={transactions || []}
            handleClick={(item: any) =>
              history.push(`/transactions/${item.transactionId}`)
            }
          />
        </div>
        <div className="col-md-6">
          <div className="col-md-12 d-flex align-items-end justify-content-between">
            <div className="d-flex flex-column w-100 me-2">
              <h4>Notes</h4>
              <CFormLabel>Add Note</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                value={note}
                onChange={(e: any) => setNote(e.target.value)}
              />
            </div>
            <CButton disabled={!note} type="button" onClick={updateNote}>
              Add
            </CButton>
          </div>
          {fields && fields.length
            ? fields.map((note: any, i: number) => (
              <Note
                index={note.id}
                key={note.id}
                note={note}
                deleteNote={() => remove(i)}
              />
            ))
            : null}
        </div>
      </form>
    </>
  )
}

export default SellOrderDetailForm
