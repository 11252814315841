import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import PurchaseCreditsDetailForm from './PurchaseCreditsDetailForm'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import privateAPI from '../../APIs/private'

const getPurchaseCredit: any = async (id: number) => {
  const data = {
    creditTransactionId: { filterValues: [id] },
    createdAt: {},
    orderId: {},
    sellOrderId: {},
    amount: {},
    note: {},
    reason: {},
    expiresAt: {}
  }
  const response = await privateAPI.post('/queries/credit_transactions', data)
  return response?.data?.rows?.[0]
}

const updatePurchaseCredit: any = async (id: number, data: any) => {
  const response = await privateAPI.post(`/queries/credit_transactions/${id}`, data)
  return response.data
}

type PurchaseCreditsType = {
  amount: number
  createdAt: number
  creditTransactionId: string
  note: string
  expiresAt: number
  reason: string
  sellOrderId?: number
  orderId?: number
  userId: number
}

const PurchaseCreditsDetail: React.FC = () => {
  const { id }: any = useParams()
  const queryClient = useQueryClient()
  const methods = useForm()
  const { reset } = methods

  const { data: purchaseCreditsData, isInitialLoading } = useQuery({
    queryKey: ['purchase_credits', id],
    queryFn: () => getPurchaseCredit(id),
    enabled: +id > 0,
  })

  const handleUpdatePurchaseCredit = useMutation({
    mutationFn: async (data: any) => {
      if (data.note && data.note.length) {
        data.note.forEach((n: any) => delete n.updated)
      }
      data.note = JSON.stringify(data.note)
      await updatePurchaseCredit(id, data)
    },
    onSuccess: () => queryClient.invalidateQueries(['purchase_credits', id])
  })

  useEffect(() => {
    const data: PurchaseCreditsType | undefined =
      purchaseCreditsData || undefined
    if (data?.note && typeof data.note === 'string') {
      data.note = JSON.parse(data.note)
    }
    reset(data)
  }, [reset, purchaseCreditsData])


  const onSubmit = async (data: any) =>
    handleUpdatePurchaseCredit.mutate(data)

  if (isInitialLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <h2>{`Purchase Credits No. ${id}`}</h2>
        <PurchaseCreditsDetailForm
          id={id}
          onSubmit={onSubmit}
          btnLoading={handleUpdatePurchaseCredit.isLoading}
          data={purchaseCreditsData}
        />
      </FormProvider>
    </PageLayout>
  )
}

export default PurchaseCreditsDetail
