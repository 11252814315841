import React, { useEffect, useState } from 'react'
import {
  CButton,
  CFormCheck,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CTooltip,
} from '@coreui/react-pro'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import {
  getCustomizedListData,
  getListColumns,
  toggleCheckbox,
  toggleGroupFieldsCheckbox,
  toggleVisualizeReportCheckbox,
  updateChartType,
  updateAxis,
  initializeStore,
  getGraphData,
} from '../../../features/listsSlice'
import { RootState } from '../../../store'
import useQuery from '../../../hooks/useQuery'
import Spinner from '../../../components/Spinner'
import Filter from './Filter'
import _ from 'lodash'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import GroupFieldFilter from './GroupFieldFilter'
import history from '../../../history'
import moment from 'moment'

const Modal: any = ({ open, setOpen }: any) => {
  // HOOKS
  const query = useQuery()
  const dispatch = useAppDispatch()
  const {
    originalColumns,
    columns,
    modifiedColumns,
    groupFields,
    groupFieldsFilters,
    visualizeReport,
    chartType,
    xAxisColumns,
    yAxisColumns,
    xAxis,
    yAxis,
  } = useAppSelector((state: RootState) => state.list)

  // QUERY PARAMS
  const params = query.get('params')
  const queryName = query.get('queryName')
  const reportId: number = Number(query.get('reportId')) || 0
  const length: number = Number(query.get('length')) || 10
  const visible = query.get('visible')
  const x = query.get('xAxis')
  const y = query.get('yAxis')

  // LOCAL STATE
  const [loading, setLoading] = useState<boolean>(true)
  const [filters, setFilters] = useState<any>([])

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getListColumns({ queryName, params }))
      setLoading(false)
    }
    fetchData()
    //eslint-disable-next-line
  }, [queryName])

  useEffect(() => {
    dispatch(initializeStore({ visible, chartType, xAxis: x, yAxis: y }))
    //eslint-disable-next-line
  }, [])

  // update filters when query params contain default values
  useEffect(() => {
    if (params) {
      try {
        const fParams = JSON.parse(params)
        if (fParams && filters && filters.length === 0) {
          const arr: any = []
          for (const prop in fParams) {
            if (fParams[prop]?.filterValues) {
              originalColumns.forEach((col: any) => {
                if (col.name.toLowerCase() === prop.toLowerCase()) {
                  if (typeof fParams[prop]?.filterValues[0] === 'number') {
                    const data = fParams[prop].filterValues
                    for (let i = 0; i < data.length; i++) {
                      arr.push(...filters, {
                        ...col,
                        input: { filter: 'filterMax', value: data[i] },
                      })
                    }
                  } else {
                    let values = col.values
                    let OPTIONS = null
                    if (!values) {
                      values = fParams[prop]?.filterValues
                    }
                    if (
                      fParams[prop]?.filterValues[0] === 'true' ||
                      fParams[prop]?.filterValues[0] === 'false' ||
                      typeof fParams[prop]?.filterValues[0] === 'boolean'
                    ) {
                      OPTIONS = fParams[prop]?.filterValues[0]
                    } else {
                      values = values.map((val: any, i: number) => {
                        const obj = {
                          label: val,
                          value: val,
                          selected: fParams[prop].filterValues.includes(val),
                        }
                        return obj
                      })
                      OPTIONS = fParams[prop]?.filterValues.map(
                        (option: any, i: number) => {
                          if (typeof option === 'number') {
                            return {
                              name: col.name,
                              label: col.label,
                              value: option,
                              type: col.type,
                              input: { value: option, filter: 'filterMax' },
                            }
                          } else {
                            if (option) {
                              return {
                                label: option,
                                value: option,
                                selected:
                                  fParams[prop].filterValues.includes(option),
                              }
                            } else {
                              return {}
                            }
                          }
                        },
                      )
                    }
                    arr.push(...filters, {
                      name: col.name,
                      label: col.label,
                      value: col.value,
                      type: col.type,
                      values,
                      input: OPTIONS,
                    })
                  }
                }
              })
            }
            if (
              fParams[prop]?.filterIsEqual &&
              fParams[prop]?.filterMin &&
              fParams[prop]?.filterMax
            ) {
              originalColumns.forEach((col) => {
                if (col.name.toLowerCase() === prop.toLowerCase()) {
                  arr.push(...filters, {
                    name: col.name,
                    label: col.label,
                    value: col.value,
                    type: col.type,
                    input: {
                      value: fParams[prop]?.filterIsEqual,
                      filter: 'filterIsEqual',
                    },
                    filterIsNull: !!fParams[prop].filterIsNull,
                  })
                }
              })
            } else if (fParams[prop]?.filterMin && fParams[prop]?.filterMax) {
              originalColumns.forEach((col) => {
                if (col.name.toLowerCase() === prop.toLowerCase()) {
                  arr.push(...filters, {
                    name: col.name,
                    label: col.label,
                    value: col.value,
                    type: col.type,
                    input: {
                      filterMax: fParams[prop]?.filterMax,
                      filterMin: fParams[prop]?.filterMin,
                    },
                    filterIsNull: !!fParams[prop].filterIsNull,
                  })
                }
              })
            } else if (fParams[prop]?.filterMin && !fParams[prop]?.filterMax) {
              originalColumns.forEach((col) => {
                if (col.name.toLowerCase() === prop.toLowerCase()) {
                  if (col.type === 'Date') {
                    arr.push(...filters, {
                      name: col.name,
                      label: col.label,
                      value: col.value,
                      type: col.type,
                      input: {
                        filterMin: fParams[prop]?.filterMin,
                        filter: 'filterMin',
                      },
                      filterIsNull: !!fParams[prop].filterIsNull,
                    })
                  } else {
                    arr.push(...filters, {
                      name: col.name,
                      label: col.label,
                      value: col.value,
                      type: col.type,
                      input: {
                        value: fParams[prop]?.filterMin,
                        filter: 'filterMin',
                      },
                      filterIsNull: !!fParams[prop].filterIsNull,
                    })
                  }
                }
              })
            } else if (fParams[prop]?.filterMax && !fParams[prop]?.filterMin) {
              originalColumns.forEach((col) => {
                if (col.name.toLowerCase() === prop.toLowerCase()) {
                  if (col.type === 'Date') {
                    arr.push(...filters, {
                      name: col.name,
                      label: col.label,
                      value: col.value,
                      type: col.type,
                      input: {
                        filterMax: fParams[prop]?.filterMax,
                        filter: 'filterMax',
                      },
                      filterIsNull: !!fParams[prop].filterIsNull,
                    })
                  } else {
                    arr.push(...filters, {
                      name: col.name,
                      label: col.label,
                      value: col.value,
                      type: col.type,
                      input: {
                        value: fParams[prop]?.filterMax,
                        filter: 'filterMax',
                      },
                      filterIsNull: !!fParams[prop].filterIsNull,
                    })
                  }
                }
              })
            }
            if (fParams[prop]?.filterDateFunction) {
              originalColumns.forEach((col) => {
                if (col.name.toLowerCase() === prop.toLowerCase()) {
                  arr.push(...filters, {
                    name: col.name,
                    label: col.label,
                    value: col.value,
                    type: col.type,
                    input: {
                      filterDateFunction: fParams[prop]?.filterDateFunction,
                      filterDateNumber: fParams[prop]?.filterDateNumber,
                    },
                  })
                }
              })
            }
            if (fParams[prop]?.filterLike || fParams[prop].filterIsNull) {
              originalColumns.forEach((col) => {
                if (col.name.toLowerCase() === prop.toLowerCase()) {
                  if (col.type === 'String') {
                    arr.push(...filters, {
                      name: col.name,
                      label: col.label,
                      value: col.value,
                      type: col.type,
                      input: fParams[prop]?.filterLike?.replaceAll('%', ''),
                      filterIsNull: !!fParams[prop].filterIsNull,
                    })
                  } else {
                    arr.push(...filters, {
                      name: col.name,
                      label: col.label,
                      value: col.value,
                      type: col.type,
                      input: {},
                      filterIsNull: !!fParams[prop].filterIsNull,
                    })
                  }
                }
              })
            } else if (fParams[prop]?.filterValuesNot) {
              const values = fParams[prop]?.filterValuesNot
              let inputVal = ''
              if (values) {
                inputVal = values.join(',')
              }
              originalColumns.forEach((col) => {
                if (col.name.toLowerCase() === prop.toLowerCase()) {
                  if (col.type === 'String') {
                    arr.push(...filters, {
                      name: col.name,
                      label: col.label,
                      value: col.value,
                      type: col.type,
                      input: inputVal,
                      filterValuesNotChecked: true,
                    })
                  } else if (col.type === 'Number') {
                    arr.push(...filters, {
                      name: col.name,
                      label: col.label,
                      value: col.value,
                      type: col.type,
                      input: {
                        filter: 'filterValuesNotChecked',
                        value: inputVal,
                        filterValuesNotChecked: true,
                      },
                    })
                  }
                }
              })
            }
          }
          if (!_.isEqual(filters, arr)) {
            setFilters([...filters, ...arr])
          }
        }
      } catch (e) {
        console.log('UNABLE TO PARSE QUERY PARAMS: --- MODAL USE EFFECT', e)
      }
    }
    //eslint-disable-next-line
  }, [params, originalColumns])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const object = filters.reduce((obj: any, item: any) => {
        const data: any = {}
        switch (item.type) {
          case 'String': {
            if (item.values && item.values.length) {
              data[item.name] = {
                filterValues: item.input.map(
                  (option: any, i: number) => option.value,
                ),
              }
            } else {
              if (item.filterValuesNotChecked) {
                const values = item?.input?.split(',')
                data[item.name] = {
                  filterValuesNot: values,
                }
              } else if (item.input || item.filterIsNull) {
                data[item.name] = {
                  filterLike: item.input ? `%${item.input}` : null,
                  filterIsNull: !!item.filterIsNull,
                }
              } else {
                data[item.name] = {}
              }
            }
            break
          }
          case 'Number': {
            if (item.filterIsNull) {
              data[item.name] = { filterIsNull: true }
            } else if (item?.input?.filter === 'filterIsEqual') {
              data[item.name] = {
                filterMin: item?.input?.value,
                filterMax: item?.input?.value,
                filterIsEqual: item?.input?.value,
              }
            } else if (item?.input?.filter === 'filterValuesNotChecked') {
              const values = item?.input?.value
                ?.replace(/ /g, '')
                ?.split(',')
                ?.map((v: string) => parseInt(v))
              data[item.name] = {
                filterValuesNot: values,
              }
            } else {
              data[item.name] = {
                [item.input.filter]: item.input.value,
                filterIsNull: !!item.filterIsNull,
              }
            }
            break
          }
          case 'Date': {
            if (item && item.input) {
              if (item.input?.filterMin && item.input?.filterMax) {
                let start = item.input?.filterMin
                let end = item.input?.filterMax
                if (typeof start === 'string' || typeof end === 'string') {
                  start = moment(start).utc().add(8, 'hours')
                  end = moment(end).utc()
                }
                const startDay = moment(start.toString())
                  .startOf('day')
                  .toString()
                const endDay = moment(end.toString()).endOf('day').toString()
                data[item.name] = {
                  filterMin: moment(startDay).utcOffset(0, true),
                  filterMax: moment(endDay).utcOffset(0, true),
                }
              } else {
                if (item.filterIsNull) {
                  data[item.name] = { filterIsNull: true, type: 'Date' }
                } else {
                  data[item.name] = { ...item.input }
                }
              }
            } else {
              data[item.name] = { ...item.input }
            }
            break
          }
          case 'Boolean': {
            data[item.name] = { filterValues: [item.input] }
            break
          }
        }
        return { ...obj, ...data }
      }, {})
      const fParams = JSON.parse(params)
      for (const key of columns) {
        if (
          object[key.name] !== undefined &&
          object[key.name] !== fParams[key.name]
        ) {
          if (
            typeof fParams[key.name]?.sortIndex !== 'undefined' &&
            typeof fParams[key.name]?.sortAsc !== 'undefined'
          ) {
            object[key.name].sortIndex = fParams[key.name].sortIndex
            object[key.name].sortAsc = fParams[key.name].sortAsc
          }
          fParams[key.name] = undefined
        } else {
          object[key.name] = undefined
          if (fParams[key.name]) {
            fParams[key.name].filterMin = undefined
            fParams[key.name].filterMax = undefined
            fParams[key.name].filterValues = undefined
            fParams[key.name].filterDateFunction = undefined
            fParams[key.name].filterDateNumber = undefined
            fParams[key.name].filterLike = undefined
            fParams[key.name].filterIsNull = undefined
            fParams[key.name].filterValuesNot = undefined
          }
        }
        object[key.name] = { ...fParams[key.name], ...object[key.name] }
      }
      if (fParams?.freeTextSearch) {
        object.freeTextSearch = fParams.freeTextSearch
      }
      if (groupFields) {
        object.groupFields = true
        if (groupFieldsFilters && groupFieldsFilters.length) {
          for (let i = 0; i < groupFieldsFilters.length; i++) {
            if (object[groupFieldsFilters[i].name]) {
              if (groupFieldsFilters[i].type === 'Number') {
                if (groupFieldsFilters[i]?.value === 'NONE') {
                  object[groupFieldsFilters[i].name].groupByFunction = undefined
                  object[groupFieldsFilters[i].name].groupByNone =
                    groupFieldsFilters[i].value
                } else {
                  object[groupFieldsFilters[i].name].groupByNone = undefined
                  object[groupFieldsFilters[i].name].groupByFunction =
                    groupFieldsFilters[i].value
                }
              } else {
                object[groupFieldsFilters[i].name].truncateDateFunction =
                  groupFieldsFilters[i].value
              }
            } else {
              object[groupFieldsFilters[i].name] = {}
              if (groupFieldsFilters[i].type === 'Number') {
                if (groupFieldsFilters[i]?.value === 'NONE') {
                  object[groupFieldsFilters[i].name].groupByFunction = undefined
                  object[groupFieldsFilters[i].name].groupByNone =
                    groupFieldsFilters[i].value
                } else {
                  object[groupFieldsFilters[i].name].groupByNone = undefined
                  object[groupFieldsFilters[i].name].groupByFunction =
                    groupFieldsFilters[i].value
                }
              } else {
                object[groupFieldsFilters[i].name].truncateDateFunction =
                  groupFieldsFilters[i].value
              }
            }
          }
        }
      }

      if (visualizeReport) {
        object.chartType = chartType
        if (!xAxis || !yAxis) {
          setLoading(false)
          return alert('Both axis are required.')
        }
        const { payload }: any = await dispatch(
          getGraphData({
            queryName,
            columns: modifiedColumns,
            length,
            params: object,
            visible,
            type: chartType,
            xAxis: JSON.stringify(xAxis),
            yAxis: JSON.stringify(yAxis),
          }),
        )

        history.replace(
          `/list?queryName=${queryName}&reportId=${reportId}&current=${0}&start=${0}&length=${1000}&params=${encodeURIComponent(
            JSON.stringify(payload.params),
          )}&visible=${visualizeReport}&chartType=${chartType}&xAxis=${encodeURIComponent(
            JSON.stringify(xAxis),
          )}&yAxis=${encodeURIComponent(JSON.stringify(yAxis))}`,
        )
      } else {
        await dispatch(
          getCustomizedListData({
            queryName,
            columns: modifiedColumns,
            length,
            reportId,
            params: object,
            visible,
            type: chartType,
          }),
        )
      }
    } catch (e) {
      console.log('ERROR PARSING: HANDLE SUBMIT', e)
    }
    setLoading(false)
    setOpen(false)
  }

  const handleVisualChange = () => {
    dispatch(toggleVisualizeReportCheckbox())
  }

  const handleChartChange = (value: string) => {
    dispatch(updateChartType(value))
  }

  if (loading) {
    return (
      <CModal visible alignment="center">
        <CModalBody>
          <Spinner />
        </CModalBody>
      </CModal>
    )
  }

  return (
    <CModal visible={open} alignment="center" onClose={setOpen} size="xl">
      <CModalHeader>Customize</CModalHeader>
      <CModalBody>
        <h3>Columns</h3>
        <div className="list-grid mb-2">
          {originalColumns && originalColumns.length
            ? originalColumns.map((column: any, i: number) => (
                <CTooltip key={i} content={column.label}>
                  <div className="form-check list-grid-item hover">
                    <input
                      className="form-check-input hover"
                      type="checkbox"
                      defaultChecked={
                        columns && columns.length
                          ? !!columns.find(
                              (col: any) => col.name === column.name,
                            )
                          : false
                      }
                      key={i}
                      onChange={(e: any) =>
                        dispatch(
                          toggleCheckbox({ column, status: e.target.checked }),
                        )
                      }
                      id={column.label}
                    />
                    <label
                      className="form-check-label w-100 hover"
                      htmlFor={column.label}
                    >
                      {column.label}
                    </label>
                  </div>
                </CTooltip>
              ))
            : null}
        </div>
        <div className="d-flex align-items-center">
          <h3 className="me-2">Filters</h3>
          <CButton
            size="sm"
            className="rounded-circle"
            onClick={() => setFilters([...filters, {}])}
          >
            <CIcon icon={cilPlus} height={15} width={15} />
          </CButton>
        </div>
        {filters && filters.length
          ? filters.map((filter: any, i: number) => (
              <Filter
                key={i}
                index={i}
                filter={filter}
                options={originalColumns}
                filters={filters}
                setFilters={setFilters}
              />
            ))
          : null}
        <div className="d-flex mt-2">
          <div className="w-50">
            <CFormCheck
              defaultChecked={groupFields}
              onChange={() => dispatch(toggleGroupFieldsCheckbox(!groupFields))}
              label="Group Fields"
            />
            {groupFields && groupFieldsFilters && groupFieldsFilters.length
              ? groupFieldsFilters.map((field: any, i: number) => (
                  <GroupFieldFilter key={i} field={field} />
                ))
              : null}
          </div>
          <div className="w-50">
            {groupFields && (
              <CFormCheck
                defaultChecked={visualizeReport}
                onChange={() => handleVisualChange()}
                label="Visualize Report"
              />
            )}
            {groupFields && visualizeReport ? (
              <>
                <div className="mt-2">
                  <CFormLabel className="me-2 mt-1 w-25">Chart Type</CFormLabel>
                  <CFormSelect
                    value={chartType || 'bar'}
                    onChange={(e: any) => handleChartChange(e.target.value)}
                    className="w-25"
                  >
                    <option value="bar">Bar</option>
                    <option value="pie">Pie</option>
                  </CFormSelect>
                </div>
                <div className="mt-2">
                  <CFormLabel className="me-2 mt-1 w-25">X-Axis</CFormLabel>
                  <CFormSelect
                    value={
                      xAxisColumns.find(
                        (column: any) => column.name === xAxis?.name,
                      )?.name
                    }
                    onChange={(e: any) => {
                      const item = xAxisColumns.find(
                        (column: any) => column.name === e.target.value,
                      )
                      dispatch(
                        updateAxis({
                          type: 'xAxis',
                          item,
                        }),
                      )
                    }}
                    className="w-75"
                  >
                    {xAxisColumns && xAxisColumns.length
                      ? xAxisColumns.map((column: any, i: number) => (
                          <option key={i} value={column.name}>
                            {column.label}
                          </option>
                        ))
                      : null}
                  </CFormSelect>
                </div>
                <div className="mt-2">
                  <CFormLabel className="me-2 mt-1 w-25">Y-Axis</CFormLabel>
                  <CFormSelect
                    value={yAxis?.name}
                    onChange={(e: any) => {
                      const item = yAxisColumns.find(
                        (column: any) => column.name === e.target.value,
                      )
                      dispatch(
                        updateAxis({
                          type: 'yAxis',
                          item,
                        }),
                      )
                    }}
                    className="w-75"
                  >
                    {yAxisColumns && yAxisColumns.length
                      ? yAxisColumns.map((column: any, i: number) => (
                          <option key={i} value={column.name}>
                            {column.label}
                          </option>
                        ))
                      : null}
                  </CFormSelect>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton onClick={handleSubmit}>Save</CButton>
      </CModalFooter>
    </CModal>
  )
}

export default Modal
