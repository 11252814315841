import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
interface SideBarState {
  open: boolean
}

const initialState: SideBarState = {
  open: true,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggle: (state) => {
      state.open = !state.open
    },
  },
})

export const { toggle } = sidebarSlice.actions

export const isOpen = (state: RootState): boolean => state.sidebar.open

export default sidebarSlice.reducer
