import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import publicAPI from '../APIs/public'
import privateAPI from '../APIs/private'
import history from '../history'
import { globalErrorHandler } from './commonSlice'

interface UserState {
  firstName: string
  lastName: string
  error: string
  isLoggedIn: boolean
  isBrokerDealer: boolean
  isCustomerSupport: boolean
  internalServerError: boolean
  loading: boolean
  publicReports: any[]
  favoriteReports: any[]
  queries: any[]
}

const initialState: UserState = {
  firstName: '',
  lastName: '',
  error: '',
  isLoggedIn: false,
  isBrokerDealer: false,
  isCustomerSupport: false,
  internalServerError: false,
  loading: false,
  publicReports: [],
  favoriteReports: [],
  queries: [],
}

interface SignInParams {
  data: {
    username: string
    password: string
    otpToken: string
  }
  history: any
}

export const signIn = createAsyncThunk(
  'signIn',
  async ({ data }: SignInParams, { rejectWithValue }) => {
    try {
      const response = await publicAPI.post('/signIn', data)
      history.push('/dashboard')
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const signOut = createAsyncThunk(
  'signOut',
  async ({ history }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post('/signOut')
      localStorage.removeItem('token')
      history.push('/login')
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getProfile = createAsyncThunk(
  'getProfile',
  async (_, { dispatch }) => {
    try {
      const response = await privateAPI.get('/me')
      return response.data
    } catch (e) {
      console.log('ERROR GET PROFILE: ', e)
    }
  },
)

export const getPublicReports = createAsyncThunk(
  'getPublicReports',
  async (_, { dispatch }) => {
    try {
      const response = await privateAPI.get('/reports')
      return response.data
    } catch (e) {
      console.log('ERROR GET REPORTS: ', e)
      dispatch(globalErrorHandler())
    }
  },
)

export const getQueries = createAsyncThunk(
  'getQueries',
  async (_, { dispatch }) => {
    try {
      const response = await privateAPI.get('/queries')
      return response.data
    } catch (e) {
      console.log('error getting queries: ', e)
      dispatch(globalErrorHandler())
    }
  },
)

export const toggleFavoriteReport = createAsyncThunk(
  'addFavoriteReport',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.post('/reports/favorite', payload)
      return { ...payload, ...response.data }
    } catch (e) {
      console.log('ERROR TOGGLING FAVORITE REPORTS: ', e)
      return rejectWithValue(e)
    }
  },
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state, action) => {
        state.loading = true
      })
      .addCase(signIn.fulfilled, (state, { payload }: any) => {
        if (payload) {
          const { firstName, lastName, isBrokerDealer, isCustomerSupport } =
            payload
          state.firstName = firstName
          state.lastName = lastName
          state.isBrokerDealer = isBrokerDealer
          state.isCustomerSupport = isCustomerSupport
          state.loading = false
          state.isLoggedIn = true
          state.error = ''
        }
      })
      .addCase(signIn.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
        state.error =
          'There was an error with the credentials. Please try again.'
      })
      .addCase(getProfile.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getProfile.fulfilled, (state, { payload }: any) => {
        if (payload) {
          const { firstName, lastName, isBrokerDealer, isCustomerSupport } =
            payload
          state.firstName = firstName
          state.lastName = lastName
          state.isBrokerDealer = isBrokerDealer
          state.isCustomerSupport = isCustomerSupport
          state.loading = false
          state.isLoggedIn = true
        } else {
          state.loading = false
        }
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.loading = false
        state.firstName = ''
        state.lastName = ''
        state.isBrokerDealer = false
        state.isCustomerSupport = false
        state.isLoggedIn = false
        state.loading = false
      })
      .addCase(signOut.pending, (state) => {
        state.loading = true
      })
      .addCase(signOut.fulfilled, (state) => {
        state.firstName = ''
        state.lastName = ''
        state.error = ''
        state.isLoggedIn = false
        state.isBrokerDealer = false
        state.loading = false
      })
      .addCase(signOut.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(getPublicReports.fulfilled, (state, action) => {
        state.publicReports = [...action.payload]
      })
      .addCase(getQueries.fulfilled, (state, { payload }: any) => {
        if (payload) {
          // set favorite reports after API queries are returned
          let favoriteReports: any[] = []
          payload.forEach((query: any) => {
            if (query.reports && query.reports.length) {
              query.reports.forEach((report: any) => {
                if (report.isFavorite) {
                  // include queryName to handle dropdown filter on Dashboard page
                  favoriteReports = [
                    ...favoriteReports,
                    { ...report, queryName: query.queryName },
                  ]
                }
              })
            }
          })
          state.favoriteReports = [...favoriteReports]
          state.queries = payload
        }
      })
      .addCase(getQueries.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      // set favorite reports based on response from api
      .addCase(toggleFavoriteReport.fulfilled, (state, { payload }: any) => {
        if (payload) {
          if (payload.isFavorite) {
            state.favoriteReports = [...state.favoriteReports, { ...payload }]
          } else {
            const favoriteReports = state.favoriteReports
            const idx = favoriteReports.findIndex(
              (report: any) => report.reportId === payload.reportId,
            )
            if (idx >= 0) {
              favoriteReports.splice(idx, 1)
              state.favoriteReports = [...favoriteReports]
            }
          }
        }
      })
  },
})

export default userSlice.reducer
