import React, { useCallback, useMemo, useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CLoadingButton,
} from '@coreui/react-pro'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import history from '../../history'
import CreateOffer from './CreateOffer'
import WarningModal from '../../components/WarningModal'
import Alert from '../../components/Alert'
import {
  deleteOffer,
  getOffer,
  resetError,
  saveOffer,
} from '../../features/offersSlice'
import Select from '../assets/components/Select'
import { debounce } from 'lodash'
import { getAsset, getAssets } from '../../features/assetsSlice'
import { components, OptionProps } from 'react-select'

const Option = (props: OptionProps) => {
  const {
    data: { assetId, value, companyName },
  } = props || {}
  return (
    <components.Option {...props}>
      {companyName} - {value} (id: {assetId})
    </components.Option>
  )
}

const OfferDetail: React.FC = () => {
  const { id }: any = useParams()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { offer, error, errorMessage } = useAppSelector(
    (state: RootState) => state.offers,
  )
  const { asset } = useAppSelector((state: RootState) => state.assets)
  const methods = useForm()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
    setValue,
    watch,
  } = methods

  const watchCompanyName = watch('companyName', asset.companyName)
  const watchAssetName = watch('assetName', '')
  const watchAssetId = watch('assetId', '')

  useEffect(() => {
    const getEntityById = async () => {
      if (+id !== -1) {
        dispatch(resetError())
        const response = await dispatch(getOffer({ id }))
        if (response.payload && response.payload.rows[0]) {
          await dispatch(getAsset({ id: response.payload.rows[0].assetId }))
        }
      }
      setLoading(false)
    }
    getEntityById()
  }, [id, dispatch])

  useEffect(() => {
    reset(offer)
  }, [offer, reset])

  useEffect(() => {
    setValue('companyName', asset.companyName)
    setValue('companyId', asset.companyId)
    setValue('assetName', asset.name)
    setValue('assetId', asset.assetId)
  }, [asset, setValue])

  const fetchAssetOptions = useCallback(
    async (e: string) => {
      const data = {
        queryParams: JSON.stringify({
          assetId: {},
          companyId: {},
          companyName: {},
          name: {},
          freeTextSearch: e,
          length: 10,
        }),
      }
      if (e.length > 0) {
        const response = await dispatch(getAssets({ found: data }))
        const arr = response.payload?.rows || []
        if (arr && arr.length) {
          const a = arr.map((a: any) => {
            return {
              assetId: a.assetId,
              companyId: a.companyId,
              companyName: a.companyName,
              name: a.name,
              value: a.name,
              label: a.name,
            }
          })
          return a
        }
      }
    },
    [dispatch],
  )

  const loadAssetOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchAssetOptions(inputValue).then((options: any) => callback(options))
      }, 500),
    [fetchAssetOptions],
  )

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    const res = await dispatch(saveOffer({ id, data: { ...data } }))
    if (res && res.payload === 200) {
      await dispatch(getOffer({ id }))
      dispatch(resetError())
    }
    setBtnLoading(false)
  }

  const handleDeleteOffer = async () => {
    setLoading(true)
    const res: any = await dispatch(deleteOffer({ id }))
    if (res && res.payload === 200) {
      alert('Offer has been deleted')
      history.goBack()
    } else {
      setDeleteModal(false)
    }
    setLoading(false)
  }

  const handleChange = (value: any) => {
    if (value) {
      setValue('companyName', value.companyName, { shouldDirty: true })
      setValue('companyId', value.companyId, { shouldDirty: true })
      setValue('assetName', value.value, { shouldDirty: true })
      setValue('assetId', value.assetId, { shouldDirty: true })
    }
  }

  const create = () => {
    dispatch(resetError())
    history.push('/offers/-1')
  }

  const {
    companyName,
    isActive,
    offerId,
    sharePrice10k,
    sharePrice25k,
    sharePrice50k,
    sharePrice100k,
    sharePrice200k,
    shares,
    sharesAvailable,
    sharesCompleted,
    sharesPending,
  } = offer || {}

  if (+id === -1) {
    return <CreateOffer loadAssetOptions={loadAssetOptions} />
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      {error && errorMessage && (
        <Alert
          color="danger"
          visible={error}
          onClose={() => dispatch(resetError())}
          text={errorMessage}
        />
      )}
      {deleteModal && (
        <WarningModal
          header={`Delete offer ${companyName}?`}
          body="Performing this action will permanently erase this offer."
          proceed="Yes"
          abandon="No"
          proceedAction={() => handleDeleteOffer()}
          abandonAction={() => setDeleteModal(false)}
          open={deleteModal}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between">
            <h3>
              Offer No.{offerId} - {companyName}
            </h3>
            <div className="d-flex">
              <CButton className="btn-primary mx-2" onClick={() => create()}>
                Create
              </CButton>
              {/* <CButton
                className="btn-danger mx-2"
                onClick={() => setDeleteModal(true)}
              >
                Delete
              </CButton> */}
              <CLoadingButton
                disabled={!isDirty}
                loading={btnLoading}
                className="btn-success mx-2"
                type="submit"
              >
                Save
              </CLoadingButton>
            </div>
          </div>
          <h4>Offer Information</h4>
          <div className="row g-3 mb-4 align-items-end">
            <div className="col-md-3">
              <CFormLabel className="form-label">Asset</CFormLabel>
              <Select
                options={Option}
                loadSuggestedOptions={loadAssetOptions}
                handleChange={(value: any) => handleChange(value)}
                watchValue={`${watchCompanyName} - ${watchAssetName}`}
                query="assets"
                id={watchAssetId}
                {...register('asset')}
                ref={null}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel className="form-label">Shares</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('shares')}
                defaultValue={shares || ''}
              />
            </div>
            <div style={{ width: 'auto' }}>
              <div className="form-check hover">
                <input
                  disabled={btnLoading}
                  className="form-check-input hover"
                  type="checkbox"
                  {...register('isActive')}
                  defaultChecked={isActive}
                  id="isActive"
                />
                <label className="form-check-label hover" htmlFor={'isActive'}>
                  Is Active
                </label>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 10K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice10k')}
                defaultValue={sharePrice10k || ''}
                type="number"
                step=".01"
              />
            </div>
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 25K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice25k')}
                defaultValue={sharePrice25k || ''}
                type="number"
                step=".01"
              />
            </div>
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 50K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice50k')}
                defaultValue={sharePrice50k || ''}
                type="number"
                step=".01"
              />
            </div>
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 100K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice100k')}
                defaultValue={sharePrice100k || ''}
                type="number"
                step=".01"
              />
            </div>
            <div className="col-sm">
              <CFormLabel className="form-label">Share Price 200K</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('sharePrice200k')}
                defaultValue={sharePrice200k || ''}
                type="number"
                step=".01"
              />
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel className="form-label">Shares Available</CFormLabel>
              <CFormInput
                disabled
                {...register('sharesAvailable')}
                defaultValue={sharesAvailable || ''}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel className="form-label">Shares Completed</CFormLabel>
              <CFormInput
                disabled
                {...register('sharesCompleted')}
                defaultValue={sharesCompleted || ''}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel className="form-label">Shares Pending</CFormLabel>
              <CFormInput
                disabled
                {...register('sharesPending')}
                defaultValue={sharesPending || ''}
              />
            </div>
          </div>
          <div className="col g-3 mb-4"></div>
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default OfferDetail
