import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'

// Define a type for the slice state
interface CommonState {
  countries: string[]
  globalError: boolean
  globalErrorMessage: string
}

// Define the initial state using that type
const initialState: CommonState = {
  countries: [],
  globalError: false,
  globalErrorMessage: '',
}

export const getCountries = createAsyncThunk('getCountries', async () => {
  try {
    const res = await privateAPI.get('/countries')
    if (res && res.data) {
      return res.data
    }
    return []
  } catch (e) {
    console.log('ERROR GETTING COUNTRIES')
  }
})

export const commonSlice = createSlice({
  name: 'common',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    globalErrorHandler: (state) => {
      state.globalError = true
      state.globalErrorMessage =
        'There was an error. Please refresh the page or try again later.'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, { payload }: any) => {
      state.countries = payload
    })
  },
})

export const { globalErrorHandler } = commonSlice.actions

// Other code such as selectors can use the imported `RootState` type

export default commonSlice.reducer
