// This is user search query base object. Whenever we have a user search dropdown, we should use this as a base query. In production, we have a lot of user, if we don't use this base query, it will take a lot of time to load the dropdown.
export const fastUserSearchQuery = {
  includeTotals: false,
  userId: {
    freeTextSearchType: 'EXACT'
  },
  firstName: {
    freeTextSearchType: 'STARTS_WITH'
  },
  lastName: {
    freeTextSearchType: 'STARTS_WITH'
  },
  fullName: {
    freeTextSearchType: 'STARTS_WITH'
  },
  email: {
    freeTextSearchType: 'STARTS_WITH'
  },
  current: 0,
  length: 10,
  start: 0
}