import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'

type RIAFirm = {
  riaFirmId?: number
  riaFirmName?: string
}

interface RIAState {
  riaFirms: RIAFirm[]
  loading: boolean
  riaFirm: any
}

const initialState: RIAState = {
  riaFirms: [],
  loading: false,
  riaFirm: null,
}

export const getRIAFirms = createAsyncThunk(
  'getRIAFirms',
  async ({ found }: any) => {
    const params = found.queryParams
    try {
      const response = await privateAPI.post(
        '/queries/ria_firms',
        JSON.parse(params),
      )
      return response.data
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
)

export const getRIAFirm = createAsyncThunk(
  'getRIAFirm',
  async ({ id }: any) => {
    try {
      const response = await privateAPI.get(`/queries/ria_firms/${id}`)
      return response.data
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
)

export const updateRIAFirm = createAsyncThunk(
  'updateRIAFirm',
  async ({ id, data }: any) => {
    try {
      await privateAPI.post(`/queries/ria_firms/${id}`, data)
      return 200
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
)

export const createRIAFirm = createAsyncThunk(
  'createRIAFirm',
  async ({ data }: any) => {
    try {
      await privateAPI.post('/queries/ria_firms/-1', data)
      return 200
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
)

export const getRIALinkedAdvisors = createAsyncThunk(
  'getRIALinkedAdvisors',
  async ({ id }: any, { rejectWithValue }) => {
    const obj = {
      riaFirmId: { filterMin: id, filterMax: id },
      fullName: {},
      userId: {},
      riaUserStatus: {},
    }
    try {
      const response = await privateAPI.post(`/queries/users`, obj)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const riaSlice = createSlice({
  name: 'ria',
  initialState,
  reducers: {
    resetRIAFirm: (state) => {
      state.riaFirm = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRIAFirms.pending, (state, _) => {
        state.loading = true
      })
      .addCase(getRIAFirms.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.riaFirms = payload
        }
        state.loading = false
      })
      .addCase(getRIAFirms.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(getRIAFirm.fulfilled, (state, { payload }: any) => {
        state.riaFirm = payload?.rows[0]
      })
      .addCase(getRIAFirm.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(getRIALinkedAdvisors.fulfilled, (state, { payload }: any) => {
        state.riaFirm = { ...state.riaFirm, linkedAdvisors: payload?.rows }
      })
  },
})

export const { resetRIAFirm } = riaSlice.actions

export default riaSlice.reducer
