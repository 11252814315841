import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import {
  CFormInput,
  CFormLabel,
} from '@coreui/react-pro'
import { formatToPT, formatTableRenderers } from '../../utils/formatUtils/utils'
import { useQuery } from '@tanstack/react-query'
import privateAPI from '../../APIs/private'
import GenericTable from '../../components/GenericTable'

const getRecurringInvestment: any = async (id: number) => {
  const data = {
    recurringEventId: { filterValues: [id] },
    entityId: {},
    startDate: {},
    frequency: {},
    isActive: {},
    nextOccurance: {}
  }
  const response = await privateAPI.post('/queries/RECURRING_EVENTS', data)
  return response?.data?.rows?.[0]
}

const getRecurringEventActions = async (id: number) => {
  const data = {
    recurringEventId: { filterValues: [id] },
    recurringEventActionId: {},
    companyId: {},
    companyName: {},
    maxAmount: {},
    actionType: {},
    cashExternalAccountId: {},
    accountName: {},
  }

  const response = await privateAPI.post('/queries/RECURRING_EVENT_ACTIONS', data)
  const { rows, columns } = formatTableRenderers(
    response?.data?.rows,
    response?.data?.columns,
  )
  return { actionRows: rows, actionColumns: columns }
}

const getRecurringEventLogs = async (id: number) => {
  const data = {
    recurringEventId: { filterValues: [id] },
    recurringEventLogId: {},
    recurringEventActionId: {},
    status: {},
    detail: {},
  }
  const response = await privateAPI.post('/queries/RECURRING_EVENT_LOGS', data)
  const { rows, columns } = formatTableRenderers(
    response?.data?.rows,
    response?.data?.columns,
  )
  return { logRows: rows, logColumns: columns }
}

type RecurringInvestmentsType = {
  frequency: string
  isActive: boolean
  nextOccurence: number
  recurringEventId: string
  startDate: number
  entityId: string
}

const RecurringInvestmentsDetail: React.FC = () => {
  const { id }: any = useParams()
  const methods = useForm()
  const { reset } = methods

  const { data: RecurringInvestmentsData, isInitialLoading } = useQuery({
    queryKey: ['recurring_investment', id],
    queryFn: () => getRecurringInvestment(id),
    enabled: +id > 0,
  })

  const { data: RecurringInvestmentActions, isInitialLoading: actionsLoading } = useQuery({
    queryKey: ['recurring_investment_actions', id],
    queryFn: () => getRecurringEventActions(id),
    enabled: +id > 0,
  })

  const { data: RecurringInvestmentLogs, isInitialLoading: logLoading } = useQuery({
    queryKey: ['recurring_investment_logs', id],
    queryFn: () => getRecurringEventLogs(id),
    enabled: +id > 0,
  })

  useEffect(() => {
    const data: RecurringInvestmentsType | undefined =
      RecurringInvestmentsData || undefined
    const dataLogs = RecurringInvestmentLogs || undefined
    const dataActions = RecurringInvestmentActions || undefined
    reset(data)
    reset(dataLogs)
    reset(dataActions)
  }, [reset, RecurringInvestmentsData, RecurringInvestmentLogs, RecurringInvestmentActions])


  if (isInitialLoading || actionsLoading || logLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  const { entityId, isActive, frequency, startDate, nextOccurance } = RecurringInvestmentsData || {}
  const { logRows, logColumns } = RecurringInvestmentLogs || {}
  const { actionRows, actionColumns } = RecurringInvestmentActions || {}
  return (
    <PageLayout>
      <FormProvider {...methods}>
        <h2>{`Recurring Event No. ${id}`}</h2>
        <>
          <form>
            <div>
              <div className="row mb-4">
                <div className="col-md-3">
                  <CFormLabel className="form-label">Entity ID</CFormLabel>
                  <CFormInput disabled value={entityId || 'Individual'} />
                </div>
                <div className="col-md-3">
                  <CFormLabel className="form-label">Start Date </CFormLabel>
                  <CFormInput disabled value={formatToPT(startDate, 'MM/DD/YYYY')} />
                </div>
                <div className="col-md-3">
                  <CFormLabel className="form-label">Frequency</CFormLabel>
                  <CFormInput disabled value={frequency} />
                </div>
                <div className="col-md-3">
                  <CFormLabel className="form-label">Next Occurence</CFormLabel>
                  <CFormInput disabled value={formatToPT(nextOccurance, 'MM/DD/YYYY')} />
                </div>
              </div>
              <div className="col-md-3">
                <CFormLabel className="form-label">Is Active Investment</CFormLabel>
                <CFormInput disabled value={isActive} />
              </div>
              <div className="mt-4">
                <h3>Recurring Events Log</h3>
                <div className="row g-3">
                  <div>
                    <GenericTable items={logRows} columns={logColumns} />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <h3>Recurring Events Actions</h3>
                <div className="row g-3">
                  <div>
                    <GenericTable items={actionRows} columns={actionColumns} />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      </FormProvider>
    </PageLayout>
  )
}

export default RecurringInvestmentsDetail
