import React, { useEffect, useState } from 'react'
import { CButton, CFormInput, CFormSelect } from '@coreui/react-pro'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import Null from './Null'

const OPTIONS = [
  'CURRENT_MONTH',
  'CURRENT_DAY',
  'LAST_N_HOURS',
  'LAST_N_DAYS',
  'LAST_N_WEEKS',
  'LAST_N_MONTHS',
  'LAST_N_QUARTERS',
  'LAST_N_YEARS',
  'LAST_FULL_N_HOURS',
  'LAST_FULL_N_DAYS',
  'LAST_FULL_N_WEEKS',
  'LAST_FULL_N_MONTHS',
  'LAST_FULL_N_QUARTERS',
  'LAST_FULL_N_YEARS',
]

const DateTime: any = ({ filter, filters, setFilters }: any) => {
  const [type, setType] = useState('')
  const [start, setStart] = useState(new Date())
  const [end, setEnd] = useState(new Date())
  const { input } = filter

  useEffect(() => {
    if (input?.filterDateFunction) {
      setType('default')
    } else {
      setStart(
        input?.filterMin
          ? new Date(
              moment(input.filterMin).utc().format('YYYY-MM-DD 00:00:00'),
            )
          : new Date(),
      )
      setEnd(
        input?.filterMax
          ? new Date(
              moment(input.filterMax).utc().format('YYYY-MM-DD 23:59:59'),
            )
          : new Date(),
      )
      setType('custom')
    }
  }, [filter, input])

  const handleChange = (e: any, input: string) => {
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      if (input === 'time') {
        arr[idx].input.filterDateFunction = e.target.value
      } else if (input === 'number') {
        arr[idx].input.filterDateNumber = e.target.value
      } else if (input === 'from') {
        arr[idx].input.filterMin = e
        setStart(e)
      } else if (input === 'to') {
        arr[idx].input.filterMax = e
        setEnd(e)
      }
      setFilters([...arr])
    }
  }

  const handleBtnClick = (type: string) => {
    setType(type)
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)

    if (idx !== -1) {
      if (type === 'custom') {
        delete arr[idx]?.input?.filterDateFunction
        delete arr[idx]?.input?.filterDateNumber
        arr[idx].input.filterMax = new Date()
        arr[idx].input.filterMin = new Date()
      } else {
        delete arr[idx]?.input?.filterMax
        delete arr[idx]?.input?.filterMin
        arr[idx].input.filterDateNumber = '14'
        arr[idx].input.filterDateFunction = 'LAST_N_DAYS'
      }
      setFilters([...arr])
    }
  }

  const handleNullChange = (e: any) => {
    const arr = [...filters]
    const idx = arr.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      const checked = e.target.checked
      if (checked) {
        arr[idx] = { ...arr[idx], filterIsNull: e.target.checked, input: {} }
      } else {
        arr[idx] = {
          ...arr[idx],
          filterIsNull: false,
          input: { filterMin: new Date(), filterMax: new Date() },
        }
      }
      setFilters([...arr])
    }
  }

  if (type === 'default') {
    return (
      <div className="d-flex align-items-center">
        {!filter.filterIsNull && (
          <>
            <CFormSelect
              className="date-input me-2"
              defaultValue={input.filterDateFunction}
              onChange={(e: any) => handleChange(e, 'time')}
            >
              {OPTIONS.map((option: any, i: number) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </CFormSelect>
            <CFormInput
              className="me-2"
              onChange={(e: any) => handleChange(e, 'number')}
              value={input.filterDateNumber}
            />
            <CButton
              className="text-nowrap"
              onClick={() => handleBtnClick('custom')}
            >
              Custom Range
            </CButton>
          </>
        )}
        <Null
          handleNullChange={handleNullChange}
          filterIsNull={filter.filterIsNull}
        />
      </div>
    )
  }

  return (
    <div className="d-flex align-items-center">
      {!filter.filterIsNull && (
        <>
          <DatePicker
            className="form-control"
            selected={start}
            endDate={end}
            onChange={(date: any) => handleChange(date, 'from')}
          />
          <DatePicker
            className="form-control ms-2"
            startDate={start}
            selected={end}
            onChange={(date: any) => handleChange(date, 'to')}
          />
          <CButton
            className="text-nowrap ms-3"
            onClick={() => handleBtnClick('default')}
          >
            Default Range
          </CButton>
        </>
      )}
      <Null
        handleNullChange={handleNullChange}
        filterIsNull={filter.filterIsNull}
      />
    </div>
  )
}

export default DateTime
