import React, { FC, ReactNode } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'

import { Badge, NavItem } from '../_nav'
import {
  cilContact,
  cilNewspaper,
  cilSave,
  cilBan,
  cilTransfer,
} from '@coreui/icons'
import { useAppDispatch, useAppSelector } from '../features/hooks'
import { RootState } from '../store'
import useQuery from '../hooks/useQuery'
import { toggleFavoriteReport } from '../features/userSlice'

interface AppSidebarNavProps {
  items: NavItem[]
}

export const AppSidebarNav: FC<AppSidebarNavProps> = ({ items }) => {
  const user = useAppSelector((state: RootState) => state.user)
  const { queries } = useAppSelector((state: RootState) => state.user)
  const { favoriteReports } = user
  const dispatch = useAppDispatch()
  const query = useQuery()
  const location = useLocation()
  const navLink = (
    name: string | JSX.Element,
    icon: string | ReactNode,
    badge?: Badge | undefined,
    uniqueid: string | undefined,
    location?: string | undefined,
  ) => {
    const toggleReport = (name: string) => {
      // Try to find the report from their favorite reports & update isFavorite status
      if (favoriteReports && favoriteReports.length) {
        const found = favoriteReports.find((item: any) => item.name === name)
        if (found) {
          dispatch(
            toggleFavoriteReport({
              reportId: found.reportId,
              name: found.name,
              isFavorite: false,
              queryParams: found.queryParams,
            }),
          )
        } else {
          const foundItem = queries.map((query: any) => {
            let obj = null
            if (query.reports && query.reports.length) {
              const found = query.reports.find(
                (item: any) => item.name === name,
              )
              if (found) {
                obj = { data: found, queryName: query.queryName }
              }
            }
            return obj
          })

          const foundFavoriteReport = foundItem.find(
            (item: any) => item !== null,
          )

          if (foundFavoriteReport) {
            const {
              data: { reportId, name, queryParams },
              queryName,
            } = foundFavoriteReport

            dispatch(
              toggleFavoriteReport({
                reportId: reportId,
                name: name,
                isFavorite: true,
                queryName,
                queryParams,
              }),
            )
          }
        }
      } else {
        // If user has no favoriteReports, use queries array to find report & update favorite status
        const foundItem = queries.map((query: any) => {
          let obj = null
          if (query.reports && query.reports.length) {
            const found = query.reports.find((item: any) => item.name === name)
            if (found) {
              obj = { data: found, queryName: query.queryName }
            }
          }
          return obj
        })

        const foundFavoriteReport = foundItem.find((item: any) => item !== null)

        if (foundFavoriteReport) {
          const {
            data: { reportId, queryParams, name },
            queryName,
          } = foundFavoriteReport
          dispatch(
            toggleFavoriteReport({
              reportId,
              name,
              queryParams,
              queryName,
              isFavorite: true,
            }),
          )
        }
      }
    }

    if (uniqueid && location) {
      return (
        <div className="w-100 h-100 d-flex justify-content-between align-items-center mx-3">
          <NavLink
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '16px',
              whiteSpace: 'pre-wrap',
              height: '100%',
              width: '100%',
              textDecoration: 'none',
              color: '#fff',
            }}
            to={location}
          >
            {name}
          </NavLink>
          <CIcon
            style={{ cursor: 'pointer' }}
            onClick={() => toggleReport(name as string)}
            icon={
              !favoriteReports.some((report: any) => report.name === name)
                ? cilSave
                : cilBan
            }
            height={18}
            width={18}
          />
        </div>
      )
    }
    return (
      <div className="d-flex" title={name.toString()}>
        {icon && typeof icon === 'string' ? (
          <CIcon icon={icon} customClassName="nav-icon" />
        ) : (
          icon
        )}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </div>
    )
  }

  const { isBrokerDealer } = user || {}

  const navItem = (item: NavItem, index: number) => {
    const { component, name, badge, icon, uniqueid, ...rest } = item
    const Component = component
    const params = new URLSearchParams(rest.to)

    if (name === 'IRM') {
      return (
        <Component
          style={{
            padding: name !== 'IRM' && '.75rem 2rem',
            fontSize: name !== 'IRM' && '14px',
            whiteSpace: name !== 'IRM' && 'pre-wrap',
          }}
          {...(rest.to &&
            !rest.items && {
              component: NavLink,
              activeClassName:
                params.get('reportId') === query.get('reportId')
                  ? 'active'
                  : null,
            })}
          key={index}
          {...rest}
        >
          {navLink(name, icon, badge, uniqueid, rest.to)}
        </Component>
      )
    }
    return (
      <Component
        style={{
          padding: '0',
          height: '60px',
        }}
        {...(rest.to &&
          !rest.items && {
            component: 'div',
            className:
              params.get('reportId') === query.get('reportId')
                ? 'active'
                : null,
          })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge, uniqueid, rest.to)}
      </Component>
    )
  }
  const navGroup = (item: NavItem, index: number) => {
    const { component, name, icon, to, uniqueid, ...rest } = item
    const Component = component
    const q = location.pathname.split('/')
    return (
      <Component
        className={q?.[1] === to ? 'show' : null}
        idx={uniqueid}
        key={index}
        toggler={navLink(name, icon, undefined, undefined, undefined)}
        visible={query.get('queryName') === to}
        {...rest}
      >
        {item.items?.map((item: NavItem, index: number) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {items &&
        items.map((item: NavItem, index: number) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      <div className="nav-group">
        <a
          className="nav-link"
          href={`${process.env.REACT_APP_API_URL}/downloadExampleEmails`}
        >
          <CIcon icon={cilContact} customClassName="nav-icon" />
          Emails
        </a>
        <a
          className="nav-link"
          href={`${process.env.REACT_APP_API_URL}/getPermissionsXlsx`}
        >
          <CIcon icon={cilContact} customClassName="nav-icon" />
          Permissions
        </a>
      </div>
      <div className="nav-group">
        <NavLink className="nav-link" to="/transactions">
          <CIcon icon={cilTransfer} customClassName="nav-icon" />
          Transactions
        </NavLink>
      </div>
      {isBrokerDealer && (
        <div className="nav-group">
          <NavLink className="nav-link" to="/closed-orders">
            <CIcon icon={cilNewspaper} customClassName="nav-icon" />
            Closed Orders
          </NavLink>
        </div>
      )}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
