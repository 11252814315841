import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import { getUser, resetUser } from '../../features/usersSlice'
import SellOfferDetailForm from './SellOfferDetailForm'
import { getEntity } from '../../features/entitySlice'
import {
  getSellOffer,
  saveSellOffer,
  resetSellOffer,
  getOfferTransactions,
} from '../../features/offersSlice'

const SellOfferDetail: React.FC = () => {
  const { id }: any = useParams()
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const { sellOffer } = useAppSelector((state: RootState) => state.offers)
  const methods = useForm()

  const { reset } = methods

  useEffect(() => {
    const getSellOfferById = async () => {
      const res = await dispatch(getSellOffer({ id }))
      const data = res?.payload?.rows?.[0] || {}
      if (data.userId) {
        await dispatch(getUser({ id: data.userId }))
      }
      if (data.entityId) {
        await dispatch(getEntity({ id: data.entityId }))
      }
      await dispatch(getOfferTransactions({ id }))
      setLoading(false)
    }
    getSellOfferById()
    return () => {
      dispatch(resetSellOffer())
      dispatch(resetUser())
    }
  }, [id, dispatch])

  useEffect(() => reset(sellOffer), [reset, sellOffer])

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    if (data.notes && data.notes.length) {
      data.notes.forEach((note: any) => delete note.updated)
    }
    data.notes = JSON.stringify(data.notes)
    if (data?.status) {
      if (data.status === 'Cancel' || data.status === 'Close') {
        data.isActive = false
      }
    }
    await dispatch(saveSellOffer({ id, data }))
    await dispatch(getSellOffer({ id }))
    await dispatch(getOfferTransactions({ id }))
    setBtnLoading(false)
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <FormProvider {...methods}>
      <PageLayout>
        <SellOfferDetailForm onSubmit={onSubmit} btnLoading={btnLoading} />
      </PageLayout>
    </FormProvider>
  )
}

export default SellOfferDetail
