import React from 'react'
import Select from 'react-select'
import Number from './filters/Number'
import String from './filters/String'
import Boolean from './filters/Boolean'
import MultiSelect from './filters/MultiSelect'
import DateTime from './filters/Date'
import { CButton } from '@coreui/react-pro'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import CIcon from '@coreui/icons-react'
import { cilMinus } from '@coreui/icons'

const baseStyles = {
  control: {
    borderRadius: '7px',
    boxShadow: 'none',
    margin: '10px 0',
    minWidth: '300px',
    maxWidth: '300px',
  },
  menu: {
    marginTop: 0,
    padding: 0,
    zIndex: 999,
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  group: {
    padding: 0,
  },
}

// custom styles for Select option
const customStyles = {
  container: (base: any) => ({
    ...base,
    minWidth: '300px',
    maxWidth: '300px',
    marginRight: '15px',
  }),
  control: (base: any) => ({
    ...base,
    ...baseStyles.control,
  }),
  menu: (base: any) => ({
    ...base,
    ...baseStyles.menu,
  }),
  menuList: (base: any) => ({
    ...base,
    ...baseStyles.menuList,
  }),
  group: (base: any) => ({
    ...base,
    ...baseStyles.group,
  }),
  input: (base: any) => ({
    ...base,
    visibility: 'visible',
  }),
}

const Filter: any = ({ index, filter, options, filters, setFilters }: any) => {
  const { originalColumns } = useAppSelector((state: RootState) => state.list)
  const renderFilterOption = (filter: any) => {
    switch (filter.type) {
      case 'String': {
        if (filter.values && filter.values.length) {
          return (
            <MultiSelect
              filter={filter}
              filters={filters}
              setFilters={setFilters}
            />
          )
        } else {
          return (
            <String filter={filter} filters={filters} setFilters={setFilters} />
          )
        }
      }
      case 'Number':
        return (
          <Number filter={filter} filters={filters} setFilters={setFilters} />
        )
      case 'Date':
        return (
          <DateTime filter={filter} filters={filters} setFilters={setFilters} />
        )
      case 'Boolean':
        return (
          <Boolean filter={filter} filters={filters} setFilters={setFilters} />
        )
      default:
        return ''
    }
  }

  const deleteFilter = (index: number) => {
    const arr = [...filters]
    if (index !== -1) {
      arr.splice(index, 1)
      setFilters([...arr])
    }
  }

  const handleDropdownChange = (e: any) => {
    const arr = [...filters]
    const found = originalColumns.find((item: any) => item.name === e.name)
    if (found) {
      if (found?.renderer) {
        if (found.renderer?.includes('Status')) {
          arr[index] = {
            ...e,
            values: found?.values?.map((option: any, i: number) => ({
              label: option,
              value: option,
              selected: false,
            })),
          }
        } else if (found.renderer?.includes('Id')) {
          arr[index] = {
            ...e,
            input: { filter: 'filterIsEqual', value: 0 },
          }
        } else if (found.renderer.includes('Date')) {
          arr[index] = {
            ...e,
            input: { filterMin: new Date(), filterMax: new Date() },
          }
        } else if (found.renderer.includes('Money')) {
          arr[index] = {
            ...e,
            input: { filter: 'filterMax', value: 0 },
          }
        } else if (found.renderer.includes('Shares')) {
          arr[index] = {
            ...e,
            input: { filter: 'filterMax', value: 0 },
          }
        } else if (found.renderer.includes('Notes')) {
          arr[index] = { ...e, input: '' }
        } else if (found.renderer.includes('Series')) {
          arr[index] = { ...e, input: '' }
        } else if (found.renderer.includes('Percent')) {
          arr[index] = {
            ...e,
            input: { filter: 'filterMax', value: 0 },
          }
        }
      } else if (!found.renderer && found?.type === 'String') {
        arr[index] = { ...e, input: '' }
      } else if (!found.renderer && found?.type === 'Boolean') {
        arr[index] = { ...e, input: true }
      } else if (!found.renderer && found?.type === 'Number') {
        arr[index] = {
          ...e,
          input: { filter: 'filterMax', value: 0 },
        }
      } else if (!found.renderer && found?.type === 'Date') {
        arr[index] = {
          ...e,
          input: { filterMin: new Date(), filterMax: new Date() },
        }
      }
    }

    setFilters([...arr])
  }

  return (
    <div className="d-flex align-items-center">
      <Select
        styles={customStyles}
        value={filter}
        options={options}
        onChange={(e: any) => handleDropdownChange(e)}
      />
      {renderFilterOption(filter)}
      <CButton
        size="sm"
        className="rounded-circle ms-2"
        onClick={() => deleteFilter(index)}
      >
        <CIcon icon={cilMinus} height={15} width={15} />
      </CButton>
    </div>
  )
}

export default Filter
