import React, { useState } from 'react'
import { CButton, CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import { formatDecimal, formatToPT } from '../../utils/formatUtils/utils'
import Note from '../../components/Note'
import SubmitButton from '../../components/SubmitButton'
import Select from '../assets/components/Select'
import GenericTable from '../../components/GenericTable'

const SellOfferDetailForm: React.FC<any> = ({ btnLoading, onSubmit }) => {
  const [note, setNote] = useState<string>('')
  const me = useAppSelector((state: RootState) => state.user)
  const { sellOffer, transactionColumns } = useAppSelector(
    (state: RootState) => state.offers,
  )
  const { user } = useAppSelector((state: RootState) => state.users)
  const { entity } = useAppSelector((state: RootState) => state.entities)
  const { register, handleSubmit, control } = useFormContext()
  const history = useHistory()
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'notes',
  })

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  const {
    offerId,
    userId,
    entityId,
    createdAt,
    subTotal,
    transactionFee,
    transactionFlatFee,
    shares,
    isActive,
    deactivatedAt,
    sharesCompleted,
    proceedsTotal,
    projectedProceeds,
    sharePrice,
    sharesAvailable,
    transactions,
  } = sellOffer || {}

  const { fullName } = user || {}
  const { name } = entity || {}

  let btnCopy = null

  if (isActive) {
    const copy =
      sharesCompleted === 0
        ? 'Cancel'
        : sharesCompleted > 0
        ? 'Close'
        : 'Cancel'
    btnCopy = (
      <div className="d-flex ">
        <CFormSelect {...register('status')}>
          <option value="Active">Active</option>
          <option value={copy}>{copy}</option>
        </CFormSelect>
      </div>
    )
  } else if (!isActive && transactions && transactions.length > 0) {
    btnCopy = <CFormInput disabled value="Closed" />
  } else if (!isActive && transactions && transactions.length === 0) {
    btnCopy = <CFormInput disabled value="Cancelled" />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between">
        <h3>Sell Offer No. {offerId}</h3>
        <SubmitButton loading={btnLoading} text="Save" />
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-3 d-flex flex-column align-items-between">
          <CFormLabel>Status</CFormLabel>
          {btnCopy}
        </div>
        <div className="col-md-3">
          <CFormLabel>Sell Offer Created At</CFormLabel>
          <CFormInput disabled value={formatToPT(createdAt, 'MM/DD/YY')} />
        </div>
        {deactivatedAt && (
          <div className="col-md-3">
            <CFormLabel>Sell Offer Deactivated At</CFormLabel>
            <CFormInput
              disabled
              value={formatToPT(deactivatedAt, 'MM/DD/YY')}
            />
          </div>
        )}
        <div className="col-md-3">
          <CFormLabel>Company Name</CFormLabel>
          <CFormInput disabled {...register('companyName')} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Share Price</CFormLabel>
          <CFormInput disabled value={formatDecimal(sharePrice || 0)} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Shares</CFormLabel>
          <CFormInput
            disabled
            value={shares?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          />
        </div>
        <div className="col-md-3">
          <CFormLabel>Subtotal</CFormLabel>
          <CFormInput disabled value={formatDecimal(subTotal || 0)} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Transaction Fees</CFormLabel>
          <CFormInput disabled value={formatDecimal(transactionFee || 0)} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Regulatory Recovery Fee</CFormLabel>
          <CFormInput disabled value={formatDecimal(transactionFlatFee || 0)} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Shares Remaining</CFormLabel>
          <CFormInput
            disabled
            value={sharesAvailable?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          />
        </div>
        <div className="col-md-3">
          <CFormLabel>Total Proceeds</CFormLabel>
          <CFormInput disabled value={formatDecimal(proceedsTotal || 0)} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Projected Proceeds</CFormLabel>
          <CFormInput disabled value={formatDecimal(projectedProceeds || 0)} />
        </div>
        {userId && (
          <div className="col-md-3">
            <CFormLabel>User</CFormLabel>
            <Select
              readOnly
              options={Option}
              watchValue={fullName}
              query="users"
              id={userId}
              ref={null}
            />
          </div>
        )}
        {entityId && (
          <div className="col-md-3">
            <CFormLabel>Owner Name</CFormLabel>
            <Select
              readOnly
              options={Option}
              watchValue={name}
              query="entities"
              id={entityId}
              ref={null}
            />
          </div>
        )}
      </div>
      <div className="d-flex mt-4">
        <div className="col-md-6">
          <div className="col-md-12 d-flex align-items-end justify-content-between">
            <div className="d-flex flex-column w-100 me-2">
              <h4>Notes</h4>
              <CFormLabel>Add Note</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                value={note}
                onChange={(e: any) => setNote(e.target.value)}
              />
            </div>
            <CButton disabled={!note} type="button" onClick={updateNote}>
              Add
            </CButton>
          </div>
          {fields && fields.length
            ? fields.map((note: any, i: number) => (
                <Note
                  index={note.id}
                  key={note.id}
                  note={note}
                  deleteNote={() => remove(i)}
                />
              ))
            : null}
        </div>
      </div>
      <h3 className="mt-4">Completed Sales</h3>
      <GenericTable
        items={transactions || []}
        columns={transactionColumns}
        handleClick={(item: any) =>
          history.push(`/sell_orders/${item.sellOrderId}`)
        }
      />
    </form>
  )
}

export default SellOfferDetailForm
