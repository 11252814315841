import React from 'react'
import { CButton, CFormTextarea } from '@coreui/react-pro'
import {
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'

interface ListFieldsProps {
  items: Record<'id', string>[]
  add: UseFieldArrayAppend<FieldValues, string>
  remove: UseFieldArrayRemove
  type: string
}

const ListFields: React.FC<ListFieldsProps> = ({
  items,
  add,
  remove,
  type,
}: ListFieldsProps) => {
  const { register } = useFormContext()
  const { loading: btnLoading } = useAppSelector(
    (state: RootState) => state.companies,
  )

  if (!items) {
    return <h4>No facts...</h4>
  }

  return (
    <>
      {items.map((field: any, index: number) => (
        <div key={field.id} className="d-flex align-items-start">
          <CFormTextarea
            rows={3}
            style={{ marginRight: '10px', marginBottom: '10px' }}
            {...register(`companyDetail.${type}.${index}.fact`)}
            disabled={btnLoading}
          />
          <CButton
            size="sm"
            style={{ marginRight: '10px', marginBottom: '10px' }}
            onClick={() => remove(index)}
            disabled={btnLoading}
          >
            Delete
          </CButton>
        </div>
      ))}
      <CButton
        disabled={btnLoading}
        style={{ float: 'right', margin: '10px' }}
        onClick={add}
      >
        Add
      </CButton>
    </>
  )
}

export default ListFields
