import React from 'react'
import { BarLoader } from 'react-spinners'

const Spinner: React.FC = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BarLoader color="#0039ec" loading height={5} width={200} />
    </div>
  )
}
export default Spinner
