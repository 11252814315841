import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'

interface UsersState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  loading: boolean
  originationCompany: any
}

const initialState: UsersState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  loading: false,
  originationCompany: {},
}

export const getOriginationCompany = createAsyncThunk(
  'getOriginationCompany',
  async ({ id }: any) => {
    try {
      const response = await privateAPI.get(
        `/queries/ORIGINATION_COMPANIES/${id}`,
      )
      return response.data
    } catch (e) {
      console.log(e)
    }
  },
)

export const saveOriginationCompany = createAsyncThunk(
  'saveOriginationCompany',
  async ({ id, data }: any) => {
    try {
      const response = await privateAPI.post(
        `/queries/ORIGINATION_COMPANIES/${id}`,
        data,
      )
      return response.data
    } catch (e) {
      console.log(e)
    }
  },
)

export const getOriginationCompanies = createAsyncThunk(
  'getOriginationCompanies',
  async ({ found }: any) => {
    const params = found.queryParams
    try {
      const response = await privateAPI.post(
        '/queries/ORIGINATION_COMPANIES',
        JSON.parse(params),
      )
      return response.data
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
)

export const getOriginationCompaniesReports = createAsyncThunk(
  'getOriginationCompaniesReports',
  async () => {
    try {
      const response = await privateAPI.get(
        '/queries/ORIGINATION_COMPANIES/reports',
      )
      return response.data
    } catch (e) {
      console.log('e', e)
    }
  },
)

export const originationCompaniesSlice = createSlice({
  name: 'originationCompanies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOriginationCompaniesReports.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getOriginationCompaniesReports.fulfilled,
        (state, { payload }: any) => {
          if (payload) {
            state.reports = [...payload]
          }
          state.loading = false
        },
      )
      .addCase(getOriginationCompaniesReports.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
      .addCase(getOriginationCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(getOriginationCompany.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.filtered = payload.filtered
          state.originationCompany = payload.rows[0]
        }
        state.loading = false
      })
      .addCase(getOriginationCompany.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
      .addCase(saveOriginationCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(saveOriginationCompany.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.originationCompany = payload.rows[0]
        }
        state.loading = false
      })
      .addCase(saveOriginationCompany.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
      .addCase(getOriginationCompanies.pending, (state) => {
        state.loading = true
      })
      .addCase(getOriginationCompanies.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.columns = payload.columns
          state.filtered = payload.filtered
          state.rows = payload.rows
          state.loading = false
        }
      })
      .addCase(getOriginationCompanies.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
  },
})

export default originationCompaniesSlice.reducer
