import { CFormSelect } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import PublicReport from './PublicReport'

interface PublicReportsProps {
  title: string
  reports: any[]
  options?: Option[]
}

type Option = {
  label: string
  value: string
}

type Report = {
  isMine: boolean
  isPrivate: boolean
  reportId: number
  queryParams: string
  name: string
}

const PublicReports: React.FC<PublicReportsProps> = ({
  title,
  reports,
  options,
}: PublicReportsProps) => {
  const [filter, setFilter] = useState('assets')
  const [filteredReports, setFilteredReports] = useState<Report[]>([])

  useEffect(() => {
    if (options && options.length && reports && reports.length) {
      setFilteredReports(
        reports.filter(
          (report: any) => report.queryName === filter.toUpperCase(),
        ),
      )
    } else {
      setFilteredReports([...reports])
    }
  }, [filter, reports, options])

  return (
    <>
      <h4>{title}</h4>
      <div className="public-reports-container vh-40 bg-light rounded shadow p-2">
        {options && options.length ? (
          <>
            <CFormSelect
              className="my-2"
              value={filter}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setFilter(e.target.value)
              }
            >
              {options.map(({ label, value }: Option) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </CFormSelect>
          </>
        ) : null}
        {filteredReports && filteredReports.length
          ? filteredReports.map(
              ({ reportId, name, queryParams, queryName }: any) => (
                <PublicReport
                  key={reportId}
                  reportId={reportId}
                  filter={
                    options && options.length
                      ? filter
                      : queryName?.toLowerCase()
                  }
                  queryParams={queryParams}
                  name={name}
                />
              ),
            )
          : null}
      </div>
    </>
  )
}

export default PublicReports
