import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilPhone, cilUser } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../components/Spinner'
import { useForm } from 'react-hook-form'
import { signIn } from '../../features/userSlice'
import { RootState } from '../../store'

const getWebUrl = (domain: string) => {
  const arr = domain.split('.').slice(1) // Skip the first part (e.g., 'irm')
  const newDomain = `https://app.${arr.join('.')}/settings`
  return newDomain
}

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const location = useLocation<any>()
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, isLoggedIn, error } = useSelector(
    (state: RootState) => state.user,
  )

  useEffect(() => {
    if (isLoggedIn) {
      history.push(
        location.state?.from.pathname + location.state?.from.search ||
          location.pathname,
      )
    }
    //eslint-disable-next-line
  }, [isLoggedIn, history])

  const handleClick = async (data: any) =>
    await dispatch(signIn({ data, history }))

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(handleClick)}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">
                      Sign In to your account
                    </p>
                    {errors.username && (
                      <span className="text-danger m-1">
                        {errors.username.message}
                      </span>
                    )}
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Email"
                        {...register('username', {
                          required: 'Email is required.',
                        })}
                      />
                    </CInputGroup>
                    {errors.password && (
                      <span className="text-danger">
                        {errors.password.message}
                      </span>
                    )}
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        {...register('password', {
                          required: 'Password is required.',
                        })}
                      />
                    </CInputGroup>
                    {errors.otpToken && (
                      <span className="text-danger">
                        {errors.otpToken.message}
                      </span>
                    )}
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilPhone} />
                      </CInputGroupText>
                      <CFormInput
                        maxLength={6}
                        placeholder="Authentication"
                        {...register('otpToken', {
                          required: 'Authentication Code is required.',
                        })}
                      />
                    </CInputGroup>
                    {error && <CAlert color="danger">{error}</CAlert>}
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" type="submit" className="px-4">
                          Login
                        </CButton>
                      </CCol>
                      <CCol
                        xs={6}
                        className="d-flex align-items-end justify-content-end"
                      >
                        <a href={getWebUrl(window.location.host)} target="_blank" rel="noopener noreferrer">
                          Setup
                        </a>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
