import React from 'react'
import { MoonLoader } from 'react-spinners'

const Loading: React.FC = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '150px' }}
    >
      <MoonLoader color="#0039ec" loading size={30} />
    </div>
  )
}

export default Loading
