import React, { useState } from 'react'
import Note from '../../components/Note'
import Select from '../assets/components/Select'
import Document from '../../components/Document'
import { formatToPT } from '../../utils/formatUtils/utils'
import { useFormContext, useFieldArray } from 'react-hook-form'
import SubmitButton from '../../components/SubmitButton'
import { CButton, CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import type { Origination } from './OriginationDetail'
interface OriginationDetailFormProps {
  onSubmit: any
  btnLoading: boolean
  origination: Origination | undefined
}

const OriginationDetailForm: React.FC<OriginationDetailFormProps> = ({
  onSubmit,
  btnLoading,
  origination,
}: OriginationDetailFormProps) => {
  const [note, setNote] = useState('')
  const me = useAppSelector((state: RootState) => state.user)
  const { handleSubmit, watch, register, control } = useFormContext()
  const { fields, remove, prepend } = useFieldArray({ control, name: 'notes' })

  const {
    originationId,
    status,
    shares,
    purchaseAmount,
    updatedAt,
    createdAt,
    sharePrice,
    documents,
  } = origination || {}

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between">
        <h3>Origination No.{originationId}</h3>
        <div className="d-flex">
          <SubmitButton loading={btnLoading} text="Save" />
        </div>
      </div>
      <h4>Origination Information</h4>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel className="form-label">Company</CFormLabel>
          <div className="d-flex flex-row">
            <Select
              readOnly
              options={Option}
              watchValue={watch('companyName')}
              query="companies"
              id={watch('companyId')}
            />
          </div>
        </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">User</CFormLabel>
          <div className="d-flex flex-row">
            <Select
              readOnly
              options={Option}
              watchValue={watch('fullName')}
              query="users"
              id={watch('userId')}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel className="form-label">Status</CFormLabel>
          <CFormSelect
            disabled={btnLoading}
            defaultValue={status}
            {...register('status')}
          >
            <option value="SUBMITTED">Submitted</option>
            <option value="PENDING">Pending</option>
            <option value="CANCELLED">Cancelled</option>
            <option value="CLOSED">Closed</option>
          </CFormSelect>
        </div>
        {watch('status') === 'CANCELLED' ? (
          <div className="col-md-3">
            <CFormLabel className="form-label">Cancel Reason</CFormLabel>
            <CFormSelect disabled={btnLoading} {...register('cancelReason')}>
              <option value="NOT_REAL_SELLER">Not Real Seller</option>
              <option value="SIZE_TOO_SMALL">Size too Small</option>
              <option value="SIZE_TOO_LARGE">Size too Large</option>
              <option value="WANTS_TO_WAIT">Wants to Wait</option>
              <option value="PRICING_SELLER_WANTS_HIGHER">
                Pricing - Seller Wants Higher
              </option>
              <option value="ROFR">ROFR</option>
              <option value="DISQUALIFIED_NOT_VESTED">
                Disqualified - Not Vested
              </option>
              <option value="DISQUALIFIED_RESTRICTED_SHARES">
                Disqualified - Restricted Shares
              </option>
              <option value="PRICING_BETTER_WAY">Better Away - Pricing</option>
              <option value="SIZING_BETTER_WAY">Better Away - Sizing</option>
              <option value="UNKNOWNBETTER_WAY">Better Away - Unknown</option>
              <option value="SELLER_WITHDREW_LACK_OF_COMPANY_RESPONSE">
                Seller Withdrew - Lack of Company Response
              </option>
              <option value="SELLER_WITHDREW_OTHER">
                Seller Withdrew - Other
              </option>
              <option value="OTHER">Other</option>
            </CFormSelect>
          </div>
        ) : null}
        <div className="col-md-3">
          <CFormLabel className="form-label">Date Created</CFormLabel>
          <CFormInput disabled value={formatToPT(createdAt, 'MM/DD/YY')} />
        </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">Date Closed</CFormLabel>
          <CFormInput disabled value={formatToPT(updatedAt, 'MM/DD/YY')} />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel className="form-label">Certificate Number</CFormLabel>
          <CFormInput disabled={btnLoading} {...register('certificateNum')} />
        </div>
        <div className="col-md-3">
          <CFormLabel>Class Of Shares</CFormLabel>
          <CFormSelect disabled={btnLoading} {...register('shareClass')}>
            <option value="COMMON_SHARES">Common Shares</option>
            <option value="PREFERRED_SHARES">Preferred Shares</option>
            <option value="OPTIONS_FOR_COMMON_SHARES">
              Options For Common Shares
            </option>
            <option value="OPTIONS_FOR_PREFERRED_SHARES">
              Options For Preferred Shares
            </option>
          </CFormSelect>
        </div>
        <div className="col-md-3">
          <CFormLabel>Offer Share Price</CFormLabel>
          <CFormInput
            disabled
            value={`$${sharePrice?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
          />
        </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">Number of Shares</CFormLabel>
          <CFormInput disabled value={`${shares?.toLocaleString('en-US')}`} />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-3">
          <CFormLabel className="form-label">Total Purchase Amount</CFormLabel>
          <CFormInput
            disabled
            value={`$${purchaseAmount?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
          />
        </div>
        <div className="col-md-3">
          <CFormLabel>Strike Price</CFormLabel>
          <CFormInput
            type="number"
            step=".01"
            {...register('optionsStrikePrice')}
          />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-6">
          <div className="col-md-12 d-flex align-items-end justify-content-between">
            <div className="d-flex flex-column w-100 me-2">
              <h4>Notes</h4>
              <CFormLabel>Add Note</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                value={note}
                onChange={(e: any) => setNote(e.target.value)}
              />
            </div>
            <CButton disabled={!note} type="button" onClick={updateNote}>
              Add
            </CButton>
          </div>
          {fields && fields.length
            ? fields.map((note: any, i: number) => (
              <Note
                index={note.id}
                key={note.id}
                note={note}
                deleteNote={() => remove(i)}
              />
            ))
            : null}
        </div>
        <div className="col-md-6">
          <div className="col">
            <h4>Documents</h4>
            {documents && documents.length
              ? documents.map(({ name, url, createdAt }: any, i: number) => (
                <Document
                  key={i}
                  name={name}
                  url={url}
                  createdAt={createdAt}
                  loading={btnLoading}
                />
              ))
              : null}
          </div>
        </div>
      </div>
    </form>
  )
}

export default OriginationDetailForm
