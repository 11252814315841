import React from 'react'
import AsyncSelectInput from '../../../components/AsyncSelectInput'
import { CInputGroup, CInputGroupText, CTooltip } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLink } from '@coreui/icons'
import { Controller, useFormContext } from 'react-hook-form'

const Select: any = ({
  loadSuggestedOptions,
  handleChange,
  watchValue,
  query,
  id,
  options,
  readOnly = false,
  isClearable = false,
  placeholder = '',
}: any) => {
  const { control } = useFormContext()

  return (
    <CInputGroup style={{ flexWrap: 'nowrap' }}>
      <CTooltip content={watchValue ? `${watchValue} (id: ${id})` : null}>
        <CInputGroupText
          style={{ cursor: 'pointer', padding: '0' }}
          id="basic-addon1"
        >
          <a
            href={`/${query}/${id}`}
            style={{
              width: '100%',
              height: '100%',
              padding: '0.375rem 0.75rem',
            }}
          >
            <CIcon icon={cilLink} />
          </a>
        </CInputGroupText>
      </CTooltip>
      <Controller
        control={control}
        name="data"
        render={() => (
          <AsyncSelectInput
            readOnly={readOnly}
            option={options}
            loadOptions={loadSuggestedOptions}
            onChange={handleChange}
            isClearable={isClearable}
            value={
              watchValue
                ? {
                    label: watchValue && `${watchValue} (id: ${id})`,
                    value: watchValue && `${watchValue} (id: ${id})`,
                  }
                : null
            }
            placeholder={placeholder}
          />
        )}
      />
    </CInputGroup>
  )
}

export default Select
