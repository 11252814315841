import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import useQuery from '../../hooks/useQuery'
import Table from '../../components/Table'
import { getListData } from '../../features/listsSlice'
import { CButton } from '@coreui/react-pro'
import { useDebounceSearch } from '../../hooks/useDebounce'
import Pagination from './components/Pagination'
import BarChart from './components/BarChart'
import PieChart from './components/PieChart'
import Modal from './components/Modal'

interface ReduxStore {
  origination_sell_to_linqto_requests: any
  origination_companies: any
  orders: any
  entities: any
  users: any
  tags: any
  companies: any
  rewards: any
  list: any
  sidebar: any
  team_members: any
  offers: any
  assets: any
  originations: any
  broker_infos: any
  common: any
  series_formd_infos: any
  series_blue_sky_fees: any
  faqs: any
  ria_firms: any
  cash_external_accounts: any
  cash_accounts: any
  sell_orders: any
  sell_offers: any
  cash_payment_orders: any
  uphold_accesses: any
  company_ipo_datas: any
  user_company_ipo_choices: any
  discounts: any
  bundles: any
  company_messages: any
  serieses: any
  promo_fulfillments: any
  custom_deals: any
}

const createPage: any = {
  tags: 'Tag',
  ria_firms: 'RIA Firm',
  entity: 'Entity',
  assets: 'Asset',
  faqs: 'FAQ',
  team_members: 'Team Member',
  offers: 'Offer',
  discounts: 'Discount',
  company_ipo_datas: 'Company IPO',
  bundles: 'Bundle',
  custom_deals: 'Custom Deal'
}

const List: React.FC = () => {
  // HOOKS
  const query = useQuery()
  const history = useHistory()
  const dispatch = useAppDispatch()

  // REDUX STORE
  const queryName: keyof ReduxStore = query.get('queryName')
  const { reports } =
    useAppSelector((state: RootState) =>
      state.user.queries?.find(
        (query) => query.queryName === queryName.toUpperCase(),
      ),
    ) || []
  const { columns, rows, filtered, xAxisData, yAxisData, yAxis } =
    useAppSelector((state: RootState) => state.list)

  // QUERY PARAMS
  const params = query.get('params')
  const reportId: number = Number(query.get('reportId')) || 0
  const length: number = Number(query.get('length')) || 10
  const current: number = Number(query.get('current')) || 0
  const start: number = Number(query.get('start')) || 0
  const visible = query.get('visible') || false
  const type = query.get('chartType')
  const x = query.get('xAxis')
  const y = query.get('yAxis')

  // LOCAL STATE
  const [loading, setLoading] = useState(true)
  const [sorter, setSorter] = useState<any>(undefined)
  const [freeText, setFreeText] = useState<string>('')
  const inputRef = useRef<any>()
  const [open, setOpen] = useState<boolean>(false)
  const [isExportAllowed, setIsExportAllowed] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  useEffect(() => {
    const found = reports?.find((r: any, i: number) => r.reportId === reportId)
    if (found) {
      setIsExportAllowed(found.isExportAllowed)
    }
  }, [reports, reportId])

  useEffect(() => {
    setSorter(undefined)
    const fetchData = async () => {
      setLoading(true)
      const { payload } = await dispatch(
        getListData({
          queryName,
          found: { queryParams: JSON.stringify(JSON.parse(params)) },
          current,
          length: Number(length),
          xAxis: JSON.parse(x),
          yAxis: JSON.parse(y),
        }),
      )
      return payload
    }
    fetchData().then((res: any) => {
      setResponse(res)
      setLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryName, reportId])

  useEffect(() => {
    if (response) {
      const data = { ...JSON.parse(params), ...response }
      history.replace(
        `/list?queryName=${queryName}&reportId=${reportId}&current=${current}&start=${start}&length=${(data.visualizeReport !== undefined &&
          data.visualizeReport === true) ||
          data?.chartType === 'bar' ||
          data?.chartType === 'pie'
          ? 1000
          : length === 1000
            ? 10
            : length
        }&params=${encodeURIComponent(
          JSON.stringify(JSON.parse(params)),
        )}&visible=${data.visible}&chartType=${data.chartType || null
        }&xAxis=${encodeURIComponent(
          JSON.stringify(data.xAxis || null),
        )}&yAxis=${encodeURIComponent(JSON.stringify(data.yAxis || null))}`,
      )
    }
    //eslint-disable-next-line
  }, [response])

  useEffect(() => {
    try {
      const data = JSON.parse(params)
      if (data) {
        setFreeText(data?.freeTextSearch || '')
      }
    } catch (e) {
      console.log('USE EFFECT: UNABLE TO PARSE QUERY PARAMS')
    }
  }, [params])

  const handleClick = (item: any) => {
    switch (queryName) {
      case 'users': {
        item.userId && history.push(`/users/${item.userId}`)
        break
      }
      case 'entities': {
        item.entityId && history.push(`/entities/${item.entityId}`)
        break
      }
      case 'orders': {
        item.orderId && history.push(`/orders/${item.orderId}`)
        break
      }
      case 'origination_companies': {
        item.originationCompanyId &&
          history.push(`/origination_companies/${item.originationCompanyId}`)
        break
      }
      case 'origination_sell_to_linqto_requests': {
        item.originationSellToLinqtoRequestId &&
          history.push(
            `/origination_sell_to_linqto_requests/${item.originationSellToLinqtoRequestId}`,
          )
        break
      }
      case 'tags': {
        item.tagId && history.push(`/tags/${item.tagId}`)
        break
      }
      case 'companies': {
        item.companyId && history.push(`/companies/${item.companyId}`)
        break
      }
      case 'team_members': {
        item.teamMemberId && history.push(`/team_members/${item.teamMemberId}`)
        break
      }
      case 'offers': {
        item.offerId && history.push(`/offers/${item.offerId}`)
        break
      }
      case 'assets': {
        item.assetId && history.push(`/assets/${item.assetId}`)
        break
      }
      case 'originations': {
        item.originationId &&
          history.push(`/originations/${item.originationId}`)
        break
      }
      case 'broker_infos': {
        item.brokerInfoId && history.push(`/broker_infos/${item.brokerInfoId}`)
        break
      }
      case 'series_formd_infos': {
        item.seriesId && history.push(`/series_formd_infos/${item.seriesId}`)
        break
      }
      case 'series_blue_sky_fees': {
        if (item.blueSkyFeeId) {
          item.blueSkyFeeId &&
            history.push(`/series_blue_sky_fees/${item.blueSkyFeeId}`)
        } else {
          history.push(
            `/series_blue_sky_fees/-1?seriesId=${item?.seriesId}&state=${item?.state}&efdid=${item?.efdid}&firstSaleDate=${item?.firstSaleDate}&totalSold=${item?.totalAmountSold}&totalInvestors=${item?.totalInvestors}&seriesName=${item?.seriesName}`,
          )
        }
        break
      }
      case 'faqs': {
        item.faqId && history.push(`/faqs/${item.faqId}`)
        break
      }
      case 'ria_firms': {
        item.riaFirmId && history.push(`/ria_firms/${item.riaFirmId}`)
        break
      }
      case 'cash_external_accounts': {
        item.cashExternalAccountId &&
          history.push(`/cash_external_accounts/${item.cashExternalAccountId}`)
        break
      }
      case 'cash_accounts': {
        item.cashAccountId &&
          history.push(`/cash_accounts/${item.cashAccountId}`)
        break
      }
      case 'sell_orders': {
        item.sellOrderId && history.push(`/sell_orders/${item.sellOrderId}`)
        break
      }
      case 'sell_offers': {
        item.offerId && history.push(`/sell_offers/${item.offerId}`)
        break
      }
      case 'cash_payment_orders': {
        item.cashPaymentOrderId &&
          history.push(`/cash_payment_orders/${item.cashPaymentOrderId}`)
        break
      }
      case 'uphold_accesses': {
        item.upholdAccessId &&
          history.push(`/uphold_accesses/${item.upholdAccessId}`)
        break
      }
      case 'company_ipo_datas': {
        item.companyIpoDataId &&
          history.push(`/company_ipo_datas/${item.companyIpoDataId}`)
        break
      }
      case 'user_company_ipo_choices': {
        item.userCompanyIpoChoiceId &&
          history.push(
            `/user_company_ipo_choices/${item.userCompanyIpoChoiceId}`,
          )
        break
      }
      case 'discounts': {
        item.discountId &&
          history.push(
            `/discounts/${item.discountId}`,
          )
        break
      }
      case 'bundles': {
        item.bundleId && history.push(`/bundles/${item.bundleId}`)
        break
      }
      case 'company_messages': {
        item.companyMessageId &&
          history.push(`/company_messages/${item.companyMessageId}`)
        break
      }
      case 'serieses': {
        item.seriesId && history.push(`/serieses/${item.seriesId}`)
        break
      }
      case 'promo_fulfillments': {
        item.promoFulfillmentId && history.push(`/promo_fulfillments/${item.promoFulfillmentId}`)
        break
      }
      case 'custom_deals': {
        item.customDealId && history.push(`/custom_deals/${item.customDealId}`)
        break
      }
    }
  }

  const handlePageChange = async (item: any) => {
    const current = item * length
    const queryParams = query.get('params')
    history.replace(
      `/list?queryName=${queryName}&reportId=${reportId}&current=${item}&start=${current}&length=${length}&params=${encodeURIComponent(
        queryParams,
      )}&visible=${visible}&chartType=${type}`,
    )
    setLoading(true)
    await dispatch(
      getListData({
        queryName,
        found: { queryParams: JSON.stringify(JSON.parse(params)) },
        current: item,
        length: Number(length),
      }),
    )
    setLoading(false)
  }

  const handleLengthChange = async (e: any) => {
    e.preventDefault()
    const length = e.target.value
    const queryParams = query.get('params')
    history.replace(
      `/list?queryName=${queryName}&reportId=${reportId}&current=${0}&start=${0}&length=${length}&params=${encodeURIComponent(
        queryParams,
      )}&visible=${visible}&chartType=${type}`,
    )
    setLoading(true)
    await dispatch(
      getListData({
        queryName,
        found: { queryParams: JSON.stringify(JSON.parse(params)) },
        current: 0,
        length: Number(length),
      }),
    )
    setLoading(false)
  }

  const handleSort = async (item: any) => {
    try {
      const data = JSON.parse(query.get('params'))
      if (Object.keys(item).length !== 0) {
        data[item.column] = {
          ...data[item.column],
          sortAsc:
            item.state === 0 ? undefined : item.state === 'asc' ? true : false,
          sortIndex: item.state === 0 ? undefined : 1,
        }
        for (const prop in data) {
          if (data[prop]?.sortIndex) {
            if (prop !== item?.column) {
              data[prop].sortAsc = undefined
              data[prop].sortIndex = undefined
            }
          }
        }
        if (item.state === 0) {
          setSorter(null)
        }
        history.replace(
          `/list?queryName=${queryName}&reportId=${reportId}&current=${current}&start=${current * length
          }&length=${length}&params=${encodeURIComponent(
            JSON.stringify(data),
          )}&visible=${visible}&chartType=${type}`,
        )
        await dispatch(
          getListData({
            queryName,
            found: { queryParams: JSON.stringify(data) },
            current,
            length: Number(length),
          }),
        )
      }
    } catch (e) {
      console.log('unable to parse query params HANDLE SORT')
    }
  }

  const handleFreeTextSearch = async (
    value: string,
    queryStr: any,
    rId: any,
    params: any,
  ) => {
    setFreeText(value)
    try {
      const data = JSON.parse(params)
      if (data) {
        data.freeTextSearch = value
        setLoading(true)
        await dispatch(
          getListData({
            queryName: queryStr,
            found: { queryParams: JSON.stringify(data) },
            current: 0,
            length: Number(length),
          }),
        )
        history.replace(
          `/list?queryName=${queryStr}&reportId=${rId}&current=${0}&start=${0 * length
          }&length=${data.visualizeReport ? 1000 : length === 1000 ? 10 : length
          }&params=${encodeURIComponent(
            JSON.stringify(data),
          )}&visible=${false}&chartType=${type}`,
        )
        setLoading(false)
        inputRef.current.focus()
      }
    } catch (e) {
      console.log('HANDLE FREE TEXT: UNABLE TO PARSE QUERY PARAMS')
    }
  }

  const debouncedChangeHandler = useDebounceSearch(handleFreeTextSearch, 500)

  const handleChangeSubmit = (e: string) => {
    setFreeText(e)
    const queryParams = query.get('params')
    try {
      const data = JSON.parse(queryParams)
      data.freeTextSearch = e
      debouncedChangeHandler(
        e,
        query.get('queryName'),
        query.get('reportId'),
        query.get('params'),
      )
    } catch (e) {
      console.log('HANDLE FREE TEXT: UNABLE TO PARSE QUERY PARAMS')
    }
  }

  if (JSON.parse(visible)) {
    switch (type) {
      case 'bar': {
        return (
          <PageLayout>
            {open && <Modal open={open} setOpen={() => setOpen(false)} />}
            <Pagination
              open={open}
              setOpen={setOpen}
              current={current}
              count={filtered?.count}
              start={start}
              length={length}
              handlePageChange={handlePageChange}
              handleLengthChange={handleLengthChange}
              inputRef={inputRef}
              freeText={freeText}
              handleChangeSubmit={handleChangeSubmit}
              reportId={reportId}
              isExportAllowed={isExportAllowed}
            />
            <BarChart
              xAxisData={xAxisData}
              yAxis={yAxis}
              yAxisData={yAxisData}
            />
          </PageLayout>
        )
      }
      case 'pie': {
        return (
          <PageLayout>
            {open && <Modal open={open} setOpen={() => setOpen(false)} />}
            <Pagination
              open={open}
              setOpen={setOpen}
              current={current}
              count={filtered?.count}
              start={start}
              length={length}
              handlePageChange={handlePageChange}
              handleLengthChange={handleLengthChange}
              inputRef={inputRef}
              freeText={freeText}
              handleChangeSubmit={handleChangeSubmit}
              reportId={reportId}
              isExportAllowed={isExportAllowed}
            />
            <PieChart
              xAxisData={xAxisData}
              yAxis={yAxis}
              yAxisData={yAxisData}
            />
          </PageLayout>
        )
      }
    }
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      {open && <Modal open={open} setOpen={() => setOpen(false)} />}
      <Pagination
        open={open}
        setOpen={setOpen}
        current={current}
        count={filtered?.count}
        start={start}
        length={length}
        handlePageChange={handlePageChange}
        handleLengthChange={handleLengthChange}
        inputRef={inputRef}
        freeText={freeText}
        handleChangeSubmit={handleChangeSubmit}
        reportId={reportId}
        isExportAllowed={isExportAllowed}
      />
      {createPage[queryName] !== undefined ? (
        <div className="mb-4 d-flex align-items-center justify-content-end">
          <CButton
            className="me-2 btn-primary"
            onClick={() => history.push(`${queryName}/-1`)}
          >
            Create {createPage[queryName]}
          </CButton>
        </div>
      ) : null}
      <Table
        columns={columns}
        items={rows}
        handleClick={(item: any) => handleClick(item)}
        handleSort={(item: any) => handleSort(item)}
        isList
        sorter={sorter}
        setSorter={setSorter}
      />
    </PageLayout>
  )
}

export default List
