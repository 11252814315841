import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import axios from 'axios'
import { formatTableRenderers } from '../utils/formatUtils/utils'

interface UsersState {
  filtered: any
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  loading: boolean
  user: any
  manualKycUser: any
  edited: boolean
  error: boolean
  errorMessage: string
  riaUser: any
  holdingsColumns: any[]
  ordersColumns: any[]
  sellOrderColumns: any[]
  sellOfferColumns: any[]
  userCreditRows: any[]
  userCreditColumns: any[]
  plaidReports: any
  summarizedPlaidReports: any
}

const initialState: UsersState = {
  filtered: { completedTotal: 0, count: 0 },
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [
    {
      reportId: 100,
      isMine: false,
      isPrivate: false,
      name: 'Users With Tags',
      queryName: 'USERS',
      queryParams: JSON.stringify({
        userId: {},
        tagsString: { include: true, filterIsNull: false },
        firstName: {},
        lastName: {},
        email: {},
        completedTotal: {},
      }),
    },
  ],
  loading: false,
  user: {},
  manualKycUser: {
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    idType: '',
    gender: 'UNKNOWN',
    address: {
      country: '',
    },
  },
  edited: false,
  error: false,
  errorMessage: '',
  riaUser: {},
  holdingsColumns: [],
  ordersColumns: [],
  sellOrderColumns: [],
  sellOfferColumns: [],
  userCreditColumns: [],
  userCreditRows: [],
  plaidReports: null,
  summarizedPlaidReports: null
}

export const getUserReports = createAsyncThunk('getUserReports', async () => {
  try {
    const response = await privateAPI.get('/queries/USERS/reports')
    return response.data
  } catch (e) {
    console.log('e', e)
  }
})

export const getUsers = createAsyncThunk('getUsers', async ({ found }: any) => {
  const params = found.queryParams
  try {
    const response = await privateAPI.post('/queries/users', JSON.parse(params))
    return response.data
  } catch (e) {
    console.log('ERROR: ', e)
  }
})

export const getUser = createAsyncThunk('getUser', async ({ id }: any) => {
  try {
    const response = await privateAPI.get(`/queries/users/${id}`)
    return response.data
  } catch (e) {
    console.log('error getting user...', e)
  }
})

export const getPlaidReports = createAsyncThunk('getPlaidReports', async (id: number) => {
  try {
    const response = await privateAPI.get(`/users/${id}/plaidAccreditationReports`)
    return response.data
  } catch (e) {
    console.log('e', e)
  }
})

export const getSummarizedPlaidReports = createAsyncThunk('getSummarizedPlaidReports', async (id: number) => {
  try {
    const response = await privateAPI.get(`/users/${id}/summarizedPlaidAccreditationReports`)
    return response.data
  } catch (e) {
    console.log('e', e)
  }
})

export const getUserOrders = createAsyncThunk(
  'getUserOrders',
  async ({ id }: any) => {
    const data = {
      orderId: {},
      price: {},
      status: {},
      orderCreatedAt: { sortIndex: 2, sortAsc: false },
      sharePrice: {},
      series: {},
      notes: {},
      entityName: {},
      userId: { filterValues: [id] },
      tradeId: {},
    }
    try {
      const response = await privateAPI.post('/queries/orders', data)
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getUserHoldings = createAsyncThunk(
  'getUserHoldings',
  async ({ id }: any) => {
    const data = {
      userId: { include: false, filterValues: [id] },
      transactionId: {},
      companyId: {},
      companyName: {},
      ownerName: {},
      unsoldShares: {},
      unsoldPrice: {},
      boughtPrice: {},
      boughtFees: {},
      boughtShares: {},
      soldPrice: {},
      soldFees: {},
      soldShares: {},
      buyOrderCount: {},
    }
    try {
      const response = await privateAPI.post('/queries/ownerships', data)
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getUserSellOrders = createAsyncThunk(
  'getUserSellOrders',
  async ({ id }: any) => {
    const data = {
      sellOrderId: {},
      userId: { include: false, filterValues: [id] },
      companyName: {},
      ownerName: {},
      shares: {},
      sharePrice: {},
      status: {},
      createdAt: { sortIndex: 2, sortAsc: false },
      notes: {},
      tradeId: {},
      seriesName: {},
    }
    try {
      const response = await privateAPI.post('/queries/sell_orders', data)
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getUserSellOffers = createAsyncThunk(
  'getUserSellOffers',
  async ({ id }: any) => {
    const data = {
      offerId: {},
      companyName: {},
      userId: { include: false, filterValues: [id] },
      shares: {},
      sharePrice: {},
      sharesRemaining: {},
      projectedProceeds: {},
      proceedsRemaining: {},
      computedStatus: {},
      createdAt: { sortIndex: 2, sortAsc: false },
      notes: {},
    }
    try {
      const response = await privateAPI.post('/queries/sell_offers', data)
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getUserTags = createAsyncThunk(
  'getUserTags',
  async ({ userId }: any) => {
    const data = {
      orderId: {},
      userId: { include: false, filterValues: [userId] },
      orderCreatedAt: { sortIndex: 2, sortAsc: false },
      series: {},
      sharePrice: {},
      price: {},
      status: {},
      notes: {},
    }
    try {
      const response = await privateAPI.post('/queries/orders', data)
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const saveUser = createAsyncThunk(
  'saveUser',
  async ({ id, data }: any, { rejectWithValue }) => {
    delete data.trustedContact
    delete data.sectors
    delete data.allSectors

    if (data?.notes?.length) {
      data.notes.forEach((note: any) => delete note.updated)
    }
    data.notes = JSON.stringify(data.notes)

    try {
      await privateAPI.post(`/queries/users/${id}`, data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const deleteUser = createAsyncThunk(
  'deleteUser',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      await privateAPI.delete(`/queries/users/${id}`)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getManualKyc = createAsyncThunk(
  'getManualKyc',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.get(`/queries/USERS/${id}/manualKyc`)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const saveManualKyc = createAsyncThunk(
  'saveManualKyc',
  async ({ id, data }: any) => {
    try {
      const response = await privateAPI.post(
        `/queries/USERS/${id}/manualKyc`,
        data,
      )
      return response.data
    } catch (e) {
      console.log('error getting manual kyc: ', e)
    }
  },
)

export const getRIAFirm = createAsyncThunk(
  'getRIAFirm',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.get(`/queries/ria_firms/${id}`)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getTrustedContact = createAsyncThunk(
  'getTrustedContact',
  async ({ id }: any, { rejectWithValue }) => {
    const data = {
      trustedContactId: {},
      trustedByUserId: { filterValues: [+id] },
      firstName: {},
      lastName: {},
      relationship: {},
      addressId: {},
      phone: {},
      street1: {},
      street2: {},
      city: {},
      state: {},
      zip: {},
      country: {},
    }
    try {
      const response = await privateAPI.post('/queries/TRUSTED_CONTACTS', data)
      return response?.data?.rows?.[0] || {}
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const saveTrustedContact = createAsyncThunk(
  'saveTrustedContact',
  async (data: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(
        `/queries/TRUSTED_CONTACTS/${data?.trustedContactId}`,
        data,
      )
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getAllSectors = createAsyncThunk(
  'getAllSectors',
  async (_, { rejectWithValue }) => {
    const data = {
      sectorId: {},
      name: {},
    }
    try {
      const response = await privateAPI.post('/queries/SECTORS', data)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getUserSectors = createAsyncThunk(
  'getUserSectors',
  async ({ id }: any, { rejectWithValue }) => {
    const data = {
      userId: { filterValues: [+id] },
      sectorId: {},
      interestedIn: { filterValues: [true] },
      sectorName: {},
    }
    try {
      const response = await privateAPI.post('/queries/USER_SECTORS', data)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getUserPurchaseCredits = createAsyncThunk(
  'getUserPurchaseCredits',
  async ({ userId }: any, { rejectWithValue }) => {
    const data = {
      userId: { filterValues: [userId] },
      creditTransactionId: {},
      createdAt: { sortIndex: 2, sortAsc: false },
      sellOrderId: {},
      orderId: {},
      amount: {},
      note: {},
      reason: {},
      expiresAt: {},
    }
    try {
      const response = await privateAPI.post(
        '/queries/credit_transactions',
        data,
      )
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const addUserPurchaseCredit = createAsyncThunk(
  'addUserPurchaseCredit',
  async ({ userId, data: { amount, expiresAt, note } }: any) => {
    try {
      const response = await privateAPI.post(
        '/queries/credit_transactions/-1',
        {
          userId,
          amount: +amount,
          expiresAt,
          note,
        },
      )
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getRIALinkedClients = createAsyncThunk(
  'getRIALinkedClients',
  async ({ id }: any, { rejectWithValue }) => {
    const obj = {
      riaUserId: { filterMin: id, filterMax: id },
      fullName: {},
      userId: {},
      riaUserStatus: {},
    }
    try {
      const response = await privateAPI.post(`/queries/users`, obj)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getParentRIAUser = createAsyncThunk(
  'getParentRIAUser',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.get(`/queries/users/${id}`)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const updateRIAUserStatus = createAsyncThunk(
  'updateRIAUserStatus',
  async ({ userId, ...data }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.post(`/queries/users/${userId}`, data)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getRIAUser = createAsyncThunk(
  'getRIAUser',
  async ({ id }: any) => {
    try {
      const response = await privateAPI.get(`/queries/users/${id}`)
      return response.data
    } catch (e) {
      console.log('error getting user...', e)
    }
  },
)

export const enableUserMfaEmail = createAsyncThunk(
  'enableUserMfaEmail',
  async ({ id }: any) => {
    try {
      const response = await privateAPI.post(`/users/${id}/enableUserMfaEmail`)
      return response.data
    } catch (e) {
      console.log('error getting user...', e)
    }
  },
)

export const resetThrottle = createAsyncThunk(
  'resetThrottle',
  async ({ id, throttle }: any) => {
    try {
      const response = await privateAPI.post(
        `/users/${id}/resetUserThrottling?throttleName=${throttle}`,
      )
      return response.data
    } catch (e) {
      console.log('error resetting throttle...', e)
    }
  },
)

export const getPlaidData = createAsyncThunk(
  'getPlaidData',
  async (id: string) => {
    try {
      const response = await privateAPI.get(`/users/${id}/externalIdVerify`)
      return response.data
    } catch (e) {
      console.log('error getting plaid...', e)
    }
  },
)

export const uploadDocs = createAsyncThunk(
  'uploadDocs',
  async (
    {
      acceptedFile,
      fileName,
      type,
      userId,
      entityId = null,
      cashExternalAccountId = null,
    }: any,
    { rejectWithValue },
  ) => {
    const trimmedString = acceptedFile?.name?.substring(0, 100) || ''
    let apiEndPoint = ''
    switch (type) {
      case 'AI':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/accreditationDocuments`
        break
      case 'FA':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/financialAdvisorDocuments`
        break
      case 'ID':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/kycDocuments`
        break
      case 'TAX':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/taxWithholdingAndReportingDocuments`
        break
      case 'ENTITY_TAX':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/queries/ENTITIES/${entityId}/taxWithholdingAndReportingDocuments`
        break
      case 'INTERNAL_DOCUMENT':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/queries/ENTITIES/${entityId}/internalDocuments`
        break
      case 'RIA_FIRM':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/riaFirms/${userId}/Documents`
        break
      case 'INTERNAL':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/internalDocuments`
        break
      case 'USER_TAX':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/taxDocuments`
        break
      case 'USER_UPLOADED':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/userUploadedDocuments`
        break
      case 'CASH_EXTERNAL_ACCOUNT':
        apiEndPoint = `${process.env.REACT_APP_API_URL}/queries/CASH_EXTERNAL_ACCOUNTS/${cashExternalAccountId}/internalDocuments`
        break
      default:
        apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/accreditationDocuments`
    }
    const data = new FormData()
    data.append('file', acceptedFile)
    data.append('name', trimmedString)
    const config: any = {
      method: 'post',
      url: apiEndPoint,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data,
    }

    try {
      await axios(config)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addReferrer: (state, { payload }) => {
      state.user.referredByUserName = payload.fullName
      state.user.referredByUserId = payload.userId
      state.user.referredByStatus = !payload.value?.includes('FAILED')
        ? 'MANUAL_MATCH_SUCCEEDED'
        : payload.value
    },
    resetError: (state) => {
      state.error = false
      state.errorMessage = ''
    },
    resetUser: (state) => {
      state.user = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserReports.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserReports.fulfilled, (state, { payload }: any) => {
        if (payload) {
          const reports = JSON.parse(JSON.stringify(state.reports))
          const found = reports.find((report: any) => report.reportId === 100)
          state.reports = [...payload, found]
        }
        state.loading = false
      })
      .addCase(getUserReports.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = true
      })
      .addCase(getUsers.fulfilled, (state, { payload }: any) => {
        const { columns, filtered, rows } = payload || {}
        const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
          rows,
          columns,
        )

        state.columns = dataColumns
        state.rows = dataRows
        state.filtered = filtered
        state.loading = false
      })
      .addCase(getUsers.rejected, (state, action) => {
        console.log('rejected: ', action)
        state.loading = false
      })
      .addCase(getUser.fulfilled, (state, { payload }: any) => {
        if (payload) {
          const user = payload?.rows[0] || {}
          const { cashAccounts } = user || {}
          state.columns = payload.columns
          state.user = {
            ...state.user,
            ...user,
            riaFirmId: user?.riaFirmId || null,
            riaFirmName: user?.riaFirmName || null,
            accreditedFinraCrdNumber: user?.accreditedFinraCrdNumber || null,
            foreignTaxId: user?.foreignTaxId || null,
            taxId: user?.taxId || null,
            phone: user?.phone || null,
            preferredCustomerAt: user?.preferredCustomerAt || null,
            cashAccounts: cashAccounts?.sort(
              (a: any, b: any) => b.cashAccountId - a.cashAccountId,
            ),
            notes: user?.notes ? JSON.parse(user?.notes) : [],
          }
        }
      })
      .addCase(getUser.rejected, (state, action) => {
        console.log('rejected: ', action)
      })
      .addCase(deleteUser.rejected, (state) => {
        state.error = true
        state.errorMessage =
          'User cannot be deleted. One or more orders or cash accounts is in an active state.'
      })
      .addCase(uploadDocs.pending, (state) => {
        state.loading = true
      })
      .addCase(uploadDocs.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(uploadDocs.rejected, (state, action) => {
        state.loading = false
        state.error = true
        state.errorMessage = 'There was an error saving this document.'
      })
      .addCase(saveUser.pending, (state) => {
        state.loading = true
      })
      .addCase(saveUser.fulfilled, (state) => {
        state.loading = false
        state.edited = false
      })
      .addCase(saveUser.rejected, (state, action) => {
        state.error = true
        state.errorMessage = 'There was an error saving this user.'
      })
      .addCase(getManualKyc.fulfilled, (state, { payload }: any) => {
        state.manualKycUser = { ...payload }
        state.error = false
        state.errorMessage = ''
      })
      .addCase(getManualKyc.rejected, (state, action) => {
        state.error = true
        state.errorMessage = 'Request failed with status code 403.'
      })
      .addCase(getRIAFirm.fulfilled, (state, { payload }: any) => {
        state.user = {
          ...state.user,
          riaFirmId: payload?.rows[0]?.riaFirmId,
          riaFirmName: payload.rows[0]?.name,
        }
      })
      .addCase(getRIAFirm.rejected, (state, action) => {
        state.user = { ...state.user, riaFirmId: null, riaFirmName: null }
      })
      .addCase(getUserPurchaseCredits.fulfilled, (state, { payload }) => {
        const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
          payload?.rows,
          payload?.columns,
        )
        dataRows.map(
          (row: any) =>
            (row.note = row?.note?.length ? JSON.parse(row.note) : []),
        )
        dataColumns.map((col: any) => {
          if (col.label === 'Note') {
            col.renderer = 'Notes'
          }
          return col
        })
        state.userCreditRows = dataRows
        state.userCreditColumns = dataColumns
      })
      .addCase(getRIALinkedClients.fulfilled, (state, { payload }: any) => {
        state.user = {
          ...state.user,
          linkedClients: payload?.rows || [],
        }
      })
      .addCase(getParentRIAUser.fulfilled, (state, { payload }: any) => {
        state.user = {
          ...state.user,
          parentUser: payload?.rows[0] || {},
        }
      })
      .addCase(getRIAUser.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.riaUser = payload.rows[0]
        }
      })
      .addCase(getUserHoldings.fulfilled, (state, { payload }: any) => {
        if (payload) {
          const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
            payload?.rows,
            payload?.columns,
          )
          state.user = { ...state.user, holdings: dataRows }
          state.holdingsColumns = dataColumns || []
        }
      })
      .addCase(getUserSellOrders.fulfilled, (state, { payload }: any) => {
        if (payload) {
          const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
            payload?.rows,
            payload?.columns,
          )
          state.user = { ...state.user, sellOrders: dataRows }
          state.sellOrderColumns = dataColumns || []
        }
      })
      .addCase(getUserOrders.fulfilled, (state, { payload }: any) => {
        if (payload && payload?.rows) {
          const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
            payload?.rows,
            payload?.columns,
          )
          state.user = { ...state.user, orders: dataRows }
          state.ordersColumns = dataColumns || []
        }
      })
      .addCase(getUserSellOffers.fulfilled, (state, { payload }: any) => {
        if (payload) {
          const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
            payload?.rows,
            payload?.columns,
          )
          state.user = { ...state.user, offers: dataRows }
          state.sellOfferColumns = dataColumns || []
        }
      })
      .addCase(getTrustedContact.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.user = {
            ...state.user,
            trustedContact: payload,
          }
        }
      })
      .addCase(getAllSectors.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.user = {
            ...state.user,
            allSectors: payload.rows || [],
          }
        }
      })
      .addCase(getUserSectors.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.user = {
            ...state.user,
            sectors: payload.rows || [],
          }
        }
      })
      .addCase(getPlaidData.fulfilled, (state, { payload }: any) => {
        if (payload && payload?.identity_verifications) {
          const FAILED_IDV = payload.identity_verifications?.filter(
            (idv: any) => idv?.status === 'failed',
          )
          const SUCCESS_IDV = payload.identity_verifications?.filter(
            (idv: any) => idv?.status === 'success',
          )
          state.user.plaidData = {
            'Failed ID Verifications': FAILED_IDV,
            'Success ID Verifications': SUCCESS_IDV,
          }
        }
      })
      .addCase(getPlaidReports.fulfilled, (state, { payload }: any) => {
        state.plaidReports = payload
      })
      .addCase(getSummarizedPlaidReports.fulfilled, (state, { payload }: any) => {
        state.summarizedPlaidReports = payload
      })
  },
})

export const { addReferrer, resetError, resetUser } = usersSlice.actions

export default usersSlice.reducer
