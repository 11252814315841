import { CFormInput, CFormLabel, CLoadingButton, CButton } from '@coreui/react-pro'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Select from './components/Select'
import { components } from 'react-select'
import { debounce } from 'lodash'
import { useAppDispatch } from '../../features/hooks'
import { getCompanies } from '../../features/companiesSlice'
import { AssetFormValues } from './asset-types'

interface AssetDetailFormProps {
  onSubmit: any
  asset: AssetFormValues
  loading: boolean
  header: string
  onAddSharesToPool: any
}

type SelectValue = {
  id: string
  value: string
  label: string
}

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}

const AssetDetailForm: React.FC<AssetDetailFormProps> = ({
  onSubmit,
  asset,
  loading,
  header,
  onAddSharesToPool
}: AssetDetailFormProps) => {
  const [isAddSharesDisabled, setIsAddSharesDisabled] = useState(false);
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    setValue,
    watch,
  } = useFormContext()

  const fetchData = useCallback(
    async (e: string) => {
      const data = {
        queryParams: JSON.stringify({
          companyId: {},
          name: {},
          freeTextSearch: e,
          length: 10,
        }),
      }
      if (e.length > 0) {
        const response = await dispatch(getCompanies({ found: data }))
        const arr = response.payload?.rows || []
        if (arr && arr.length) {
          const a = arr.map((a: { companyId: string; name: string }) => {
            return {
              id: a.companyId,
              value: a.name,
              label: a.name,
            }
          })
          return a
        }
      }
    },
    [dispatch],
  )

  const loadSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchData(inputValue).then((options: SelectValue) => callback(options))
      }, 500),
    [fetchData],
  )

  const handleChange = (value: SelectValue) => {
    if (value) {
      setValue('companyName', value.value, { shouldDirty: true })
      setValue('companyId', value.id, { shouldDirty: true })
    }
  }

  const handleAddSharesClick = () => {
    setIsAddSharesDisabled(true)
    onAddSharesToPool()
  }

  const {
    companyId,
    commissionPercent,
    name,
    costPerShare,
    shares,
    totalCost,
    sharesAvailable,
    companyName,
    action_AddSharesToPool
  } = asset || {}

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between">
        <h3>{header}</h3>
        <div className="d-flex">
          <CLoadingButton
            disabled={!isDirty}
            loading={loading}
            className="btn-success mx-2"
            type="submit"
          >
            Save
          </CLoadingButton>
        </div>
      </div>
      <h4>Asset Information</h4>
      <div className="row g-3 mb-4">
        <div className="col-md-4">
          <CFormLabel className="form-label">Company</CFormLabel>
          <div className="d-flex flex-row">
            <Select
              options={Option}
              loadSuggestedOptions={loadSuggestedOptions}
              handleChange={(value: SelectValue) => handleChange(value)}
              watchValue={watch('companyName') ?? companyName}
              query="companies"
              id={watch('companyId') ?? companyId}
              readOnly={loading}
            />
          </div>
          {errors.companyName && (
            <span className="text-danger">{errors.companyName.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Shares</CFormLabel>
          <CFormInput
            type="number"
            disabled={loading}
            {...register('shares', {
              required: 'This field is required.',
            })}
            defaultValue={shares || ''}
          />
          {errors.shares && (
            <span className="text-danger">{errors.shares.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Commission Percent</CFormLabel>
          <CFormInput
            disabled={loading}
            {...register('commissionPercent')}
            defaultValue={commissionPercent || ''}
          />
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col-md-4">
          <CFormLabel className="form-label">Name</CFormLabel>
          <CFormInput
            type="text"
            disabled={loading}
            {...register('name', {
              required: 'This field is required.',
            })}
            defaultValue={name || ''}
          />
          {errors.name && (
            <span className="text-danger">{errors.name.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Cost</CFormLabel>
          <CFormInput
            disabled={loading}
            {...register('totalCost')}
            defaultValue={totalCost || ''}
          />
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Cost Per Share</CFormLabel>
          <CFormInput
            disabled
            {...register('costPerShare')}
            defaultValue={costPerShare || ''}
          />
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Shares Available</CFormLabel>
          <CFormInput
            disabled
            {...register('sharesAvailable')}
            defaultValue={sharesAvailable || ''}
          />
        </div>
        <div className="col-md-4" style={{ marginTop: '47px' }}>
          <CButton
            disabled={isAddSharesDisabled || !action_AddSharesToPool || sharesAvailable <= 0}
            onClick={handleAddSharesClick}>
            Add Shares to Pool
          </CButton>
        </div>
      </div>
    </form>
  )
}

export default AssetDetailForm
