import { CButton } from '@coreui/react-pro'
import React from 'react'
import { toCamalCase } from '../../utils/formatUtils/utils'
interface LinkedClientProps {
  userId: number
  fullName: string
  riaUserStatus: string
  unlinkUser: any
  type: string
}

const LinkedClient: React.FC<LinkedClientProps> = ({
  userId,
  fullName,
  unlinkUser,
  type,
  riaUserStatus
}: LinkedClientProps) => {
  return (
    <div className="my-2 col-md-12 d-flex justify-content-between p-1 border-bottom border-primary">
      <a
        href={`/users/${userId}`}
        style={{ cursor: 'pointer', width: '100%' }}
        className="text-decoration-none"
      >
        <div style={{ display: 'flex', gap: '100px' }}>
          <span style={{ minWidth: '150px' }}>{fullName}</span>
          <span>{userId}</span>
          <span>{riaUserStatus && toCamalCase(riaUserStatus)}</span>
        </div>
      </a>
      {type === 'users' && riaUserStatus === 'APPROVED' && <CButton onClick={unlinkUser}>Unlink</CButton>}
    </div>
  )
}

export default LinkedClient
