import { CFormInput, CFormLabel, CFormTextarea } from '@coreui/react-pro'
import { debounce } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import SubmitButton from '../../components/SubmitButton'
import Select from '../assets/components/Select'
import { components } from 'react-select'
import WarningModal from '../../components/WarningModal'
import DeleteButton from '../../components/DeleteButton'
import api from '../../APIs/private'

interface FAQDetailFormProps {
  onSubmit: any
  btnLoading: boolean
  id: string
  faq: any
  deleteFAQ: any
}

type FAQListResponse = {
  faqListId: number
  title: string
}

type FAQQueryParams = {
  faqListId: Record<string, never>
  title: Record<string, never>
  freeTextSearch: string
  length: number
}

type FAQListOption = {
  id: number
  value: string
  label: string
}

const getFAQLists = async (
  data: FAQQueryParams,
): Promise<FAQListResponse[]> => {
  const response = await api.post('/queries/faq_lists', data)
  return response.data?.rows || null
}

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}

const FAQDetailForm: React.FC<FAQDetailFormProps> = ({
  onSubmit,
  btnLoading,
  id,
  faq,
  deleteFAQ,
}: FAQDetailFormProps) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext()

  const fetchData = useCallback(async (e: string) => {
    const data = {
      faqListId: {},
      title: {},
      freeTextSearch: e,
      length: 10,
    }
    if (e.length > 0) {
      const response = await getFAQLists(data)
      if (response && response.length) {
        const options: FAQListOption[] | undefined = response.map(
          (a: FAQListResponse) => {
            return {
              id: a.faqListId,
              value: a.title,
              label: a.title,
            }
          },
        )
        return options
      }
    }
  }, [])

  const loadSuggestedOptions = useMemo(
    () =>
      debounce((inputValue: string, callback) => {
        fetchData(inputValue).then((options: FAQListOption[] | undefined) =>
          callback(options),
        )
      }, 500),
    [fetchData],
  )

  const handleChange = (value: any) => {
    if (value) {
      setValue(
        'data',
        { id: value.id, label: value.value, value: value.value },
        { shouldDirty: true },
      )
      setValue('title', value.value, { shouldDirty: true })
      setValue('faqListId', value.id, { shouldDirty: true })
    }
  }

  const { faqId, question } = faq || {}

  let title = ''
  let deleteBtn: JSX.Element | null = null

  if (+id === -1) {
    title = 'Create FAQ'
  } else {
    deleteBtn = (
      <>
        <DeleteButton text="Delete" handleClick={() => setDeleteModal(true)} />
      </>
    )
    title = `FAQ No. ${faqId}`
  }

  return (
    <>
      {deleteModal && (
        <WarningModal
          header={`Delete FAQ: "${question}"?`}
          body="Performing this action will permanently erase this FAQ."
          proceed="Yes"
          abandon="No"
          proceedAction={deleteFAQ}
          abandonAction={() => setDeleteModal(false)}
          open={deleteModal}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between">
          <h3>{title}</h3>
          <div>
            {deleteBtn}
            <SubmitButton loading={btnLoading} text="Save" />
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <div>
              <CFormLabel className="form-label">FAQ List</CFormLabel>
              <Select
                options={Option}
                loadSuggestedOptions={loadSuggestedOptions}
                handleChange={handleChange}
                watchValue={watch('title')}
                query="faq_lists"
                id={watch('faqListId')}
                readOnly={btnLoading}
              />
            </div>
            <div className="my-4">
              <CFormLabel>Order Index</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                {...register('orderIndex', {
                  required: 'This field is required',
                })}
                type="number"
              />
              {errors.orderIndex && (
                <span className="text-danger">{errors.orderIndex.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-8">
            <CFormLabel className="form-label">Question</CFormLabel>
            <CFormTextarea
              disabled={btnLoading}
              rows={5}
              cols={100}
              {...register('question', { required: 'This field is required.' })}
            />
            {errors.question && (
              <span className="text-danger">{errors.question.message}</span>
            )}
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div>
            <CFormLabel className="form-label">Answer</CFormLabel>
            <CFormTextarea
              disabled={btnLoading}
              rows={10}
              {...register('answer', { required: 'This field is required.' })}
            />
            {errors.answer && (
              <span className="text-danger">{errors.answer.message}</span>
            )}
          </div>
        </div>
      </form>
    </>
  )
}

export default FAQDetailForm
