import { CFormInput, CFormLabel, CLoadingButton, CFormSelect } from '@coreui/react-pro'
import React, { useCallback, useMemo, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import Select from './components/Select'
import { components } from 'react-select'
import { debounce } from 'lodash'
import { CustomDealsValues } from './customDeals-types'
import { getDataList } from '../../utils/commonActions'
import { useQuery } from '@tanstack/react-query'
import privateAPI from '../../APIs/private'
import {
  formatToPT,
} from '../../utils/formatUtils/utils'
import Document from '../../components/Document'
import { fastUserSearchQuery } from '../../utils/queryUtils/utils'

interface CustomDealDetailFormProps {
  onSubmit: any
  customDeal: CustomDealsValues
  loading: boolean
  header: string
}

type SelectValue = {
  id: string
  value: string
  label: string
}

interface Company {
  id: number;
  name: string;
}

interface User {
  id: number;
  fullName: string;
}

interface Entity {
  id: number;
  name: string;
}

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}
const getCompany = async (id: string) => {
  const response = await privateAPI.get(`/queries/companies/${id}`)
  return response?.data?.rows?.[0] || {}
}
const getUser = async (id: string) => {
  const response = await privateAPI.post(`/queries/users`, {userId: { filterValues: [id] }, fullName: {}})
  return response?.data?.rows?.[0] || {}
}
const getEntity = async (id: string) => {
  const response = await privateAPI.get(`/queries/entities/${id}`)
  return response?.data?.rows?.[0] || {}
}
const CustomDealDetailForm: React.FC<CustomDealDetailFormProps> = ({
  onSubmit,
  customDeal,
  loading,
  header,
}: CustomDealDetailFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    setValue,
    watch,
    reset
  } = useFormContext()
  const {
    customDealId,
    companyId,
    buyerUserId,
    buyerEntityId,
    totalPrice,
    shares,
    sharePrice,
    status,
    createdByUserId,
    approvedByUserId,
    approvedAt,
    createdAt,
    documents
  } = customDeal || {}
  const watchShares = watch('shares')
  const watchSharePrice = watch('sharePrice')
  const fetchCompanyData = useCallback(
    async (e: string) => {
      const data = {
          companyId: {},
          name: {},
          freeTextSearch: e,
          length: 10
      }
      if (e.length > 0) {
        const response = await getDataList('companies', data)
        const arr = response || []
        if (arr && arr.length) {
          const a = arr.map((a: { companyId: string; name: string }) => {
            return {
              id: a.companyId,
              value: a.name,
              label: a.name,
            }
          })
          return a
        }
      }
    },
    [],
  )
  const fetchUserData = useCallback(
    async (e: string) => {
      const data = {...fastUserSearchQuery, freeTextSearch: e}
      if (e.length > 0) {
        const response = await getDataList('users', data)
        const arr = response || []
        if (arr && arr.length) {
          const a = arr.map((a: { userId: string; fullName: string }) => {
            return {
              id: a.userId,
              value: a.fullName,
              label: a.fullName,
            }
          })
          return a
        }
      }
    },
    [],
  )
  const fetchEntitiesData = useCallback(
    async (e: string) => {
      const data = {
        name: {},
        entityId: {},
        freeTextSearch: e,
        length: 10,
        userId: {}
      }
      if (customDeal.buyerUserId) {
        data.userId = { filterValues: [customDeal.buyerUserId] }
      }
      if (e.length > 0) {
        const response = await getDataList('entities', data)
        const arr = response || []
        if (arr && arr.length) {
          const a = arr.map((a: { entityId: string; name: string }) => {
            return {
              id: a.entityId,
              value: a.name,
              label: a.name,
            }
          })
          return a
        }
      }
    },
    [],
  )

  const loadCompanySuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchCompanyData(inputValue).then((options: SelectValue) => callback(options))
      }, 500),
    [fetchCompanyData],
  )
  const loadUserSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchUserData(inputValue).then((options: SelectValue) => callback(options))
      }, 500),
    [fetchUserData],
  )
  const loadEntitySuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchEntitiesData(inputValue).then((options: SelectValue) => callback(options))
      }, 500),
    [fetchEntitiesData],
  )

  const handleChange = (value: SelectValue, type: string) => {
    if (value) {
      if (type === 'company') {
        setValue('companyName', value.value, { shouldDirty: true })
        setValue('companyId', value.id, { shouldDirty: true })
      }
      if (type === 'user') {
        setValue('buyerUserName', value.value, { shouldDirty: true })
        setValue('buyerUserId', value.id, { shouldDirty: true })
      }
      if (type === 'entity') {
        setValue('buyerEntityName', value.value, { shouldDirty: true })
        setValue('buyerEntityId', value.id, { shouldDirty: true })
      }
    }
  }
  
  // company data
  const { data: companyData, isInitialLoading: companyLoading } = useQuery<Company>({
    queryKey: ['custom_deals_companies', companyId],
    queryFn: () => getCompany(companyId?.toString() || ''),
    enabled: !!companyId,
  })

  const company = useMemo(() => companyData, [companyData])

  // user data
  const { data: userData, isInitialLoading: userLoading } = useQuery<User>({
    queryKey: ['custom_deals_users', buyerUserId],
    queryFn: () => getUser(buyerUserId?.toString() || ''),
    enabled: !!buyerUserId,
  })

  const user = useMemo(() => userData, [userData])

  // entity data
  const { data: entityData, isInitialLoading: entityLoading } = useQuery<Entity>({
    queryKey: ['custom_deals_entities', buyerEntityId],
    queryFn: () => getEntity(buyerEntityId?.toString() || ''),
    enabled: !!buyerEntityId,
  })

  const entity = useMemo(() => entityData, [entityData])

   // approved user data
   const { data: approvedUserData } = useQuery<User>({
    queryKey: ['custom_deals_approved_by_user', approvedByUserId],
    queryFn: () => getUser(approvedByUserId?.toString() || ''),
    enabled: !!approvedByUserId,
  })

  const approvedByUser = useMemo(() => approvedUserData, [approvedUserData])

  
   // created user data
   const { data: createdUserData } = useQuery<User>({
    queryKey: ['custom_deals_created_by_user', createdByUserId],
    queryFn: () => getUser(createdByUserId?.toString() || ''),
    enabled: !!createdByUserId,
  })

  const createdByUser = useMemo(() => createdUserData, [createdUserData])
  
  
  // reset form data when company, user and entity data is loaded
  useEffect(() => reset({ ...customDeal, companyName: company?.name, buyerUserName: user?.fullName, buyerEntityName: entity?.name, approvedByUserName: approvedByUser?.fullName, createdByUserName: createdByUser?.fullName }), [customDeal, company, user, entity, approvedByUser, createdByUser, reset])
  
  useEffect(() => {
    if (watchShares || watchSharePrice) {
      const totalPrice = (parseFloat(watchShares) * parseFloat(watchSharePrice)).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })
      if (totalPrice !== 'NaN') {
        setValue('totalPrice', totalPrice)
      } else {
        setValue('totalPrice', '')
      }
    }
  }, [watchShares, watchSharePrice, setValue, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between">
        <h3>{header}</h3>
        <div className="d-flex">
          <CLoadingButton
            disabled={!isDirty}
            loading={loading}
            className="btn-success mx-2"
            type="submit"
          >
            Save
          </CLoadingButton>
        </div>
      </div>
      <h4>Custom Deal Information</h4>
      <div className="row g-3 mb-4">
        <div className="col-md-4">
          <CFormLabel className="form-label">Company</CFormLabel>
          <div className="d-flex flex-row">
            <Select
              options={Option}
              loadSuggestedOptions={loadCompanySuggestedOptions}
              handleChange={(value: SelectValue) => handleChange(value, 'company')}
              watchValue={watch('companyName')}
              query="companies"
              id={watch('companyId') ?? companyId}
              readOnly={companyLoading || customDealId}
              {...register('companyId', { required: 'This field is required' })}
            />
          </div>
          {errors.companyId && (
            <span className="text-danger">{errors.companyId.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Buyer</CFormLabel>
          <div className="d-flex flex-row">
            <Select
              options={Option}
              loadSuggestedOptions={loadUserSuggestedOptions}
              handleChange={(value: SelectValue) => handleChange(value, 'user')}
              watchValue={watch('buyerUserName')}
              query="users"
              id={watch('buyerUserId') ?? buyerUserId}
              readOnly={userLoading || customDealId}
              {...register('buyerUserId', { required: 'This field is required' })}
            />
          </div>
          {errors.buyerUserId && (
            <span className="text-danger">{errors.buyerUserId.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <CFormLabel className="form-label">Buyer Entity</CFormLabel>
          <div className="d-flex flex-row">
            <Select
              options={Option}
              loadSuggestedOptions={loadEntitySuggestedOptions}
              handleChange={(value: SelectValue) => handleChange(value, 'entity')}
              watchValue={watch('buyerEntityName')}
              query="entities"
              id={watch('buyerEntityId') ?? buyerEntityId}
              readOnly={entityLoading || customDealId}
              {...register('buyerEntityId')}
            />
          </div>
        </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">Shares</CFormLabel>
          <CFormInput
            type="number"
            step="1"
            disabled={loading || !!customDealId}
            {...register('shares', {
              required: 'This field is required.',
            })}
            defaultValue={shares || ''}
          />
          {errors.shares && (
            <span className="text-danger">{errors.shares.message}</span>
          )}
        </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">Share Price</CFormLabel>
          <CFormInput
            type="number"
            step="0.01"
            {...register('sharePrice', {
              required: 'This field is required.'
            })}
            defaultValue={sharePrice || ''}
            disabled={loading || !!customDealId}
          />
        </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">Total Price</CFormLabel>
          <CFormInput
            type="string"
            {...register('totalPrice')}
            defaultValue={totalPrice || ''}
            disabled={true}
          />
          {errors.totalPrice && (
            <span className="text-danger">{errors.totalPrice.message}</span>
          )}
        </div>
        <div className="col-md-3">
              <CFormLabel>Status</CFormLabel>
              <CFormSelect
                disabled={
                  loading ||
                  (status !== 'CREATED')
                }
                defaultValue={status || 'CREATED'}
                {...register('status')}
                name="status"
              >
                <option value="CREATED" key="CREATED">
                Created
                </option>
                <option value="APPROVED" key="APPROVED">
                Approved
                </option>
                <option value="DECLINED" key="DECLINED">
                Declined
                </option>
              </CFormSelect>
              {errors.status && (
                <span className="text-danger">{errors.status.message}</span>
              )}
            </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">Created By UserId</CFormLabel>
          <div className="d-flex flex-row">
            <Select
              watchValue={watch('createdByUserName')}
              query="users"
              id={watch('createdByUserId') ?? createdByUserId}
              readOnly={true}
              {...register('createdByUserId')}
            />
          </div>
        </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">Approved By UserId</CFormLabel>
          <Select
              watchValue={watch('approvedByUserName')}
              query="users"
              id={watch('approvedByUserId') ?? approvedByUserId}
              readOnly={true}
              {...register('approvedByUserId')}
            />
        </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">Approved At</CFormLabel>
          <CFormInput
            disabled={true}
            value={formatToPT(approvedAt, 'MM/DD/YY') || ''}
          />
        </div>
        <div className="col-md-3">
          <CFormLabel className="form-label">Created At</CFormLabel>
          <CFormInput
            disabled={true}
            value={formatToPT(createdAt, 'MM/DD/YY') || ''}
          />
        </div>
      </div>
      {documents && <h4>Document</h4>}
      <div className="row g-3 mb-4 d-flex align-items-center">
        <div className="col-mb-6 w-50">
          {documents?.map(
            ({ name, url, createdAt }: any, i: number) => (
              <Document key={i} name={name} url={url} createdAt={createdAt} loading={loading} />
            ),
          )}
        </div>
      </div>
    </form>
  )
}

export default CustomDealDetailForm
