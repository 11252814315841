import { CLoadingButton } from '@coreui/react-pro'
import React from 'react'

type DeleteButtonProps = {
  text: string
  handleClick: () => any
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  text,
  handleClick,
}: DeleteButtonProps) => {
  return (
    <CLoadingButton className="btn-danger" onClick={handleClick}>
      {text}
    </CLoadingButton>
  )
}

export default DeleteButton
