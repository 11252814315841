import { CModal, CModalBody, CModalHeader } from '@coreui/react-pro'
import React from 'react'
import { useAppSelector } from '../features/hooks'
import { RootState } from '../store'

const GlobalErrorModal: React.FC = () => {
  const { globalErrorMessage } = useAppSelector(
    (state: RootState) => state.common,
  )
  return (
    <CModal visible alignment="center">
      <CModalHeader>Error</CModalHeader>
      <CModalBody>{globalErrorMessage} </CModalBody>
    </CModal>
  )
}

export default GlobalErrorModal
