import { CLoadingButton } from '@coreui/react-pro'
import React from 'react'
import { useFormContext, useFormState } from 'react-hook-form'

type SubmitButtonProps = {
  loading: boolean
  text: string
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  loading,
  text,
}: SubmitButtonProps) => {
  const { dirtyFields } = useFormState()
  const {
    formState: { isDirty },
  } = useFormContext()
  return (
    <CLoadingButton
      disabled={!isDirty || Object.keys(dirtyFields).length === 0 || loading}
      loading={loading}
      className="btn-success mx-2"
      type="submit"
    >
      {text}
    </CLoadingButton>
  )
}

export default SubmitButton
