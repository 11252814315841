import React, { useCallback, useMemo } from 'react'
import {
  CButton,
  CFormLabel,
  CLoadingButton,
  CModal, CModalBody, CModalFooter, CModalHeader
} from '@coreui/react-pro'
import Select from '../../assets/components/Select'
import { components } from 'react-select'
import { debounce } from 'lodash'
import { useAppDispatch } from '../../../features/hooks'
import { getCompanies } from '../../../features/companiesSlice'
import { FormProvider, useForm } from 'react-hook-form'
import privateAPI from '../../../APIs/private'
import { useMutation } from '@tanstack/react-query'

interface DiscountCompanyModalProps {
  header: string
  open: boolean
  discountId: string
  discountCompany?: any
  abandonAction: (arg?: string) => void
}

type SelectValue = {
  id: string
  value: string
  label: string
}

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}

const addDiscountCompany = async (body: any) =>
  await privateAPI.post(`/queries/DISCOUNT_COMPANIES/-1`, body)

const saveDiscountCompany = async (id: string, body: any) =>
  await privateAPI.post(`/queries/DISCOUNT_COMPANIES/${id}`, body)

const DiscountCompanyModal: React.FC<DiscountCompanyModalProps> = ({ header, open, discountId, discountCompany, abandonAction }: DiscountCompanyModalProps) => {
  const dispatch = useAppDispatch()
  const methods = useForm()
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm()

  const fetchData = useCallback(
    async (e: string) => {
      const data = {
        queryParams: JSON.stringify({
          companyId: {},
          name: {},
          freeTextSearch: e,
          length: 10,
        }),
      }
      if (e.length > 0) {
        const response = await dispatch(getCompanies({ found: data }))
        const arr = response.payload?.rows || []
        return arr.map((a: { companyId: string; name: string }) => ({ id: a.companyId, value: a.name, label: a.name }))
      }
    },
    [dispatch],
  )

  const loadSuggestedOptions = useMemo(() =>
    debounce((inputValue, callback) => {
      fetchData(inputValue).then((options: SelectValue) => callback(options))
    }, 500), [fetchData])

  const handleChange = (value: SelectValue) => {
    if (value) {
      setValue('companyName', value.value, { shouldDirty: true })
      setValue('companyId', value.id, { shouldDirty: true })
    }
  }

  const addDiscountMutation = useMutation({
    mutationFn: async (data: any) => await addDiscountCompany(data),
    onSuccess: () => abandonAction('success')
  })

  const saveDiscountMutation = useMutation({
    mutationFn: async (data: any) => await saveDiscountCompany(discountCompany?.discountCompanyId, data),
    onSuccess: () => abandonAction('success')
  })

  const onSubmit = async (data: any) => {
    data.discountId = discountId
    discountCompany ? saveDiscountMutation.mutate(data) : addDiscountMutation.mutate(data)
  }

  return (
    <CModal visible={open} alignment="center" onClose={abandonAction}>
      <CModalHeader>{header}</CModalHeader>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CModalBody>
            <div className="col-md-8">
              <CFormLabel className="form-label">Company</CFormLabel>
              <div className="d-flex flex-row">
                <Select
                  options={Option}
                  loadSuggestedOptions={loadSuggestedOptions}
                  handleChange={(value: SelectValue) => handleChange(value)}
                  watchValue={watch('companyName') || discountCompany?.companyName}
                  query="companies"
                  id={watch('companyId') || discountCompany?.companyId}
                />
              </div>
              {errors.companyName && <span className="text-danger">{errors.companyName.message}</span>}
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton className="btn-danger" onClick={abandonAction}>
              Cancel
            </CButton>
            <CLoadingButton loading={addDiscountMutation.isLoading} type="submit">
              {discountCompany ? 'Update' : 'Add'}
            </CLoadingButton>
          </CModalFooter>
        </form>
      </FormProvider>
    </CModal>
  )
}

export default DiscountCompanyModal
