import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import { RegionDropdown } from 'react-country-region-selector'
import { CFormInput, CFormLabel } from '@coreui/react-pro'
import _ from 'lodash'
import CountryDropdown from '../../../components/CountryDropdown'

const Address: React.FC<any> = ({ loading }: any) => {
  const [sameAddress, setSameAddress] = useState(false)
  const {
    register,
    control,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext()
  const { fields, append } = useFieldArray({
    control,
    name: 'addresses',
  })

  const { user } = useAppSelector((state: RootState) => state.users)

  useEffect(() => {
    reset(user)
    if (user && user.addresses) {
      const primary = _.pick(user.addresses[0], [
        'street1',
        'street2',
        'city',
        'state',
        'country',
        'zip',
      ])
      const mailing = _.pick(user.addresses[1], [
        'street1',
        'street2',
        'city',
        'state',
        'country',
        'zip',
      ])
      if (_.isEqual(primary, mailing)) {
        setSameAddress(true)
      } else {
        setSameAddress(false)
      }
    }
  }, [user, reset])

  useEffect(() => {
    if (!fields || fields.length === 0) {
      append({
        city: '',
        country: '',
        state: '',
        street1: '',
        street2: '',
        type: 'ADDRESS',
        zip: '',
      })
      append({
        city: '',
        country: '',
        state: '',
        street1: '',
        street2: '',
        type: 'MAILING',
        zip: '',
      })
    }
  }, [fields, append])

  const handleChange = (func: any, i: number) => {
    if (!sameAddress) {
      setValue(`addresses.${i}.state`, '')
    } else {
      setValue(`addresses.[0].state`, '')
      setValue(`addresses.[1].state`, '')
    }
  }

  if (sameAddress) {
    return (
      <div className="d-flex">
        <div className="mt-4 w-50 mx-1">
          <h4>Primary Residence Address</h4>
          <div className="row g-3 mb-4">
            <div className="col-md-12">
              <Controller
                control={control}
                name={`addresses.[0].country`}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <CountryDropdown
                    disabled={loading}
                    name="country"
                    label="Country of Residence"
                    ref={null}
                    onChange={(val: string, e: any) =>
                      handleChange(onChange(val, e), 0)
                    }
                    value={value ?? undefined}
                  />
                )}
              />
              {errors?.addresses && errors?.addresses.length
                ? errors?.addresses[0]?.country && (
                    <span className="text-danger">
                      {errors?.addresses[0]?.country.message}
                    </span>
                  )
                : null}
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-6">
              <CFormLabel>Street 1</CFormLabel>
              <CFormInput
                disabled={loading}
                {...register(`addresses.[0].street1`)}
              />
            </div>
            <div className="col-md-6">
              <CFormLabel>Street 2</CFormLabel>
              <CFormInput
                disabled={loading}
                {...register(`addresses.[0].street2`)}
              />
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-6">
              <CFormLabel>City</CFormLabel>
              <CFormInput
                disabled={loading}
                {...register(`addresses.[0].city`)}
              />
            </div>
            <div className="col-md-6">
              <CFormLabel>Postal Code/Zip</CFormLabel>
              <CFormInput
                disabled={loading}
                {...register(`addresses.[0].zip`)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <CFormLabel>State</CFormLabel>
            <Controller
              control={control}
              name={`addresses.[0].state`}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <RegionDropdown
                  country={watch(`addresses.[0].country`)}
                  classes="form-select"
                  onChange={onChange}
                  value={value ?? undefined}
                  disabled={loading}
                />
              )}
            />
            {errors?.addresses && errors?.addresses.length
              ? errors?.addresses[0]?.state && (
                  <span className="text-danger">
                    {errors?.addresses[0]?.state.message}
                  </span>
                )
              : null}
          </div>
        </div>
        <div className="mt-4 w-50 mx-1">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Mailing Address</h4>
            <div className="form-check">
              <label className="form-check-label" htmlFor="field-rain">
                <input
                  disabled={loading}
                  className="form-check-input me-2"
                  {...register('sameAddress')}
                  name="sameAddress"
                  id="field-rain"
                  type="checkbox"
                  onClick={() => setSameAddress(!sameAddress)}
                  defaultChecked={sameAddress}
                />
                Same Address
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      {fields && fields.length
        ? fields.map((address: any, i: number) => (
            <div key={address.id} className="mt-4 mx-1 w-50">
              <div className="d-flex justify-content-between">
                <h4>
                  {address.type === 'ADDRESS' ? 'Primary Residence' : 'Mailing'}{' '}
                  Address
                </h4>
                {address.type === 'MAILING' ? (
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="field-rain">
                      <input
                        disabled={loading}
                        className="form-check-input"
                        {...register('sameAddress')}
                        name="sameAddress"
                        id="field-rain"
                        type="checkbox"
                        onClick={() => setSameAddress(!sameAddress)}
                        defaultChecked={sameAddress}
                      />
                      Same Address
                    </label>
                  </div>
                ) : null}
              </div>
              <div className="row g-3 mb-4">
                <div className="col-md-12">
                  <Controller
                    control={control}
                    name={`addresses.${i}.country`}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CountryDropdown
                        disabled={loading}
                        name={`addresses.${i}.country`}
                        label="Country"
                        ref={null}
                        onChange={(val: string, e: any) =>
                          handleChange(onChange(val, e), i)
                        }
                        value={value}
                      />
                    )}
                  />
                  {errors?.addresses && errors?.addresses.length
                    ? errors?.addresses[i]?.country && (
                        <span className="text-danger">
                          {errors?.addresses[i]?.country.message}
                        </span>
                      )
                    : null}
                </div>
              </div>
              <div className="row g-3 mb-4">
                <div className="col-md-6">
                  <CFormLabel>Street 1</CFormLabel>
                  <CFormInput
                    disabled={loading}
                    {...register(`addresses.${i}.street1`)}
                  />
                </div>
                <div className="col-md-6">
                  <CFormLabel>Street 2</CFormLabel>
                  <CFormInput
                    disabled={loading}
                    {...register(`addresses.${i}.street2`)}
                  />
                </div>
              </div>
              <div className="row g-3 mb-4">
                <div className="col-md-6">
                  <CFormLabel>City</CFormLabel>
                  <CFormInput
                    disabled={loading}
                    {...register(`addresses.${i}.city`)}
                  />
                </div>
                <div className="col-md-6">
                  <CFormLabel>Postal Code/Zip</CFormLabel>
                  <CFormInput
                    disabled={loading}
                    {...register(`addresses.${i}.zip`)}
                  />
                </div>
                <div className="col-md-12">
                  <CFormLabel>State</CFormLabel>
                  <Controller
                    control={control}
                    name={`addresses.${i}.state`}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <RegionDropdown
                        disabled={loading}
                        country={watch(`addresses.${i}.country`)}
                        classes="form-select"
                        onChange={onChange}
                        value={value ?? undefined}
                      />
                    )}
                  />
                  {errors?.addresses && errors?.addresses.length
                    ? errors?.addresses[i]?.state && (
                        <span className="text-danger">
                          {errors?.addresses[i]?.state.message}
                        </span>
                      )
                    : null}
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  )
}

export default Address
