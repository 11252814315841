import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PageLayout from '../../layout/PageLayout'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import privateAPI from '../../APIs/private'
import { useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import SubmitButton from '../../components/SubmitButton'
import { CButton, CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import Select from '../assets/components/Select'
import { formatToPT } from '../../utils/formatUtils/utils'
import Checkbox from '../../components/Checkbox'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import Note from '../../components/Note'
import {
  getCompanyData,
  getUser,
  getBrokerInfo,
  getCashAccount,
} from '../../utils/commonActions'

type ColumnType = {
  label: string
  name: string
  type: string
}

type CompanyIpoDetail = {
  choice: string
  companyId: number
  createdAt: number
  defaultedToLinqto: boolean
  rowCount: number
  status: string
  userCompanyIpoChoiceId: number
  userId: number
  notes: string | undefined
  cashAccountId: number
  brokerInfoId: number
}

type CompanyIpoDetailObject = {
  data: {
    columns: ColumnType[]
    rows: CompanyIpoDetail[]
  }
}

const getUserCompanyIpoChoiceData = async (
  id: string,
): Promise<CompanyIpoDetailObject> => {
  return await privateAPI.get(`/queries/user_company_ipo_choices/${id}`)
}

const saveUserCompanyIpoChoice = async (id: string, data: any) =>
  await privateAPI.post(`/queries/user_company_ipo_choices/${id}`, data)

const UserCompanyIpoChoiceDetail = () => {
  const methods = useForm()
  const { handleSubmit, reset, register, control } = methods
  const queryClient = useQueryClient()
  const me = useAppSelector((state: RootState) => state.user)
  const { id }: any = useParams()
  const [note, setNote] = useState<string>('')
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'notes',
  })

  // user company ipo choice data query
  const {
    data: userCompanyIpoChoiceData,
    isInitialLoading: userCompanyIpoChoiceLoading,
  } = useQuery({
    queryKey: ['user_company_ipo_choices', id],
    queryFn: () => getUserCompanyIpoChoiceData(id),
    enabled: +id > 0,
  })

  const userCompanyIpoChoice: CompanyIpoDetail =
    userCompanyIpoChoiceData?.data.rows?.[0] || {}

  useEffect(() => {
    if (
      userCompanyIpoChoice?.notes &&
      typeof userCompanyIpoChoice.notes === 'string'
    ) {
      userCompanyIpoChoice.notes = JSON.parse(userCompanyIpoChoice.notes)
    }
    reset(userCompanyIpoChoice)
  }, [reset, userCompanyIpoChoice])

  // company query
  const { data: companyData, isInitialLoading: companyDataLoading } = useQuery({
    queryKey: [
      'user_company_ipo_choices_companies',
      userCompanyIpoChoice.companyId,
    ],
    queryFn: () => getCompanyData(userCompanyIpoChoice?.companyId),
    enabled: !!userCompanyIpoChoice?.companyId,
  })

  // broker info query
  const { data: brokerInfoData, isInitialLoading: brokerInfoLoading } =
    useQuery({
      queryKey: [
        'user_company_ipo_choices_broker_infos',
        userCompanyIpoChoice.brokerInfoId,
      ],
      queryFn: () => getBrokerInfo(userCompanyIpoChoice?.brokerInfoId),
      enabled: !!userCompanyIpoChoice?.brokerInfoId,
    })

  // cash account query
  const { data: cashAccountData, isInitialLoading: cashAccountLoading } =
    useQuery({
      queryKey: [
        'user_company_ipo_choices_cash_accounts',
        userCompanyIpoChoice.cashAccountId,
      ],
      queryFn: () => getCashAccount(userCompanyIpoChoice?.cashAccountId),
      enabled: !!userCompanyIpoChoice?.cashAccountId,
    })

  // user query
  const { data: userData, isInitialLoading: userDataLoading } = useQuery({
    queryKey: ['users', userCompanyIpoChoice.userId],
    queryFn: () => getUser(userCompanyIpoChoice?.userId),
    enabled: !!userCompanyIpoChoice?.userId,
  })

  // user company ipo choice mutation
  const saveUserCompanyIpoChoiceMutation = useMutation({
    mutationFn: async (data: any) => {
      if (data.notes && data.notes.length) {
        data.notes.forEach((note: any) => delete note.updated)
      }
      data.notes = JSON.stringify(data.notes)
      await saveUserCompanyIpoChoice(id, data)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['user_company_ipo_choices', id],
      }),
  })

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  const { companyId, createdAt, userId, brokerInfoId, cashAccountId } =
    userCompanyIpoChoice || {}
  const { name } = companyData || {}
  const { fullName } = userData || {}
  const { ownerName } = cashAccountData || {}
  const { equityFirmName } = brokerInfoData || {}
  const loading =
    userCompanyIpoChoiceLoading ||
    companyDataLoading ||
    userDataLoading ||
    brokerInfoLoading ||
    cashAccountLoading

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveUserCompanyIpoChoiceMutation.mutate)}>
          <div className="d-flex justify-content-between">
            <h3>Company IPO Data No. {id}</h3>
            <SubmitButton
              loading={saveUserCompanyIpoChoiceMutation.isLoading}
              text="Save"
            />
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>Created At</CFormLabel>
              <CFormInput disabled value={formatToPT(createdAt, 'MM/DD/YY')} />
            </div>
            <div className="col-md-3">
              <CFormLabel>Choice</CFormLabel>
              <CFormSelect
                {...register('choice')}
                disabled={saveUserCompanyIpoChoiceMutation.isLoading}
              >
                <option value="BROKERAGE">Brokerage</option>
                <option value="CASH">Cash</option>
              </CFormSelect>
            </div>
            <div className="col-md-3">
              <CFormLabel>Status</CFormLabel>
              <CFormSelect
                {...register('status')}
                disabled={saveUserCompanyIpoChoiceMutation.isLoading}
              >
                <option value="PENDING">Pending</option>
                <option value="PROCESSED">Processed</option>
                <option value="NEEDS_REVIEW">Needs Review</option>
              </CFormSelect>
            </div>
            {companyId && (
              <div className="col-md-3">
                <CFormLabel>Company</CFormLabel>
                <Select
                  readOnly
                  watchValue={name}
                  query="companies"
                  id={companyId}
                  ref={null}
                />
              </div>
            )}
            {userId && (
              <div className="col-md-3">
                <CFormLabel>User</CFormLabel>
                <Select
                  readOnly
                  watchValue={fullName}
                  query="users"
                  id={userId}
                  ref={null}
                />
              </div>
            )}
            {brokerInfoId && (
              <div className="col-md-3">
                <CFormLabel>Broker</CFormLabel>
                <Select
                  readOnly
                  watchValue={equityFirmName}
                  query="broker_infos"
                  id={brokerInfoId}
                  ref={null}
                />
              </div>
            )}
            {cashAccountId && (
              <div className="col-md-3">
                <CFormLabel>Cash Account</CFormLabel>
                <Select
                  readOnly
                  watchValue={ownerName}
                  query="cash_accounts"
                  id={cashAccountId}
                  ref={null}
                />
              </div>
            )}
            <div className="col-md-3">
              <Checkbox
                formLabel="Defaulted To Linqto"
                registerValue="defaultedToLinqto"
                btnLoading={saveUserCompanyIpoChoiceMutation.isLoading}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-md-12 d-flex align-items-end justify-content-between">
              <div className="d-flex flex-column w-100 me-2">
                <h4>Notes</h4>
                <CFormInput
                  placeholder="Add note..."
                  disabled={saveUserCompanyIpoChoiceMutation.isLoading}
                  value={note}
                  onChange={(e: any) => setNote(e.target.value)}
                />
              </div>
              <CButton disabled={!note} type="button" onClick={updateNote}>
                Add
              </CButton>
            </div>
            {fields && fields.length
              ? fields.map((note: any, i: number) => (
                  <Note
                    index={note.id}
                    key={note.id}
                    note={note}
                    deleteNote={() => remove(i)}
                  />
                ))
              : null}
          </div>
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default UserCompanyIpoChoiceDetail
