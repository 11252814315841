import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import { formatDecimal, formatTableRenderers } from '../utils/formatUtils/utils'

interface SellOrderState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  sellOrder: any
  error: boolean
  errorMessage: string
  transactions: any[]
  transactionsColumns: any[]
}

const initialState: SellOrderState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  sellOrder: {},
  error: false,
  errorMessage: '',
  transactions: [],
  transactionsColumns: [],
}

export const getSellOrderTransactions = createAsyncThunk(
  'getSellOrderTransactions',
  async ({ id }: any) => {
    const data = {
      companyName: {},
      createdAt: {},
      doneAt: {},
      price: {},
      sharePrice: {},
      shares: {},
      status: {},
      orderId: {},
      sellOrderId: { filterValues: [+id] },
      transactionId: {},
    }
    try {
      const response = await privateAPI.post('/queries/transactions', data)
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getSellOrder = createAsyncThunk(
  'getSellOrder',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.get(`/queries/sell_orders/${id}`)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const saveSellOrder = createAsyncThunk(
  'saveSellOrder',
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/queries/sell_orders/${id}`, data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const sellOrderSlice = createSlice({
  name: 'sellOrders',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = false
      state.errorMessage = ''
    },
    resetSellOrder: (state) => {
      state.sellOrder = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSellOrder.fulfilled, (state, { payload }: any) => {
        const sellOrder = payload?.rows?.[0] || {}
        const buyOrders = sellOrder?.costBasis?.breakdown?.buyOrders || []
        if (buyOrders && buyOrders.length) {
          buyOrders.forEach((order: any) => {
            order.totalAmount = formatDecimal(order.totalAmount)
          })
        }
        state.sellOrder = sellOrder
        try {
          state.sellOrder.notes = JSON.parse(sellOrder.notes)
        } catch (e) {
          state.sellOrder.notes = []
        }
      })
      .addCase(saveSellOrder.rejected, (state) => {
        state.error = true
        state.errorMessage = 'There was an error saving this sellOrder.'
      })
      .addCase(getSellOrderTransactions.fulfilled, (state, { payload }) => {
        const { rows, columns } = payload
        const { rows: dataRows, columns: dataColumns } = formatTableRenderers(
          rows,
          columns,
        )
        state.transactionsColumns = dataColumns
        state.transactions = dataRows
      })
  },
})

export const { resetError, resetSellOrder } = sellOrderSlice.actions

export default sellOrderSlice.reducer
