import React, { useCallback, useEffect, useMemo } from 'react'
import PageLayout from '../../layout/PageLayout'
import { useParams, useHistory } from 'react-router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'
import privateAPI from '../../APIs/private'
import Spinner from '../../components/Spinner'
import { CFormInput, CFormLabel } from '@coreui/react-pro'
import SubmitButton from '../../components/SubmitButton'
import Select from '../assets/components/Select'
import { getCompanyData, getDataList } from '../../utils/commonActions'
import { debounce } from 'lodash'
import { components } from 'react-select'
import { formatToPT } from '../../utils/formatUtils/utils'
import query from '../../hooks/useQuery'

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}

const getBundleCompany = async (id: string) => {
  const response = await privateAPI.get(`/queries/bundle_companies/${id}`)
  return response?.data?.rows?.[0] || {}
}

const saveBundleCompany = async (id: string, body: any) => await privateAPI.post(`/queries/bundle_companies/${id}`, body)

const BundleCompanyDetail: React.FC<any> = () => {
  const { id }: any = useParams()
  const q = query()
  const bundleId = q.get('bundleId')
  const history = useHistory()
  const queryClient = useQueryClient()
  const methods = useForm()
  const { reset, register, handleSubmit, setValue, watch, formState: { errors } } = methods

  // bundle company data
  const { data, isInitialLoading: bundleDataLoading } = useQuery({
    queryKey: ['bundle_company', id],
    queryFn: () => getBundleCompany(id),
    enabled: +id > 0,
  })

  const { companyId, createdAt } = data || {}

  // company data
  const { data: company, isInitialLoading: companyDataLoading } = useQuery({
    queryKey: ['companies', companyId],
    queryFn: () => getCompanyData(companyId),
    enabled: +companyId > 0,
  })

  const bundle = useMemo(() => data, [data])

  // save bundle company
  const saveBundleCompanyMutation = useMutation({
    mutationFn: async (data: any) => await saveBundleCompany(id, { ...data, bundleId }),
    onSuccess: () => {
      // if creating a new bundle company, go back to the list page after saving
      if (isCreateBundleCompany) {
        history.goBack()
      } else {
        // if updating an existing bundle company, invalidate the query to refetch the data
        queryClient.invalidateQueries({ queryKey: ['bundles', id] })
      }
    }
  })

  const fetchCompanyData = useCallback(
    async (e: string) => {
      const data = {
        companyId: {},
        name: {},
        freeTextSearch: e,
        length: 10,
      }

      if (e.length > 0) {
        const response = await getDataList('companies', data)
        if (response && response.length) {
          const options: any[] | undefined = response.map(
            (a: any) => {
              return {
                id: a.companyId,
                value: a.name,
                label: a.name
              }
            },
          )
          return options
        }
      }
    },
    [],
  )

  const loadSegmentSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchCompanyData(inputValue).then((options: any[] | undefined) => callback(options))
      }, 500),
    [fetchCompanyData],
  )

  const handleSegmentChange = (value: any) => {
    if (value) {
      setValue('name', value.value, { shouldDirty: true })
      setValue('companyId', value.id, { shouldDirty: true })
    }
  }

  // handle form submit
  const onSubmit = async (data: any) => saveBundleCompanyMutation.mutate(data)

  // reset form data when bundle and company data is loaded
  useEffect(() => reset({ ...bundle, ...company }), [bundle, company, reset])

  const { isLoading: saveLoading } = saveBundleCompanyMutation

  if (bundleDataLoading || companyDataLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  // check if user is creating a new bundle company or updating an existing one
  const isCreateBundleCompany = +id === -1

  return (
    <>
      <PageLayout>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='d-flex justify-content-between'>
              <h4>{isCreateBundleCompany ? 'Create Bundle Company' : `Bundle Company No. ${id}`}</h4>
              <SubmitButton loading={saveLoading} text={isCreateBundleCompany ? 'Create' : 'Save'} />
            </div>
            <div className="row g-3 mb-4">
              <div className="col-md-3">
                <CFormLabel>Shares</CFormLabel>
                <CFormInput {...register('shares', { required: 'This field is required' })} disabled={saveLoading} />
                {errors.shares && <span className="text-danger">{errors.shares.message}</span>}
              </div>
              <div className="col-md-3">
                <CFormLabel>Total Price</CFormLabel>
                <CFormInput {...register('totalPrice', { required: 'This field is required' })} disabled={saveLoading} />
                {errors.totalPrice && <span className="text-danger">{errors.totalPrice.message}</span>}
              </div>
              <div className="col-md-3">
                <CFormLabel className="form-label">Company</CFormLabel>
                <div className="d-flex flex-row">
                  <Select
                    options={Option}
                    loadSuggestedOptions={loadSegmentSuggestedOptions}
                    handleChange={(value: any) => handleSegmentChange(value)}
                    watchValue={watch('name')}
                    query="companies"
                    id={watch('companyId')}
                    readOnly={saveLoading}
                    {...register('companyId', { required: 'This field is required' })}
                  />
                </div>
                {errors.companyId && <span className="text-danger">{errors.companyId.message}</span>}
              </div>
              <div className="col-md-3">
                <CFormLabel>Created At</CFormLabel>
                <CFormInput value={formatToPT(createdAt, 'MM/DD/YYYY')} disabled />
              </div>
            </div>
          </form>
        </FormProvider>
      </PageLayout>
    </>
  )
}

export default BundleCompanyDetail
