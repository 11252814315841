import { CButton } from '@coreui/react-pro'
import React from 'react'
import { Link } from 'react-router-dom'

interface PublicReportsProps {
  reportId: string
  filter: string
  queryParams: any
  name: string
}

const PublicReport: React.FC<PublicReportsProps> = ({
  reportId,
  filter,
  queryParams,
  name,
}: PublicReportsProps) => {
  return (
    <CButton
      style={{ height: '40px' }}
      key={reportId}
      color="light"
      className="p-0 w-100 mb-2 text-start"
    >
      <Link
        className="d-flex w-100 align-items-center ms-2 h-100 text-decoration-none w-100"
        to={`/list?queryName=${filter}&reportId=${reportId}&current={0}&start=0&params=${encodeURIComponent(
          queryParams,
        )}`}
      >
        {name}
      </Link>
    </CButton>
  )
}

export default PublicReport
