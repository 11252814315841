import {
  CButton,
  CFormLabel,
  CFormCheck,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { uploadDocs } from '../../../utils/commonActions'

interface UploadDocumentModalProps {
  abandonAction: () => void
  userId: string,
  open: boolean
}

const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({
  open,
  abandonAction,
  userId,
}: UploadDocumentModalProps) => {
  const [docType, setDocType] = useState("INTERNAL")

  const uploadDocMutation = useMutation({
    mutationFn: async ({ file, fileType }: any) => {
      await uploadDocs({
        acceptedFile: file?.target?.files?.[0],
        fileName: file?.target?.files?.[0].name,
        type: fileType,
        userId: userId
      })
    },
    onSuccess: () => {
      // Refresh the user page and set documents tab in focus
      window.history.replaceState({}, '', '/users/' + userId + '?tab=Documents')
      window.location.reload()
      abandonAction()
    }
  })

  return (
    <CModal visible={open} alignment="center" onClose={abandonAction} size="sm">
      <CModalHeader className='me-4'>Upload Document</CModalHeader>
      <CModalBody>
        <div className="row g-3 mb-4">
          <CFormLabel>Select document type:</CFormLabel>
          <div className="ms-3">
            <CFormCheck type="radio"
              name="documentType"
              id="INTERNAL"
              label="Internal"
              onChange={(e) => setDocType(e.target.id)}
              defaultChecked
            />
            <CFormCheck type="radio"
              name="documentType"
              id="AI"
              label="Accreditation"
              onChange={(e) => setDocType(e.target.id)}
            />
            <CFormCheck type="radio"
              name="documentType"
              id="FA"
              label="Financial Advisor"
              onChange={(e) => setDocType(e.target.id)}
            />
            <CFormCheck type="radio"
              name="documentType"
              id="TAX"
              label="Tax Withholding and Reporting"
              onChange={(e) => setDocType(e.target.id)}
            />
            <CFormCheck type="radio"
              name="documentType"
              id="USER_TAX"
              label="User Tax"
              onChange={(e) => setDocType(e.target.id)}
            />
            <CFormCheck type="radio"
              name="documentType"
              id="USER_UPLOADED"
              label="User Uploaded"
              onChange={(e) => setDocType(e.target.id)}
            />
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <CFormLabel className="pretty-file ms-2 my-0 bg-primary rounded-3 text-white cursor-pointer text-align-center d-flex align-items-center justify-content-center" style={{ height: '38px', width: '150px' }}>
          {uploadDocMutation.isLoading
            ? 'Uploading...'
            : 'Upload File'}
          <CFormInput
            type="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              uploadDocMutation.mutate({ file: e, fileType: docType })
            }
            accept=".pdf,.png,.jpg,.jpeg"
          />
        </CFormLabel>
      </CModalFooter>
    </CModal>
  )
}

export default UploadDocumentModal
