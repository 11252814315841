import React from 'react'
import Select from 'react-select'

const MultiSelect: any = ({ filters, filter, setFilters }: any) => {
  const handleChange = (value: any) => {
    const arr = [...filters]
    const idx = filters.findIndex((el: any) => el.name === filter.name)
    if (idx !== -1) {
      const data = arr[idx]
      if (data) {
        data.input = [...value]
        setFilters([...arr])
      }
    }
  }

  const data = filter.values.map((val: any, i: number) => {
    if (typeof val === 'string') {
      const obj = {
        label: val,
        value: val,
        selected: false,
      }
      return obj
    } else {
      return val
    }
  })

  const defaults = filter.values.filter((item: any, i: number) => item.selected)

  return (
    <Select
      defaultValue={defaults}
      isMulti
      className="multiselect-input"
      options={data}
      onChange={(e: any) => handleChange(e)}
    />
  )
}

export default MultiSelect
