import {
  CFormInput,
  CFormLabel,
  CInputGroup,
  CLoadingButton,
} from '@coreui/react-pro'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import SubmitButton from '../../components/SubmitButton'
import { formatToPT } from '../../utils/formatUtils/utils'

const TagDetailForm: React.FC<any> = ({
  onSubmit,
  btnLoading,
  id,
  setOpen,
  data,
}: any) => {
  const { handleSubmit, register } = useFormContext()
  const { createdAt } = data || {}
  let form: JSX.Element | null
  let deleteBtn: JSX.Element | null = null

  if (+id === -1) {
    form = (
      <div className="row mb-4">
        <div className="col-md-4">
          <CFormLabel className="form-label">Name</CFormLabel>
          <CFormInput {...register('name')} />
        </div>
      </div>
    )
  } else {
    deleteBtn = (
      <CLoadingButton
        loading={btnLoading}
        className="btn-danger"
        onClick={() => setOpen(true)}
      >
        Delete
      </CLoadingButton>
    )
    form = (
      <div>
        <div className="row mb-4">
          <div className="col-md-4">
            <CFormLabel className="form-label">Name</CFormLabel>
            <CFormInput {...register('name')} disabled />
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Created At </CFormLabel>
            <CInputGroup className="input-group-text">
              {formatToPT(createdAt, 'MMMM Do YYYY')}
            </CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">User Count</CFormLabel>
            <CFormInput {...register('userCount')} disabled />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4">
            <CFormLabel>Update Tag Name</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('newName', {
                required: 'This field is required',
              })}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-end">
          {deleteBtn}
          <SubmitButton loading={btnLoading} text="Save" />
        </div>
        {form}
      </form>
    </>
  )
}

export default TagDetailForm
