import React from 'react'
import { CButton, CFormCheck, CFormLabel, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react-pro'

const FilterDocumentsModal = ({ open, abandonAction, documentFilters, setDocumentFilters, getUserDocuments }: any) => {
  
  const handleCheck = (e: any) => {
    const { id } = e.target
    if (documentFilters.includes(id)) {
      setDocumentFilters(documentFilters.filter((item: any) => item !== id))
    } else {
      setDocumentFilters([...documentFilters, id])
    }
  }

  return (
    <CModal visible={open} alignment="center" onClose={abandonAction} size="sm">
      <CModalHeader className='me-4'>Set Filter Types:</CModalHeader>
      <CModalBody>
        <div style={{ paddingLeft: '20px' }} className="row g-3 mb-4">
          <CFormCheck
            name="documentType"
            className='ms-4'
            id="USER_VIEW"
            label="Accreditation"
            onChange={(e) => handleCheck(e)}
            defaultChecked={documentFilters.includes('USER_VIEW')}
          />
          <CFormCheck
            name="documentType"
            className='ms-4'
            id="FINANCIAL_ADVISOR_VIEW"
            label="Financial Advisor View"
            onChange={(e) => handleCheck(e)}
            defaultChecked={documentFilters.includes('FINANCIAL_ADVISOR_VIEW')}
          />
          <CFormCheck
            name="documentType"
            className='ms-4'
            id="INTERNAL"
            label="Internal"
            onChange={(e) => handleCheck(e)}
            defaultChecked={documentFilters.includes('INTERNAL')}
          />
          <CFormCheck
            name="documentType"
            className='ms-4'
            id="TAX_WITHHOLDING_AND_REPORTING"
            label="Tax Withholding and Reporting"
            onChange={(e) => handleCheck(e)}
            defaultChecked={documentFilters.includes('TAX_WITHHOLDING_AND_REPORTING')}
          />
          <CFormCheck
            name="documentType"
            className='ms-4'
            id="USER_TAX"
            label="User Tax"
            onChange={(e) => handleCheck(e)}
            defaultChecked={documentFilters.includes('USER_TAX')}
          />
          <CFormCheck
            name="documentType"
            className='ms-4'
            id="USER_UPLOADED"
            label="User Uploaded"
            onChange={(e) => handleCheck(e)}
            defaultChecked={documentFilters.includes('USER_UPLOADED')}
          />
          <CFormCheck
            name="documentType"
            className='ms-4'
            id="MONTHLY_STATEMENT"
            label="Monthly Statement"
            onChange={(e) => handleCheck(e)}
            defaultChecked={documentFilters.includes('MONTHLY_STATEMENT')}
            />
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color='light' onClick={abandonAction}>Cancel</CButton>
        <CButton onClick={getUserDocuments}>Submit</CButton>
      </CModalFooter>
    </CModal>
  )
}

export default FilterDocumentsModal