import React from 'react'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CModal, CModalBody, CModalFooter, CModalHeader
} from '@coreui/react-pro'
import { FormProvider, useForm } from 'react-hook-form'
import privateAPI from '../../../APIs/private'
import { useMutation } from '@tanstack/react-query'
import { getNumericDollar } from '../../../utils/formatUtils/utils'

interface DiscountTierModalProps {
  header: string
  open: boolean
  discountId: string
  discountTier: any
  abandonAction: (arg?: string) => void
}

const addDiscountTier = async (body: any) =>
  await privateAPI.post('/queries/DISCOUNT_TIERS/-1', body)

const saveDiscountTier = async (id: string, body: any) =>
  await privateAPI.post(`/queries/DISCOUNT_TIERS/${id}`, body)

const DiscountTierModal: React.FC<DiscountTierModalProps> = ({ header, open, discountId, discountTier, abandonAction }: DiscountTierModalProps) => {
  const methods = useForm()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange'
  })

  const addDiscountTierMutation = useMutation({
    mutationFn: async (data: any) => await addDiscountTier(data),
    onSuccess: () => abandonAction('success')
  })

  const saveDiscountTierMutation = useMutation({
    mutationFn: async (data: any) => await saveDiscountTier(discountTier?.discountTierId, data),
    onSuccess: () => abandonAction('success')
  })

  const onSubmit = async (data: any) => {
    data.discountId = discountId
    discountTier ? saveDiscountTierMutation.mutate(data) : addDiscountTierMutation.mutate(data)
  }

  const { amount, percent, minimumPurchase } = discountTier || {}
  return (
    <CModal visible={open} alignment="center" onClose={abandonAction}>
      <CModalHeader>{header}</CModalHeader>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CModalBody>
            <div className="row g-3 mb-4">
              <div className="col-md-4">
                <CFormLabel className="form-label">Amount</CFormLabel>
                <CFormInput
                  type="number"
                  {...register('amount', {
                    required: 'This field is required.',
                  })}
                  defaultValue={amount ? getNumericDollar(amount) : 0}
                />
                {errors.amount && (
                  <span className="text-danger">{errors.amount.message}</span>
                )}
              </div>
              <div className="col-md-4">
                <CFormLabel className="form-label">Percent</CFormLabel>
                <CFormInput
                  type="number"
                  {...register('percent', {
                    validate: (value: number) =>
                      !!value && value >= 0 && value <= 1 || 'Percent must be between 0 and 1'
                  })}
                  step={0.05}
                  defaultValue={percent || 0}
                />
                {errors.percent && (
                  <span className="text-danger">{errors.percent.message}</span>
                )}
              </div>
              <div className="col-md-4">
                <CFormLabel className="form-label">Minimum Purchase</CFormLabel>
                <CFormInput
                  type="number"
                  {...register('minimumPurchase')}
                  defaultValue={minimumPurchase ? getNumericDollar(minimumPurchase) : 0}
                />
                {errors.minimumPurchase && (
                  <span className="text-danger">{errors.minimumPurchase.message}</span>
                )}
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton className="btn-danger" onClick={abandonAction}>
              Cancel
            </CButton>
            <CLoadingButton loading={false} type="submit">
              {discountTier ? 'Update' : 'Add'}
            </CLoadingButton>
          </CModalFooter>
        </form>
      </FormProvider>
    </CModal>)
}

export default DiscountTierModal
