import {
  CFormInput,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CFormLabel,
} from '@coreui/react-pro'
import React, { Fragment, useEffect, useState } from 'react'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'

interface DeleteUserModalProps {
  handleClose: () => void
  visible: boolean
  deleteUser: (reason: string) => Promise<void>
  btnLoading: boolean
}

const deleteAccountReasons: any = {
  TOO_MANY_EMAIL: 'Too many emails',
  MISSED_PROMOTION: 'I missed out on a promotion',
  INVESTMENT_MINIMUM: 'Investment minimum of $10k is too high',
  NOT_QUALIFIED: "I don't qualify as an accredited investor",
  NO_COMPANIES: 'No companies I want to invest in right now',
  UNABLE_TO_FUND: 'Unable to fund orders with a preferred payment method',
  OTHER: 'Other (Please specify below)',
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  handleClose,
  visible,
  deleteUser,
  btnLoading,
}: DeleteUserModalProps) => {
  const {
    user: { email, deletionReason },
    error,
    errorMessage,
  } = useAppSelector((state: RootState) => state.users)

  const [userEmail, setUserEmail] = useState('')
  const [deleteReason, setDeleteReason] = useState(deletionReason)
  const [otherReason, setOtherReason] = useState('')
  const [isValid, setValid] = useState(false)

  useEffect(() => {
    if (!userEmail || userEmail !== email) {
      setValid(false)
    } else if (
      deleteReason === 'Other (Please specify below)' &&
      !otherReason.length
    ) {
      setValid(false)
    } else if (!deleteReason?.length) {
      setValid(false)
    } else {
      setValid(true)
    }
  }, [deleteReason, otherReason, userEmail, email])

  useEffect(() => {
    if (
      !!deletionReason &&
      !Object.values(deleteAccountReasons).some((r) => r === deletionReason)
    ) {
      setOtherReason(deletionReason)
      setDeleteReason('Other (Please specify below)')
    }
  }, [deletionReason])

  const onSubmit = () => {
    const reason = !!otherReason.length ? otherReason : deleteReason
    deleteUser(reason)
  }

  const bodyCopy: JSX.Element = (
    <>
      <CModalBody>
        <CFormLabel>Select A Delete Reason</CFormLabel>
        {Object.keys(deleteAccountReasons).map((type: string, i: number) => (
          <Fragment key={i}>
            <label className="form-check-label d-flex">
              <input
                type="radio"
                className="form-check-input me-2"
                value={deleteAccountReasons[type]}
                checked={deleteReason === deleteAccountReasons[type]}
                onChange={(e: any) => {
                  setDeleteReason(e.target.value)
                  setOtherReason('')
                }}
              />
              {deleteAccountReasons[type]}
            </label>
          </Fragment>
        ))}
        {deleteReason === 'Other (Please specify below)' && (
          <CFormInput
            className="mt-4"
            type="text"
            placeholder=""
            value={otherReason}
            onChange={(e: any) => setOtherReason(e.target.value)}
          />
        )}
        <br />
        Are you sure you want to delete this user? Please confirm by entering
        this user&apos;s email address: <span className="fw-bold">{email}</span>
        <CFormInput
          className="mt-4"
          type="text"
          placeholder="Email"
          value={userEmail}
          onChange={(e: any) => setUserEmail(e.target.value)}
        />
      </CModalBody>
      <CModalFooter>
        <CLoadingButton
          type="submit"
          className="btn-danger"
          disabled={!isValid}
          loading={btnLoading}
          onClick={onSubmit}
        >
          Confirm
        </CLoadingButton>
      </CModalFooter>
    </>
  )

  if (error && errorMessage) {
    return null
  }

  return (
    <CModal visible={visible} alignment="center" onClose={handleClose}>
      <CModalHeader>Delete User</CModalHeader>
      {bodyCopy}
    </CModal>
  )
}

export default DeleteUserModal
