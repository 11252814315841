import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PageLayout from '../layout/PageLayout'
import { RootState } from '../store'
import Spinner from './Spinner'

const PrivateRoute: any = ({ component: Component, ...rest }: any) => {
  const { isLoggedIn, loading } = useSelector((state: RootState) => state.user)

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
