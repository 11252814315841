import React, { useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { CFormLabel, CInputGroup, CLoadingButton } from '@coreui/react-pro'
import {
  getOriginationCompany,
  saveOriginationCompany,
} from '../../features/originationCompaniesSlice'
import { useAppDispatch, useAppSelector } from '../../features/hooks'

const OriginationCompaniesDetail: React.FC = () => {
  const { id }: any = useParams()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const { originationCompany } = useAppSelector(
    (state: RootState) => state.origination_companies,
  )
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm()

  useEffect(() => {
    const getEntityById = async () => {
      await dispatch(getOriginationCompany({ id }))
      setLoading(false)
    }
    getEntityById()
  }, [id, dispatch])

  useEffect(() => {
    reset(originationCompany)
  }, [originationCompany, reset])

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    await dispatch(
      saveOriginationCompany({ id, data: { ...originationCompany, ...data } }),
    )
    await dispatch(getOriginationCompany({ id }))
    setBtnLoading(false)
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  const {
    originationCompanyId,
    city,
    country,
    dateJoined,
    name,
    url,
    valuation,
    interestedInBuying,
  } = originationCompany

  return (
    <PageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between">
          <h3>
            Origination Company No. {originationCompanyId} - {name}
          </h3>
          <CLoadingButton
            loading={btnLoading}
            className="btn-success"
            type="submit"
            disabled={!isDirty}
          >
            Save
          </CLoadingButton>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <CFormLabel className="form-label">City</CFormLabel>
            <CInputGroup className="input-group-text">{city}</CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Country</CFormLabel>
            <CInputGroup className="input-group-text">{country}</CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Date Joined</CFormLabel>
            <CInputGroup className="input-group-text">{dateJoined}</CInputGroup>
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <CFormLabel className="form-label">Name</CFormLabel>
            <CInputGroup className="input-group-text">{name}</CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">URL</CFormLabel>
            <CInputGroup className="input-group-text">{url}</CInputGroup>
          </div>
          <div className="col-md-4">
            <CFormLabel className="form-label">Valuation</CFormLabel>
            <CInputGroup className="input-group-text">{valuation}</CInputGroup>
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                defaultChecked={interestedInBuying}
                {...register('interestedInBuying')}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Interested In Buying
              </label>
            </div>
          </div>
        </div>
      </form>
    </PageLayout>
  )
}

export default OriginationCompaniesDetail
